/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Logo from "./Logo";
import BackgroundImage from "./BackgroundImage";
import BackScreen from "./BackScreen";

interface CommonBackImgAuthScreensProps {
  tagline: string;
  redirectToHome:Function;
}

const CommonBackImgAuthScreens: React.FC<CommonBackImgAuthScreensProps> = (props) => {
  const {tagline,redirectToHome}=props;
  return (
    <section className="flex relative h-[35%] flex-col items-center px-10 pt-10 pb-20 text-white ">
      <BackScreen redirectToHome={redirectToHome} />
      <BackgroundImage />
      <Logo />
      <p className="relative mt-3 w-[100%] text-sm tracking-wide text-center">
        {tagline}
      </p>
    </section>
  );
};

export default CommonBackImgAuthScreens;
