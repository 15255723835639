import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import AccountStatementDesktop from "../components/Desktop/AccountStatement/AccountStatement";
import { RootState } from "../store";
import { fetchRecords } from "../store/reports/reportsThunk";
import { defaultFilters, Filters } from "../store/reports/reportSelectors";
import { connect } from "react-redux";
import moment from "moment";
import AccountStatement from "../components/Mobile/AccountStatements/AccountStatement/AccountStatement";

interface AccountStatementViewProps {
  records: any;
  fetchRecords: Function;
  handleDateChange: Function;
  filters: any;
  setFilters: any;
  pageToken: string;
}

const AccountStatementView: React.FC<AccountStatementViewProps> = (props) => {
  const { records, fetchRecords, pageToken } = props;
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [nextPageToken, setNextPageToken] = useState<string>(null);

  useEffect(() => {
    fetchRecords(filters);
  }, [filters]);

  const handleDateChange = (newFromDate: Date, newToDate: Date) => {
    setFilters({
      ...filters,
      startDate: moment(newFromDate),
      endDate: moment(newToDate),
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      pageToken: [...filters.pageToken, pageToken],
    });
  };

  const prevPage = () => {
    if (filters.pageToken.length > 0) {
      let pagetokens = filters.pageToken;
      pagetokens.pop();
      setFilters({ ...filters, pageToken: [...pagetokens] });
    }
    setNextPageToken(null);
  };

  return (
    <>
      {isMobile ? (
        <>
          <AccountStatement
            pageToken={pageToken}
            prevPage={prevPage}
            nextPage={nextPage}
            fetchRecords={fetchRecords}
            records={records}
            handleDateChange={handleDateChange}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      ) : (
        <AccountStatementDesktop
          pageToken={pageToken}
          prevPage={prevPage}
          nextPage={nextPage}
          fetchRecords={fetchRecords}
          records={records}
          handleDateChange={handleDateChange}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    records: state.reports.records,
    pageToken: state.reports.pageToken,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchRecords: (event: any) => dispatch(fetchRecords(event)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountStatementView);
