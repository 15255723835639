import { AxiosResponse } from "axios";
import REPORTING_API from "./api-services/reporting-api";
import SVLS_API from "./api-services/svls-api";
import moment from "moment";
import AGPAY_API from "./api-services/feature-api";

export const fetchMyBets = async (filters, pageSize) => {
  try {
    const response: AxiosResponse = await REPORTING_API.get(
      "reports/v2/orders/:search",
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
          Accept: "application/json",
        },
        params: {
          reportType: "ORDER_LIST",
          categoryType:
            filters.status === "unmatched"
              ? "-"
              : filters.selectedGame === "All"
              ? null
              : filters.selectedGame,
          status:
            filters.status === "unmatched"
              ? "Unmatched"
              : filters.status === "All"
              ? null
              : filters.status,
          sportId:
            filters.selectedGame === "SPORTS"
              ? filters.sport == "All"
                ? null
                : filters.sport
              : null,
          sortOrder: "DESC",
          pageSize: pageSize,
          pageToken: filters.pageToken[filters.pageToken.length - 1],
          startDate: filters.startDate.startOf("day").toISOString(),
          endDate: filters.endDate.endOf("day").toISOString(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAccSTmtRecords = async (filters, pageSize) => {
  try {
    const response: any = await REPORTING_API.post(
      "/reports/v2/account-statement-report",
      {
        user: "",
        transactionGroup: "all",
        startDate: filters.startDate.startOf("day").toISOString(),
        endDate: filters.endDate.endOf("day").toISOString(),
        pageSize: pageSize,
        transactionType: filters?.transactionType === "ALL" ? "-1" : filters?.transactionType,
        pageToken: filters.pageToken[filters.pageToken.length - 1],
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
          Accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchTransactionRequestAPI = async (filters, pageSize) => {
  try {
    const response = await AGPAY_API.get("/agpay/v2/transactions/:search", {
      params: {
        startDate: filters.startDate.startOf("day").toISOString(),
        endDate: filters.endDate.endOf("day").toISOString(),
        pageSize: pageSize,
        pageToken: filters.pageToken[filters.pageToken.length - 1],
        status:
          filters.transactionStatus === "ALL" ? "" : filters?.transactionStatus,
        type: filters?.transactionType === "ALL" ? "" : filters?.transactionType,
        isAscending: false,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchPLStatementRecords = async (filters, pageSize) => {
  try {
    const response: any = await REPORTING_API.post(
      "/reports/v2/profit-statement",
      {
        user: "",
        categoryType: "all",
        startDate: filters.startDate.startOf("day").toISOString(),
        endDate: filters.endDate.endOf("day").toISOString(),
        pageSize: pageSize,
        pageToken: filters.pageToken[filters.pageToken.length - 1],
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
        params: {},
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOpenBetsRiskAPI = async (eventId) => {
  try {
    const response = await SVLS_API.get(
      "/reports/v2/risk-management/user-risk",
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
        params: {
          eventId: eventId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOpenBetsRiskMgmtAPI = async (eventIds, marketIds) => {
  try {
    const response = await SVLS_API.get(
      "/reports/v2/risk-management/user-risk",
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
        params: {
          eventId: Array.isArray(eventIds) ? eventIds.join(",") : eventIds,
          marketId: Array.isArray(marketIds) ? marketIds.join(",") : marketIds,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUplineRecordsAPI = async (params) => {
  try {
    const response = await SVLS_API.get("/reports/v2/commission/", {
      headers: {
        Authorization: sessionStorage.getItem("jwt_token"),
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPLByMarketAPI = async (filters, pageSize) => {
  try {
    const response = await REPORTING_API.get("reports/v2/orders/:search", {
      headers: {
        Authorization: sessionStorage.getItem("jwt_token"),
        Accept: "application/json",
      },
      params: {
        reportType: "PL_REPORT",
        eventId: filters.selectedMarket.eventId,
        categoryType: filters.selectedMarket.categoryType,
        marketId:
          filters.selectedMarket.categoryType !== "CASINO"
            ? filters.selectedMarket.marketId
            : null,
        startDate:
          filters.selectedMarket.categoryType === "CASINO"
            ? moment(filters.selectedMarket.betPlacedTime)
                .startOf("day")
                .toISOString()
            : filters.startDate.startOf("day").toISOString(),
        endDate:
          filters.selectedMarket.categoryType === "CASINO"
            ? moment(filters.selectedMarket.betPlacedTime)
                .endOf("day")
                .toISOString()
            : filters.endDate.endOf("day").toISOString(),
        pageSize: pageSize,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchTurnoverHistoryAPI = async (filters, pageSize, username) => {
  try {
    const response = await SVLS_API.get(
      "/marketing/v1/bonus-accounts/turnover-statement",
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
          Accept: "application/json",
        },
        params: {
          pageSize: pageSize,
          pageToken: filters.pageToken[filters.pageToken.length - 1],
          startDate: filters.startDate.startOf("day").toISOString(),
          endDate: filters.endDate.endOf("day").toISOString(),
          username: username,
          transactionType:
            filters?.transactionType === "ALL" ? null : filters?.transactionType,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBonusDataAPI = async (filters, pageSize, userId) => {
  try {
    const response = await SVLS_API.get("marketing/v1/bonuses/", {
      headers: {
        Authorization: sessionStorage.getItem("jwt_token"),
        Accept: "application/json",
      },
      params: {
        accountId: userId,
        startDate: filters.startDate.startOf("day").toISOString(),
        endDate: filters.endDate.endOf("day").toISOString(),
        pageToken: filters.pageToken[filters.pageToken?.length - 1]
          ? filters.pageToken[filters.pageToken?.length - 1]
          : null,
        pageSize: pageSize,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
