/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Logo from "./Logo";
import BackgroundImage from "./BackgroundImage";

interface WinBuzzProps {
  tagline: string;
}

const WinBuzz: React.FC<WinBuzzProps> = ({ tagline }) => {
  return (
    <section className="flex h-full relative flex-col items-center px-20 pt-14 pb-20 text-white w-[75%]">
      <BackgroundImage />
      <Logo />
      <p className="relative w-[300px]  mt-3  text-sm tracking-wide text-center">
        {tagline}
      </p>
    </section>
  );
};

export default WinBuzz;
