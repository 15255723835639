/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { PaymentMethodObjType } from "../../../../store/withdraw/withdrawSlice";
import { maskAccountNumber } from "../../../../utils/commonUtils";

interface PaymentCardProps {
  onSavedAccountCardClick: (account: PaymentMethodObjType) => void;
  onAccountDeleteClick: (account: PaymentMethodObjType) => void;
  currentPaymentMethod: PaymentMethodObjType;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  onSavedAccountCardClick,
  onAccountDeleteClick,
  currentPaymentMethod,
}) => {
  const {
    displayName,
    paymentMethodDetails: { accountHolderName, accountNumber },
  } = currentPaymentMethod;

  return (
    <div
      onClick={() => onSavedAccountCardClick(currentPaymentMethod)}
      className="cursor-pointer flex flex-col w-[63%] max-md:ml-0 max-md:w-full"
    >
      <div className="flex flex-col grow justify-center text-[14px] font-semibold text-black rounded-none">
        <div className="flex flex-col justify-center items-start bg-purple-200 rounded-lg">
          <div className="flex relative flex-col items-end py-2.5 pl-2.5 rounded-none aspect-[1.73] w-[190px]">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/40665205797c48977236fc5149af9ff9c4d82bf21b168b503f7fb37e7c2089b1?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
              alt="Card background"
              className="object-cover absolute inset-0 size-full"
            />
            <div className="flex relative gap-5 justify-between whitespace-nowrap tracking-[2.4px]">
              <div className="my-auto">{displayName}</div>
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  onAccountDeleteClick(currentPaymentMethod);
                }}
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/527d4023b2aa888db7e3cdb2615f3165b5149e712c3620987c0ad94b89c3a51b?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
                alt=""
                className="shrink-0 w-4 rounded-none aspect-square"
              />
            </div>
            <div className="relative self-stretch mt-6 text-sm tracking-[4.48px]">
              {maskAccountNumber(accountNumber)}
            </div>
            <div className="relative mt-8 font-medium text-right">
              {accountHolderName}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
