import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import API from "../../api";
import { PlaceBetRequest } from "../../models/BsData";
import { ButtonVariable } from "../../models/ButtonVariables";
import { updateSuspendedMarkets } from "../catalog";
// import { updateMultiSuspendedMarkets } from '../multimarket/multimarketAction';
import { SuspendedMarketDTO } from "../../models/common/SuspendedMarketDTO";
import REPORTING_API from "../../api/api-services/reporting-api";
import SVLS_API from "../../api/api-services/svls-api";
import {
  addBetHandler,
  clearExchangeBets,
  fetchBetStatusFail,
  fetchBetStatusInProgress,
  fetchBetStatusSuccess,
  fetchOpenBetsSuccess,
  setButtonVariables,
} from "./betSlipSlice";
import store from "..";
import { showToast } from "../common/commonSlice";
import { BUTTON_VARIABLES } from "../../constants/ButtonVariables";

export const addExchangeBet = createAsyncThunk(
  "betslip/addExchangeBet",
  async (data: PlaceBetRequest, { dispatch }) => {
    dispatch(clearExchangeBets());
    dispatch(addBetHandler(data));
  }
);

export const fetchOpenBets = createAsyncThunk(
  "betslip/fetchOpenBets",
  async (
    { eventId, sportId }: { eventId: string[] | string; sportId?: string },
    { dispatch }
  ) => {
    if (eventId) {
      try {
        const response = await REPORTING_API.get("/reports/v2/orders/:search", {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
            Accept: "application/json",
          },
          params: {
            reportType: "OPEN_ORDERS",
            status: "Open",
            eventId: Array.isArray(eventId) ? eventId.join(",") : eventId,
            // sportId: sportId,
          },
        });
        dispatch(
          fetchOpenBetsSuccess({
            result: response.data.orders,
            totalOrders: response.data.totalOrders,
          })
        );
      } catch (err) {
        dispatch(fetchOpenBetsSuccess({ result: [], totalOrders: 0 }));
      }
    }
  }
);

export const fetchCasinoOpenBets = createAsyncThunk(
  "betslip/fetchCasinoOpenBets",
  async (
    { gameType, mId }: { gameType: string; mId: string },
    { dispatch }
  ) => {
    try {
      const response = await API.post(
        "/indian-casino/open-bets",
        {},
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
          },
          params: {
            gameType: gameType,
            mId: mId,
          },
        }
      );

      const payload = response.data.map((rec: any) => ({
        betPlacedTime: rec.betPlacedTime,
        stakeAmount: rec.stakeAmount,
        oddValue: rec.odds,
        outcomeDesc: rec.nation,
        betType: 0,
        outcomeId: rec.sId,
      }));
      dispatch(
        fetchOpenBetsSuccess({ result: payload, totalOrders: payload.length })
      );
    } catch (err) {
      dispatch(fetchOpenBetsSuccess({ result: [], totalOrders: 0 }));
    }
  }
);

export const fetchButtonVariables = createAsyncThunk(
  "betslip/fetchButtonVariables",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<ButtonVariable[]> = await SVLS_API.get(
        "/catalog/v2/settings/favourite-stakes/users",
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
          },
        }
      );
      const btnVars = response.data.map((btnVar: ButtonVariable) => ({
        label: btnVar.label,
        stake: btnVar.stake,
      }));
      thunkAPI.dispatch(setButtonVariables(btnVars));
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateButtonVariables = createAsyncThunk(
  "betslip/updateButtonVariables",
  async (updateVariables: any, { dispatch }) => {
    for (let uV of updateVariables) {
      if (!uV.label || !uV.stake) {
        dispatch(
          showToast({
            type: "error",
            message: "Label or Amount invalid",
          })
        );
        return 0;
      }
    }
    const response = await SVLS_API.put(
      "/catalog/v2/settings/favourite-stakes/users",
      updateVariables,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
      }
    );
    if ((response.status = 204)) {
      dispatch(showToast({
        type: "success",
        message: "Button Variables Saved Successfully",
      }));
    } else {
      dispatch(showToast({
        type: "error",
        message: "Something went wrong",
      }));
    }
    fetchButtonVariables();
  }
);

export const betStatus = createAsyncThunk(
  "betslip/betStatus",
  async (_, { dispatch }) => {
    try {
      const response: AxiosResponse<any> = await API.get("/bs/bet-status", {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
      });

      if (response.data.message === "Market suspended") {
        updateSuspendedMarket(response.data);
      }

      if (response.data.success) {
        if (response.data.message === "BETTING_IN_PROGRESS") {
          dispatch(
            fetchBetStatusInProgress({
              status: "IN_PROGRESS",
              message: "Bet is in progress.",
            })
          );
        } else {
          dispatch(
            fetchBetStatusSuccess({
              status: "SUCCESS",
              message: response.data.message,
            })
          );
        }
      } else {
        dispatch(
          fetchBetStatusFail({
            status: "FAIL",
            message: `Bet failed - ${response.data.message}`,
          })
        );
      }
    } catch (err) {
      dispatch(
        fetchBetStatusFail({
          status: "FAIL",
          message: err.message,
        })
      );
    }
  }
);

const updateSuspendedMarket = (data: any) => {
  const suspendedMarket: SuspendedMarketDTO = {
    providerId: "",
    sportId: data?.sportId,
    competitionId: data?.seriesId,
    eventId: data?.eventId,
    marketType:
      data?.marketType === "MO"
        ? "MATCH_ODDS"
        : data?.marketType === "BM"
        ? "BOOKMAKER"
        : data?.marketType,
    marketId: data?.marketId,
    suspend: true,
  };

  if (window.location.pathname === "/exchange_sports/multi-markets") {
    store.dispatch(updateSuspendedMarkets(suspendedMarket));
  } else {
    // store.dispatch(updateMultiSuspendedMarkets(suspendedMarket));
  }
};
