import React, { useEffect } from "react";
import { EventDTO } from "../../../models/common/EventDTO";
import moment from "moment";
import EventDateDisplay from "../../../common/EventDateDisplay/EventDateDisplay";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { SelectedObj } from "../../../models/SportsState";
import { fetchCompetitions } from "../../../api/catalog";
import { RootState } from "../../../store";
import {
  setCompetitionThunk,
  setEventTypeThunk,
  setExchEventThunk,
} from "../../../store/catalog/catalogThunk";
import { setExchEvent } from "../../../store/catalog";
import MarketBtn from "../MarketComponents/MarketBtn/MarketBtn";
import { isMobile } from "react-device-detect";

interface ExchEventTableProps {
  selectedDate: string | null;
  showLive: boolean;
  iEvent: EventDTO;
  setEventType: Function;
  setCompetition: Function;
  setExchEvent: Function;
  selectedEventType: SelectedObj;
  loggedIn: boolean;
}

export const EmptyOddsBlock = React.memo(() => {
  return (
    <React.Fragment>
      <div className="flex w-[110px] cursor-pointer rounded-[3px] overflow-hidden mx-auto">
        <MarketBtn mainValue={null} oddType="back-odd" />
        <MarketBtn mainValue={null} oddType="lay-odd" />
      </div>
    </React.Fragment>
  );
});

const ExchEventTable: React.FC<ExchEventTableProps> = ({
  selectedDate,
  showLive,
  iEvent,
  setEventType,
  setCompetition,
  setExchEvent,
  selectedEventType,
  loggedIn,
}) => {
  const runnerTypes = ["home", "draw", "away"];

  const navigate = useNavigate();

  const getCompetitionSlug = (competitionName: string) => {
    return competitionName
      ?.toLocaleLowerCase()
      .replace(/[^a-z0-9]/g, " ")
      .replace(/ +/g, " ")
      .trim()
      .split(" ")
      .join("-");
  };
  const handleEventChange = (event: EventDTO) => {
    setCompetition({
      id: event.competitionId,
      name: event.competitionName,
      slug: getCompetitionSlug(event.competitionName),
    });
    setExchEvent({
      id: event.eventId,
      name: event.eventName,
      slug: event.eventSlug,
    });
  };

  const disableFutureEvents = (date: any) => {
    let dt = date;
    dt = moment.utc(date).toString();

    let retDate = moment(dt);
    retDate = retDate.local();

    return moment(retDate).diff(moment(), "minutes") > 60 * 24 * 2
      ? true
      : false;
  };

  const getOdds = (eventData: EventDTO, teamType: string) => {
    const team =
      teamType === "home"
        ? eventData.homeTeam
        : teamType === "away"
        ? eventData.awayTeam
        : teamType;

    const teamLowerCase = team.toLowerCase();
    for (let runner of eventData.matchOdds.runners) {
      let runnerLowCase = runner.runnerName.toLowerCase();
      if (
        runnerLowCase === teamLowerCase ||
        runnerLowCase.includes(teamLowerCase)
      ) {
        return [
          {
            type: "back-odd",
            price: runner?.backPrices[0]?.price,
            size: runner.backPrices[0]?.size,
            outcomeId: runner?.runnerId,
            outcomeName: runner.runnerName,
          },
          {
            type: "lay-odd",
            price: runner?.layPrices[0]?.price,
            size: runner?.layPrices[0]?.size,
            outcomeId: runner.runnerId,
            outcomeName: runner.runnerName,
          },
        ];
      }
    }
    return null;
  };

  return (
    <>
      {isMobile ? (
        <div
          className="cursor-pointer w-full border-b p-[4px] flex flex-col gap-2 "
          onClick={() => {
            handleEventChange(iEvent);
            navigate(
              iEvent?.providerName?.toLowerCase() === "sportradar" &&
                iEvent?.catId === "SR VIRTUAL"
                ? loggedIn
                  ? `/exchange_sports/virtuals/${
                      selectedEventType.slug
                    }/${getCompetitionSlug(iEvent.competitionName)}/${
                      iEvent.eventSlug
                    }/${btoa(
                      `${iEvent.sportId}:${iEvent.competitionId}:${iEvent.eventId}`
                    )}`
                  : "/login"
                : `/exchange_sports/${
                    selectedEventType.slug
                  }/${getCompetitionSlug(iEvent.competitionName)}/${
                    iEvent.eventSlug
                  }/${btoa(
                    `${iEvent.sportId}:${iEvent.competitionId}:${iEvent.eventId}`
                  )}`
            );
          }}
        >
          <div className="  flex w-full text-[14px] font-medium">
            <div className="w-[60px]">
              {iEvent.status.toLowerCase() === "in_play" ? (
                <div className="">
                  <div className=" bg-[#3C7701] text-white text-[12px] font-bold h-[24px] w-[52px] text-center leading-[24px] ml-1 my-1 mr-[23px] rounded-[3px]">
                    LIVE
                  </div>
                </div>
              ) : (
                <div className="">
                  <div className=" bg-red-600 text-white text-[10px] font-bold h-[24px] w-[64px] text-center leading-[24px] ml-1 my-1 mr-[23px] rounded-[3px]">
                    UPCOMING
                  </div>
                </div>
              )}
            </div>
            <div className=" max-w-[60%] grow pl-4 font-medium">
              {iEvent?.homeTeam !== "" && iEvent?.awayTeam !== "" ? (
                <div className=" font-medium text-[14px] max-w-[150px] truncate capitalize">
                  {" "}
                  {iEvent.homeTeam} vs {iEvent.awayTeam}{" "}
                </div>
              ) : (
                <div className=" font-medium text-[14px] max-w-[150px] truncate capitalize">
                  {iEvent.eventName}
                </div>
              )}
              <div className="text-[12px] text-stone-900 text-center">
                <div className=" py-1">
                  <EventDateDisplay openDate={iEvent.openDate} />
                </div>
              </div>
            </div>
            <div className="  flex  ml-auto ">
              {iEvent?.enableMatchOdds ? (
                <button className="w-7 h-6 text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                  MO
                </button>
              ) : null}
              {iEvent?.enableBookmaker ? (
                <button className="w-7 h-6 text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                  BM
                </button>
              ) : null}
              {iEvent?.enableFancy ? (
                <button className="w-7 h-6 text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                  F
                </button>
              ) : null}
              {iEvent?.enablePremium ? (
                <button className="w-7 h-6 text-[14px] leading-[13.5px] bg-red-600 rounded text-white font-semibold mr-2">
                  P
                </button>
              ) : null}
            </div>
          </div>
          <div className=" flex justify-end">
            {runnerTypes?.map((runnerType) => (
              <div>
                <div className="flex w-[110px] cursor-pointer rounded-[3px] overflow-hidden mx-auto">
                  {iEvent?.matchOdds ? (
                    getOdds(iEvent, runnerType) ? (
                      getOdds(iEvent, runnerType).map((odd) => (
                        <MarketBtn
                          mainValue={odd.price}
                          subValue={odd.size}
                          oddType={
                            odd.type === "back-odd" ? "back-odd" : "lay-odd"
                          }
                          disable={iEvent.matchOdds.status
                            .toLowerCase()
                            .includes("suspended")}
                          valueType="matchOdds"
                          showSubValueinKformat={true}
                          onClick={() => null}
                        />
                      ))
                    ) : (
                      <EmptyOddsBlock />
                    )
                  ) : (
                    <EmptyOddsBlock />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="cursor-pointer w-full border-b p-[4px] flex flex-col gap-2 "
          onClick={() => {
            handleEventChange(iEvent);
            navigate(
              iEvent?.providerName?.toLowerCase() === "sportradar" &&
                iEvent?.catId === "SR VIRTUAL"
                ? loggedIn
                  ? `/exchange_sports/virtuals/${
                      selectedEventType.slug
                    }/${getCompetitionSlug(iEvent.competitionName)}/${
                      iEvent.eventSlug
                    }/${btoa(
                      `${iEvent.sportId}:${iEvent.competitionId}:${iEvent.eventId}`
                    )}`
                  : "/login"
                : `/exchange_sports/${
                    selectedEventType.slug
                  }/${getCompetitionSlug(iEvent.competitionName)}/${
                    iEvent.eventSlug
                  }/${btoa(
                    `${iEvent.sportId}:${iEvent.competitionId}:${iEvent.eventId}`
                  )}`
            );
          }}
        >
          <div className="  flex w-full text-[14px] font-medium">
            <div className="w-[60px]">
              {iEvent.status.toLowerCase() === "in_play" ? (
                <div className="">
                  <div className=" bg-[#3C7701] text-white text-[12px] font-bold h-[24px] w-[52px] text-center leading-[24px] ml-1 my-1 mr-[23px] rounded-[3px]">
                    LIVE
                  </div>
                </div>
              ) : (
                <div className="">
                  <div className=" bg-red-600 text-white text-[10px] font-bold h-[24px] w-[64px] text-center leading-[24px] ml-1 my-1 mr-[23px] rounded-[3px]">
                    UPCOMING
                  </div>
                </div>
              )}
            </div>
            <div className=" flex flex-col max-w-[100%] grow pl-4 font-medium ">
              {iEvent?.homeTeam !== "" && iEvent?.awayTeam !== "" ? (
                <div className=" font-medium capitalize  text-[14px]">
                  {" "}
                  {iEvent.homeTeam} vs {iEvent.awayTeam}{" "}
                </div>
              ) : (
                <div className=" font-medium capitalize text-[14px]">
                  {iEvent.eventName}
                </div>
              )}
              <div className="text-[12px] text-stone-900 text-center">
                <div className=" py-1">
                  <EventDateDisplay openDate={iEvent.openDate} />
                </div>
              </div>
            </div>
            <div className=" flex  ml-auto ">
              {iEvent?.enableMatchOdds ? (
                <button className="w-7 h-6 text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                  MO
                </button>
              ) : null}
              {iEvent?.enableBookmaker ? (
                <button className="w-7 h-6 text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                  BM
                </button>
              ) : null}
              {iEvent?.enableFancy ? (
                <button className="w-6 h-6 text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                  F
                </button>
              ) : null}
              {iEvent?.enablePremium ? (
                <button className="w-6 h-6 text-[14px] leading-[13.5px] bg-red-600 rounded text-white font-semibold mr-2">
                  P
                </button>
              ) : null}
            </div>
            <div className=" flex justify-end">
              {runnerTypes?.map((runnerType) => (
                <div>
                  <div className="flex w-[110px] cursor-pointer rounded-[3px] overflow-hidden mx-auto">
                    {iEvent?.matchOdds ? (
                      getOdds(iEvent, runnerType) ? (
                        getOdds(iEvent, runnerType).map((odd) => (
                          <MarketBtn
                            mainValue={odd.price}
                            subValue={odd.size}
                            oddType={
                              odd.type === "back-odd" ? "back-odd" : "lay-odd"
                            }
                            disable={iEvent.matchOdds.status
                              .toLowerCase()
                              .includes("suspended")}
                            valueType="matchOdds"
                            showSubValueinKformat={true}
                            onClick={() => null}
                          />
                        ))
                      ) : (
                        <EmptyOddsBlock />
                      )
                    ) : (
                      <EmptyOddsBlock />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    // bets: state.exchBetslip.bets,
    // allowedConfig: state.common.allowedConfig,
    loggedIn: state.auth.loggedIn,
    loading: state.catalog.loading,
    topicUrls: state?.catalog?.topicUrls,
    selectedEventType: state.catalog.selectedEventType,
    // betFairWSConnected: state.catalog.betFairWSConnected,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setEventType: (eType: SelectedObj) => dispatch(setEventTypeThunk(eType)),
    setCompetition: (competition: SelectedObj) =>
      dispatch(setCompetitionThunk(competition)),
    setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
    fetchCompetitions: (eventTypeId: string) =>
      dispatch(fetchCompetitions(eventTypeId)),
    // fetchEventsByCompetition: (etId: string, cId: string) =>
    // dispatch(fetchEventsByCompetition(etId, cId, [])),
    // addExchangeBet: (data: BsData) => dispatch(addExchangeBet(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchEventTable);
