/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface ButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="w-full px-16 py-3 mt-[45px] text-base font-semibold text-center text-white bg-fuchsia-800 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.05)]"
    >
      {children}
    </button>
  );
};

export default Button;
