/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import SearchGames from "./SearchGames";
import MenuList from "./MenuList";
import OtherMenu from "./OtherMenu";
import SocialLinks from "./SocialLinks";
import SecurityLogout from "../../Desktop/Sidebar/SecurityLogout";
// import SecurityLogout from "./SecurityLogout";

type SidebarProps = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  classProp: string;
  setShowSidebar: any;
}

const MobileSidebar: React.FC<SidebarProps> = ({selectedTab, setSelectedTab, classProp, setShowSidebar}) => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <aside className={` ${classProp} h-screen z-50 duration-700 w-[220px] min-w-[200px] bg-white  border-r border-neutral-200 overflow-y-auto scrollbar-hide`}>
      <div className="flex flex-col p-4 ">
        <MenuList setShowSidebar={setShowSidebar} searchTerm={searchTerm} setSelectedTab={setSelectedTab} />
        <OtherMenu setShowSidebar={setShowSidebar} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <SocialLinks />
        <SecurityLogout />
      </div>
    </aside>
  );
};

export default MobileSidebar;
