import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CURRENCY_TYPE_FACTOR } from '../../../constants/CurrencyTypeFactor';
import { PlaceBetRequest } from '../../../models/BsData';
import { EventDTO } from '../../../models/common/EventDTO';
import { SPEventDTO, MatchOdds, Runner } from '../../../models/SPEventDTO';
import { SelectedObj } from '../../../models/SportsState';
import { UserBet } from '../../../models/UserBet';
import { RootState } from '../../../store';
import { addExchangeBet } from '../../../store/betSlip/betSlipThunk';
import { getPremiumMarkets } from '../../../store/catalog/catalogSelectors';
import { getCurrencyTypeFromToken } from '../../../utils/commonUtils';
import MarketBtn from '../MarketComponents/MarketBtn/MarketBtn';
import { isMobile } from 'react-device-detect';
import { IoIosArrowUp } from 'react-icons/io';
import Betslip from '../Betslip/Betslip';
type OddsInfoMsg = {
    launch: boolean;
    oddsType: string;
    eventTypeID: string;
};

type StoreProps = {
    dreamData: EventDTO;
    eventData: SPEventDTO;
    bets: PlaceBetRequest[];
    openBets: UserBet[];
    addExchangeBet: (data: PlaceBetRequest) => void;
    selectedEventType: SelectedObj;
    loggedIn: boolean;
    getFormattedMinLimit: (num: number) => string;
    getFormattedMaxLimit: (num: number) => string;
    setBetStartTime: Function;
    setAddNewBet: Function;
    showWinnerMarket?: boolean;
};

const PremiumMarketsView: React.FC<StoreProps> = (props) => {
    const {
        eventData,
        dreamData,
        bets,
        openBets,
        addExchangeBet,
        setBetStartTime,
        setAddNewBet,
        showWinnerMarket
    } = props;
    const [premiumMarkets, setPremiummarkets] = useState<MatchOdds[]>();
    const disabledStatus = [
        'deactivated',
        'suspended',
        'closed',
        'suspended-manually',
    ];
    const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
    const [selectedRow, setSelectedRow] = useState<string>('');
    const [collapsedMarkets, setCollapsedMarkets] = useState({});

    const [infoDilalog, setInfoDialog] = useState<OddsInfoMsg>({
        launch: false,
        oddsType: null,
        eventTypeID: null,
    });

    useEffect(() => {
        if (eventData) {
            setPremiummarkets(eventData.markets.matchOdds);
        }
    }, [eventData]);

    const getOpenBetsPL = (marketId: string, runner: Runner) => {
        let returns = null;
        const mOpenBets = openBets.filter(
            (b) => b.marketType === 0 && b.marketId === marketId
        );
        for (let bet of mOpenBets) {
            const plVal = bet.oddValue * bet.stakeAmount - bet.stakeAmount;
            if (bet.betType === 0) {
                if (bet.outcomeId === runner?.runnerId) {
                    returns ? (returns += plVal) : (returns = plVal);
                } else {
                    returns
                        ? (returns -= bet.stakeAmount)
                        : (returns = 0 - bet.stakeAmount);
                }
            } else if (bet.betType === 1) {
                if (bet.outcomeId !== runner?.runnerId) {
                    returns ? (returns += bet.stakeAmount) : (returns = bet.stakeAmount);
                } else {
                    returns ? (returns -= plVal) : (returns = 0 - plVal);
                }
            }
        }
        const retVal = returns ? returns / cFactor : null;
        return retVal;
    };

    const getOpenBetsPLInArray = (marketId: string, runner: Runner) => {
        return [getOpenBetsPL(marketId, runner)];
    };

    const getTotalPL = (marketId: string, runner: Runner) => {
        let returns = null;
        const mBetslipBets = bets.filter(
            (b) => b.marketId === marketId && b.amount && b.amount > 0
        );
        if (mBetslipBets.length > 0) {
            returns = getOpenBetsPL(marketId, runner);
            for (let bet of mBetslipBets) {
                const plVal = bet.oddValue * bet.amount - bet.amount;
                if (bet.betType === 'BACK') {
                    if (bet.outcomeId === runner?.runnerId) {
                        returns ? (returns += plVal) : (returns = plVal);
                    } else {
                        returns ? (returns -= bet.amount) : (returns = 0 - bet.amount);
                    }
                } else if (bet.betType === 'LAY') {
                    if (bet.outcomeId !== runner?.runnerId) {
                        returns ? (returns += bet.amount) : (returns = bet.amount);
                    } else {
                        returns ? (returns -= plVal) : (returns = 0 - plVal);
                    }
                }
            }
        }
        return [returns];
    };

    const handleToggle = (marketId) => {
        setCollapsedMarkets(prevState => ({
            ...prevState,
            [marketId]: !prevState[marketId]
        }));
    };
    function rotate(e) {
        const element = e.currentTarget;
        element.classList.toggle('rotate-[180deg]');
    }

    return (
        <>
            <div className="premium-markets-table-ctn">
                <div className="pm-table-content table-ctn">
                    {premiumMarkets && premiumMarkets.length > 0 ? (
                        <>
                            {(showWinnerMarket ? premiumMarkets.filter(market => market?.marketId === "340" || market?.marketId === "186") : premiumMarkets).map((pm, idx) =>
                                pm && pm.runners.length > 0 ? (
                                    <div className=" transition-all duration-[1000ms] w-full rounded mb-5 border-r border-l border-[#e0e0e0] overflow-hidden">
                                        <div className="">
                                            <div className="bg-stone-900 flex gap-2 items-center  h-[31px] bg-no-repeat text-white pl-3 bg-contain text-[14px] leading-[31px] " >
                                                <div onClick={(e) => { handleToggle(pm?.marketId); rotate(e) }} className={` cursor-pointer rounded-full p-1 `}><IoIosArrowUp /></div>
                                                {pm?.marketName}
                                            </div>
                                        </div>
                                        <div className={` ${collapsedMarkets[pm?.marketId] ? 'h-0 overflow-hidden' : 'h-auto'} ${pm?.runners?.length <= 3 ? " rounded-b flex  flex-wrap text-[14px] premium-ctn " : " rounded-b flex flex-wrap text-[14px] premium-ctn-more "}`}>
                                            {
                                                pm?.runners?.map(runner => (
                                                    <PremiumMarketRow
                                                        eventData={eventData}
                                                        dreamData={dreamData}
                                                        matchOddsData={pm}
                                                        runner={runner}
                                                        getOpenBetsPL={getOpenBetsPLInArray}
                                                        getTotalPL={getTotalPL}
                                                        disabledStatus={disabledStatus}
                                                        addExchangeBet={addExchangeBet}
                                                        bets={bets}
                                                        selectedRow={selectedRow}
                                                        setSelectedRow={setSelectedRow}
                                                        setBetStartTime={(date) =>
                                                            setBetStartTime(date)
                                                        }
                                                        setAddNewBet={(val) => setAddNewBet(val)}
                                                        showWinnerMarket={showWinnerMarket}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : null
                            )}
                        </>
                    ) : null}

                </div>
            </div>
        </>
    );
};

type PremiumMarketRowProps = {
    eventData: SPEventDTO;
    dreamData: EventDTO;
    matchOddsData: MatchOdds;
    runner: Runner;
    getOpenBetsPL: (marketId: string, runner: Runner, type: string) => number[];
    getTotalPL: (marketId: string, runner: Runner) => number[];
    disabledStatus: string[];
    addExchangeBet: (data: PlaceBetRequest) => void;
    bets: PlaceBetRequest[];
    selectedRow: string;
    setSelectedRow: (data) => void;
    setBetStartTime: Function;
    setAddNewBet: Function;
    showWinnerMarket: boolean;
};

export const PremiumMarketRow: React.FC<PremiumMarketRowProps> = (props) => {
    const [betSlips, setBetSlips] = useState(false);
    const {
        eventData,
        dreamData,
        matchOddsData,
        runner,
        getOpenBetsPL,
        getTotalPL,
        disabledStatus,
        addExchangeBet,
        bets,
        selectedRow,
        setSelectedRow,
        setBetStartTime,
        setAddNewBet,
        showWinnerMarket
    } = props;

    const marketEnableStatus = ["active", "open"];
    const betslipRef = useRef(null);

    useEffect(() => {
      if (bets?.length > 0 && betslipRef.current) {
        betslipRef.current.scrollIntoView({
          behavior: 'smooth', // Smooth scrolling
          block: 'center', // Scroll so the element is in the center of the view
        });
      }
    }, [bets]);


    return (
        <>
         {bets?.[0]?.marketName === matchOddsData.marketName &&
                selectedRow === runner?.runnerName + 'PREMIUM' &&
                isMobile ? (
                <tr className="mob-view inline-betslip">
                    <td colSpan={12}>
                    <div ref={betslipRef} className="betslip-container">
                        <Betslip eventData={eventData} />{' '}
                        </div>
                    </td>
                </tr>
            ) : null}
            <div className={` px-3 border-b border-[#e0e0e0] h-[41px] premium-item ${isMobile ? ' w-full ' : ' grow '}`}>
                <div className="flex gap-5 items-center h-full justify-between">
                    <div className="text-[14px] font-medium">{runner?.runnerName}</div>
                    {runner?.backPrices?.length > 0 ? (
                        runner?.backPrices?.map((odds, idx) => (
                            <div onClick={() => setBetSlips(true)}>
                                <MarketBtn
                                    marketType='pm'
                                    mainValue={odds.price}
                                    subValue={null}
                                    showSubValueinKformat={true}
                                    oddType="premium-odd"
                                    valueType="matchOdds"
                                    oddsSet={[
                                        runner?.backPrices?.[0]
                                            ? runner?.backPrices?.[0]?.price
                                            : 0,
                                        runner?.backPrices?.[1]
                                            ? runner?.backPrices?.[1]?.price
                                            : 0,
                                        runner?.backPrices?.[2]
                                            ? runner?.backPrices?.[2]?.price
                                            : 0,
                                    ]}
                                    key={idx}
                                    disable={
                                        false
                                    }
                                    onClick={() => {
                                        setSelectedRow(runner.runnerName + 'PREMIUM');
                                        addExchangeBet({
                                            sportId: dreamData.sportId,
                                            seriesId: dreamData.competitionId,
                                            seriesName: eventData.competitionName,
                                            eventId: dreamData.eventId.split("_").join(":"),
                                            eventName: eventData.eventName,
                                            eventDate: +eventData.openDate,
                                            marketId: matchOddsData.marketId,
                                            marketName: matchOddsData.marketName,
                                            marketType: 'PREMIUM',
                                            outcomeId: runner?.runnerId,
                                            outcomeDesc: runner?.runnerName,
                                            betType: 'BACK',
                                            amount: 0,
                                            oddValue: runner?.backPrices?.[idx]?.price,
                                            sessionPrice: -1,
                                            srEventId: eventData.eventId,
                                            srSeriesId: eventData.competitionId,
                                            srSportId: eventData.sportId,
                                            mcategory: 'ALL',
                                        });
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <>
                            <div className="exch-odd-view">
                                <div className={'premium-odd disabled exch-odd-button'}>
                                    -
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
           
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    const eventType = state.catalog.selectedEventType;
    const event = state.catalog.selectedEvent;
    return {
        eventData: getPremiumMarkets(
            state.catalog.premiumMarketsMap,
            event.id
        ),
        selectedEventType: eventType,
        bets: state.betSlip.bets,
        openBets: state.betSlip.openBets,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        addExchangeBet: (data: PlaceBetRequest) => dispatch(addExchangeBet(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumMarketsView);
