import React from 'react';
import moment, { Moment } from 'moment';
import ReactDatePicker from '../Mobile/ReactDatePicker';

type DatePickerProps = {
  selectedDate: Moment;
  onDateChange: (date: Moment) => void;
  minDate?: Moment;
  maxDate?: Moment;
};

const DatePicker: React.FC<DatePickerProps> = ({ selectedDate, onDateChange, minDate, maxDate }) => {
  const handleChange = (date) => {
    onDateChange(moment(date));
  };

  return (
    <div className="flex mt-1 ml-1 justify-center align-middle">
      <ReactDatePicker date={selectedDate} setDate={handleChange} />
    </div>
  );
};

export default DatePicker;
