import { combineReducers } from '@reduxjs/toolkit';
import accountReducer from './account/accountSlice';
import authReducer from './auth/authSlice';
import catalogReducer from './catalog/catalogSlice';
import commonReducer from './common/commonSlice';
import reportsReducer from './reports/reportsSlice';
import betSlipReducer from './betSlip/betSlipSlice';
import multiMarketReducer from './multiMarket/multiMarketSlice';
import withdrawReducer from "./withdraw/withdrawSlice";

const rootReducer = combineReducers({
    account: accountReducer,
    auth: authReducer,
    catalog: catalogReducer,
    common: commonReducer,
    reports: reportsReducer,
    betSlip: betSlipReducer,
    multiMarket: multiMarketReducer,
    withdraw: withdrawReducer,
});

export default rootReducer;
