import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";

interface MarketBtnProps {
    mainValue?: any;
    mainValueClass?: 'price' | 'runs';
    subValue?: number;
    subValueClass?: 'size' | 'odds';
    disable?: boolean;
    oddType:
    | 'back-odd'
    | 'lay-odd'
    | 'odds-yes-cell'
    | 'odds-no-cell'
    | 'premium-odd';
    valueType?:
    | 'matchOdds'
    | 'bookmakerOdds'
    | 'fancyMarketOdds'
    | 'binaryOdds'
    | 'premiumOdds';
    onClick?: () => void;
    oddsSet?: number[];
    showSubValueinKformat?: boolean;
    marketType?: string;
}

const MarketBtn: React.FC<MarketBtnProps> = (props) => {
    const {
        marketType,
        mainValue,
        mainValueClass,
        subValue,
        subValueClass,
        disable,
        oddType,
        valueType,
        onClick,
        oddsSet,
        showSubValueinKformat,
    } = props;
    const [currentValue, setCurrentValue] = useState<number>(null);
    const [classes, setClasses] = useState<string>('exch-odd-button');
    const [timer, setTimer] = useState(null);
    const [currentOdds, setCurrentOdds] = useState<number[]>([]);
    const [volume, setVolume] = useState<number>();
    const subValprecision: number = valueType === 'matchOdds' ? 0 : 0;

    useEffect(() => {
        if (subValue) {
            if (valueType === 'matchOdds') setVolume(subValue * 1000);
            else setVolume(subValue);
        }
    }, [subValue, valueType]);

    const oddValueChanged = () => {
        clearTimeout(timer);

        const chVal = mainValue;
        if (chVal) {
            const cVal = currentValue;
            setCurrentValue(chVal);
            let showAnimation = true;
            if (oddsSet && oddsSet.length > 0) {
                showAnimation = !currentOdds.includes(chVal);
                setCurrentOdds(oddsSet);
            }
            if (showAnimation) {
                if (cVal && chVal !== cVal) {
                    if (!classes.includes('odd-change'))
                        setClasses(classes + ' odd-change');
                    setTimer(
                        setTimeout(
                            () => setClasses(classes.replace(' odd-change', '')),
                            5000
                        )
                    );
                }
            }
        }
    };

    useEffect(() => oddValueChanged(), [mainValue, subValue]);

    const clipClass = () => {
        if (marketType) {
            return 'rounded'
        }
        if (valueType !== "fancyMarketOdds") {
            if (oddType === 'lay-odd') {
                return 'lay-btn-clip ml-[-15px] rounded-tr-md rounded-br-md';
            } else {
                return 'back-btn-clip rounded-tl-md rounded-bl-md';
            }
        } else {
            if (oddType === 'lay-odd') {
                return 'back-btn-clip rounded-tl-md rounded-bl-md';
            } else {
                return 'lay-btn-clip ml-[-15px] rounded-tr-md rounded-br-md';
            }
        }
    }

    return (
        <>
            {mainValue && Number(mainValue) > 0 ? (
                <div className={`${disable ? ' opacity-50 disabled ': ''} ${classes} ${clipClass()} cursor-pointer flex justify-center items-center relative  ${oddType === "premium-odd" ? "w-[120px]": " w-[56px] "}  text-white ${oddType === "lay-odd" ? 'bg-[#F9CFCE]' : 'bg-[#A5D9FE]'} h-[28px]`} 
                onClick={() => !disable ? onClick(): {}}>
                    <div className={`flex flex-col justify-center items-center ${oddType === "premium-odd" ? "h-full": ""} `} >
                        <div className={`text-[14px] pt-1 text-[#1E1E1E] font-[600] leading-[10px] `}>
                            {Number.isNaN(Number(mainValue)) ? (
                                <br></br>
                            ) : valueType === 'fancyMarketOdds' ? (
                                Number(mainValue)
                            ) : (
                                Number(mainValue).toFixed(2)
                            )}
                        </div>
                        {oddType !== 'premium-odd' && valueType !== 'bookmakerOdds' ? (
                            <div className="text-[8px] text-[#757575] leading-none">
                                {volume
                                    ? showSubValueinKformat && Number(volume > 999)
                                        ? Number(volume / 1000).toFixed(subValprecision) + 'K'
                                        : Number(volume).toFixed(subValprecision)
                                    : '0.00K'}
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className={`${oddType === "lay-odd" ? 'bg-[#f2f2f2]' : 'bg-[#E0E0E0]'} ${clipClass()} disabled bg-[#E0E0E0] w-[56px] text-center h-[28px] flex items-center justify-center ` + classes}>
                    <FaLock color="#a19a9a" size={10} />
                    {/* <LockOutlined className="lock-icon" /> */}
                </div>
            )}
        </>
    );
}
export default MarketBtn;