/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Filters, turnOverReptxTypes } from "../../../store/reports/reportSelectors";
import DropDown from "../../Mobile/DropDown";
import ReactDatePicker from "../../Mobile/ReactDatePicker";

interface DatePickerProps {
  onDateChange: (fromDate: Date, toDate: Date) => void;
  filters: Filters;
  setFilters: Function
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  filters,
  setFilters
}) => {
  const handleFromDateChange = (date) => {
    onDateChange(date, filters.endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(filters.startDate, date);
  };

  const handleTransactionType = (event) => {
    setFilters({ ...filters, transactionType: event.value });
  };

  return (
    <div className="flex gap-3 self-end pl-20 mt-3.5 text-[14px] whitespace-nowrap max-md:flex-wrap max-md:pl-5 max-md:mr-2.5">
      <div className="flex gap-2.5">
        <div className="grow my-auto capitalize text-neutral-500">
          Transaction Type
        </div>
        <div className="w-[200px]">
          <DropDown options={turnOverReptxTypes} selectedOption={filters?.transactionType === "ALL" ? { value: "ALL", label: "All" } : filters?.transactionType} onChange={handleTransactionType} />
        </div>
      </div>
      <div className="flex gap-2.5 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">From</div>
        <div className="flex mt-1 justify-center align-middle">
          <ReactDatePicker date={filters.startDate} setDate={handleFromDateChange} />
        </div>
      </div>
      <div className="flex gap-2.5 mr-2 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">To</div>
        <div className="flex mt-1 justify-center align-middle">
          <ReactDatePicker date={filters.endDate} setDate={handleToDateChange} />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
