import moment from "moment";
import cricket from "../assets/cricket.svg";
import football from "../assets/football.svg";
import tennis from "../assets/tennis.svg";
import basketball from "../assets/basketball.svg";
import baseball from "../assets/baseball.svg";
import greyhound from "../assets/greyhound.svg";
import horse from "../assets/horse.svg";
import multimarket from "../assets/multimarketicon.svg";
import { SPORTS_MAP } from "../constants/ExchangeEventTypes";

interface MenuItem {
  icon: string;
  text: string;
  subTabs?: string[];
  path: string;
}

export const subHeaders = (type) =>
  type === "mobile" ? [...menuItems, ...navItems] : [...navItems, ...menuItems];

export const navItems = [
  {
    text: "Sportsbook",
    path: "/sportsbook",
  },
  {
    text: "Exchange",
    path: "/exchange_sports/inplay",
  },
  {
    text: "Virtual Casino",
    path: "/virtual-casino",
  },
  {
    text: "Live Casino",
    path: "/live-casino",
  },
  {
    text: "Indian Casino",
    path: "/indian-casino",
  },
];

export const menuItems: MenuItem[] = [
  {
    icon: cricket,
    text: "Cricket",
    subTabs: ["T20", "ODI", "Test"],
    path: "/exchange_sports/cricket",
  },
  {
    icon: football,
    text: "Football",
    subTabs: ["Premier League", "La Liga", "Bundesliga"],
    path: "/exchange_sports/football",
  },
  {
    icon: tennis,
    text: "Tennis",
    subTabs: ["Grand Slam", "ATP", "WTA"],
    path: "/exchange_sports/tennis",
  },
  {
    icon: basketball,
    text: "Basketball",
    subTabs: ["NBA", "EuroLeague", "NCAA"],
    path: "/exchange_sports/basketball",
  },
  {
    icon: baseball,
    text: "Baseball",
    subTabs: ["MLB", "NPB", "KBO"],
    path: "/exchange_sports/baseball",
  },
  {
    icon: greyhound,
    text: "Greyhound Race",
    subTabs: ["UK", "Australia", "USA"],
    path: "/exchange_sports/greyhound",
  },
  {
    icon: horse,
    text: "Horse Race",
    subTabs: ["Flat Racing", "Jump Racing", "Harness Racing"],
    path: "/exchange_sports/horserace",
  },
  {
    icon: multimarket,
    text: "Multi Market",
    // subTabs: ["Flat Racing", "Jump Racing", "Harness Racing"],
    path: "/multimarket",
  },
];

export const SPToBFIdMap = {
  "sr:sport:1": "1",
  "sr:sport:5": "2",
  "sr:sport:21": "4",
  "sr:sport:2": "7522",
  "sr:sport:3": "7511",
  "sr:sport:29": "sr_sport_29",
  "sr:sport:22": "sr_sport_22",
  "sr:sport:23": "sr_sport_23",
  "sr:sport:20": "sr_sport_20",
  "sr:sport:4": "sr_sport_4",
};

export const BFToSRIdMap = {
  "1": "sr:sport:1",
  "2": "sr:sport:5",
  "4": "sr:sport:21",
  "7522": "sr:sport:2",
  "7511": "sr:sport:3",
  sr_sport_29: "sr:sport:29",
  sr_sport_22: "sr:sport:22",
  sr_sport_23: "sr:sport:23",
  sr_sport_20: "sr:sport:20",
  sr_sport_4: "sr:sport:4",
};

export const capitalizeWord = (str: string) => {
  const words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    if (words[i].trim().length > 0) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
  }

  return words.join(" ");
};

export const demoUserPrefix = "zzzzsysdemo";

export enum JwtToken {
  ACCOUNT_ID = "aid",
  HOUSE_ID = "hid",
  SAP_TOKEN = "sap",
  CURRENCY_TYPE = "cur",
  PERMISSION = "perm",
  ROLE = "role",
  SUBJECT_NAME = "sub",
  OWNER_NAME = "owner",
  ACCOUNT_PATH = "path",
  STATUS = "sts",
}

export const getFieldFromToken = (value: JwtToken) => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token")?.split(".")[1];
    return JSON.parse(window.atob(claim))[value];
  }
  return null;
};

export const demoUser = () => {
  return getFieldFromToken(JwtToken.SUBJECT_NAME)?.startsWith(demoUserPrefix);
};

export const getUsername = () => {
  return demoUser() ? "Demo User" : sessionStorage.getItem("username");
};

export const getRoleFromToken = (): string => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token").split(".")[1];
    return JSON.parse(window.atob(claim)).role;
  }
  return null;
};

export const getPermissionFromToken = () => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token").split(".")[1];
    return JSON.parse(window.atob(claim)).perm;
  }
  return null;
};

export const getCurrencyTypeFromToken = () => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token").split(".")[1];
    return JSON.parse(window.atob(claim)).cur;
  }
  return 0;
};

export const getSapTokenFromToken = () => {
  var sapToken = sessionStorage.getItem("sap_token");
  if (sapToken) {
    return sapToken;
  }
  return null;
};

export const getHouseIdFromToken = () => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token").split(".")[1];
    return JSON.parse(window.atob(claim)).hid;
  }
  return null;
};

export const getSportsBookFromToken = () => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token").split(".")[1];
    return JSON.parse(window.atob(claim)).org;
  }
  return null;
};

export const getStatusFromToken = () => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token").split(".")[1];
    return JSON.parse(window.atob(claim)).status;
  }
  return null;
};

export const getParentIdFromToken = () => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token").split(".")[1];
    return JSON.parse(window.atob(claim)).pid;
  }
  return null;
};

export const getAccountPathFromToken = () => {
  if (sessionStorage.getItem("jwt_token")) {
    const claim = sessionStorage.getItem("jwt_token").split(".")[1];
    return JSON.parse(window.atob(claim)).path;
  }
  return null;
};

export const ThousandFormatter = (num: any) => {
  if (num > 999) {
    return (num / 1000).toFixed() + "K";
  } else {
    return num;
  }
};

export function formatTime(date: any) {
  try {
    return moment(date).format("HH:mm");
  } catch (err) {
    console.log(date, err);
    return date;
  }
}

export const maskAccountNumber = (accountNumber: string | number) => {
  if (accountNumber) {
    const accountStr = accountNumber.toString();
    const hideLength = accountStr.length >= 4 ? 4 : accountStr.length - 1;
    const firstFourDigits = accountStr.slice(0, hideLength);
    const maskedPart = "*".repeat(accountStr.length - hideLength);
    return firstFourDigits + maskedPart;
  }
  return "";
};

export const getBetTimeoutByMarket: { [key: string]: number } = {
  FANCY: 1,
  MO: 1,
  BM: 1,
  BINARY: 1,
  CASINO: 1,
  OT: 1,
};

export function getSportDetailsBySlug(slug) {
  for (let [key, value] of SPORTS_MAP) {
    if (value.slug === slug) {
      return { name: value.name, id: value.id };
    }
  }
  return null; // return null if the slug is not found
}
