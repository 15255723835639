import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdDateRange } from "react-icons/md";

interface ReactDatePickerProps {
  date: any;
  setDate: any;
}

const ReactDatePicker: React.FC<ReactDatePickerProps> = (props) => {
  const { date, setDate } = props;

  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  return (
    <div className="relative w-full max-w-xs mx-auto">
      <DatePicker
        className="w-full text-center border py-1.5 px-0 rounded-[20px] cursor-pointer pr-10"
        selected={new Date(date)}
        dateFormat="dd-MM-yyyy"
        onChange={setDate}
        dayClassName={(dt) => {
          const isSelected = isSameDay(new Date(dt), new Date(date));
          return "text-sm " + (isSelected ? "!bg-fuchsia-600 !text-white" : "");
        }}
        popperClassName="z-50"
        popperPlacement="bottom-start"
        popperContainer={({ children }) => (
          <div style={{ zIndex: 1000 }}>{children}</div>
        )}
        portalId="root-portal"
      />
      <MdDateRange className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
    </div>
  );
};

export default ReactDatePicker;
