import React from "react";

const Deposit: React.FC = () => {
  return (
    <div className="flex flex-col pt-px pb-20 mt-3.5 w-full bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6 max-md:max-w-full">
      {/* <div className="flex flex-col justify-center items-start text-[14px] font-medium text-white rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
      <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
        <div className="flex flex-col justify-center w-[50%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
          <div className="flex min-w-[170px]">
            <div className=" bg-[#804091] h-8 w-full py-2 pr-4 pl-2">
              Deposit
            </div>
            <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[7px] w-4 h-8"></div>
            <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-8 -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-8"></div>
          </div>
        </div>
      </div>
      </div> */}
      <div className="mx-7 mt-4 text-base font-semibold text-center text-stone-900 tracking-[3.52px] max-md:mr-2.5 max-md:max-w-full">
        DEPOSIT FUNDS
      </div>
      <form className="mx-7 mt-4 max-md:mr-2.5 max-md:max-w-full">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Amount
            </label>
            <input
              type="number"
              min="0"
              step="0.01"
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Payment Method
            </label>
            <select className="mt-1 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <option>Credit Card</option>
              <option>Bank Transfer</option>
              <option>Cryptocurrency</option>
            </select>
          </div>
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Deposit Funds
          </button>
        </div>
      </form>
    </div>
  );
};

export default Deposit;
