/**
 * This code was generated by Builder.io.
 */
import React from "react";
import GameGrid from "./GameGrid";
import Modal from "../../Modal/Modal";

interface LiveCasinoViewProps {
  games: any;
  providerList: any;
  categoryList: any;
  selectedProvider: string;
  searchTerm: string;
  setSearchTerm: Function;
  setSelectedProvider: Function;
  selectedGameType: string;
  setSelectedGameType: Function;
  dialogShow: boolean;
  setDialogShow: Function;
  handleGameClick: Function;
  title: string;
}

const LiveCasino: React.FC<LiveCasinoViewProps> = (props) => {
  const {
    games,
    title,
    selectedProvider,
    selectedGameType,
    setSelectedGameType,
    setSelectedProvider,
    searchTerm,
    providerList,
    categoryList,
    setSearchTerm,
    setDialogShow,
    dialogShow,
    handleGameClick,
  } = props;
  return (
    <main className="w-full flex gap-5 max-md:flex-col">
      <GameGrid
        providerList={providerList}
        categoryList={categoryList}
        title={title}
        games={games}
        selectedProvider={selectedProvider}
        selectedGameType={selectedGameType}
        setSelectedGameType={setSelectedGameType}
        setSelectedProvider={setSelectedProvider}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleGameClick={handleGameClick}
      />
      <Modal
        isOpen={dialogShow}
        title="NOTICE"
        onClose={() => setDialogShow(false)}
      >
        Access required for gameplay. Please log in to proceed.
      </Modal>
    </main>
  );
};

export default LiveCasino;
