/**
 * This code was generated by Builder.io.
 */
import React from "react";
import MenuItems from "./MenuItems";

const Layout: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col mx-auto w-full bg-zinc-100 max-w-[480px]">
      <main>
        <MenuItems />
      </main>
    </div>
  );
};

export default Layout;
