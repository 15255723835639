/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Casino from "./Casino";
import Modal from "../../Modal/Modal";

interface LiveCasinoViewProps {
  games: any;
  providerList: any;
  selectedProvider: string;
  searchTerm: string;
  setSearchTerm: Function;
  setSelectedProvider: Function;
  selectedGameType: string;
  setSelectedGameType: Function;
  dialogShow: boolean;
  setDialogShow: Function;
  handleGameClick: Function;
  title: string;
  categoryList: any;
}

const WinBuzzGaming: React.FC<LiveCasinoViewProps> = (props) => {
  const {
    games,
    title,
    selectedProvider,
    selectedGameType,
    setSelectedGameType,
    setSelectedProvider,
    searchTerm,
    providerList,
    setSearchTerm,
    setDialogShow,
    dialogShow,
    handleGameClick,
    categoryList
  } = props;
  return (
    <div className="flex flex-col mx-auto w-full bg-zinc-100 max-w-[480px]">
      <Casino
        title={title}
        providerList={providerList}
        games={games}
        selectedProvider={selectedProvider}
        selectedGameType={selectedGameType}
        setSelectedGameType={setSelectedGameType}
        setSelectedProvider={setSelectedProvider}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setDialogShow={setDialogShow}
        dialogShow={dialogShow}
        handleGameClick={handleGameClick}
        categoryList={categoryList}
      />
      <Modal
        isOpen={dialogShow}
        title="NOTICE"
        onClose={() => setDialogShow(false)}
      >
        Access required for gameplay. Please log in to proceed.
      </Modal>
    </div>
  );
};

export default WinBuzzGaming;
