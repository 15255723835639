import moment from "moment";
import React from "react";
import { transactionTypesMap } from "../../../store/reports/reportSelectors";

interface TurnoverHistoryTableProps {
  records: any;
}

const TurnoverHistoryTable: React.FC<TurnoverHistoryTableProps> = (props) => {
  const { records } = props;
  const headings = [
    "Event Date",
    "Event Name",
    "Transaction Type",
    "Market",
    "Market Type",
    "Amount",
    "Turnover Balance",
  ];

  return (
    <>
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500">
            {headings.map((heading, index) => (
              <th
                key={index}
                className={`px-2 py-1 border-r font-[500] text-[14px] leading-[16.2px] ${
                  index < headings.length - 1 ? "border-[#757575]" : ""
                }`}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records?.map((row, index) => (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {moment(row.event_date).format("DD/MM/YYYY")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {transactionTypesMap[row.transaction_type]}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.event_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.market_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.market_type}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.amount}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.turnover_balance}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {records && records.length === 0 && (
        <div className=" h-12 flex justify-center items-center">No history found</div>
      )}
    </>
  );
};

export default TurnoverHistoryTable;
