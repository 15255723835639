/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import WithdrawDesktop from "../components/Desktop/Withdraw/WithdrawComponent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import {
  createNewAccount,
  deleteAccount,
  getPaymentProviders,
  withdrawFunds,
} from "../store/withdraw/withdrawThunk";
import {
  API_PAYMENT_METHODS,
  API_PAYMENT_METHODS_MAP,
  PAYMENT_METHODS,
} from "../components/Desktop/MyProfile/ProfileTabs/utils/types";
import {
  AddAccountFormType,
  AddAccountPayloadType,
  AddUpiAccountFormType,
  AddUpiAccountPayloadType,
  DeleteAccountParamsType,
  WithdrawFormType,
} from "../components/Desktop/Withdraw/utils/types";
import {
  getAddAccountFromInitialState,
  getAddUpiAccountInitialForm,
  getInitialWithdrawForm,
} from "../components/Desktop/Withdraw/utils/Withdraw.utils";
import { PaymentMethodObjType } from "../store/withdraw/withdrawSlice";
import { CURRENCY_TYPES } from "../common/commonTypes";
import { useNavigate } from "react-router-dom";
import WithdrawMobile from "../components/Mobile/MyProfileWithdraw/WinBuzzGaming/WithdrawMobile";

const initialAddAccountFormState = getAddAccountFromInitialState();
const initialWithdrawForm = getInitialWithdrawForm();
const initialAddUpiAccountState = getAddUpiAccountInitialForm();

interface WithdrawComponentProps {}

const WithdrawComponent: React.FC<WithdrawComponentProps> = () => {
  const navigate = useNavigate();
  const [currentSelectedAccount, setCurrentSelectedAccount] =
    useState<PaymentMethodObjType>();
  const { paymentMethodList } = useSelector(
    (state: RootState) => state.withdraw
  );
  const dispatch = useDispatch<AppDispatch>();

  const [showAddAccountForm, setShowAddAccountForm] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PAYMENT_METHODS>(PAYMENT_METHODS.BANK_TRANSFER);

  useEffect(() => {
    dispatch(getPaymentProviders(selectedPaymentMethod));
  }, [dispatch, selectedPaymentMethod]);

  useEffect(() => {
    setCurrentSelectedAccount(null);
    resetForms();
    setShowAddAccountForm(false);
  }, [selectedPaymentMethod]);

  const [withdrawFormData, setWithdrawFormData] =
    useState<WithdrawFormType>(initialWithdrawForm);

  const [addAccountFormData, setAddAccountFormData] =
    useState<AddAccountFormType>(initialAddAccountFormState);

  const [addUpiAccountFormData, setAddUpiAccountFormData] =
    useState<AddUpiAccountFormType>(initialAddUpiAccountState);

  const handleWithdrawFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    withdrawFormData[event.target.id] = event.target.value;
    setWithdrawFormData({ ...withdrawFormData });
  };

  const handleAddAccountFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    addAccountFormData[event.target.id] = event.target.value;
    setAddAccountFormData({ ...addAccountFormData });
  };

  const handleAddUpiAccountFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    addUpiAccountFormData[event.target.id] = event.target.value;
    setAddUpiAccountFormData({ ...addUpiAccountFormData });
  };

  const resetForms = () => {
    // TODO: Make this work with initialFormData from utils
    setAddAccountFormData({
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      branchName: "",
      ifscCode: "",
      displayName: "",
    });
    setWithdrawFormData({ amount: "", notes: "" });
  };

  const isWithdrawSaveBtnDisabled = !(
    withdrawFormData.amount && withdrawFormData.notes
  );

  const checkAddAccountSaveBtnEnabledStatus = () => {
    if (selectedPaymentMethod === PAYMENT_METHODS.BANK_TRANSFER) {
      return !(
        addAccountFormData.accountNumber &&
        addAccountFormData.accountHolderName &&
        addAccountFormData.displayName &&
        addAccountFormData.bankName &&
        addAccountFormData.branchName &&
        addAccountFormData.ifscCode
      );
    } else {
      return !(
        addUpiAccountFormData.accountHolderName &&
        addUpiAccountFormData.displayName &&
        addUpiAccountFormData.upiId
      );
    }
  };

  const isAddAccountSaveBtnDisabled = checkAddAccountSaveBtnEnabledStatus();

  const onSuccessCallback = () => {
    resetForms();
    navigate("/my_transactions");
  };

  const onWithdrawFormSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let payload;
    if (selectedPaymentMethod === PAYMENT_METHODS.BANK_TRANSFER) {
      payload = {
        amount: withdrawFormData.amount,
        notes: withdrawFormData.notes,
        payment_method_id: currentSelectedAccount.id.toString(),
        payment_method: API_PAYMENT_METHODS.BANK_TRANSFER,
        currency_type: CURRENCY_TYPES.INR,
        mobile_number: "9876543211",
        onSuccessCallback: onSuccessCallback,
      };
    } else {
      payload = {
        amount: withdrawFormData.amount,
        notes: withdrawFormData.notes,
        payment_method_id: currentSelectedAccount.id.toString(),
        currency_type: CURRENCY_TYPES.INR,
        onSuccessCallback: onSuccessCallback,
      };
    }

    dispatch(withdrawFunds(payload));
  };

  const addAccountSuccess = () => {
    dispatch(getPaymentProviders(selectedPaymentMethod));
    resetForms();
  };

  const onAddAccountSaveBtnClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let payload: AddUpiAccountPayloadType | AddAccountPayloadType = null;
    if (selectedPaymentMethod === PAYMENT_METHODS.UPI) {
      payload = {
        accountHolderName: addUpiAccountFormData.accountHolderName,
        accountNumber: addUpiAccountFormData.upiId,
        displayName: addUpiAccountFormData.displayName,
        ifscCode: "",
        paymentMethod: API_PAYMENT_METHODS.UPI_TRANSFER,
        onSuccessCallback: addAccountSuccess,
      };
    } else {
      payload = {
        accountHolderName: addAccountFormData.accountHolderName,
        accountNumber: addAccountFormData.accountNumber,
        bankName: addAccountFormData.bankName,
        branchName: addAccountFormData.branchName,
        displayName: addAccountFormData.displayName,
        ifscCode: addAccountFormData.accountNumber,
        paymentMethod: API_PAYMENT_METHODS_MAP[selectedPaymentMethod],
        onSuccessCallback: addAccountSuccess,
      };
    }
    dispatch(createNewAccount(payload));
  };

  const onSavedAccountCardClick = (account: PaymentMethodObjType) => {
    setShowAddAccountForm(false);
    setCurrentSelectedAccount(account);
  };

  const onAddNewAccountPress = () => {
    setShowAddAccountForm(true);
  };

  const onAccountDeleteSuccess = () => {
    dispatch(getPaymentProviders(selectedPaymentMethod));
    setCurrentSelectedAccount(null);
  };

  const onAccountDeleteClick = (account: PaymentMethodObjType) => {
    const deleteAccountParams: DeleteAccountParamsType = {
      paymentMethodId: account.id.toString(),
      onSuccessCallback: onAccountDeleteSuccess,
    };
    dispatch(deleteAccount(deleteAccountParams));
  };

  return (
    <>
      {isMobile ? (
        <WithdrawMobile
          addUpiAccountFormData={addUpiAccountFormData}
          handleAddUpiAccountFormChange={handleAddUpiAccountFormChange}
          addAccountFormData={addAccountFormData}
          currentSelectedAccount={currentSelectedAccount}
          handleAddAccountFormChange={handleAddAccountFormChange}
          handleWithdrawFormChange={handleWithdrawFormChange}
          isWithdrawSaveBtnDisabled={isWithdrawSaveBtnDisabled}
          isAddAccountSaveBtnDisabled={isAddAccountSaveBtnDisabled}
          onAccountDeleteClick={onAccountDeleteClick}
          onAddAccountSaveBtnClick={onAddAccountSaveBtnClick}
          onAddNewAccountPress={onAddNewAccountPress}
          onSavedAccountCardClick={onSavedAccountCardClick}
          onWithdrawFormSave={onWithdrawFormSave}
          paymentMethodList={paymentMethodList}
          resetForms={resetForms}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          showAddAccountForm={showAddAccountForm}
          withdrawFormData={withdrawFormData}
        />
      ) : (
        <WithdrawDesktop
          addUpiAccountFormData={addUpiAccountFormData}
          handleAddUpiAccountFormChange={handleAddUpiAccountFormChange}
          isAddAccountSaveBtnDisabled={isAddAccountSaveBtnDisabled}
          addAccountFormData={addAccountFormData}
          currentSelectedAccount={currentSelectedAccount}
          handleAddAccountFormChange={handleAddAccountFormChange}
          handleWithdrawFormChange={handleWithdrawFormChange}
          isWithdrawSaveBtnDisabled={isWithdrawSaveBtnDisabled}
          onAccountDeleteClick={onAccountDeleteClick}
          onAddAccountSaveBtnClick={onAddAccountSaveBtnClick}
          onAddNewAccountPress={onAddNewAccountPress}
          onSavedAccountCardClick={onSavedAccountCardClick}
          onWithdrawFormSave={onWithdrawFormSave}
          paymentMethodList={paymentMethodList}
          resetForms={resetForms}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          showAddAccountForm={showAddAccountForm}
          withdrawFormData={withdrawFormData}
        />
      )}
    </>
  );
};

export default WithdrawComponent;
