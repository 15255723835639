import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAccSTmtRecords,
  fetchMyBets,
  fetchOpenBetsRiskAPI,
  fetchOpenBetsRiskMgmtAPI,
  fetchPLByMarketAPI,
  fetchPLStatementRecords,
  fetchTransactionRequestAPI,
  fetchTurnoverHistoryAPI,
  fetchUplineRecordsAPI,
  getBonusDataAPI,
} from "../../api/reports";
import { setErrorMsg } from "../common/common.Slice";
import { cFactor, Filters, pageSize, PLRecordMktLvl } from "./reportSelectors";
import {
  setBackTotal,
  setBets,
  setBonusData,
  setExposureMap,
  setItems,
  setLayTotal,
  setMarketTotal,
  setNetMarket,
  setPageToken,
  setPlStatement,
  setRecords,
  setTransactionRequest,
  setTurnover,
} from "./reportsSlice";
import { logout } from "../auth/authThunk";

export const fetchData = createAsyncThunk(
  "reports/fetchData",
  async (filters: Filters, thunkAPI) => {
    try {
      const response = await fetchMyBets(filters, pageSize);
      let betList = response?.orders;
      for (const bet of betList) {
        bet.stakeAmount = bet.stakeAmount / cFactor;
        bet.payOutAmount = bet.payOutAmount / cFactor;
      }
      thunkAPI.dispatch(setBets(betList));
      thunkAPI.dispatch(setPageToken(response?.pageToken));
    } catch (err) {
      thunkAPI.dispatch(setBets([]));
    }
  }
);

export const fetchPLByMarket = createAsyncThunk(
  "reports/fetchPLByMarket",
  async (filters: Filters, thunkAPI) => {
    try {
      const response = await fetchPLByMarketAPI(filters, pageSize);
      if (response.status === 200) {
        if (response.data?.orders) {
          let records: PLRecordMktLvl[] = response.data?.orders;
          thunkAPI.dispatch(setItems(records));
          let backCount = 0;
          let layCount = 0;
          let marketAmt = 0;
          for (let record of records) {
            if (record.betType === "BACK") {
              backCount += record.payOutAmount;
            } else {
              layCount += record.payOutAmount;
            }
            marketAmt += record.payOutAmount;
          }
          thunkAPI.dispatch(setBackTotal(backCount / cFactor));
          thunkAPI.dispatch(setLayTotal(layCount / cFactor));
          thunkAPI.dispatch(setMarketTotal(marketAmt / cFactor));
          thunkAPI.dispatch(
            setNetMarket(
              marketAmt / cFactor +
                (filters.selectedMarket.commission
                  ? filters.selectedMarket.commission
                  : 0)
            )
          );
        }
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.error(err.response.data.error);
      }
    }
  }
);

export const fetchRecords = createAsyncThunk(
  "reports/fetchRecords",
  async (filters: Filters, thunkAPI) => {
    try {
      if (!filters.bettingCurrency) return false;
      const response = await fetchAccSTmtRecords(filters, pageSize);
      let statements = response?.data.accountStatement;
      for (const statement of statements) {
        // statement.amount = statement.amount / cFactor;
        statement.balanceAfter = statement.balanceAfter / cFactor;
      }
      thunkAPI.dispatch(setRecords(statements));
      thunkAPI.dispatch(setPageToken(response?.data?.nextPageToken));
    } catch (err) {
      if (err?.response) {
        setErrorMsg(err.response.data.message);
      }
      if (err.response && err.response.status === 401) {
        thunkAPI.dispatch(logout());
      }
    }
  }
);

export const fetchPLStatement = createAsyncThunk(
  "reports/fetchPLStatement",
  async (filters: Filters, thunkAPI) => {
    try {
      setErrorMsg(null);
      const response = await fetchPLStatementRecords(filters, pageSize);
      const pl_records = response?.plEntries;
      for (const pl of pl_records) {
        pl.profit = pl.profit / cFactor;
        pl.commission = pl.commission / cFactor;
      }
      thunkAPI.dispatch(setPlStatement(pl_records));
      thunkAPI.dispatch(setPageToken(response?.nextPageToken));
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
      console.log(err);
      thunkAPI.dispatch(setPlStatement([]));
    }
  }
);

export const fetchOpenBetsRisk = createAsyncThunk(
  "reports/fetchOpenBetsRisk",
  async (eventId: string, thunkAPI) => {
    try {
      if (!eventId || !sessionStorage.getItem("jwt_token")) return;
      const response = await fetchOpenBetsRiskAPI(eventId);
      thunkAPI.dispatch(setExposureMap(response?.marketExposureMap));
    } catch (e) {
      console.log(e);
    }
  }
);

export const fetchOpenBetsRiskMgmt = createAsyncThunk(
  "reports/fetchOpenBetsRiskMgmt",
  async (multiMarketData: any, thunkAPI) => {
    try {
      if (!multiMarketData?.length) return;
      let eventIds = [];
      let marketIds = [];
      multiMarketData.forEach((item) => {
        eventIds.push(item?.eventId);
        marketIds.push(item?.marketId);
      });
      const response = await fetchOpenBetsRiskMgmtAPI(eventIds, marketIds);
      thunkAPI.dispatch(setExposureMap(response?.marketExposureMap));
    } catch (e) {
      console.log(e);
    }
  }
);

export const fetchUplineRecords = async (
  nRec,
  page,
  startDate,
  endDate,
  sportId,
  competitionId,
  eventId
) => {
  try {
    let params = {
      groupBy: "SPORT",
      nRec: nRec,
      page: page,
      startDate: startDate.startOf("day").toISOString(),
      endDate: endDate.endOf("day").toISOString(),
    };
    if (sportId !== null) {
      params["sportId"] = sportId;
      params["groupBy"] = "COMPETITION";
    } else {
      return params;
    }
    if (competitionId !== null) {
      params["competitionId"] = competitionId;
      params["groupBy"] = "EVENT";
    } else {
      return params;
    }
    if (eventId !== null) {
      params["eventId"] = eventId;
      params["groupBy"] = "MARKET";
    }
    const response = await fetchUplineRecordsAPI(params);
    if (response.status === 200) {
      setRecords(response.data);
    } else {
      throw new Error(response.data);
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchTransactionRequest = createAsyncThunk(
  "reports/fetchTurnoverHistory",
  async (filters: Filters, thunkAPI) => {
    try {
      setErrorMsg(null);
      const response = await fetchTransactionRequestAPI(filters, pageSize);
      const items = response.transactions;
      for (const item of items) {
        item.amount = item.amount / cFactor;
      }
      thunkAPI.dispatch(setTransactionRequest(items));
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
    }
  }
);

export const fetchTurnoverHistory = createAsyncThunk(
  "reports/fetchTurnoverHistory",
  async (filters: Filters, thunkAPI) => {
    try {
      const api_key = sessionStorage.getItem("jwt_token");
      const claims = api_key.split(".")[1];
      const username = JSON.parse(window.atob(claims)).sub;
      const response = await fetchTurnoverHistoryAPI(
        filters,
        pageSize,
        username
      );
      thunkAPI.dispatch(setTurnover(response?.turnover_entries));
    } catch (err) {
      console.log(err);
    }
  }
);

export const getBonusData = createAsyncThunk(
  "reports/getBonusData",
  async (filters: Filters, thunkAPI) => {
    try {
      const auth_key = sessionStorage.getItem("jwt_token");
      const claims = auth_key.split(".")[1];
      const userId = JSON.parse(window.atob(claims)).aid;
      const response = await getBonusDataAPI(filters, pageSize, userId);
      thunkAPI.dispatch(setBonusData(response?.data?.bonuses));
    } catch (err) {
      console.log(err);
      setBonusData([]);
    }
  }
);
