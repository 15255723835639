/**
 * This code was generated by Builder.io.
 */
import React from "react";
import crouselImage1 from "../../../assets/CrouselImage/CrouselImg1.webp";
import crouselImage2 from "../../../assets/CrouselImage/CrouselImg2.webp";
import crouselImage3 from "../../../assets/CrouselImage/CrouselImg3.webp";
import crouselImage4 from "../../../assets/CrouselImage/CrouselImg4.webp";
import crouselImage5 from "../../../assets/CrouselImage/CrouselImg5.webp";
import crouselImage6 from "../../../assets/CrouselImage/CrouselImg6.webp";
import crouselImage7 from "../../../assets/CrouselImage/CrouselImg7.webp";
import crouselImage8 from "../../../assets/CrouselImage/CrouselImg8.webp";

const carouselImages = [
  crouselImage1,
  crouselImage2,
  crouselImage3,
  crouselImage4,
  crouselImage5,
  crouselImage6,
  crouselImage7,
  crouselImage8,
];

interface CarouselProps {
  games: any;
  getBannerGameUrl: Function;
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const { games, getBannerGameUrl } = props;

  return (
    <div className="grid grid-cols-4 gap-1 mb-4">
      {games?.slice(0, 8)?.map((game, index) => (
        <img
          key={index}
          src={game.trendingThumbnail}
          onClick={() => {
            getBannerGameUrl(
              game?.gameId,
              game?.gameName,
              game?.gameCode,
              game?.providerName,
              game?.subProviderName
            );
          }}
          alt={`Carousel ${index + 1}`}
          className="w-full h-auto aspect-[2.17]"
        />
      ))}
    </div>
  );
};

export default Carousel;
