import React, { useEffect, useState } from 'react';
import plus from '../../../assets/plus.png'
import minus from '../../../assets/minus.png'
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { fetchBalance } from "../../../store/auth/authThunk";
import { addOpenBets, clearExchangeBets, clearSuccessBets, exchangeBetOddsChange, removeExchangeBet, setBettingInprogress, setExchangeBetStake } from "../../../store/betSlip/betSlipSlice";
import { fetchCasinoOpenBets, fetchOpenBets } from "../../../store/betSlip/betSlipThunk";
import { AlertDTO } from "../../../models/Alert";
import { showToast } from "../../../store/common/commonSlice";
import { getAllMarketsByEvent } from "../../../store/catalog/catalogSelectors";
import { RootState } from "../../../store";
import { BinaryBetrequest, BsResponse, PlaceBetRequest } from '../../../models/BsData';
import { ButtonVariable } from '../../../models/ButtonVariables';
import { EventDTO } from '../../../models/common/EventDTO';
import { AxiosResponse } from 'axios';
import { PROVIDER_ID } from '../../../constants/Branding';
import API from '../../../api';
import { useLocation } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";
import { NumberFormatBase, NumericFormat } from 'react-number-format';
import { FaMinus, FaPlus, FaSpinner } from 'react-icons/fa';
import Loader from '../../Loader/Loader';
import OpenBetsTab from '../../OpenBets/OpenBets';
import { SuspendedMarketDTO } from '../../../models/common/SuspendedMarketDTO';
import { triggerMultiFetchOrders, updateMultiSuspendedMarkets } from '../../../store/multiMarket/multiMarketSlice';
import { updateSuspendedMarkets } from '../../../store/catalog';
import { getBetTimeoutByMarket } from '../../../utils/commonUtils';
import { cFactor } from '../../../store/reports/reportSelectors';
import { checkPNStompClientSubscriptions } from '../../../webSocket/pnWebSocket';
import { useMarketLocalState } from '../../../hooks/storageHook';
import { setTriggerOrders } from '../../../store/catalog/catalogSlice';
import { UserBet } from '../../../models/UserBet';

interface BettingSlipProps {
    balance: number;
    exposure: number;
    bets: PlaceBetRequest[];
    // oneClickAmount: number;
    // isOneClickEnable: boolean;
    exchangeBetOddsChange: (index: number, odds: number) => void;
    removeExchangeBet: (index: number) => void;
    setExchangeBetStake: (
        index: number,
        amount: number,
        type: 'ADD' | 'SET'
    ) => void;
    eventData: EventDTO;
    buttonVariables: ButtonVariable[];
    bettingInprogress: boolean;
    cancelBetSuccess?: (betId: string) => void;
    setBettingInprogress: (val: boolean) => void;
    mobileView?: boolean;
    // setBetStartTime?: any;
    // setAddNewBet?: any;
    showToast: Function;
    triggerMultiFetchOrders: Function;
    setTriggerOrders: Function;
    clearExchangeBets: Function;
    openBets: UserBet[];
}

interface BetTabProps {
    betSlipsCount: number;
    openBetsCount: number;
    onTabChange: (tabId: string) => void;
    activeTab: string;
}

interface AmountButtonProps {
    amount: number;
}

interface BetDetailProps {
    betDetail: PlaceBetRequest;
    dispatch: Function;
    index?: number;
    bettingInprogress: boolean;
}

const TabOptions: React.FC<BetTabProps> = ({ betSlipsCount, openBetsCount, onTabChange, activeTab }) => (
    <div className="flex gap-4 w-full self-stretch  text-sm font-medium text-center bg-white rounded-t-lg border-b border-fuchsia-900">
        <button
            onClick={() => onTabChange('tab1')}
            className={`grow shrink my-auto py-2.5 w-1/2 rounded-tl-md ${activeTab === 'tab1' ? 'bg-gradient-to-br from-[#592669] to-[#ea89ff] text-white' : 'text-black'}`}
        >
            Bet Slips ({betSlipsCount})
        </button>
        <button
            onClick={() => onTabChange('tab2')}
            className={`grow shrink my-auto w-1/2 py-2.5  ${activeTab === 'tab2' ? 'bg-gradient-to-br from-[#592669] to-[#ea89ff] text-white' : 'text-black'}`}
        >
            Open Bets ({openBetsCount})
        </button>
    </div>
);

const BetDetails: React.FC<BetDetailProps> = ({
    betDetail,
    dispatch,
    index,
    bettingInprogress
}) => (
    <div className={`flex justify-between gap-6 p-2 mt-2.5 ${betDetail?.betType === "BACK" ? ` bg-blue-200 border-blue-200 ` : ` bg-[#F9CFCE] `} rounded-md border-solid ${isMobile ? 'w-[300px]' : 'w-[260px]'} text-stone-900`}>
        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loader size="h-16 w-16" color="text-red-500" />
        </div> */}
        <div className="flex flex-col">
            <div className="text-[14px] font-semibold">{betDetail?.eventName}</div>
            <div className="self-start text-[14px] font-medium">
                {betDetail?.marketType === 'PREMIUM' ? (
                    <>
                        {betDetail?.marketName}
                        <span className=" text-stone-900 ">@{' '}{betDetail?.outcomeDesc}</span>
                    </>
                ) : betDetail?.marketType === 'FANCY' ? (
                    <>
                        {betDetail?.marketName} {betDetail?.outcomeDesc}
                    </>
                ) : (
                    <>
                        {betDetail?.outcomeDesc}
                        <span className=" text-stone-900 ">
                            @{' '}
                            {betDetail?.displayOddValue
                                ? betDetail?.displayOddValue
                                : betDetail?.oddValue}
                        </span>
                    </>
                )}
            </div>
        </div>

        <div>
            <button
                disabled={bettingInprogress}
                onClick={() => dispatch(removeExchangeBet(index))}
            >
                <IoIosClose
                    className="cursor-pointer size-6"
                />
            </button>
        </div>
    </div>
);

const BetSlip: React.FC<BettingSlipProps> = (props) => {
    const {
        balance,
        exposure,
        bets,
        eventData,
        cancelBetSuccess,
        exchangeBetOddsChange,
        removeExchangeBet,
        setExchangeBetStake,
        buttonVariables,
        bettingInprogress,
        setBettingInprogress,
        showToast,
        triggerMultiFetchOrders,
        setTriggerOrders,
        clearExchangeBets,
        openBets,
        // setBetStartTime,
        // setAddNewBet,
    } = props;
    const multiMarketData = useMarketLocalState();
    const { pathname } = useLocation();

    const [activeTab, setActiveTab] = useState('tab1');
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const jwt = sessionStorage.getItem('jwt_token');
    const dispatch = useAppDispatch();

    const betStatus = async (market: string) => {
        const startTime = new Date();
        while (1) {
            const timeNow = new Date();
            if (timeNow.getTime() - startTime.getTime() > 15000) {
                showToast({
                    message: "Bet not placed, timeout.",
                    type: "error"
                });
                break;
            }

            let response: AxiosResponse<any>;
            response = await API.get('/bs/bet-status', {
                headers: {
                    Authorization: sessionStorage.getItem('jwt_token'),
                },
            });

            if (
                response.data.message === 'BETTING_IN_PROGRESS' ||
                response.data.betStatus === 'PENDING'
            ) {
                await new Promise((f) =>
                    setTimeout(f, getBetTimeoutByMarket[market] * 500)
                );
                continue;
            } else if (response.data.message === 'Market suspended') {
                let suspendedMarket: SuspendedMarketDTO = {
                    // TODO: pass provider Id later
                    providerId: '',
                    sportId: bets[0]?.sportId,
                    competitionId: bets[0]?.seriesId,
                    eventId: bets[0]?.eventId,
                    // TODO
                    marketType:
                        bets[0]?.marketType === 'MO'
                            ? 'MATCH_ODDS'
                            : bets[0]?.marketType === 'BM'
                                ? 'BOOKMAKER'
                                : bets[0]?.marketType,
                    marketId: bets[0]?.marketId,
                    suspend: true,
                };
                window.location.pathname === '/exchange_sports/multi-markets'
                    ? updateMultiSuspendedMarkets(suspendedMarket)
                    : updateSuspendedMarkets(suspendedMarket);
            }
            return response;
        }
    };

    const betPlaceHandler = async () => {
        checkPNStompClientSubscriptions();

        if (!sessionStorage.getItem('jwt_token')) {
            return showToast({
                message: "You have to login to place bet(s).",
                type: "error"
            });
        }

        if (bets[0].amount < 1) {
            return showToast({
                message: "Minimum stake amount required.",
                type: "error"
            });
        }

        if (ValidateOdds(bets)) {
            return showToast({
                message: "Wrong odds",
                type: "error"
            });
        }

        if (+bets[0].oddLimt) {
            switch (bets[0].marketType) {
                case 'MO':
                    if (bets[0].oddValue > +bets[0].oddLimt) {
                        return showToast({
                            message: `Bet not accepted. Rate over ${bets[0].oddLimt}`,
                            type: "error"
                        });
                    }
                    break;

                case 'BM':
                    if ((bets[0].oddValue + 100) / 100 > +bets[0].oddLimt) {
                        return showToast({
                            message: `Bet not accepted. Rate over ${bets[0].oddLimt}`,
                            type: "error"
                        });
                    }
                    break;

                case 'FANCY':
                    if (bets[0].oddValue / 100 + 1 > +bets[0].oddLimt) {
                        return showToast({
                            message: `Bet not accepted. Rate over ${bets[0].oddLimt}`,
                            type: "error"
                        });
                    }
                    break;
            }
        }

        // if (bets[0].amount >= balance - exposure) {
        //   return toast.error(
        //     <div>
        //       <ErrorIcon /> {'Insufficient Balance.'}
        //     </div>
        //   );
        // }

        if (
            (bets[0]?.minStake !== 0 &&
                bets[0]?.minStake / cFactor > bets[0].amount) ||
            (bets[0]?.maxStake !== 0 && bets[0]?.maxStake / cFactor < bets[0].amount)
        ) {
            return showToast({
                message: `Total stake should not be less than ${bets[0]?.minStake / cFactor
                    } and
              should not exceed ${bets[0]?.maxStake / cFactor} `,
                type: "error"
            });
        }

        // Remove spinner for fancy
        if (bets[0].marketType === 'FANCY' || bets[0].marketType === 'BM') {
            setShowSpinner(false);
        }

        if (!bets[0].oddValue) {
            showToast({
                message: "Invalid odds",
                type: "error"
            });
        }

        let binaryPayload: BinaryBetrequest = null;
        let addNewBet = true;
        let url = '';
        try {
            for (const data of bets) {
                // Cancel Bet for edit unMatched bet
                if (data.betId) {
                    addNewBet = false;
                    // setAddNewBet(false);
                    // await cancelBetHandler(data);

                    const postBody = {
                        cancelBets: [{ betId: data.betId, cancelSize: 0 }],
                        marketId: data.marketId,
                        eventId: data.eventId,
                        sportId: data.sportId,
                        seriesId: data.seriesId,
                        // operatorId: OPERATORID,
                        providerId: PROVIDER_ID,
                    };

                    try {
                        const cancelResponse = await API.post(
                            `/bs/cancel-sap-bet`,
                            postBody,
                            {
                                headers: {
                                    Authorization: sessionStorage.getItem('jwt_token'),
                                },
                            }
                        );

                        setShowSpinner(true);
                        if (cancelResponse?.data?.status !== 'RS_OK') {
                            return showToast({
                                message: "Unable to cancel the old bet.",
                                type: "error"
                            });
                        } else if (cancelResponse?.data?.status === 'RS_OK') {
                            cancelBetSuccess(data.betId);
                        }
                    } catch (err) {
                        setShowSpinner(false);
                        return showToast({
                            message: "Unable to cancel bet.",
                            type: "error"
                        });
                    }
                }

                setBettingInprogress(true);
                switch (data.marketType) {
                    case 'MO': {
                        url = '/place-matchodds-bet';
                        break;
                    }
                    case 'BM': {
                        url = '/place-bookmaker-bet';
                        break;
                    }
                    case 'FANCY': {
                        url = '/place-fancy-bet';
                        break;
                    }
                    case 'PREMIUM': {
                        url = '/place-premium-bet';
                        break;
                    }
                }
            }

            // setAddNewBet(true);
            let response: AxiosResponse<BsResponse>;
            try {
                response = await API.post('/bs' + url, bets[0], {
                    headers: {
                        Authorization: jwt,
                    },
                    timeout: 1000 * 20,
                });

                if (binaryPayload !== null) {
                    response = await API.post('/binary/single-bet', binaryPayload, {
                        headers: {
                            Authorization: jwt,
                        },
                    });
                }
            } catch (e) {
                setShowSpinner(false);
                setBettingInprogress(false);
                return showToast({
                    message: e.response?.data?.message
                        ? e.response?.data?.message
                        : 'Something went wrong',
                    type: "success"
                });
            }

            if (response && response?.status === 200) {
                let statusResponse: any;
                if (response?.data?.success) {
                    statusResponse = await betStatus(bets[0].marketType);
                    if (statusResponse) {
                        if (statusResponse.data['success']) {
                            dispatch(clearSuccessBets());
                            showToast({
                                message: statusResponse.data['message'],
                                type: "success"
                            });

                            if (addNewBet) {
                                addOpenBets(statusResponse.data.newBets);
                                fetchOpenBets({ eventId: bets[0]?.eventId });
                                setTimeout(() => {
                                    addOpenBets(statusResponse.data.newBets);
                                    setTriggerOrders();
                                }, 1000);
                            }
                            fetchBalance();
                        } else {
                            showToast({
                                message: `Bet failed - ${statusResponse.data['message']}`,
                                type: "error"
                            });
                        }
                    }
                }
                // setBetStartTime(new Date());
            } else {
                showToast({
                    message: response?.data?.message
                        ? response.data.message
                        : 'Bet is not placed, Try again',
                    type: "error"
                });
            }
            setBettingInprogress(false);
            setShowSpinner(false);
            if (bets[0].amount) {
                clearExchangeBets();
            }
            if (pathname.includes('/multimarket') && multiMarketData) {
                let eventIds = [];
                multiMarketData[0].forEach((item) => {
                    eventIds.push(item?.eventId);
                });
                eventIds = eventIds?.filter((item) => (item ? true : false));
                if (eventIds.length > 0) triggerMultiFetchOrders(bets[0]?.eventId);
            }
        } catch (ex) {
            setBettingInprogress(false);
            setShowSpinner(false);
            if (ex.response) {
                showToast({
                    message: "Previous bet in progress.Bet not placed",
                    type: "error"
                });
            } else {
                showToast({
                    message: "Something went wrong",
                    type: "error"
                });
            }
        }
        setBettingInprogress(false);
        setShowSpinner(false);
    };

    const onChangeOddHandler = (
        index: any,
        data: PlaceBetRequest,
        control: number
    ) => {
        if (data.oddValue === 1) {
            return false;
        }

        let value;
        if (control === 1) {
            let inc;
            if (data.oddValue < 2) inc = 0.01;
            else if (data.oddValue >= 2 && data.oddValue < 3) inc = 0.02;
            else if (data.oddValue >= 3 && data.oddValue < 4) inc = 0.05;
            else if (data.oddValue >= 4 && data.oddValue < 6) inc = 0.1;
            else if (data.oddValue >= 6 && data.oddValue < 10) inc = 0.2;
            else if (data.oddValue >= 10 && data.oddValue < 20) inc = 0.5;
            else if (data.oddValue >= 20 && data.oddValue < 30) inc = 1;
            else if (data.oddValue >= 30 && data.oddValue < 50) inc = 2;
            else if (data.oddValue >= 50 && data.oddValue < 100) inc = 5;
            else if (data.oddValue >= 100 && data.oddValue < 1000) inc = 10;
            else inc = 10;
            // value =
            //   data.marketType !== 'FANCY'
            //     ? data.marketType === 'CASINO'
            //       ? data.casinoOdds
            //       : (data.oddValue + inc).toFixed(2)
            //     : data.oddsSize;
            value =
                data.marketType !== 'FANCY'
                    ? (data.oddValue + inc).toFixed(2)
                    : data.oddValue.toFixed(2);
        } else {
            let inc;
            if (data.oddValue <= 2) inc = 0.01;
            else if (data.oddValue > 2 && data.oddValue <= 3) inc = 0.02;
            else if (data.oddValue > 3 && data.oddValue <= 4) inc = 0.05;
            else if (data.oddValue > 4 && data.oddValue <= 6) inc = 0.1;
            else if (data.oddValue > 6 && data.oddValue <= 10) inc = 0.2;
            else if (data.oddValue > 10 && data.oddValue <= 20) inc = 0.5;
            else if (data.oddValue > 20 && data.oddValue <= 30) inc = 1;
            else if (data.oddValue > 30 && data.oddValue <= 50) inc = 2;
            else if (data.oddValue > 50 && data.oddValue <= 100) inc = 5;
            else if (data.oddValue > 100 && data.oddValue <= 1000) inc = 10;
            else inc = 10;
            // value =
            //   data.marketType !== 'FANCY'
            //     ? data.marketType === 'CASINO'
            //       ? data.casinoOdds
            //       : (data.oddValue - inc).toFixed(2)
            //     : data.oddsSize;
            value =
                data.marketType !== 'FANCY'
                    ? (data.oddValue - inc).toFixed(2)
                    : data.oddValue.toFixed(2);
        }

        exchangeBetOddsChange(index, parseFloat(value.toString()));
    };

    const ValidateOdds = (bets: PlaceBetRequest[]) => {
        const matchOdds = eventData?.matchOdds?.runners;

        // Match Odds odd check
        if (matchOdds) {
            for (let bet of bets) {
                if (
                    bet?.marketType === 'MO' &&
                    bet?.marketId === eventData.matchOdds.marketId
                ) {
                    for (let mo of matchOdds) {
                        if (mo.runnerId === bet.outcomeId) {
                            if (bet.betType === 'BACK') {
                                if (mo?.backPrices) {
                                    if (bet.oddValue <= mo.backPrices[0]?.price) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            } else {
                                if (mo?.layPrices) {
                                    console.log(mo.layPrices[0].price, '<=', bet.oddValue);
                                    if (mo.layPrices[0]?.price <= bet.oddValue) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (eventData && eventData.status === 'IN_PLAY') {
            setActiveTab('tab1');
        }
        if (openBets?.length && !isMobile) {
            setActiveTab('tab2');
        } else {
            setActiveTab('tab1');
        }
    }, [openBets?.length]);

    useEffect(() => {
        if (openBets?.length > 0 && !isMobile) setActiveTab('tab2');
    }, [openBets?.length]);

    useEffect(() => {
        if (bets?.length > 0) setActiveTab('tab1');
    }, [bets]);

    return (
        <section className="relative flex flex-col rounded-lg w-full overflow-hidden mb-2 h-fit bg-white mt-4 pb-3">
            {bettingInprogress ? (
                <Loader />
            ) : null}
            <div className="flex flex-col items-center  w-full bg-white rounded-lg border-solid border-neutral-200">
                <TabOptions
                    betSlipsCount={bets?.length}
                    openBetsCount={openBets?.length}
                    onTabChange={setActiveTab}
                    activeTab={activeTab}
                />
                {
                    bets && bets?.length > 0 ?
                        activeTab === 'tab1' &&
                        bets?.map((data, index) => (
                            <>
                                <BetDetails
                                    betDetail={data}
                                    dispatch={dispatch}
                                    index={index}
                                    bettingInprogress={bettingInprogress}
                                />

                                <div className="flex gap-5 justify-between mt-1.5 w-[90%] text-[14px] font-medium max-w-[400px] text-stone-900">
                                    <div className="text-center">Odd Value</div>
                                    <div className="text-right">Amount</div>
                                </div>
                                <div className="flex justify-between relative gap-1 w-[90%] text-[14px] font-medium whitespace-nowrap max-w-[400px] text-neutral-500">
                                    <div className=' w-1/2 flex justify-between items-center cursor-pointer px-1 bg-neutral-200 h-[30px] leading-[30px] text-center rounded-full '>
                                        <button
                                            onClick={() => onChangeOddHandler(index, data, 0)}
                                            className='bg-[#757575] rounded-3xl h-[24px] w-[24px] flex justify-center items-center cursor-pointer '
                                            disabled={
                                                bettingInprogress || data.marketType !== 'MO'
                                            }>
                                            <FaMinus color='#fff' />
                                        </button>
                                        {data?.oddValue}
                                        <button
                                            onClick={() => onChangeOddHandler(index, data, 1)}
                                            className='bg-[#757575] rounded-3xl h-[24px] w-[24px] flex justify-center items-center cursor-pointer '
                                            disabled={
                                                bettingInprogress || data.marketType !== 'MO'
                                            }>
                                            <FaPlus color='#fff' />
                                        </button>
                                    </div>

                                    <NumericFormat
                                        className={`px-1 h-[30px] ${isMobile ? 'max-w-[160px]' : 'max-w-[120px]'} leading-[30px] text-center text-black bg-neutral-200 rounded-[100px]`}
                                        value={data.amount > 0 ? data.amount : 0}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        allowNegative={false}
                                        disabled={bettingInprogress}
                                        onValueChange={(val) => {
                                            setExchangeBetStake(index, val.floatValue, 'SET');
                                        }}
                                    />
                                    <span
                                        className={
                                            bettingInprogress
                                                ? `disabled text b-text absolute ${isMobile ? 'right-6' : 'right-2'} top-[6px] text-[12px]`
                                                : `text b-text absolute ${isMobile ? 'right-6' : 'right-2'}  top-[6px] text-[12px] cursor-pointer`
                                        }
                                        onClick={() => {
                                            if (!bettingInprogress) {
                                                setExchangeBetStake(index, -data.amount, 'ADD');
                                            }
                                        }}
                                    >
                                        Clear
                                    </span>
                                </div>

                                <div className=' mx-auto'>
                                    <div className="flex justify-center flex-wrap gap-[5px] mt-2.5 text-[14px] w-full text-center whitespace-nowrap text-stone-900">
                                        {buttonVariables.map((button) => (
                                            <button className={`py-1.5 ${isMobile ? 'max-w-[180px] w-[110px]' : 'max-w-[80px] w-[80px]'} text-center border border-solid bg-purple-800 bg-opacity-0 border-stone-900 rounded-[100px]`}
                                                onClick={() =>
                                                    setExchangeBetStake(index, button.stake, 'ADD')
                                                }>
                                                +{button?.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                {/* Action buttons */}
                                {data.marketType !== 'PREMIUM' && (
                                    <div className="flex gap-1 mt-1.5 w-[90%] text-[14px] font-bold tracking-widest text-center text-white max-w-[400px]">
                                        <button
                                            className="w-[50%] py-1.5 bg-sky-500 rounded-[100px]"
                                            disabled={bettingInprogress}
                                            onClick={() =>
                                                setExchangeBetStake(
                                                    index,
                                                    Math.floor(balance - exposure),
                                                    'SET'
                                                )
                                            }>ALL IN
                                        </button>
                                        <button
                                            className="w-[50%] py-1.5 whitespace-nowrap bg-amber-400 rounded-[100px]"
                                            disabled={bettingInprogress}
                                            onClick={() =>
                                                setExchangeBetStake(index, data.maxStake, 'SET')
                                            }>MAX
                                        </button>
                                    </div>
                                )}

                                {/* Profit loss */}
                                <div className="flex gap-5 justify-between mt-2.5 w-[90%] max-w-[400px] text-stone-900">
                                    <div className="flex flex-col my-auto text-[14px] font-medium">
                                        <div>Your profit/loss as per placed bet</div>
                                        <div className="self-start mt-2">Total Amount (in PTS)</div>
                                    </div>
                                    <div className="flex flex-col text-sm font-semibold whitespace-nowrap">
                                        <div>
                                            {data.marketType === 'MO'
                                                ? data.betType === 'BACK'
                                                    ? (data.amount * (data.oddValue - 1)).toFixed(2)
                                                    : data.amount.toFixed(2)
                                                : data.marketType === 'BM'
                                                    ? data.betType === 'BACK'
                                                        ? (
                                                            data.amount *
                                                            ((data.oddValue + 100) / 100 - 1)
                                                        ).toFixed(2)
                                                        : data.amount.toFixed(2)
                                                    : data.marketType === 'PREMIUM'
                                                        ? (data.amount * (data.oddValue - 1)).toFixed(2)
                                                        : data.betType === 'BACK'
                                                            ? (data.amount * ((data.oddValue + 100) / 100 - 1)) //size
                                                                .toFixed(2)
                                                            : data.amount.toFixed(2)}
                                        </div>
                                        <div>
                                            {bets.reduce((a, v) => a + v.amount, 0).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                                <button className="px-16 py-1.5 mt-4 w-[90%] bg-gradient-to-br from-[#592669] to-[#ea89ff] text-sm font-medium text-center text-white rounded-md cursor-pointer"
                                    disabled={bettingInprogress}
                                    onClick={betPlaceHandler}>Place Bet</button>
                            </>
                        ))
                        : activeTab === "tab1" && (
                            <div className="flex items-center justify-center text-[14px] text-[#757575] h-full pt-7 pb-6 ">
                                Place bet to see it here.
                            </div>
                        )}
                {activeTab === 'tab2' && (
                    <OpenBetsTab />
                )}
            </div>
        </section>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        bets: state.betSlip.bets,
        selectedEvent: state.catalog.selectedEvent,
        selectedEventType: state.catalog.selectedEventType,
        buttonVariables: state.betSlip.buttonVariables,
        bettingInprogress: state.betSlip.bettingInprogress,
        oneClickAmount: state.betSlip.oneClickAmount,
        isOneClickEnable: state.betSlip.isOneClickEnable,
        balance: state.auth.balanceSummary.balance,
        exposure: state.auth.balanceSummary.exposure,
        openBets: state.betSlip.openBets
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        fetchBalance: () => dispatch(fetchBalance()),
        // cancelBetSuccess: (betId: string) => dispatch(cancelBetSuccess(betId)),
        exchangeBetOddsChange: (index: number, odds: number) =>
            dispatch(exchangeBetOddsChange({ index, odds })),
        removeExchangeBet: (index: number) => dispatch(removeExchangeBet(index)),
        setExchangeBetStake: (index: number, amount: number, type: 'ADD' | 'SET') =>
            dispatch(setExchangeBetStake({ index, amount, type })),
        fetchOpenBets: (eventId: string | string[], sportId?: string) =>
            dispatch(fetchOpenBets({ eventId, sportId })),
        addOpenBets: (openBets) => dispatch(addOpenBets(openBets)),
        setBettingInprogress: (val: boolean) => dispatch(setBettingInprogress(val)),
        fetchCasinoOpenBets: (gameType: string, mId: string) =>
            dispatch(fetchCasinoOpenBets({ gameType, mId })),
        showToast: (alert: AlertDTO) => dispatch(showToast(alert)),
        triggerMultiFetchOrders: (eventId: string) =>
            dispatch(triggerMultiFetchOrders(eventId)),
        setTriggerOrders: () => dispatch(setTriggerOrders()),
        clearExchangeBets: () => clearExchangeBets(),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetSlip);
