import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { RootState } from "../store";
import { fetchRecords,getBonusData } from "../store/reports/reportsThunk";
import { defaultFilters, Filters } from "../store/reports/reportSelectors";
import { connect } from "react-redux";
import moment from "moment";
import BonusStatement from "../components/Desktop/BonusStatement/BonusStatement";

import BonusStatementMobile from '../components/Mobile/BonusStatement/BonusStatement/BonusStatement'


interface BonusStatementViewProps {
  records: any;
  fetchRecords: Function;
  handleDateChange: Function;
  filters: any;
  setFilters: any;
  getBonusData:any;
}

const BonusStatementView: React.FC<BonusStatementViewProps> = (props) => {
    const { records, getBonusData } = props;
    const [filters, setFilters] = useState<Filters>(defaultFilters);
  
    useEffect(() => {
      getBonusData(filters);
    }, [filters]);
  
    const handleDateChange = (newFromDate: Date, newToDate: Date) => {
      setFilters({
        ...filters,
        startDate: moment(newFromDate),
        endDate: moment(newToDate),
      });
    };
  return (
    <>
      {isMobile ? <BonusStatementMobile records={records} filters={filters} setFilters={setFilters} handleDateChange={handleDateChange} getBonusData={getBonusData} />
       :<BonusStatement records={records} filters={filters} setFilters={setFilters} handleDateChange={handleDateChange} getBonusData={getBonusData}/>}
      </>
    
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    records: state.reports.records,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getBonusData: (event: any) => dispatch(getBonusData(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BonusStatementView);

