import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../store';
import { fetchButtonVariables } from '../../store/betSlip/betSlipThunk';
import { connectToWS, disconnectToWS } from '../../webSocket/websocket';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../store/storeHooks';
import { clearExchangeBets } from '../../store/betSlip/betSlipSlice';
import { SelectedObj } from '../../models/SportsState';
import { EventType } from '../../models/EventType';
import { setEventType } from '../../store/catalog';
import { EXCH_SPORTS_MAP } from '../../constants/EventType';
import { SPORTS_MAP } from '../../constants/ExchangeEventTypes';
import { getSportDetailsBySlug } from '../../utils/commonUtils';

type SportsViewProps = {
    loggedIn: boolean;
    fetchButtonVariables: () => void;
    topicUrls: any;
    betFairWSConnected: boolean;
    setEventType: Function;
    contentConfig: any;
    selectedEventType: SelectedObj;
    eventTypes: EventType[];
    selectedCompetition: SelectedObj;
};

const SportsView: React.FC<SportsViewProps> = (props) => {
    const { loggedIn, fetchButtonVariables, topicUrls, betFairWSConnected, setEventType, contentConfig, selectedEventType, eventTypes, selectedCompetition } = props;
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const pathParams = useParams();
    const navigate = useNavigate();

    const eventType = pathParams['eventType'];

    // To get eventType on refresh
    useEffect(() => {
        if (eventType) {
            let eventDetails = getSportDetailsBySlug(eventType === "horserace" ? "horseracing": eventType);
            if (eventDetails?.id) {
                setEventType({ id: eventDetails?.id, name: eventDetails?.name, slug: eventType})
            }
        }
    }, [])

    useEffect(() => {
        if (loggedIn) {
            fetchButtonVariables();
        }
    }, [loggedIn]);

    // Websocket handler
    useEffect(() => {
        if (loggedIn && topicUrls?.matchOddsBaseUrl) {
            if (betFairWSConnected) {
                disconnectToWS();
            }
            const baseUrlsPayload = {
                matchOddsBaseUrl: topicUrls?.matchOddsBaseUrl,
                bookMakerAndFancyBaseUrl: topicUrls?.bookMakerBaseUrl,
                premiumBaseUrl: topicUrls?.premiumBaseUrl,
            };
            connectToWS(baseUrlsPayload);

            return () => {
                if (betFairWSConnected) {
                    disconnectToWS();
                }
            };
        }
    }, [loggedIn, topicUrls?.matchOddsBaseUrl, topicUrls?.bookMakerBaseUrl, topicUrls?.premiumBaseUrl]);


    useEffect(() => {
        dispatch(clearExchangeBets());
    }, []);

    return <Outlet />;
};

const mapStateToProps = (state: RootState) => {
    return {
        topicUrls: state?.catalog?.topicUrls,
        betFairWSConnected: state.catalog.betFairWSConnected,
        contentConfig: state.common.contentConfig,
        selectedEventType: state.catalog.selectedEventType,
        eventTypes: state.catalog.eventTypes,
        selectedCompetition: state.catalog.selectedCompetition
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        fetchButtonVariables: () => dispatch(fetchButtonVariables()),
        setEventType: (eventType: SelectedObj) => dispatch(setEventType(eventType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SportsView);
