/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import DatePicker from "../DatePicker";
import { defaultFilters, Filters } from "../../../../store/reports/reportSelectors";

interface TransactionalFilter {

  handleDateChange: Function;
  startDate:any;
  endDate:any;
  filters:any;

}
const TransactionFilter: React.FC<TransactionalFilter> = (props) => {
  const { handleDateChange,filters} = props;
  return (
    <form className="flex flex-col px-3 mt-2.5 w-full text-[14px] font-medium text-neutral-500">
      <DatePicker onDateChange={handleDateChange} startDate={filters.startDate} endDate={filters.endDate} />
    </form>
  );
};

export default TransactionFilter;
