import axios from 'axios';
import store from '../store'; // Adjust the path as needed
import { AppDispatch } from '../store'; // Adjust the path as needed
import { getBaseUrl } from './api-services/environment-url/environment-url';
import { logout } from '../store/auth/authThunk';

const API = axios.create({
  baseURL: getBaseUrl(process.env.REACT_APP_NODE_ENV, 'REACT_APP_REST_API_URL'),
  responseType: 'json',
  withCredentials: false,
  timeout: 10000, // 10 seconds
});

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('***********Unauthorized Error**********');
      const token = sessionStorage.getItem('jwt_token');
      if (token) {
        const dispatch = store.dispatch as AppDispatch;
        dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default API;
