/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { DEPOSIT_PAYMENT_TYPES, DepositPageProps } from "./utils/types";
import NavigationBarWrapper from "./NavigationBar";
import InputForm from "./InputComponents/InputForm";
import DepositUpiDetailsComp from "./DepositUpiDetails";
import { AccountDetails } from "./AccountDetails/AccountDetails";

const DepositDesktop: React.FC<DepositPageProps> = ({
  depositMethods,
  selectedDepositMethod,
  setSelectedDepositMethod,
  isDepositBtnDisabled,
  handleDepositAmountClick,
  bankDepositFormData,
  handleBankAccountFormChange,
  upiDepositPaymentDetails,
  handleReferenceIdChange,
  isConfirmPaymentBtnDisabled,
  setUpiReferenceId,
  upiReferenceId,
  bankDepositPaymentDetails,
  bankDepositAccountDetailsForm,
  bankAccountDetailsSelectedPaymentOpt,
  handleBankDetailsFormChange,
  handleConfirmPaymentBtnClick,
  isBankDepositFormDisabled,
}) => {
  return (
    <div className="flex flex-col p-2 pt-px pb-20 mt-3.5 w-full bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6 max-md:max-w-full">
      <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
        <div className="flex flex-col justify-center w-[50%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
          <div className="flex min-w-[170px]">
            <div className=" bg-[#804091] h-8 w-full py-2 pr-4 pl-2">Deposit</div>
            <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[7px] w-4 h-8"></div>
            <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-8 -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-8"></div>
          </div>
        </div>
      </div>
      <div className="mx-7 mt-6 text-base font-semibold text-center text-stone-900 tracking-[3.52px] max-md:mr-2.5 max-md:max-w-full">
        DEPOSIT FUNDS
      </div>
      <NavigationBarWrapper
        selectedPaymentType={selectedDepositMethod}
        setSelectedPaymentType={setSelectedDepositMethod}
      />
      <InputForm
        isBankDepositFormDisabled={isBankDepositFormDisabled}
        bankDepositFormData={bankDepositFormData}
        handleBankAccountFormChange={handleBankAccountFormChange}
        handleDepositAmountClick={handleDepositAmountClick}
        isDepositBtnDisabled={isDepositBtnDisabled}
      />
      {selectedDepositMethod === DEPOSIT_PAYMENT_TYPES.UPI &&
        upiDepositPaymentDetails && (
          <div className="mt-2">
            <DepositUpiDetailsComp
              handleBankDetailsFormChange={handleBankDetailsFormChange}
              bankDepositAccountDetailsForm={bankDepositAccountDetailsForm}
              bankAccountDetailsSelectedPaymentOpt={
                bankAccountDetailsSelectedPaymentOpt
              }
              upiDepositPaymentDetails={upiDepositPaymentDetails}
              handleReferenceIdChange={handleReferenceIdChange}
              upiReferenceId={upiReferenceId}
            />
          </div>
        )}

      {selectedDepositMethod === DEPOSIT_PAYMENT_TYPES.BANK &&
        bankDepositPaymentDetails && (
          <div className="mt-4">
            <AccountDetails
              bankAccountDetailsSelectedPaymentOpt={
                bankAccountDetailsSelectedPaymentOpt
              }
              bankDepositAccountDetailsForm={bankDepositAccountDetailsForm}
              handleBankDetailsFormChange={handleBankDetailsFormChange}
              bankDepositPaymentDetails={bankDepositPaymentDetails}
            />
          </div>
        )}

      {(bankDepositPaymentDetails || upiDepositPaymentDetails) && (
        <div className="mt-6 w-full">
          <button
            onClick={handleConfirmPaymentBtnClick}
            disabled={isConfirmPaymentBtnDisabled}
            className={`px-4 py-2 ${
              isConfirmPaymentBtnDisabled ? "bg-green-300" : "bg-green-600"
            } text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
          >
            Confirm Payment
          </button>
        </div>
      )}
    </div>
  );
};

export default DepositDesktop;
