import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MyBets from "../components/Desktop/MyProfile/ProfileTabs/MyBets";
import BettingApp from "../components/Mobile/MyProfile/MyBet/BettingApp";
import moment from "moment";
import { defaultFilters, Filters } from "../store/reports/reportSelectors";
import { fetchData } from "../store/reports/reportsThunk";
import { connect } from "react-redux";
import { RootState } from "../store";

type StoreProps = {
  bets: any;
  fetchData: Function;
  pageToken: string;
};

const MyBetsView: React.FC<StoreProps> = (props) => {
  const { bets, pageToken, fetchData } = props;

  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [nextPageToken, setNextPageToken] = useState<string>(null);
  const sports = [
    { name: "All", value: "All" },
    { name: "Cricket", value: "4" },
    { name: "Football", value: "1" },
    { name: "Tennis", value: "2" },
  ];
  const games = [
    { value: "All", label: "All", allow: true },
    {
      value: "SPORTS",
      label: "Sports",
    },
    {
      value: "SPORTS_BOOK",
      label: "Sportsbook",
    },
    { value: "PREMIUM", label: "Premium" },
    {
      value: "CASINO",
      label: "Casino",
    },
  ];

  const betStatus = [
    {value: "All", label: "All"},
    {value: "Open", label: "Open"},
    {value: "Settled", label: "Settled"},
    {value: "Won", label: "Won"},
    {value: "Lost", label: "Lost"},
    {value: "Void", label: "Void"}
  ]

  const nextPage = () => {
    setFilters({
      ...filters,
      pageToken: [...filters.pageToken, pageToken],
    });
  };

  const prevPage = () => {
    if (filters.pageToken.length > 0) {
      let pagetokens = filters.pageToken;
      pagetokens.pop();
      setFilters({ ...filters, pageToken: [...pagetokens] });
    }
    setNextPageToken(null);
  };

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  const handleDateChange = (newFromDate: Date, newToDate: Date) => {
    setFilters({
      ...filters,
      startDate: moment(newFromDate),
      endDate: moment(newToDate),
    });
  };

  return (
    <>
      {isMobile ? (
        <BettingApp
          sports={sports}
          betStatus={betStatus}
          games={games}
          bets={bets}
          fetchData={fetchData}
          nextPage={nextPage}
          prevPage={prevPage}
          handleDateChange={handleDateChange}
          filters={filters}
          setFilters={setFilters}
          nextPageToken={nextPageToken}
          setNextPageToken={setNextPageToken}
        />
      ) : (
        <MyBets
          sports={sports}
          betStatus={betStatus}
          games={games}
          bets={bets}
          fetchData={fetchData}
          nextPage={nextPage}
          prevPage={prevPage}
          handleDateChange={handleDateChange}
          filters={filters}
          setFilters={setFilters}
          pageToken={pageToken}
          nextPageToken={nextPageToken}
          setNextPageToken={setNextPageToken}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    bets: state.reports.bets,
    pageToken: state.reports.pageToken,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchData: (event: any) => dispatch(fetchData(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBetsView);
