/**
 * This code was generated by Builder.io.
 */
import React from "react";
import GameCard from "./GameCard";
import { Game } from "../../Desktop/LiveCasino/types";

interface GameGridProps {
  games: Game[];
  handleGameClick: Function;
}

const GameGrid: React.FC<GameGridProps> = ({ games, handleGameClick }) => {
  return (
    // <div className="px-[1.5rem]">
    <div className="overflow-x-scroll no-scrollbar w-full max-w-[446px] px-1">
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
      {games.map((game, index) => (
        <div
          key={index}
          className="flex flex-col w-[200px] max-md:w-[200px] bg-white"
        >
          <GameCard handleGameClick={handleGameClick} game={game} />
        </div>
      ))}
    </div>
  </div>
    // </div>
  );
};

export default GameGrid;
