import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MyTransaction from "../components/Desktop/MyTransaction/MyTransaction";
import { RootState } from "../store";
import {
  fetchPLStatement,
  fetchTransactionRequest,
} from "../store/reports/reportsThunk";
import { connect } from "react-redux";
import { defaultFilters, Filters } from "../store/reports/reportSelectors";
import moment from "moment";
import BettingProfitLoss from "../components/Desktop/ProfitLoss/BettingProfitLoss/BettingProfitLoss";
import ProfitLossMobile from "../components/Mobile/ProfitLoss/AccountStatement/ProfitLoss";
interface ProfitLossViewProps {
  plStatement: any;
  fetchPLStatement?: Function;
  pageToken: string;
}
const ProfitLossView: React.FC<ProfitLossViewProps> = (props) => {
  const { plStatement, fetchPLStatement, pageToken } = props;
  const [filters, setFilters] = useState<Filters>(defaultFilters);

  useEffect(() => {
    fetchPLStatement(filters);
  }, [filters]);

  const handleDateChange = (newFromDate: Date, newToDate: Date) => {
    setFilters({
      ...filters,
      startDate: moment(newFromDate),
      endDate: moment(newToDate),
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      pageToken: [...filters.pageToken, pageToken],
    });
  };

  const prevPage = () => {
    if (filters.pageToken.length > 0) {
      let pagetokens = filters.pageToken;
      pagetokens.pop();
      setFilters({ ...filters, pageToken: [...pagetokens] });
    }
  };

  return (
    <>
      {isMobile ? (
        <ProfitLossMobile
          pageToken={pageToken}
          prevPage={prevPage}
          nextPage={nextPage}
          setFilters={setFilters}
          filters={filters}
          plStatement={plStatement}
          fetchPLStatement={fetchPLStatement}
          handleDateChange={handleDateChange}
        />
      ) : (
        <BettingProfitLoss
          pageToken={pageToken}
          prevPage={prevPage}
          nextPage={nextPage}
          setFilters={setFilters}
          filters={filters}
          plStatement={plStatement}
          fetchPLStatement={fetchPLStatement}
          handleDateChange={handleDateChange}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    plStatement: state.reports.plStatement,
    pageToken: state.reports.pageToken,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchPLStatement: (event: any) => dispatch(fetchPLStatement(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfitLossView);
