import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { RootState } from '../../store';
import SVLS_API from '../../api/api-services/svls-api';
import { FaTruckLoading } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import MobHeader from '../Mobile/HeaderSection/MobHeader';
import Header from '../Desktop/HeaderSection/Header';

type StoreProps = {
  gameUrl: string;
  loggedIn: boolean;
  prefersDark?: string;
};

type RouteParams = {
  gamePath: string;
};

const SportsbookIframe: React.FC<StoreProps> = (props) => {
  const [gameSrc, setGameSrc] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { loggedIn } = props;

  const { gamePath } = useParams<RouteParams>();
  const navigate = useNavigate();

  const getGameUrl = async () => {
    if (loggedIn) {
      setLoading(true);
      const claims = sessionStorage?.getItem('jwt_token')?.split('.')[1];
      const userStatus = claims ? JSON.parse(window.atob(claims))?.status : 0;

      if (userStatus === 0 || userStatus === 3) {
        return navigate(`/home`);
      }
      let response;
      try {
        const reqBody = 'desktop';
        response = await SVLS_API.get(
          `/catalog/v2/sports-book/lobby-urls/${reqBody}`,
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );
      } catch (e) {
        console.log(e);
      }
      if (response) {
        if (response.data.url) {
          const callBackUrl = `&callBackUrl=https://dev.hypexexch.com/exchange_sports/inplay`;
          // if (prefersDark === 'yellow') {
          setGameSrc(
            response.data.url +
              callBackUrl
          );
        }
        setLoading(false);
      }
      setLoading(false);
    } else {
      navigate(`/`);
    }
  };

  useEffect(() => {
    getGameUrl();
  }, []);

  return (
    <div className="w-[100%] h-[90vh] ">
      {
        isMobile ?
          <MobHeader setShowSideBar={() => {}} />
        : (
          <Header selectedTab={''} setSelectedTab={() => {}} />
        )
      }
      {loading ? (
        <FaTruckLoading />
      ) : (
        <iframe
          src={gameSrc}
          title="Sports Book"
          allowFullScreen
          className='h-full w-full'
          sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
        ></iframe>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gameUrl: state.common.dcGameUrl,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(SportsbookIframe);
