import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MyWallet from "../components/Desktop/MyProfile/ProfileTabs/MyWallet";
import { defaultFilters, Filters } from "../store/reports/reportSelectors";
import moment from "moment";

const MyWalletView: React.FC = () => {
  return <MyWallet />;
};

export default MyWalletView;
