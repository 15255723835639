import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInplayEvent, getCasinoGame } from './catalogThunk';
import { EventsMap, PremiumMarketsMap, SecondaryMatchOddsMap, SelectedObj } from '../../models/SportsState';
import { SuspendedMarketDTOMap } from '../../models/common/SuspendedMarketDTO';
import { ExchangePriceDTO } from '../../models/SPEventDTO';
import { capitalizeWord, SPToBFIdMap } from '../../utils/commonUtils';
import { MatchOddsRunnerDTO } from '../../models/common/MatchOddsDTO';
import { EXCHANGE_EVENT_TYPES } from '../../constants/EventType';
import { EventType } from '../../models/EventType';
import moment from 'moment';
import { BookmakerDTO, BookmakerRunnerDTO } from '../../models/common/BookmakerDTO';
import { FancyMarketDTO } from '../../models/common/FancyMarketDTO';

interface CatalogState {
    details: Record<string, any>;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    events: EventsMap;
    suspendedMarketsMap: SuspendedMarketDTOMap;
    selectedCompetition: SelectedObj;
    secondaryMarketsMap: Record<string, any>;
    topicUrls: Record<string, any>;
    selectedEventType: SelectedObj;
    rgGames: [];
    bannerGames: [],
    egGames: [];
    selectedEvent: SelectedObj;
    loading: boolean;
    secondaryMatchOddsMap: Record<string, any>;
    premiumMarketsMap: PremiumMarketsMap;
    eventTypes: EventType[];
    triggerFetchMarkets: number;
    triggerFetchOrders: number;
    triggerBetStatus: number;
    initGames: [];
    games: [];
    casinoGames: [];
    providerList: [];
    categoryList: [];
    buttonVariables: [];
    gameSrc: string;
    betFairWSConnected: boolean;
    pushNotifWSConnection: boolean;
    sportsRadarWSConnected: boolean;
    dreamWSConnected: boolean;
}

const initialState: CatalogState = {
    details: {},
    status: 'idle',
    error: null,
    events: {},
    suspendedMarketsMap: {},
    selectedEventType: { id: '', name: '', slug: '' },
    rgGames: [],
    bannerGames: [],
    egGames: [],
    selectedCompetition: { id: '', name: '', slug: '' },
    secondaryMarketsMap: {},
    topicUrls: {
        matchOddsBaseUrl: '',
        matchOddsTopic: '',
        bookMakerBaseUrl: '',
        bookMakerTopic: '',
        fancyBaseUrl: '',
        fancyTopic: '',
        premiumBaseUrl: '',
        premiumTopic: '',
    },
    selectedEvent: { id: '', name: '', slug: '' },
    loading: false,
    secondaryMatchOddsMap: {},
    premiumMarketsMap: {},
    eventTypes: EXCHANGE_EVENT_TYPES,
    triggerFetchMarkets: null,
    triggerFetchOrders: null,
    triggerBetStatus: null,
    initGames: [],
    games: [],
    casinoGames: [],
    providerList: [],
    categoryList: [],
    buttonVariables: [],
    gameSrc: null,
    betFairWSConnected: false,
    pushNotifWSConnection: false,
    sportsRadarWSConnected: false,
    dreamWSConnected: false
};

const getSuspendValue = (
    suspendMarketsMap: SuspendedMarketDTOMap,
    providerId: string,
    sportId: string,
    competitionId: string,
    dtoEventId: string,
    dtoMarketType: string,
    dtoMarketId: string,
    dtoSuspsend: boolean
) => {
    let suspend: boolean;
    let key = dtoEventId + ':' + dtoMarketId;

    // case: for storing initial state from markets api call
    if (dtoSuspsend !== undefined) {
        suspend = dtoSuspsend;
        suspendMarketsMap[key] = {
            providerId: providerId,
            sportId: sportId,
            competitionId: competitionId,
            eventId: dtoEventId,
            marketType: dtoMarketType,
            marketId: dtoMarketId,
            suspend: dtoSuspsend,
        };
    } else {
        // case for odds updates from websockets
        // suspend field will not be present in odds update from websocket
        let suspendedMarket = suspendMarketsMap[key];
        if (!suspendedMarket) {
            suspend = false;
        } else if (suspendedMarket) {
            suspend = suspendedMarket.suspend;
        }
    }

    return suspend;
};

const getFancySuspendedValue = (
    suspendMarketsMap: SuspendedMarketDTOMap,
    providerId: string,
    sportId: string,
    competitionId: string,
    dtoEventId: string,
    dtoMarketType: string,
    dtoFancySuspend: boolean
) => {
    let fancySuspend: boolean;
    let key = dtoEventId + ':' + '*';
    if (suspendMarketsMap[key]) {
        // case for odds updates from websockets
        // suspend field will not be present in odds update from websocket
        let suspendedMarket = suspendMarketsMap[key];
        if (!suspendedMarket) {
            fancySuspend = false;
        } else if (suspendedMarket) {
            fancySuspend = suspendedMarket.suspend;
        }
    } else {
        fancySuspend = dtoFancySuspend;
        suspendMarketsMap[key] = {
            providerId: providerId,
            sportId: sportId,
            competitionId: competitionId,
            eventId: dtoEventId,
            marketType: dtoMarketType,
            marketId: '*',
            suspend: dtoFancySuspend,
        };
    }
    return fancySuspend;
};

const getMatchOddsSet = (prices: ExchangePriceDTO[]) => {
    let pricesSet: ExchangePriceDTO[] = [];
    if (prices?.length > 0) {
        for (let i = 0; i < 3; i += 1) {
            if (prices[i]) pricesSet.push(prices[i]);
            else pricesSet.push({ price: null, size: null });
        }
    }
    return pricesSet;
};

const getFancyCategory = (runnerName: string) => {
    return runnerName ? runnerName : 'All';
};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        resetExchangeState: (state) => {
            return {
                ...state,
                eventTypes: [],
                selectedEventType: { id: '1', name: 'Football', slug: 'football' },
                competitions: {},
                selectedCompetition: { id: '', name: '', slug: '' },
                events: {},
            };
        },
        setEventType: (state, action: PayloadAction<SelectedObj>) => {
            state.selectedEventType = action.payload;
        },
        setRgGames: (state, action: PayloadAction<[]>) => {
            state.rgGames = action.payload;
        },
        setBannerGames: (state, action: PayloadAction<[]>) => {
            state.bannerGames = action.payload;
        },
        setEgGames: (state, action: PayloadAction<[]>) => {
            state.egGames = action.payload;
        },
        setInitGames: (state, action: PayloadAction<any>) => {
            state.initGames = action.payload;
        },
        setGames: (state, action: PayloadAction<any>) => {
            state.games = action.payload;
        },
        setCasinoGames: (state, action: PayloadAction<any>) => {
            state.casinoGames = action.payload;
        },
        setProviderList: (state, action: PayloadAction<any>) => {
            state.providerList = action.payload;
        },
        setCategoryList: (state, action: PayloadAction<any>) => {
            state.categoryList = action.payload;
        },
        setButtonVariables: (state, action: PayloadAction<any>) => {
            state.buttonVariables = action.payload;
        },
        setGameSrc: (state, action: PayloadAction<any>) => {
            state.gameSrc = action.payload;
        },
        setCompetition: (state, action: PayloadAction<SelectedObj>) => {
            state.selectedCompetition = action.payload;
        },
        setExchEvent: (state, action: PayloadAction<SelectedObj>) => {
            state.selectedEvent = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateSecondaryMarkets: (state, action: PayloadAction<{ events: any[] }>) => {
            const events = action.payload.events;
            const marketsMap = { ...state.secondaryMarketsMap };
            let suspendMarketsMap = { ...state.suspendedMarketsMap };

            events?.forEach(eventData => {
                const eventId = eventData.eventId;

                // Initialize event data if not present
                if (!marketsMap[eventId]) {
                    marketsMap[eventId] = {
                        bookmakers: [],
                        enableBookmaker: false,
                        fancyMarkets: [],
                        enableFancy: false,
                        fancySuspended: false,
                    };
                }

                // Set BookmakerOdds Data
                const bookMakerOddsData = eventData.bookmakerOddsData;
                const prevBMMap = new Map();
                const bookmakers = marketsMap[eventId].bookmakers;
                let limitMap = new Map();

                for (const { marketId, commissionEnabled, marketLimits } of bookmakers) {
                    if (commissionEnabled !== undefined) {
                        prevBMMap.set(marketId, commissionEnabled);
                    }
                    if (marketLimits !== undefined) {
                        limitMap.set(marketId, marketLimits);
                    }
                }

                let bookMakerOdds = [];
                if (bookMakerOddsData && bookMakerOddsData.length) {
                    for (let br of bookMakerOddsData) {
                        let bmRunners = [];
                        let suspend = getSuspendValue(
                            suspendMarketsMap,
                            eventData?.providerId,
                            eventData?.sportId,
                            eventData?.competitionId,
                            eventId,
                            br?.marketType,
                            br?.marketId,
                            br?.suspended
                        );
                        for (let b of br.runners) {
                            bmRunners.push({
                                runnerId: b.runnerId ? b.runnerId : '',
                                runnerName: b.runnerName ? b.runnerName : '',
                                backPrice: b.backPrices[0]?.price,
                                backSize: b.backPrices[0]?.size,
                                layPrice: b.layPrices[0]?.price,
                                laySize: b.layPrices[0]?.size,
                                status: b.status ? b.status : '',
                                sort: b.sort,
                            });
                        }
                        bmRunners.sort((a, b) => +a.runnerId - +b.runnerId);

                        let index = bookMakerOdds.findIndex(itm => itm.marketId === br.marketId);
                        if (index > -1) {
                            bookMakerOdds[index] = {
                                ...bookMakerOdds[index],
                                suspend: suspend,
                                runners: bmRunners,
                                status: br.status ? br.status : 'OPEN',
                                commissionEnabled: br.commissionEnabled !== undefined ? br.commissionEnabled : bookMakerOdds[index].commissionEnabled,
                                marketLimits: br.marketLimits !== undefined ? br.marketLimits : bookMakerOdds[index].marketLimits,
                            };
                        } else {
                            bookMakerOdds.push({
                                suspend: suspend,
                                marketId: br.marketId ? br.marketId : '-1',
                                marketName: br.marketName ? br.marketName : 'Bookmaker',
                                runners: bmRunners,
                                status: br.status ? br.status : 'OPEN',
                                commissionEnabled: br.commissionEnabled !== undefined ? br.commissionEnabled : prevBMMap.get(br.marketId),
                                marketLimits: br.marketLimits !== undefined ? br.marketLimits : limitMap.get(br.marketId),
                            });
                        }
                    }
                }
                bookMakerOdds.sort((a, b) => a.marketName.localeCompare(b.marketName));

                marketsMap[eventId].bookmakers = bookMakerOdds;
                marketsMap[eventId].enableBookmaker = eventData.enableBookmaker;

                // Set Fancy markets data
                const fancyOddsData = eventData.sessionOddsData;
                const prevFancyMap = new Map();
                const fancyMarkets = marketsMap[eventId].fancyMarkets;

                for (const { marketId, commissionEnabled, marketLimits } of fancyMarkets) {
                    if (commissionEnabled !== undefined) {
                        prevFancyMap.set(marketId, commissionEnabled);
                    }
                    if (marketLimits !== undefined) {
                        limitMap.set(marketId, marketLimits);
                    }
                }

                let fancyOdds = [];
                if (fancyOddsData && fancyOddsData.length > 0) {
                    for (let f of fancyOddsData) {
                        if (!f.marketName.toLowerCase().split(' ').includes('bhav')) {
                            let suspend = getSuspendValue(
                                suspendMarketsMap,
                                eventData?.providerId,
                                eventData?.sportId,
                                eventData?.competitionId,
                                eventId,
                                f?.marketType,
                                f?.marketId,
                                f?.suspended
                            );
                            fancyOdds.push({
                                ...f,
                                marketId: f.marketId ? f.marketId : '',
                                marketName: f.marketName ? f.marketName : '',
                                status: f.status ? f.status : '',
                                sort: f.sort ? Number(f.sort) : 0,
                                layPrice: f.noValue ? (f.category === 'fancy3' || f.category === 'odd-even' ? f.noValue.toFixed(2) : f.noValue) : null,
                                backPrice: f.yesValue ? (f.category === 'fancy3' || f.category === 'odd-even' ? f.yesValue.toFixed(2) : f.yesValue) : null,
                                laySize: f.noRate ? f.noRate : null,
                                backSize: f.yesRate ? f.yesRate : null,
                                category: getFancyCategory(f.category),
                                commissionEnabled: f.commissionEnabled !== undefined ? f.commissionEnabled : prevFancyMap.get(f.marketId),
                                marketLimits: f.marketLimits !== undefined ? f.marketLimits : limitMap.get(f.marketId),
                                suspend: suspend,
                            });
                        }
                    }
                } else if (marketsMap[eventId].fancyMarkets) {
                    fancyOdds = marketsMap[eventId].fancyMarkets;
                    for (let fMarket of fancyOdds) {
                        fMarket.status = 'SUSPENDED';
                        fMarket.suspend = true;
                    }
                }
                fancyOdds.sort((a, b) => (a.sort - b.sort) || b.marketName.localeCompare(a.marketName));

                marketsMap[eventId].fancyMarkets = fancyOdds;
                marketsMap[eventId].enableFancy = eventData.enableFancy;
                marketsMap[eventId].fancySuspended = eventData.fancySuspended;
            });

            state.secondaryMarketsMap = marketsMap;
            state.suspendedMarketsMap = suspendMarketsMap;
        },
        updateTopicUrls: (state, action: PayloadAction<any>) => {
            console.log('topic urls - ', action.payload)
            if (action.payload?.matchOddsBaseUrl || action.payload?.bookMakerBaseUrl || action.payload?.premiumBaseUrl) {
                state.topicUrls = action.payload;
            }
        },
        disableEventData: (state, action: PayloadAction<any>) => {
            const { sportId, competitionId, eventId, disableEvent } = action.payload;
            const allEvents = { ...state.events };
            const allSecMarkets = { ...state.secondaryMarketsMap };

            if (allEvents[sportId][competitionId][eventId])
                if (disableEvent) {
                    const eData = allEvents[sportId][competitionId][eventId];
                    for (let runner of eData.matchOdds?.runners) {
                        runner.status = 'SUSPENDED';
                    }
                    allEvents[sportId][competitionId][eventId] = eData;
                } else {
                    delete allEvents[sportId][competitionId][eventId];
                }
            if (allSecMarkets[eventId])
                if (disableEvent) {
                    const secMarkets = allSecMarkets[eventId];
                    for (let bookmaker of secMarkets?.bookmakers) {
                        for (let runner of bookmaker.runners) {
                            runner.backPrice = '0';
                            runner.backSize = '0';
                            runner.layPrice = '0';
                            runner.laySize = '0';
                        }
                    }
                    for (let fanctMarket of secMarkets?.fancyMarkets) {
                        fanctMarket.status = 'SUSPENDED';
                    }
                    allSecMarkets[eventId] = secMarkets;
                } else {
                    delete allSecMarkets[eventId];
                }

            state.events = allEvents;
            state.secondaryMarketsMap = allSecMarkets;
        },
        updateSuspendedMarkets: (state, action: PayloadAction<any>) => {
            let suspendedMarket = action.payload;
            let allSuspendedMarketsMap = {
                ...state.suspendedMarketsMap,
            };
            const key = suspendedMarket.eventId + ':' + suspendedMarket.marketId;
            allSuspendedMarketsMap[key] = suspendedMarket;

            state.suspendedMarketsMap = allSuspendedMarketsMap;
        },
        updateCommissionMarkets: (state, action: PayloadAction<any>) => {
            let commissionMarket = action.payload;
            const eventId = commissionMarket.eventId;
            const marketType = commissionMarket.marketType;
            const marketId = commissionMarket.marketId;
            let secondaryMarketsMap = {
                ...state.secondaryMarketsMap,
            };
            let secondaryMarkets = secondaryMarketsMap[eventId];

            switch (marketType) {
                case 'BOOKMAKER': {
                    let bookmakerMarkets = secondaryMarkets.bookmakers;
                    if (bookmakerMarkets && bookmakerMarkets.length) {
                        for (let bm of bookmakerMarkets) {
                            if (bm.marketId === marketId)
                                bm.commissionEnabled = commissionMarket.commission;
                        }
                    }
                    break;
                }
                case 'FANCY': {
                    let fancyMarkets = secondaryMarkets.fancyMarkets;
                    if (fancyMarkets && fancyMarkets.length) {
                        for (let f of fancyMarkets) {
                            if (f.marketId === marketId)
                                f.commissionEnabled = commissionMarket.commission;
                        }
                    }
                    break;
                }
                default:
                    console.log('CommissionMarket: Invalid market type: ', marketType);
            }

            state.secondaryMarketsMap = secondaryMarketsMap;
        },
        fetchEventByCompetitionSuccess: (state, action: PayloadAction<any>) => {
            const events = action.payload.events;
            // Initialize allEvents and suspendMarketsMap
            const allEvents = { ...state.events };
            let suspendMarketsMap = { ...state.suspendedMarketsMap };

            events?.forEach(event => {
                const eId = event.sportId;
                let cId = event.competitionId?.split(':').join('_');
                let eventId = event.eventId?.split(':').join('_');

                // Add events map hierarchy
                if (!allEvents[eId]) {
                    allEvents[eId] = {};
                }
                if (!allEvents[eId][cId]) {
                    allEvents[eId][cId] = {};
                }

                let eData = event;
                let limitMap = new Map();
                if (allEvents[eId][cId][eventId]) {
                    eData = { ...allEvents[eId][cId][eventId] };
                    limitMap.set(
                        allEvents[eId][cId][eventId]?.matchOdds.marketId,
                        allEvents[eId][cId][eventId]?.matchOdds?.marketLimits
                    );
                } else {
                    eData = {
                        ...event,
                        status: event.status === 'OPEN' ? 'IN_PLAY' : event.status,
                    };
                }

                eData.competitionId = eData?.competitionId?.split(':').join('_');
                eData.eventId = eData?.eventId?.split(':').join('_');

                const eventName = eData?.eventName?.toLowerCase();
                let homeTeam = eData.homeTeam || '';
                let awayTeam = eData.awayTeam || '';
                if (
                    (homeTeam === '' &&
                        awayTeam === '' &&
                        (eventName?.includes(' v ') || eventName?.includes(' vs '))) ||
                    eventName?.includes(' vs. ')
                ) {
                    if (eventName?.includes(' vs. ')) {
                        homeTeam = eventName?.split(' vs. ')[0];
                        awayTeam = eventName?.split(' vs. ')[1];
                    } else {
                        homeTeam = eventName?.includes(' v ')
                            ? eventName?.split(' v ')[0].trim()
                            : eventName?.split(' vs ')[0].trim();
                        awayTeam = eventName?.includes(' v ')
                            ? eventName?.split(' v ')[1].trim().split(' - ')[0]
                            : eventName?.split(' vs ')[1].trim().split(' - ')[0];
                        homeTeam = capitalizeWord(homeTeam);
                        awayTeam = capitalizeWord(awayTeam);
                    }
                }

                // Set MatchOdds Data
                const matchOddsData = event.matchOddsData;
                const runners = [];
                if (matchOddsData) {
                    if (matchOddsData.runners && matchOddsData.runners.length > 0) {
                        let i = 0;
                        for (let e of matchOddsData.runners) {
                            if (e) {
                                let runnerName = e.runnerName ? e.runnerName : e.RunnerName;
                                if (runnerName === undefined) {
                                    runnerName = '';
                                }

                                if (
                                    eData?.provderId?.toLowerCase() !== 'sportradar' &&
                                    matchOddsData.marketName.toLowerCase().includes('winner')
                                ) {
                                    if (!runnerName.toLowerCase().includes('draw') && i === 0) {
                                        if (runnerName === '') {
                                            runnerName = homeTeam;
                                        } else if (runnerName !== awayTeam) {
                                            homeTeam = runnerName;
                                        }
                                    }

                                    if (!runnerName.toLowerCase().includes('draw') && i !== 0) {
                                        if (runnerName === '') {
                                            runnerName = awayTeam;
                                        } else if (runnerName !== homeTeam) {
                                            awayTeam = runnerName;
                                        }
                                    }
                                }

                                i += 1;
                                runners.push({
                                    runnerId: e.runnerId ? e.runnerId : e.runnerId,
                                    runnerName: runnerName,
                                    backPrices: getMatchOddsSet(e.backPrices),
                                    layPrices: getMatchOddsSet(e.layPrices ? e.layPrices : []),
                                    status: e.status,
                                    clothNumber: e?.clothNumber,
                                    jockeyName: e?.jockeyName,
                                    runnerAge: e?.runnerAge,
                                    runnerIcon: e?.runnerIcon,
                                    stallDraw: e?.stallDraw,
                                    trainerName: e?.trainerName,
                                });
                            }
                        }
                    }
                    let suspend = getSuspendValue(
                        suspendMarketsMap,
                        eData?.providerId,
                        eData?.sportId,
                        eData?.competitionId,
                        eData?.eventId,
                        eData?.marketType,
                        eData?.marketId,
                        matchOddsData?.suspended
                    );
                    const bLimits = eData?.matchOdds?.limits;
                    eData.matchOdds = {
                        marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
                        marketName: matchOddsData.marketName ? matchOddsData.marketName : '',
                        status: matchOddsData.status ? matchOddsData.status : '',
                        runners: runners,
                        limits: matchOddsData.limits ? matchOddsData?.limits : bLimits,
                        marketLimits: matchOddsData?.marketLimits ? matchOddsData?.marketLimits : limitMap.get(matchOddsData.marketId),
                        suspend: suspend,
                    };
                } else {
                    let matchOdds = eData.matchOdds;
                    if (matchOdds) {
                        eData.matchOdds = {
                            marketId: matchOdds?.marketId,
                            marketName: matchOdds?.marketName,
                            status: 'SUSPENDED',
                            runners: matchOdds?.runners,
                            limits: matchOddsData?.limits,
                            marketLimits: matchOddsData?.marketLimits,
                            suspend: true,
                        };
                    } else {
                        eData.matchOdds = {
                            marketId: '',
                            marketName: '',
                            status: 'SUSPENDED',
                            runners: [],
                            limits: null,
                            suspend: true,
                        };
                    }
                }

                if (event.raceMarkets?.length > 0) {
                    let markets = [];
                    for (const rm of event.raceMarkets) {
                        const runners = [];
                        let suspend = getSuspendValue(
                            suspendMarketsMap,
                            eData?.providerId,
                            eData?.sportId,
                            eData?.competitionId,
                            eData?.eventId,
                            eData?.marketType,
                            rm.marketId,
                            rm?.suspended
                        );
                        if (rm.runners && rm.runners.length > 0) {
                            let i = 0;
                            for (let e of rm.runners) {
                                if (e) {
                                    i += 1;
                                    runners.push({
                                        runnerId: e.runnerId,
                                        runnerName: e.runnerName,
                                        backPrices: getMatchOddsSet(e.backPrices),
                                        layPrices: getMatchOddsSet(e.layPrices),
                                        clothNumber: rm?.clothNumber,
                                        jockeyName: rm?.jockeyName,
                                        runnerAge: rm?.runnerAge,
                                        runnerIcon: rm?.runnerIcon,
                                        stallDraw: rm?.stallDraw,
                                        trainerName: rm?.trainerName,
                                        status: e.status,
                                    });
                                }
                            }
                        }
                        markets.push({
                            marketId: rm.marketId,
                            marketName: rm.marketName,
                            marketTime: rm.marketTime,
                            status: rm.status,
                            runners: runners,
                            suspend: suspend,
                        });
                    }
                    markets.sort((a, b) => (a.marketTime > b.marketTime ? 1 : b.marketTime > a.marketTime ? -1 : 0));
                    eData.raceMarkets = markets;
                }

                eData.homeTeam = homeTeam;
                eData.awayTeam = awayTeam;
                eData.secondaryMatchOddsMarketIds = event.secondaryMatchOddsMarketIds
                    ? event.secondaryMatchOddsMarketIds
                    : eData.secondaryMatchOddsMarketIds
                        ? eData.secondaryMatchOddsMarketIds
                        : [];
                eData.eventSlug = eData.eventSlug
                    ? eData.eventSlug
                    : eData.eventName
                        ? eData.eventName
                            .toLowerCase()
                            .replace(/[^a-z0-9]/g, ' ')
                            .replace(/ +/g, ' ')
                            .trim()
                            .split(' ')
                            .join('-')
                        : '';
                allEvents[eId][cId][eData.eventId] = eData;
            });

            if (action.payload?.events?.[0]?.onRefresh) {
                const firstEvent = action.payload?.events?.[0];
                let currentSelectedCompetition;
                let currentSelectedEvent;
                let currentSelectedEventType;

                try {
                    currentSelectedEventType =
                        state.selectedEventType.id === firstEvent.sportId
                            ? { ...state.selectedEventType }
                            : {
                                id: firstEvent.sportId,
                                name: EXCHANGE_EVENT_TYPES.filter((e) => e.id === firstEvent.sportId || e.id === SPToBFIdMap[firstEvent.sportId])[0].name,
                                slug: EXCHANGE_EVENT_TYPES.filter((e) => e.id === firstEvent.sportId || e.id === SPToBFIdMap[firstEvent.sportId])[0].slug,
                            };
                    currentSelectedCompetition =
                        state.selectedCompetition.id === firstEvent.competitionId
                            ? { ...state.selectedCompetition }
                            : {
                                id: firstEvent?.competitionId ? firstEvent.competitionId : '',
                                name: firstEvent?.competitionName ? firstEvent.competitionName : '',
                                slug: firstEvent?.competitionName
                                    ? firstEvent.competitionName
                                        .toLocaleLowerCase()
                                        .replace(/[^a-z0-9]/g, ' ')
                                        .replace(/ +/g, ' ')
                                        .trim()
                                        .split(' ')
                                        .join('-')
                                    : '',
                            };

                    currentSelectedEvent =
                        state.selectedEvent.id === firstEvent?.eventId
                            ? { ...state.selectedEvent }
                            : {
                                id: firstEvent?.eventId,
                                slug: firstEvent?.eventName
                                    .toLocaleLowerCase()
                                    .replace(/[^a-z0-9]/g, ' ')
                                    .replace(/ +/g, ' ')
                                    .trim()
                                    .split(' ')
                                    .join('-'),
                                name: firstEvent?.eventName,
                            };
                } catch (_) {
                    currentSelectedCompetition = { ...state.selectedCompetition };
                    currentSelectedEvent = { ...state.selectedEvent };
                    currentSelectedEventType = { ...state.selectedEventType };
                }
                state.events = allEvents;
                state.selectedEventType = currentSelectedEventType;
                state.selectedCompetition = currentSelectedCompetition;
                state.selectedEvent = currentSelectedEvent;
                state.suspendedMarketsMap = suspendMarketsMap;
            }

            state.events = allEvents;
        },
        updateSecondaryMatchOdds: (state, action: PayloadAction<any>) => {
            const eventData = action.payload;
            const eventId: string = eventData.eventId;
            const marketId: string = action.payload.marketId;
            let suspendMarketsMap: SuspendedMarketDTOMap = {
                ...state.suspendedMarketsMap,
            };

            const allSecMatchOddsMap = { ...state.secondaryMatchOddsMap };

            const matchOddsData = action.payload.matchOddsData;
            const runners: MatchOddsRunnerDTO[] = [];
            let runnersData: MatchOddsRunnerDTO[] = allSecMatchOddsMap[
                eventId + '-' + marketId
            ]?.runners?.length
                ? [...allSecMatchOddsMap[eventId + '-' + marketId]?.runners]
                : [];

            if (matchOddsData) {
                let suspend: boolean = getSuspendValue(
                    suspendMarketsMap,
                    eventData?.providerId,
                    eventData?.sportId,
                    eventData?.competitionId,
                    eventId,
                    matchOddsData?.marketType,
                    matchOddsData?.marketId,
                    matchOddsData?.suspended
                );
                if (matchOddsData.runners && matchOddsData.runners.length > 0) {
                    let data: any = {};
                    for (let e of matchOddsData.runners) {
                        if (e) {
                            data = runnersData?.find(
                                (item) => item?.runnerId === e?.runnerId
                            );

                            runners.push({
                                runnerId: e?.runnerId,
                                runnerName: e?.runnerName,
                                backPrices: getMatchOddsSet(e.backPrices),
                                layPrices: getMatchOddsSet(e.layPrices),
                                status: e?.status,
                                clothNumber: e?.clothNumber
                                    ? e?.clothNumber
                                    : data?.clothNumber ?? '',
                                jockeyName: e?.jockeyName
                                    ? e?.jockeyName
                                    : data?.jockeyName ?? '',
                                runnerAge: e?.runnerAge ? e?.runnerAge : data?.runnerAge ?? '',
                                runnerIcon: e?.runnerIcon
                                    ? e?.runnerIcon
                                    : data?.runnerIcon ?? '',
                                stallDraw: e?.stallDraw ? e?.stallDraw : data?.stallDraw ?? '',
                                trainerName: e?.trainerName
                                    ? e?.trainerName
                                    : data?.trainerName ?? '',
                            });
                        }
                    }
                }
                allSecMatchOddsMap[eventId + '-' + marketId] = {
                    marketId: matchOddsData.marketId,
                    marketName: matchOddsData.marketName,
                    marketTime: matchOddsData.marketTime,
                    // inplay: matchOddsData.inplay,
                    status: matchOddsData.status,
                    runners: runners,
                    limits: matchOddsData.limits
                        ? matchOddsData.limits
                        : allSecMatchOddsMap[eventId + '-' + marketId]?.limits,
                    marketLimits: matchOddsData?.marketLimits
                        ? matchOddsData.marketLimits
                        : allSecMatchOddsMap[eventId + '-' + marketId]?.marketLimits,
                    suspend: suspend,
                };
            } else {
                let matchOdds = allSecMatchOddsMap[eventId + '-' + marketId];
                if (matchOdds) {
                    allSecMatchOddsMap[eventId + '-' + marketId] = {
                        marketId: matchOdds.marketId,
                        marketName: matchOdds.marketName,
                        marketTime: matchOddsData.marketTime,
                        // inplay: matchOdds.inplay,
                        status: 'SUSPENDED',
                        runners: matchOdds.runners,
                        limits: matchOdds.limits,
                        marketLimits: matchOddsData?.marketLimits
                            ? matchOddsData.marketLimits
                            : allSecMatchOddsMap[eventId + '-' + marketId]?.marketLimits,
                        suspend: true,
                    };
                } else {
                    allSecMatchOddsMap[eventId + '-' + marketId] = {
                        marketId: '',
                        marketName: '',
                        // inplay: false,
                        status: 'SUSPENDED',
                        runners: [],
                        limits: null,
                        suspend: true,
                    };
                }
            }

            const secMatchOddsMap = { ...state.secondaryMatchOddsMap };
            secMatchOddsMap[eventId + '-' + marketId] =
                allSecMatchOddsMap[eventId + '-' + marketId];

            state.secondaryMatchOddsMap = secMatchOddsMap;
            state.secondaryMarketsMap = suspendMarketsMap;
        },
        updatePremiumMarkets: (
            state,
            action: PayloadAction<{ eventId: string; eventData: any }>
        ) => {
            const { eventId, eventData } = action.payload;
            state.premiumMarketsMap[eventId] = eventData;
        },
        setTriggerMarkets: (state) => {
            state.triggerFetchMarkets = moment.now();
        },
        setTriggerOrders: (state) => {
            state.triggerFetchOrders = moment.now();
        },
        setTriggerBetStatus: (state) => {
            state.triggerBetStatus = moment.now();
        },
        updateBookMakerMarkets: (state, action: PayloadAction<any>) => {
            try {
                console.log("book maker sec 2 -- ", action.payload)
                const eventData = action.payload;
                const eventId: string = eventData.eventId;
                const marketsMap = { ...state.secondaryMarketsMap };
                let suspendMarketsMap: SuspendedMarketDTOMap = { ...state.suspendedMarketsMap };

                if (eventId) {
                    if (!marketsMap[eventId]) {
                        marketsMap[eventId] = {
                            ...marketsMap[eventId],
                            bookmakers: [],
                            enableBookmaker: false,
                        };
                    }

                    const bookMakerOddsData = Array.isArray(eventData?.bookmakerOddsData)
                        ? eventData.bookmakerOddsData
                        : [eventData.bookmakerOddsData];

                    const bookmakers = marketsMap[eventId].bookmakers;
                    const prevBMMap = new Map();
                    const limitMap = new Map();

                    for (const { marketId, commissionEnabled, marketLimits } of bookmakers) {
                        if (commissionEnabled !== undefined) {
                            prevBMMap.set(marketId, commissionEnabled);
                        }
                        limitMap.set(marketId, marketLimits);
                    }

                    let bookMakerOdds: BookmakerDTO[] = [];
                    if (bookmakers.length) bookMakerOdds = bookmakers;

                    if (bookMakerOddsData && bookMakerOddsData.length) {
                        for (let br of bookMakerOddsData) {
                            let suspend: boolean = getSuspendValue(
                                suspendMarketsMap,
                                eventData?.providerId,
                                eventData?.sportId,
                                eventData?.competitionId,
                                eventId,
                                br?.marketType,
                                br?.marketId,
                                br?.suspended
                            );

                            let bmRunners: BookmakerRunnerDTO[] = [];
                            for (let b of br.runners) {
                                bmRunners.push({
                                    runnerId: b.runnerId || '',
                                    runnerName: b.runnerName || '',
                                    backPrice: b.backPrices[0]?.price,
                                    backSize: b.backPrices[0]?.size,
                                    layPrice: b.layPrices[0]?.price,
                                    laySize: b.layPrices[0]?.size,
                                    status: b.status || '',
                                    sort: b.sort ? Number(b.sort) : 0,
                                });
                            }

                            bmRunners.sort((a, b) => {
                                if (a?.sort !== b?.sort) {
                                    return a.sort - b.sort;
                                }
                                return a.runnerId.localeCompare(b.runnerId);
                            });

                            let index = bookMakerOdds.findIndex((itm) => itm.marketId === br.marketId);
                            if (index > -1) {
                                bookMakerOdds[index] = {
                                    ...bookMakerOdds[index],
                                    suspend: suspend,
                                    marketId: br.marketId || '-1',
                                    marketName: br.marketName || 'Bookmaker',
                                    runners: bmRunners,
                                    status: br.status || 'OPEN',
                                    commissionEnabled: br.commissionEnabled !== undefined ? br.commissionEnabled : bookMakerOdds[index].commissionEnabled,
                                    marketLimits: br.marketLimits || bookMakerOdds[index].marketLimits,
                                };
                            } else {
                                bookMakerOdds.push({
                                    suspend: suspend,
                                    marketId: br.marketId || '-1',
                                    marketName: br.marketName || 'Bookmaker',
                                    runners: bmRunners,
                                    status: br.status || 'OPEN',
                                    commissionEnabled: br.commissionEnabled !== undefined ? br.commissionEnabled : prevBMMap.get(br.marketId),
                                    marketLimits: br.marketLimits || limitMap.get(br.marketId),
                                });
                            }
                        }
                    }

                    bookMakerOdds.sort((a, b) => a.marketName.localeCompare(b.marketName));
                    marketsMap[eventId].bookmakers = bookMakerOdds;
                    marketsMap[eventId].enableBookmaker = eventData.enableBookmaker;
                    console.log("book maker sec 3 - ", JSON.parse(JSON.stringify((bookMakerOdds))))
                    state.secondaryMarketsMap = marketsMap;
                    state.suspendedMarketsMap = suspendMarketsMap;
                }
            } catch (e) {
                console.error('Failed to update odds', e);
            }
        },
        updateFancyMarketsWS: (state, action: PayloadAction<any>) => {
            try {
                const eventData = action.payload;
                const eventId: string = eventData.eventId;
                const fancyOddsData = action.payload.fancyUpdateData;
                const marketsMap = { ...state.secondaryMarketsMap };
                let suspendMarketsMap: SuspendedMarketDTOMap = {
                    ...state.suspendedMarketsMap,
                };
                // key as marketId value as fancy data
                const fancyMarkets = marketsMap[eventId].fancyMarkets;
                const prevFancyMap = new Map();
                let limitMap = new Map();
                if (fancyMarkets?.length > 0) {
                    for (const {
                        marketId,
                        commissionEnabled,
                        marketLimits,
                    } of fancyMarkets) {
                        if (commissionEnabled !== undefined) {
                            prevFancyMap.set(marketId, commissionEnabled);
                        }
                        limitMap.set(marketId, marketLimits);
                    }
                }

                // Add event data
                if (action.payload.eventId) {
                    if (!marketsMap[eventId]) {
                        marketsMap[eventId] = {
                            ...marketsMap[eventId],
                            fancyMarkets: [],
                            enableFancy: false,
                            fancySuspended: false,
                        };
                    }

                    // Set Fancy markets data
                    let fancyOdds: FancyMarketDTO[] = [];
                    if (fancyOddsData && fancyOddsData.length > 0) {
                        for (let f of fancyOddsData) {
                            if (!f.marketName.toLowerCase().split(' ').includes('bhav')) {
                                let suspend: boolean = getSuspendValue(
                                    suspendMarketsMap,
                                    eventData?.providerId,
                                    eventData?.sportId,
                                    eventData?.competitionId,
                                    eventId,
                                    f?.marketType,
                                    f?.marketId,
                                    f?.suspended
                                );
                                fancyOdds.push({
                                    marketId: f.marketId ? f.marketId : '',
                                    marketName: f.marketName ? f.marketName : '',
                                    status: f.status ? f.status : '',
                                    sort: f.sort ? Number(f.sort) : 0,
                                    layPrice: f.noValue
                                        ? f.category === 'fancy3' || f.category === 'odd-even'
                                            ? f.noValue.toFixed(2)
                                            : f.noValue
                                        : null,
                                    backPrice: f.yesValue
                                        ? f.category === 'fancy3' || f.category === 'odd-even'
                                            ? f.yesValue.toFixed(2)
                                            : f.yesValue
                                        : null,
                                    laySize: f.noRate ? f.noRate : null,
                                    backSize: f.yesRate ? f.yesRate : null,
                                    category: getFancyCategory(f.category),
                                    commissionEnabled:
                                        f.commissionEnabled !== undefined
                                            ? f.commissionEnabled
                                            : prevFancyMap.get(f.marketId),
                                    marketLimits:
                                        f?.marketLimits !== undefined
                                            ? f?.marketLimits
                                            : limitMap.get(f.marketId),
                                    suspend: suspend,
                                });
                            }
                        }
                    } else if (marketsMap[eventId].fancyMarkets) {
                        fancyOdds = marketsMap[eventId].fancyMarkets;
                        for (let fMarket of fancyOdds) {
                            fMarket.status = 'SUSPENDED';
                            fMarket.suspend = true;
                        }
                    }

                    fancyOdds.sort((a, b) => {
                        if (a?.sort - b?.sort != 0) {
                            return a?.sort - b?.sort;
                        }
                        const aDesc = a.marketName;
                        const bDesc = b.marketName;
                        if (aDesc > bDesc) return 1;
                        else if (aDesc < bDesc) return -1;
                        return 0;
                    });
                    marketsMap[eventId].fancyMarkets = fancyOdds;
                    marketsMap[eventId].fancySuspended = getFancySuspendedValue(
                        suspendMarketsMap,
                        eventData?.provderId,
                        eventData?.sportId,
                        eventData?.competitionId,
                        eventId,
                        'FANCY',
                        marketsMap[eventId]?.fancySuspended
                    );
                    // marketsMap[eventId].enableFancy = action.payload.enableFancy;
                    state.secondaryMarketsMap = marketsMap;
                    state.suspendedMarketsMap = suspendMarketsMap;
                }
            } catch (e) {
                console.error('Failed to update odds ', e);
            }
        },
        setBetFairWSConnection: (state, action: PayloadAction<any>) => {
            state.betFairWSConnected = action.payload;
        },
        setSportsRadarWSConnection: (state, action: PayloadAction<any>) => {
            state.sportsRadarWSConnected = action.payload;
        },
        setDreamWSConnection: (state, action: PayloadAction<any>) => {
            state.dreamWSConnected = action.payload;
        },
        setPushNotifWSConnection: (state, action: PayloadAction<any>) => {
            state.pushNotifWSConnection = action.payload;
        },
        updatePremiumMarketsWS: (state, action: PayloadAction<any>) => {
            const premiumSportsMap = { ...state.premiumMarketsMap };

            const key = action.payload.eventId;
            const eventData = action.payload.body;

            if (premiumSportsMap[key]) {
                if (
                    premiumSportsMap[key].markets &&
                    premiumSportsMap[key].markets.matchOdds.length > 0
                ) {
                    for (let market of eventData?.markets?.matchOdds) {
                        const index = premiumSportsMap[key].markets.matchOdds.findIndex(
                            (x) => x.marketId === market.marketId
                        );

                        if (index !== -1) {
                            premiumSportsMap[key].markets.matchOdds[index] = market;
                        }
                    }
                }
            }

            state.premiumMarketsMap = premiumSportsMap;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCasinoGame.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCasinoGame.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = 'succeeded';
                state.details = action.payload;
            })
            .addCase(getCasinoGame.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || "Failed to fetch API data";
            })
    },
});

export const {
    resetExchangeState,
    setEventType,
    setRgGames,
    setBannerGames,
    setEgGames,
    setCompetition,
    setExchEvent,
    setLoading,
    updateTopicUrls,
    disableEventData,
    updateSuspendedMarkets,
    updateCommissionMarkets,
    updateSecondaryMarkets,
    fetchEventByCompetitionSuccess,
    updateSecondaryMatchOdds,
    updatePremiumMarkets,
    updateBookMakerMarkets,
    setInitGames,
    setGames,
    setCasinoGames,
    setProviderList,
    setCategoryList,
    setButtonVariables,
    setGameSrc,
    setBetFairWSConnection,
    setDreamWSConnection,
    setSportsRadarWSConnection,
    setTriggerBetStatus,
    setTriggerMarkets,
    setTriggerOrders,
    updatePremiumMarketsWS,
    updateFancyMarketsWS,
    setPushNotifWSConnection
} = catalogSlice.actions;

export default catalogSlice.reducer;
