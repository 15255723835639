/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import FilterSection from "./FilterSection";
import GameTabs from "./GameTabs";
import BetList from "./BetList";

type StoreProps = {
    bets: any;
    fetchData: Function;
    nextPage:Function;
    prevPage:Function;
    handleDateChange:Function;
    filters:any;
    setFilters:any;
    nextPageToken:any;
    setNextPageToken:any;
    sports: any;
    games: any;
    betStatus: any;
};

const BettingApp: React.FC<StoreProps> = (props) => {
  const { bets, games, sports, betStatus, fetchData,nextPage,prevPage,handleDateChange,filters,setFilters,nextPageToken,setNextPageToken } = props;
  return (
    <div className="flex flex-col mx-auto w-full bg-zinc-100 max-w-[480px] overflow-scroll	">
      <div className="flex flex-col pt-px  mt-[12px] w-full bg-white border border-solid border-neutral-200">
        <div className="flex flex-col justify-center items-start w-full bg-stone-900">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1475a3ff02103fc7df44dc50edfd339f7054709d7c63791bc20d15d9bdc15a1c?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
            alt=""
            className="max-w-full aspect-[4.76] w-[149px]"
          />
        </div>
        <FilterSection
          onDateChange={handleDateChange}
          filters={filters}
          setFilters={setFilters}
          sports={sports}
          games={games}
          betStatus={betStatus}
        />
        <GameTabs filters={filters} setFilters={setFilters} sports={sports}/>
        <BetList bets={bets} />
      </div>
    </div>
  );
};

export default BettingApp;
