/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface InputFieldProps {
  label: string;
  placeholder: string;
  value: any;
  onChange: any;
}

const InputField: React.FC<InputFieldProps> = ({ label, placeholder, value, onChange }) => {
  return (
    <div>
      <label htmlFor={label.toLowerCase()} className="mt-7 text-neutral-500">
        {label}
      </label>
      <input
        type="text"
        id={label.toLowerCase()}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full px-3 py-4 mt-3 bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60"
      />
    </div>
  );
};

export default InputField;
