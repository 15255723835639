import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ReportsState {
  bets: any;
  pageToken: string;
  records: any;
  plStatement: any;
  exposureMap: any;
  items: any;
  backTotal: Number;
  layTotal: Number;
  marketTotal: Number;
  netMarket: Number;
  turnover: any;
  bonusData: any;
  transactionRequest: any;
}

const initialState: ReportsState = {
  bets: [],
  pageToken: "",
  records: [],
  plStatement: [],
  exposureMap: [],
  items: [],
  backTotal: 0,
  layTotal: 0,
  marketTotal: 0,
  netMarket: 0,
  turnover: [],
  bonusData: [],
  transactionRequest: []
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setBets: (state, action: PayloadAction<any>) => {
      state.bets = action.payload;
    },
    setPageToken: (state, action: PayloadAction<any>) => {
      state.pageToken = action.payload;
    },
    setRecords: (state, action: PayloadAction<any>) => {
      state.records = action.payload;
    },
    setPlStatement: (state, action: PayloadAction<any>) => {
      state.plStatement = action.payload;
    },
    setExposureMap: (state, action: PayloadAction<any>) => {
      state.exposureMap = action.payload;
    },
    setItems: (state, action: PayloadAction<any>) => {
      state.items = action.payload;
    },
    setBackTotal: (state, action: PayloadAction<Number>) => {
      state.backTotal = action.payload;
    },
    setLayTotal: (state, action: PayloadAction<Number>) => {
      state.layTotal = action.payload;
    },
    setMarketTotal: (state, action: PayloadAction<Number>) => {
      state.marketTotal = action.payload;
    },
    setNetMarket: (state, action: PayloadAction<Number>) => {
      state.netMarket = action.payload;
    },
    setTurnover: (state, action: PayloadAction<any>) => {
      state.turnover = action.payload;
    },
    setBonusData: (state, action: PayloadAction<any>) => {
      state.bonusData = action.payload;
    },
    setTransactionRequest: (state, action: PayloadAction<any>) => {
        state.transactionRequest = action.payload;
      },
  },
});

export const {
  setBets,
  setPageToken,
  setRecords,
  setPlStatement,
  setExposureMap,
  setItems,
  setBackTotal,
  setLayTotal,
  setMarketTotal,
  setNetMarket,
  setTurnover,
  setBonusData,
  setTransactionRequest
} = reportsSlice.actions;

export default reportsSlice.reducer;
