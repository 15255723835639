import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { RootState } from "../store";
import { defaultFilters, Filters } from "../store/reports/reportSelectors";
import { connect } from "react-redux";
import moment from "moment";
import TurnoverHistory from "../components/Desktop/TurnoverHistory/TurnoverHistory";
import TurnoverHistoryMobile from "../components/Mobile/TurnoverHistory/AccountStatement/TurnoverHistory"
import {
    fetchTurnoverHistory
  } from "../store/reports/reportsThunk";

interface TurnoverHistoryViewProps {
 turnover:any;
 fetchTurnoverHistory:any;
}

const TurnoverHistoryView: React.FC<TurnoverHistoryViewProps> = (props) => {
    const { turnover, fetchTurnoverHistory } = props;
  const [filters, setFilters] = useState<Filters>(defaultFilters);

  useEffect(() => {
    fetchTurnoverHistory(filters);
  }, [filters]);

  const handleDateChange = (newFromDate: Date, newToDate: Date) => {
    setFilters({
      ...filters,
      startDate: moment(newFromDate),
      endDate: moment(newToDate),
    });
  };  
  return (
    <>
      {isMobile ?<TurnoverHistoryMobile fetchTurnoverHistory={fetchTurnoverHistory} filters={filters} setFilters={setFilters} handleDateChange={handleDateChange} turnover={turnover} />:
      <><TurnoverHistory fetchTurnoverHistory={fetchTurnoverHistory} filters={filters} setFilters={setFilters} handleDateChange={handleDateChange} turnover={turnover} /></>}
      </>
    
  );
}
const mapStateToProps = (state: RootState) => {
    return {
      turnover: state.reports.turnover,
    };
  };
  
  const mapDispatchToProps = (dispatch: Function) => {
    return {
      fetchTurnoverHistory: (event: any) => dispatch(fetchTurnoverHistory(event)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(TurnoverHistoryView);



