import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PAYMENT_METHODS } from "../../components/Desktop/MyProfile/ProfileTabs/utils/types";

export interface PaymentMethodObjType {
  createTime: string;
  disabled: boolean;
  displayName: string;
  id: number;
  paymentMethod: PAYMENT_METHODS;
  paymentMethodDetails?: {
    accountHolderName: string;
    upiId: string;
    accountNumber: string;
    bankName: string;
    branchName: string;
    ifscCode: string;
  };
}

interface WithdrawState {
  isLoading: boolean;
  paymentMethodList: PaymentMethodObjType[];
}

const initialState: WithdrawState = {
  isLoading: false,
  paymentMethodList: [],
};

const withdrawSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    unsetLoading: (state) => {
      state.isLoading = false;
    },
    setPaymentMethodList: (
      state,
      action: PayloadAction<PaymentMethodObjType[]>
    ) => {
      state.paymentMethodList = action.payload;
    },
  },
});

export const { setLoading, setPaymentMethodList, unsetLoading } =
  withdrawSlice.actions;

export default withdrawSlice.reducer;
