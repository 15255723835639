import moment from "moment";
import React, { useState } from "react";
import Modal from "../../../Desktop/Modal/Modal";
import PLStatementMktLvl from "../../../Desktop/ProfitLoss/BettingProfitLoss/PLStatementMktLvl";
import {
  Filters,
  PLStatement,
} from "../../../../store/reports/reportSelectors";
import ProfitLossPopUp from "../ProfitLossPopUp/ProfitLossPopUp";
interface ReportingTableProps {
  plStatement: any;
  filters: Filters;
  setFilters:any;
}

const ReportingTable: React.FC<ReportingTableProps> = (props) => {
  const { plStatement, filters,setFilters } = props;
  const [active, setActive] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();

  return (
    <>
      <div className="flex flex-col justify-center px-3 py-px mt-1.5 w-full text-[14px] font-medium rounded-md bg-neutral-200 text-neutral-500">
        <div className="overflow-x-scroll text-[14px]">
          <div className="flex gap-5 justify-between w-full">
            <div className="my-auto text-wrap">Market Name</div>
            <div className="flex gap-4 whitespace-nowrap">
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-full" />
                <div className="my-auto text-wrap">Start Time</div>
              </div>
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-full" />
                <div className="my-auto text-wrap">Settled Time</div>
              </div>
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-full" />
                <div className="my-auto text-wrap">Comm.</div>
              </div>
            </div>
          </div>
          {plStatement?.map((row, index) => (
            <div
              key={index}
              className="flex flex-col items-center py-0.5 mt-1.5 w-full bg-white border-2 border-sky-200 border-solid"
            >
              <div className="flex gap-1 p-[3px] justify-between w-[100%] ">
                <div className="flex flex-col self-start mt-3 ">
                  <div className="text-sm font-medium text-stone-900">
                    {row.eventName ? row.eventName : row.gameType} -{" "}
                    {row.marketName}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto text-wrap">
                    {moment(row?.betPlacedTime).format("D/M/YYYY HH:mm:ss")}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto text-wrap">
                    {moment(row?.payOutDate).format("D/M/YYYY HH:mm:ss")}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto">
                    {!row.commission ? (
                      <div>{Number(0).toFixed(2)}</div>
                    ) : row.commission > 0 ? (
                      <div className="profit">
                        {Number(row.commission).toFixed(2)}
                      </div>
                    ) : row.commission < 0 ? (
                      <div className="loss">
                        {Number(row.commission).toFixed(2)}
                      </div>
                    ) : (
                      <>{Number(row.commission).toFixed(2) || 0}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
              <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
                <div className="flex flex-col my-auto">
                  <div className="flex gap-1">
                    <div className="font-medium text-neutral-500">Net Win:</div>
                    <div className="text-black">
                      {row.profit > 0 ? (
                        <div className="text-green-600">
                          {Number(row.profit + row.commission).toFixed(2)}
                        </div>
                      ) : row.profit < 0 ? (
                        <div className="text-red-600">
                          {Number(row.profit + row.commission).toFixed(2)}
                        </div>
                      ) : (
                        <> {Number(row.profit + row.commission).toFixed(2)}</>
                      )}
                    </div>
                  </div>
                  
                </div>
                <div className="flex gap-1">
                    <div
                      className="font-medium text-neutral-500 pr-[10px]"
                      onClick={() => {
                        setActive(true);
                        setFilters({...filters, selectedMarket: row});
                        setSelectedRecord(row)}}
                    >
                      {">"}
                    </div>
                  </div>
              </div>
            </div>
          ))}
          {plStatement && plStatement.length === 0 && (
            <div className=" text-center">No bets</div>
          )}
        </div>
        {active && (
          <div
            className={`${
              active ? "bottom-0 z-[10]" : "top-[-1000px]"
            } fixed right-[2px] h-[50%]  bg-white w-full `}
          >
            <ProfitLossPopUp filters={filters} setActive={setActive} details={selectedRecord} />
          </div>
        )}
      </div>
    </>
  );
};

{
  /* <div className="flex flex-col justify-center px-3 py-px mt-1.5 w-full text-[14px] font-medium rounded-md bg-neutral-200 text-neutral-500">
    <div className="flex gap-5 justify-between w-full">
      <div className="my-auto">Event Name</div>
      <div className="flex gap-4 whitespace-nowrap">
        <div className="flex gap-2">
          <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
          <div className="my-auto">Odds</div>
        </div>
        <div className="flex gap-2">
          <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
          <div className="my-auto">Amount</div>
        </div>
        <div className="flex gap-2">
          <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
          <div className="my-auto">Winnings</div>
        </div>
      </div>
    </div>
    {bets.map((bet, index) => (
      <div
        key={index}
        className="flex flex-col items-center py-0.5 mt-1.5 w-full bg-white border-2 border-sky-200 border-solid"
      >
        <div className="flex z-10 gap-5 justify-between w-full max-w-[379px]">
          <div className="flex flex-col self-start mt-3 pl-[10px]">
            <div className="text-sm font-medium text-stone-900">
              {bet.eventName}
            </div>
            <div className="mt-3 text-[14px] text-black">{bet.date}</div>
          </div>
          <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
            <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
            <div className="my-auto">{bet.odds}</div>
          </div>
          <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
            <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
            <div className="my-auto">{bet.amount}</div>
          </div>
          <div className="flex gap-2 text-[14px] font-semibold text-lime-700 whitespace-nowrap">
            <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
            <div className="my-auto">{bet.winnings}</div>
          </div>
        </div>
        <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
        <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
          <div className="flex flex-col my-auto">
            <div className="flex gap-1">
              <div className="font-medium text-neutral-500">Market:</div>
              <div className="text-black">{bet.market}</div>
            </div>
            <div className="flex gap-1">
              <div className="font-medium text-neutral-500">Bet on:</div>
              <div className="text-black">{bet.betOn}</div>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="shrink-0 w-px border border-solid border-neutral-200 h-[43px]" />
            <div className="flex flex-col my-auto">
              <div className="flex gap-1 whitespace-nowrap">
                <div className="font-medium text-neutral-500">Results:</div>
                <div className="text-black">{bet.result}</div>
              </div>
              <div className="flex gap-1">
                <div className="font-medium text-neutral-500">Bet type:</div>
                <div className="font-semibold text-black">{bet.betType}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div> */
}

export default ReportingTable;
