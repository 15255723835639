import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import SignInForm from "../components/Mobile/SignIn/SignInForm";
import SignIn from "../components/Desktop/AuthScreens/SignIn";
import SVLS_API from "../api/api-services/svls-api";
import { loginSuccess, setLoading } from "../store/auth/authSlice";
import { RootState } from "../store";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchBalance, login, logout } from "../store/auth/authThunk";
import { useAppDispatch } from "../store/storeHooks";
import { getSapToken } from "../api/auth";
import { PayloadAction } from "@reduxjs/toolkit";
import Loader from "../components/Loader/Loader";

type StoreProps = {
    // loginSuccess: Function;
    // loginFailed: Function;
    // requestStart: Function;
    // requestEnd: Function;
    fetchBalance: Function;
    // domainConfig: DomainConfig;
};

type LoginProps = StoreProps & {
    loading: boolean;
    loggedIn: boolean;
    // modalCloseHandler: Function;
    // redirectUrl?: string;
    loginUserThunk: Function;
    setLoading: Function;
};

type FormValueDTO = {
    username: string;
    password: string;
    code: any;
};

const LoginView: React.FC<LoginProps> = (props) => {
    const {
        loading,
        loggedIn,
        // loginSuccess,
        // loginFailed,
        // requestStart,
        // requestEnd,
        fetchBalance,
        // modalCloseHandler,
        // redirectUrl,
        // domainConfig,
        loginUserThunk,
        setLoading
    } = props;
    let redirectUrl = '/';
    const dispatch = useAppDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
    const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
    const [loadLogin, setLoadLogin] = useState(false);
    const [demoLoading, setDemoLoading] = useState(false);
    const [formValues, setFormValues] = useState<FormValueDTO>({
        username: '',
        password: '',
        code: ''
    });
    const [formErrors, setFormErrors] = useState<FormValueDTO>({
        username: '',
        password: '',
        code: ''
    });

    let navigate = useNavigate();
    const { search } = useLocation();
    const authToken = new URLSearchParams(search).get('authToken');

    const redirectToForgotPassword = () => {
        navigate('/forgotpassword');
    }
    const redirectToSignup = () => {
        navigate('/signup')
    }

    const setSapToken = async (token) => {
        await getSapToken(token);
    };

    useEffect(() => {
        if (authToken) {
            setSapToken(authToken);
            const username = authToken?.split('.')?.[1];
            let uname = JSON.parse(window?.atob(username)).sub;
            let uid = JSON.parse(window?.atob(username)).uid;
            sessionStorage.setItem('username', uname);
            sessionStorage.setItem('aid', uid);
            sessionStorage.setItem('jwt_token', authToken);
            let claim = authToken.split('.')[1];
            sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);
            // loginSuccess(authToken);
            navigate('/home');
            // fetchBalance();
        }
    }, [authToken]);

    useEffect(() => {
        if (loggedIn) {
            navigate('/');
            // modalCloseHandler();
        }
    }, [loggedIn]);

    const validate = (values: any) => {
        const errors: any = {};
        if (!values.username) {
            errors.username = 'Required';
        }
        if (!values.password) {
            errors.password = 'Required';
        }
        return errors;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        const validationErrors = validate({
            ...formValues,
            [name]: value
        });
        setFormErrors(validationErrors);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const validationErrors = validate(formValues);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            // requestStart();

            try {
                const loginRequest = {
                    username: formValues.username.toLowerCase(),
                    password: formValues.password.trim(),
                };
                setLoading(true);
                const response: PayloadAction<any> = await loginUserThunk(loginRequest?.username, loginRequest?.password);
                // requestEnd();
                if (login.rejected.match(response)) {
                    setFormErrors({ 
                        ...formErrors, 
                        code: response.payload 
                    })
                };

                sessionStorage.setItem('username', loginRequest.username.toLowerCase());
                localStorage.removeItem(
                    `multiMarket_${loginRequest?.username?.toLowerCase()}`
                );

                // loginSuccess(response.data);

                let claim = response.payload.split('.')[1];
                let status = JSON.parse(window.atob(claim)).sts;

                sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

                fetchBalance();

                if (status === 2) {
                    navigate('/accept-terms', { replace: true });
                } else if (status === 4) {
                    navigate('/reset-password', { replace: true });
                }

                if (redirectUrl) {
                    navigate(redirectUrl);
                }
                setLoading(false);
            } catch (err) {
                // loginFailed(err.response.data.message);
                setLoading(false);

            } finally {
                // requestEnd();
                setLoading(false);
            }
        }
    };

    const handleDemoLogin = async () => {
        setDemoLoading(true);
        setLoadLogin(false);
        // requestStart();
        try {
            const response = await SVLS_API.post('/account/v2/login-demo');
            setDemoLoading(false);
            if (response.status == 200) {
                // requestEnd();

                let claim = response.data.split('.')[1];
                const username = JSON.parse(window.atob(claim)).sub;

                sessionStorage.setItem('username', username.toLowerCase());
                sessionStorage.setItem('jwt_token', response.data);
                localStorage.removeItem(`multiMarket_${username.toLowerCase()}`);
                setSapToken(response.data)

                dispatch(loginSuccess(response.data));

                sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

                if (redirectUrl) {
                    navigate(redirectUrl);
                }
            }
        } catch (err) {
            setDemoLoading(false);
            console.log(err);
        }
    };

    const showPasswordClickHandler = () => {
        setShowPassword(!showPassword);
    };

    const redirectToHome = () => {
        navigate('/home');
    }

    return (
        <>
        {
            loading ?
            <Loader />
            : null
        }
            {isMobile ? (
                <SignInForm
                    redirectToHome={redirectToHome}
                    handleSubmit={handleSubmit}
                    handleDemoLogin={handleDemoLogin}
                    redirectToSignup={redirectToSignup}
                    redirectToForgotPassword={redirectToForgotPassword}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleChange={handleChange}
                    demoLoading={demoLoading}
                    loading={loading}
                    loadLogin={loadLogin}
                    showPasswordClickHandler={showPasswordClickHandler}
                    showPassword={showPassword}
                />
            )
                : <SignIn
                    redirectToHome={redirectToHome}
                    handleSubmit={handleSubmit}
                    handleDemoLogin={handleDemoLogin}
                    redirectToSignup={redirectToSignup}
                    redirectToForgotPassword={redirectToForgotPassword}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleChange={handleChange}
                    demoLoading={demoLoading}
                    loading={loading}
                    loadLogin={loadLogin}
                    showPasswordClickHandler={showPasswordClickHandler}
                    showPassword={showPassword}
                />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.auth.loading,
        loggedIn: state.auth.loggedIn,
        // domainConfig: state.common.domainConfig,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        fetchBalance: () => dispatch(fetchBalance()),
        // loginSuccess: (payload) => dispatch(loginSuccess(payload)),
        // loginFailed: (err: string) => dispatch(loginFailed(err)),
        // requestStart: () => dispatch(requestStart()),
        // requestEnd: () => dispatch(requestEnd()),
        loginUserThunk: (
            username: string,
            password: string
        ) => dispatch(login({ username, password })),
        setLoading: (loading) => dispatch(setLoading(loading)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
