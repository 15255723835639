/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import MainLayout from '../../../views/MainLayout'


const Layout: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("Exchange");
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  return (
    <div className="flex flex-col min-h-screen w-[100%] bg-[#f2f2f2]">
      {
        <>
        <MainLayout/>
        </>
      }
    </div>
  );
};

export default Layout;
