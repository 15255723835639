/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import { RootState } from "../../../../store";
import { connect } from "react-redux";
import { fetchRecords } from "../../../../store/reports/reportsThunk";
import { Currency } from "../../../../models/Currency";
import {
  cFactor,
  defaultFilters,
  Filters,
  TransactionTypeMap,
} from "../../../../store/reports/reportSelectors";
import moment from "moment";
import DatePicker from "../../ProfitLoss/BettingProfitLoss/DatePicker";

interface MyWalletProps {
  records: any;
  fetchRecords: Function;
  balances: any;
  pageToken: any;
}

const MyWallet: React.FC<MyWalletProps> = (props) => {
  const { records, fetchRecords, balances, pageToken } = props;

  const [depositAmount, setDepositAmount] = useState<number>(0);
  const [withdrawAmount, setWithdrawAmount] = useState<number>(0);
  const [nextPageToken, setNextPageToken] = useState<string>(null);
  const [filters, setFilters] = useState<Filters>(defaultFilters);

  const handleDateChange = (newFromDate: Date, newToDate: Date) => {
    setFilters({
      ...filters,
      startDate: moment(newFromDate),
      endDate: moment(newToDate),
    });
  };
  
  const nextPage = () => {
    setFilters({
      ...filters,
      pageToken: [...filters.pageToken, pageToken],
    });
  };

  const prevPage = () => {
    if (filters.pageToken.length > 0) {
      let pagetokens = filters.pageToken;
      pagetokens.pop();
      setFilters({ ...filters, pageToken: [...pagetokens] });
    }
    setNextPageToken(null);
  };

  useEffect(() => {
    setFilters({ ...defaultFilters, transactionType: "0-1-2-3" });
  }, []);

  useEffect(() => {
    fetchRecords(filters);
  }, [filters]);

  useEffect(() => {
    let deposits = 0;
    let withdraws = 0;
    records.forEach((record) => {
      if (record.amount > 0) {
        deposits += record.amount;
      } else {
        withdraws -= record.amount;
      }
    });
    setDepositAmount(deposits);
    setWithdrawAmount(withdraws);
  }, [records]);

  return (
    <>
      <div className="flex flex-col pt-px mt-3.5 w-full bg-white rounded-md border border-solid border-neutral-200 max-md:max-w-full">
        <div className="flex flex-col bg-zinc-100">
          <div className="w-full max-md:max-w-full ">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-full">
                <div className="flex flex-col py-px w-full text-[14px] font-medium bg-white rounded-md border border-solid border-neutral-200">
                  <div className="flex flex-col justify-center items-start text-[14px] h-9 text-white rounded-md bg-stone-900 max-md:pr-5">
                    <div className="flex ">
                      <div className=" bg-[#804091] w-full h-9 py-2 pr-4 pl-2 text-[14px] text-white">
                        My Wallet
                      </div>
                      <div className=" skew-x-[-25deg] bg-[#804091] h-9 -translate-x-[8px] w-6"></div>
                      <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-9 -translate-x-1"></div>
                      <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-9"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex justify-end">
        <DatePicker
          onDateChange={handleDateChange}
          startDate={filters.startDate}
          endDate={filters.endDate}
        />
      </div>
      <div className="mx-7 mt-4 max-md:mr-2.5 max-md:max-w-full">
        <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
          <div className="text-2xl font-bold text-gray-800 mb-4">
            Balance:{" "}
            {(balances.balance ? balances.balance : 0) +
              " " +
              (balances.currencyType ? balances.currencyType : 'INR')}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded-md shadow">
              <div className="text-sm text-gray-600">Total Deposits</div>
              <div className="text-lg font-semibold text-gray-800">
                {(depositAmount ? depositAmount : 0) +
                  " " +
                  (balances.currencyType ? balances.currencyType : 'INR')}
              </div>
            </div>
            <div className="bg-white p-4 rounded-md shadow">
              <div className="text-sm text-gray-600">Total Withdrawals</div>
              <div className="text-lg font-semibold text-gray-800">
                {(withdrawAmount ? withdrawAmount : 0) +
                  " " +
                  (balances.currencyType ? balances.currencyType : 'INR')}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Recent Transactions</h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {records?.map((row) => (
                <tr>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                    {moment(row.transactionTime).format("DD-MM-YY, h:mm:ss A")}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900">
                    {TransactionTypeMap[row?.transactionType]}
                  </td>
                  <td
                    className={`px-3 py-4 whitespace-nowrap text-sm ${
                      row.amount > 0 ? "text-green-600" : "text-red-600"
                    } `}
                  >
                    {Number(row.amount).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          {filters?.pageToken.length > 0 && (
            <button
              className=" p-1 leading-[16.2px] text-white bg-fuchsia-800 m-2"
              onClick={(e) => prevPage()}
            >
              Prev({filters.pageToken.length})
            </button>
          )}
          {pageToken ? (
            <button
              className=" p-1 leading-[16.2px] text-white bg-fuchsia-800 m-2"
              onClick={(e) => nextPage()}
            >
              Next({filters?.pageToken.length + 2})
            </button>
          ) : null}
        </div>
        {records.length === 0 ? (
          <div className=" text-center">No transactions</div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    records: state.reports.records,
    balances: state.auth.balances,
    pageToken: state.reports.pageToken,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchRecords: (event: any) => dispatch(fetchRecords(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyWallet);
