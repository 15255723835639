/**
 * This code was generated by Builder.io.
 */
import React from "react";
import BackButton from "./BackButton";
import PaymentSection from "./PaymentSection";
import { WithdrawComponentProps } from "../../../Desktop/Withdraw/utils/types";

interface WithdrawMobileProps extends WithdrawComponentProps {}

const WithdrawMobile: React.FC<WithdrawMobileProps> = (props) => {
  return (
    <div className="flex flex-col mx-auto w-full  max-w-[480px] ">
      {/* <BackButton /> */}
      <PaymentSection {...props} />
    </div>
  );
};

export default WithdrawMobile;
