import { createAsyncThunk } from '@reduxjs/toolkit';
import { PROVIDER_ID } from '../../constants/Branding';
import SVLS_API from '../../api/api-services/svls-api';
import { updateTopicUrls } from '../catalog';
import { setMultiMarketEventData, updateMultiSecondaryMarkets, updateMultiSecondaryMatchOdds } from './multiMarketSlice';
import { EventDTO } from '../../models/common/EventDTO';

export const fetchMultiMarketEventData = createAsyncThunk(
  'multiMarket/fetchMultiMarketEventData',
  async (_, { dispatch }) => {
    try {
      let data = [];
      const username = sessionStorage.getItem('username') ?? '';
      const multiMarket = localStorage.getItem(`multiMarket_${username}`);
      if (multiMarket) data = JSON.parse(atob(multiMarket));
      if (data?.length) {
        const response = await SVLS_API.post(
          `/catalog/v2/sports-feed/sports/markets`,
          {
            eventDetails: data,
            providerId: PROVIDER_ID,
            marketsCriteria: 'ALL',
          },
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );
        if (response?.data?.length > 0) {
          const eventData = response.data[0];
          dispatch(updateTopicUrlsInStore(eventData));
          
          for (let i = 0; i < response?.data?.length; i++) {
            const eventData = response.data[i];
            if (eventData.eventId) {
              const eData: EventDTO = {
                // inPlay: eventData.inPlay,
                status: eventData.status,
                openDate: eventData.openDate,
                sportId: eventData.sportId,
                competitionId: eventData.competitionId,
                competitionName: eventData.competitionName
                  ? eventData.competitionName
                  : 'Other',
                eventId: eventData.eventId,
                eventName: eventData.eventName,
                marketId: eventData.marketId,
                providerName: eventData.providerName,
                enabled: eventData.enabled,
              };

              const payload = {
                eventData: eData,
                //eventData: response.data,
                sportId: eData.sportId,
                competitionId: eData.competitionId,
                eventId: eventData.eventId,
                matchOddsData:
                  eventData.markets && eventData.markets.matchOdds
                    ? eventData.markets.matchOdds.find(
                        (mo) =>
                          mo.marketName === 'Match Odds' ||
                          mo.marketName.toLowerCase() === 'moneyline'
                      )
                    : null,
                onRefresh: true,
              };

              dispatch(setMultiMarketEventData(payload));
              // if (eData.sportId === '1') {
              //|| eData.sportId === '4'
              for (let mo of eventData.markets.matchOdds) {
                if (eData.sportId === '4') {
                  if (
                    mo.marketName !== 'Match Odds' &&
                    mo.marketName.toLowerCase() !== 'moneyline' &&
                    mo.marketId !== '1.196548297' &&
                    mo.marketId !== '1.196548301'
                  ) {
                    const secMOPayload = {
                      sportId: eventData.sportId,
                      competitionId: eventData.competitionId,
                      eventId: eventData.eventId,
                      marketId: mo.marketId,
                      matchOddsData: mo,
                    };

                    dispatch(updateMultiSecondaryMatchOdds(secMOPayload));
                  }
                } else {
                  if (
                    mo.marketName !== 'Match Odds' &&
                    mo.marketName.toLowerCase() !== 'moneyline'
                  ) {
                    const secMOPayload = {
                      sportId: eventData.sportId,
                      competitionId: eventData.competitionId,
                      eventId: eventData.eventId,
                      marketId: mo.marketId,
                      matchOddsData: mo,
                    };

                    dispatch(updateMultiSecondaryMatchOdds(secMOPayload));
                  }
                }
              }
              // }

              if (eData.sportId === '4') {
                const secMarketsPayload = {
                  eventId: eData.eventId,
                  sportId: eData?.sportId,
                  competitionId: eData?.competitionId,
                  bookmakerOddsData: eventData.markets
                    ? eventData.markets.bookmakers
                    : null,
                  enableBookmaker: eventData.markets
                    ? eventData.markets.enableBookmaker
                    : false,
                  sessionOddsData: eventData.markets
                    ? eventData.markets.fancyMarkets
                    : null,
                  enableFancy: eventData.markets
                    ? eventData.markets.enableFancy
                    : false,
                };
                dispatch(updateMultiSecondaryMarkets(secMarketsPayload));
              }
            }
          }

          return response.data;
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

const updateTopicUrlsInStore = (eventData) => (dispatch) => {
  const topicUrlPayload = {
    matchOddsBaseUrl: eventData?.markets?.matchOddsBaseUrl,
    matchOddsTopic: eventData?.markets?.matchOddsTopic,
    bookMakerBaseUrl: eventData?.markets?.bookMakerBaseUrl,
    bookMakerTopic: eventData?.markets?.bookMakerTopic,
    fancyBaseUrl: eventData?.markets?.fancyBaseUrl,
    fancyTopic: eventData?.markets?.fancyTopic,
    premiumBaseUrl: eventData?.markets?.premiumBaseUrl,
    premiumTopic: eventData?.markets?.premiumTopic,
  };
  dispatch(updateTopicUrls(topicUrlPayload));
};
