/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Banners from "./Banners";
import DateNavigation from "./DateNavigation";
import Footer from "./Footer";
import EventList from "./EventList";
import GameSection from "./GameSection";
import ProviderSection from "./ProviderSection";
import VirtualGamesSection from "./VirtualGamesSection";
import CategoryNavigation from "../HeaderSection/CategoryNavigation";
import { EventDTO } from "../../../models/common/EventDTO";
import { Game } from "../../Desktop/LiveCasino/types";

type InplayEventsObj = {
  sportId: string;
  sportName: string;
  sportSlug: string;
  events: EventDTO[];
};

type InplayEventProps = {
  inplayEvents: InplayEventsObj[];
  rgGames?: Game[];
  egGames?: Game[];
  handleGameClick?: Function;
  dialogShow?: boolean;
  setDialogShow?: Function;
  getBannerGameUrl: Function;
  games: any;
};

const InplayMobPage: React.FC<InplayEventProps> = (props) => {
  const {
    rgGames,
    getBannerGameUrl,
    games,
    egGames,
    handleGameClick,
    dialogShow,
    inplayEvents,
    setDialogShow,
  } = props;

  return (
    <section className="flex flex-col bg-[#f2f2f2]">
      <Banners getBannerGameUrl={getBannerGameUrl} games={games} />
      <EventList inplayEvents={inplayEvents} />
      <GameSection
        title="Royal Gaming"
        path="/indian-casino"
        setDialogShow={setDialogShow}
        dialogShow={dialogShow}
        handleGameClick={handleGameClick}
        games={rgGames}
      />
      <GameSection
        title="Evolution Gaming"
        path="/live-casino"
        setDialogShow={setDialogShow}
        dialogShow={dialogShow}
        handleGameClick={handleGameClick}
        games={egGames}
      />
      <Footer />
    </section>
  );
};

export default InplayMobPage;
