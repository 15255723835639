import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedObj } from '../../models/SportsState';

interface ReportsState {
    errorMsg: string;
}

const initialState: ReportsState = {
    errorMsg: "",
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setErrorMsg: (state, action: PayloadAction<any>) => {
            state.errorMsg = action.payload;
        }
    }
});

export const {
    setErrorMsg,
} = commonSlice.actions;

export default commonSlice.reducer;
