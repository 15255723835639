/**
 * This code was generated by Builder.io.
 */
import React from "react";
import TransactionFilter from "./TransactionFilter";
import TransactionList from "./TransactionList";
import purple from "../../../../assets/purple_bg.png"
interface MyTransactionProps {
  filters:any;
  setFilters:any;
  records: any;
  fetchTransactionRequest: Function;
  handleDateChange:Function;
  TransactionTypes: any;
}


const MyTransaction: React.FC<MyTransactionProps> = (props) => {
  const { handleDateChange, TransactionTypes, filters, setFilters, records } = props;
  return (
    <div className="flex overflow-hidden flex-col mx-auto w-full bg-zinc-100 max-w-[480px] transition ease-in-out duration-[2000ms]">
      <main className="flex flex-col pt-px mt-[12px] w-full bg-white ">
        <div className="bg-[black]">
          <div style={{ backgroundImage: `url(${purple})` }} className=" flex bg-cover h-full leading-[32px] w-[60%]">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c53a14524e4654dbbb03ce8c4290b879bd5e7fb112a1f4a27cda134aa443d0be?apiKey=b24a234f6f1a429faa4b53c4011fa200&&apiKey=b24a234f6f1a429faa4b53c4011fa200"
              alt="Account Statement"
              className="w-5 h-5 m-[6px]"
            />
            <span className="text-white">My Transaction</span>
          </div>
        </div>
        <TransactionFilter TransactionTypes={TransactionTypes} handleDateChange={handleDateChange} filters={filters} setFilters={setFilters} />
        <TransactionList records={records} />
      </main>
    </div>
  );
};

export default MyTransaction;
