/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import DatePicker from "../DatePicker";
import { defaultFilters, Filters } from "../../../../store/reports/reportSelectors";

interface TransactionalFilter {

  handleDateChange: Function;
  filters: any;
  setFilters: any;

}
const TransactionFilter: React.FC<TransactionalFilter> = (props) => {
  const { handleDateChange, filters, setFilters } = props;
  return (
    <form className="flex flex-col px-[12px] mt-[12px] w-full text-[14px] font-medium text-neutral-500">
      <DatePicker onDateChange={handleDateChange} startDate={filters.startDate} endDate={filters.endDate} />
    </form>
  );
};

export default TransactionFilter;
