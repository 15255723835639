import React from "react";
import { Game } from "./types";

interface GameCardProps {
  game: Game;
  handleGameClick: Function;
}

const GameCard: React.FC<GameCardProps> = ({ game, handleGameClick }) => {

  return (
    <div className="flex flex-col w-[15.5%] min-w-[140px] grow rounded-[4px] overflow-hidden ">
      <img
        loading="lazy"
        src={game.urlThumb}
        className="self-center aspect-[1.67] object-cover"
        alt={game.gameName}
        onClick={() =>
          handleGameClick(
            game.gameId,
            game.gameName,
            game.gameCode,
            game.subProviderName,
            game.providerName
          )
        }
      />
      <div className="flex gap-5 justify-between px-2 py-1 rounded border border-solid bg-zinc-100 border-neutral-200">
        <div className="my-auto text-ellipsis overflow-hidden whitespace-nowrap max-w-[80%]">
          {game.gameName}
        </div>
      </div>
    </div>
  );
};

export default GameCard;
