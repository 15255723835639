import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const Loader = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-[#b89c9c] opacity-25 z-50 ">
      <div className="flex flex-col items-center">
        <FaSpinner className="animate-spin text-[#000000] text-6xl" />
        <p className="text-white mt-4">Loading...</p>
      </div>
    </div>
  );
};

export default Loader;
