/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface EventCategoryProps {
  icon?: string;
  name: string;
}

const EventCategory: React.FC<EventCategoryProps> = ({ icon, name }) => {
  icon = "https://cdn.builder.io/api/v1/image/assets/TEMP/b88f6871455a8bd38fc9bbf6dd3ff47dcdf2c8bedf1c3d04ae1f1a0bebce99f9?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
  return (
    <div className="flex flex-col justify-center items-start w-full text-[14px] font-medium text-white bg-stone-900 max-md:pr-5 max-md:max-w-full">
      <div className="flex bg-stone-900 w-full">
        <div className="bg-[#804091] flex py-2 pr-4 pl-2 text-[14px] text-white font-medium">
          <img
            loading="lazy"
            src={icon}
            className="shrink-0 w-5 rounded-none aspect-square"
            alt=""
          />
          <div className="my-auto ml-2">{name} Matches</div>
        </div>
        <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[7px] w-4 "></div>
        <div className=" skew-x-[-25deg] bg-[#804091] w-1  -translate-x-1"></div>
        <div className=" skew-x-[-25deg] bg-[#804091] w-1 "></div>
      </div>
      <div className="flex relative gap-1.5">
      </div>
    </div>
  );
};

export default EventCategory;
