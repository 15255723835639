import axios from 'axios';
import { getBaseUrl } from '../environment-url/environment-url';
import store, { AppDispatch } from '../../../store';
import { logout } from '../../../store/auth/authThunk';

const SVLS_API = axios.create({
  baseURL: getBaseUrl(
    process.env.REACT_APP_NODE_ENV,
    'REACT_APP_REST_API_V2_URL'
  ),
  responseType: 'json',
  withCredentials: false,
  timeout: 10000, // 10 seconds
});

// Add a response interceptor
SVLS_API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('***********Unauthorized Error**********');
      const token = sessionStorage.getItem('jwt_token');
      if (token) {
        const dispatch = store.dispatch as AppDispatch;
        dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default SVLS_API;
