/**
 * This code was generated by Builder.io.
 */
import React from "react";

export interface AccountFieldProps {
  label: string;
  value: string;
}

export const AccountField: React.FC<AccountFieldProps> = ({ label, value }) => {
  return (
    <div className="flex flex-wrap gap-3 mt-3 w-full max-w-[559px] rounded-[1000px] max-md:max-w-full">
      <div className="grow my-auto text-sm">{label}</div>
      <div className="flex-auto gap-2.5 self-stretch px-3 py-1 text-xs tracking-wide uppercase bg-white border border-solid border-neutral-200 min-h-[24px] rounded-[1000px]">
        {value}
      </div>
    </div>
  );
};
