/**
 * This code was generated by Builder.io.
 */
import React from "react";
import NavigationItem from "./NavigationItem";
import {
  DEPOSIT_PAYMENT_TYPES,
  depositMethodList,
  depositPaymentTypesDisplayNamesMap,
} from "../utils/types";

interface NavigationBarProps {
  selectedPaymentType: DEPOSIT_PAYMENT_TYPES | string;
  setSelectedPaymentType: (type: DEPOSIT_PAYMENT_TYPES) => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  selectedPaymentType,
  setSelectedPaymentType,
}) => {
  return (
    <nav className="flex justify-between flex-wrap w-full bg-white border border-solid border-neutral-200 max-md:max-w-full">
      {depositMethodList.map((item, index) => (
        <NavigationItem
          setSelectedPaymentType={setSelectedPaymentType}
          key={index}
          value={item}
          label={depositPaymentTypesDisplayNamesMap[item]}
          isActive={selectedPaymentType === item}
        />
      ))}
    </nav>
  );
};

export default NavigationBar;
