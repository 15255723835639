/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { menuItems } from "../../../utils/commonUtils";

interface MenuListProps {
  searchTerm: string;
  setSelectedTab: (tab: string) => void;
  setShowSidebar: any;
}

const MenuList: React.FC<MenuListProps> = ({ searchTerm, setSelectedTab, setShowSidebar }) => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const toggleExpand = (text: string) => {
    setExpandedItem(expandedItem === text ? null : text);
  };

  const filteredItems = menuItems.filter(
    (item) =>
      item.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subTabs?.some((subTab) =>
        subTab.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  return (
    <>
      {filteredItems.map((item, index) => (
        <React.Fragment key={index}>
          <NavLink to={item.path}>
            <div
              className={`flex items-center font-[600] gap-3.5 cursor-pointer mt-1.5 text-[14px]
              }`}
              onClick={() => {toggleExpand(item.text);setSelectedTab(item.text)}}
            >
              <img
                src={item.icon}
                alt=""
                className={"w-5 h-5"}
              />
              <span>{item.text}</span>
            </div>
          </NavLink>
          {expandedItem === item.text && item.subTabs && (
            <div className="ml-8 mt-1">
              {item.subTabs.map((subTab, subIndex) => (
                <div key={subIndex} className="text-[14px] text-[#757575] font-normal py-1" onClick={() => {setSelectedTab(subTab);setShowSidebar(prev=>!prev)}}>
                  {subTab}
                </div>
              ))}
            </div>
          )}
          {index < filteredItems.length - 1 && (
            <div className="mt-1.5 h-px bg-[#f2f2f2]" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default MenuList;
