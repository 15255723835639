/**
 * This code was generated by Builder.io.
 */
import React from "react";
import logo from "../../../assets/logo (2).png";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/storeHooks";
import { getUsername } from "../../../utils/commonUtils";

const MainHeader: React.FC = () => {
  const { loggedIn, balanceSummary } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <div className="flex h-[52px] justify-between items-center px-10 py-2 bg-fuchsia-700">
      <div className="flex items-center gap-2.5">
        <NavLink to="/">
          <img src={logo} alt="Brand" className="w-[110px]" />
        </NavLink>
      </div>
      {loggedIn ? (
        <div className="flex gap-3">
          <div
            onClick={() => navigate("/deposit")}
            className="flex items-center cursor-pointer justify-center border border-white text-white font-[500] text-[14px] leading-[16.2px]	rounded-md w-[88px] h-[36px] bg-[#3C7701] "
          >
            Deposit
          </div>
          <div
            onClick={() => navigate("/withdraw")}
            className="flex items-center cursor-pointer justify-center border border-white font-[500] text-[14px] leading-[16.2px] text-white	rounded-md w-[88px] h-[36px] bg-[#B40202] "
          >
            Withdraw
          </div>
          <div className="flex  items-center justify-center border border-white text-white font-[500] text-[14px] leading-[16.2px]	rounded-md w-[88px] h-[36px] bg-fuchsia-700 p-1">
            Bonus: {balanceSummary?.bonus}
          </div>

          <button className="flex h-[36px] items-center text-[14px] font-medium bg-white text-white border border-white rounded-md overflow-hidden ">
            <div>
              <span
                className="flex font-[500] text-[14px] leading-[17.34px] w-[80px] text-black px-4 py-3 "
              >
                Bal: {balanceSummary?.balance ? balanceSummary?.balance : "0"}
              </span>
            </div>
            <div>
              <span className=" bg-fuchsia-700 font-[500] text-[14px] leading-[16.2px] px-4 py-3 signup-btn-clip ">
                Exp:{" "}
                {balanceSummary?.exposure ? balanceSummary?.exposure : "0.00"}
              </span>
            </div>
          </button>
        </div>
      ) : (
        <button className="flex h-[36px] items-center text-sm font-medium bg-white text-white border border-white rounded-md overflow-hidden ">
          <NavLink to="/login">
            <span
              className=" text-[14px] font-medium flex w-[60px] text-black px-4 py-3 "
            >
              LOGIN
            </span>
          </NavLink>
          <NavLink to="/signup">
            <span className=" text-[14px] font-medium signup-btn-clip bg-fuchsia-700 px-4 py-3">SIGNUP</span>
          </NavLink>
        </button>
      )}
    </div>
  );
};

export default MainHeader;
