import moment from 'moment';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

type Props = {
  openDate: any;
};

const EventDateDisplay = (props: Props) => {
  const { openDate } = props;

  useEffect(() => {
    console.log(openDate)
  }, [])

  return (
    <>
      {/* {moment(openDate).diff(moment(), 'seconds') <= 0 ? ( */}
        {/* // <img className={pathname?.includes("/inplay") || pathname?.includes("/home") ? "live-img-display inplay-img-display": "live-img-display"} src={LiveEvent} /> */}
        <>
        </>
      {/* ) : (
      )} */}
        <div className="flex date-display">
          <div>
            {moment(moment(openDate).format('DD MMM YYYY')).isSame(
              moment().format('DD MMM YYYY')
            )
              ? 'Today'
              : moment(openDate).format('DD MMM')}
          </div>
          <div>{', '}{moment(openDate).format('hh:mm A')}</div>
        </div>
    </>
  );
};

export default EventDateDisplay;
