/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import FormField from "./FormField";
import SocialIcons from "./SocialIcons";
import PersonalInfo from "./Screens/PersonalInfo";
import ChangePassword from "./Screens/ChangePassword";
import Deposit from "./Screens/Deposit";
import Referral from "./Screens/Referral";
import WithdrawComponent from "../../../../views/WithdrawView";

interface ProfileFormProps {
  profileDetails: any;
  setProfileDetails: Function;
  updateUserDetails: Function;
  resetDetails: Function;
  updateNewPassword: Function;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  setOldPassword: Function;
  setNewPassword: Function;
  setConfirmPassword: Function;
  resetPassword: Function;
}

const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  const {
    profileDetails,
    resetDetails,
    updateUserDetails,
    setProfileDetails,
    updateNewPassword,
    oldPassword,
    newPassword,
    confirmPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    resetPassword,
  } = props;

  const [activeTab, setActiveTab] = useState("Referral");

  const renderTabContent = () => {
    switch (activeTab) {
      case "Personal Info":
        return (
          <PersonalInfo
            profileDetails={profileDetails}
            setProfileDetails={setProfileDetails}
            updateUserDetails={updateUserDetails}
            resetDetails={resetDetails}
          />
        );
      case "Change Password":
        return (
          <ChangePassword
            oldPassword={oldPassword}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            updateNewPassword={updateNewPassword}
            setOldPassword={setOldPassword}
            setNewPassword={setNewPassword}
            setConfirmPassword={setConfirmPassword}
            resetPassword={resetPassword}
          />
        );
      case "Deposit":
        return <Deposit />;
      case "Withdraw":
        return <WithdrawComponent />;
      case "Referral":
        return <Referral />;
      default:
        return null;
    }
  };

  return (
    <form className="flex flex-col  mt-[10px] w-full border border-solid border-neutral-200">
      <div className="bg-[black]">
          <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
            <div className="flex flex-col justify-center w-[50%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
              <div className="flex min-w-[170px]">
                <div className=" bg-[#804091] h-8 w-full py-2 pr-4 pl-2">
                  My Profile
                </div>
                <div className=" skew-x-[-25deg] bg-[#804091] h-8 -translate-x-[7px] w-4"></div>
                <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-8 -translate-x-1"></div>
                <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-8"></div>
              </div>
            </div>
          </div>
        </div>
      <SocialIcons activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>{renderTabContent()}</div>
    </form>
  );
};

export default ProfileForm;
