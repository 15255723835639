/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import { Game } from "../../Desktop/LiveCasino/types";
import purple from "../../../assets/purple_bg.png";
import { useNavigate } from "react-router-dom";
import Modal from "../../Modal/Modal";
import { useAppSelector } from "../../../store/storeHooks";

interface GameSectionProps {
  title: string;
  games?: Game[];
  handleGameClick?: Function;
  dialogShow?: boolean;
  setDialogShow?: Function;
  path: string;
}

const GameSection: React.FC<GameSectionProps> = (props) => {
  const { title, path, games, handleGameClick, dialogShow, setDialogShow } =
    props;
  const navigate = useNavigate();

  return (
    <section className="flex flex-col  mt-1.5 w-full bg-white max-md:max-w-full">
      <div className=" flex justify-between">
        <div className="flex">
          <div className=" bg-[#804091] py-2 pr-4 pl-2 text-[14px] text-white font-medium">
            {title}
          </div>
          <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[9px] w-4 h-full"></div>
          <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full -translate-x-1"></div>
          <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full"></div>
        </div>
        <div
          onClick={() => navigate(path)}
          className=" text-neutral-500 text-[14px] font-semibold mt-2 h-[32px] mr-3"
        >
          {"See More >"}
        </div>
      </div>
      <div className="px-[12px]">
        <div className="flex flex-col mt-[10px]   max-md:max-w-full overflow-scroll scrollbar-hide">
          <div className="grid grid-cols-3 gap-2 sm:gap-4 mt-2">
            <>
              {games?.slice(0, 3)?.map((game, index) => (
                <img
                  loading="lazy"
                  className="w-full h-[74px]"
                  key={index}
                  src={game.urlThumb}
                  alt={game.gameName}
                  onClick={() =>
                    handleGameClick(
                      game.gameId,
                      game.gameName,
                      game.gameCode,
                      game.subProviderName,
                      game.providerName
                    )
                  }
                />
              ))}
            </>
          </div>
          <div className="flex gap-2.5 mt-1.5 scrollbar-hide"></div>
        </div>
        <div className="flex flex-col mt-[12px]  max-md:max-w-full overflow-scroll scrollbar-hide">
          <div className="grid grid-cols-3 gap-2 sm:gap-4 mt-2">
            <>
              {games?.slice(3, 6)?.map((game, index) => (
                <img
                  loading="lazy"
                  className="w-full h-[74px]"
                  key={index}
                  src={game.urlThumb}
                  alt={game.gameName}
                  onClick={() =>
                    handleGameClick(
                      game.gameId,
                      game.gameName,
                      game.gameCode,
                      game.subProviderName,
                      game.providerName
                    )
                  }
                />
              ))}
            </>
          </div>
          <div className="flex gap-2.5 mt-1.5 scrollbar-hide"></div>
        </div>
      </div>
      <Modal isOpen={dialogShow} title="NOTICE" onClose={() => setDialogShow(false)}>
        Access required for gameplay. Please log in to proceed.
      </Modal>
    </section>
  );
};

export default GameSection;
