/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React from "react";

interface BonusStatementTableProps {
  records: any;
}

const BonusStatementTable: React.FC<BonusStatementTableProps> = (props) => {
  const { records } = props;
  const headings = [
    "Bonus Type",
    "Approval Req.",
    "Awarded Date",
    "Awarded Amt.",
    "Turnover",
    "Installments",
    "Redeemed Amt.",
    "Status",
    "Last Date",
  ];

  return (
    <>
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500">
            {headings.map((heading, index) => (
              <th
                key={index}
                className={`px-2 py-1 border-r font-[500] text-[14px] leading-[16.2px] ${
                  index < headings.length - 1 ? "border-[#757575]" : ""
                }`}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((row, index) => (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.bonus_category}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.approval_required ? "Yes" : "No"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {moment(row.award_date).format("DD/MM/YYYY, h:mm:ss A")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.award_amount?.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {(row.turnover_met ? row.turnover_met : "-") +
                  "/" +
                  (row.turnover_required ? row.turnover_required : "-")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {(row.installments_given ? row.installments_given : "-") +
                  "/" +
                  (row.installments ? row.installments : "-")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.redeemed_amount?.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.bonus_status}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
              {row.last_redeem_date
                ? moment(row.last_redeem_date).format("DD/MM/YYYY, h:mm:ss A")
                : "-"}
            </tr>
          ))}
        </tbody>
      </table>
      {records && records.length === 0 && (
        <div className="  h-12 flex justify-center items-center">No bonus</div>
      )}
    </>
  );
};

export default BonusStatementTable;
