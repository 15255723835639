import React, { useEffect, useState } from "react";
import {
  fetchInplayEvent,
  getBannerGames,
  getCasinoGame,
} from "../../store/catalog/catalogThunk";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getInplayEvents } from "../../store/catalog/catalogSelectors";
import { EventDTO } from "../../models/common/EventDTO";
import { isMobile } from "react-device-detect";
import InplayMobPage from "../../components/Mobile/MainPage/InPlayMobilePage";
import InplayPage from "../../pages/InplayPage/InplayPage";
import { useNavigate } from "react-router-dom";
import { Game } from "../../components/Desktop/LiveCasino/types";
import { getStatusFromToken } from "../../utils/commonUtils";
import Modal from "../../components/Modal/Modal";

export type InplayEventsObj = {
  sportId: string;
  sportName: string;
  sportSlug: string;
  events: EventDTO[];
};

type InplayEventProps = {
  inplayEvents: InplayEventsObj[];
  loggedIn: boolean;
  rgGames: Game[];
  egGames: Game[];
  topicUrls: any;
  betFairWSConnected: boolean;
  bannerGames: any;
  getCasinoGame: Function;
  getBannerGames: Function;
};

const InplayView: React.FC<InplayEventProps> = (props) => {
  const {
    inplayEvents,
    bannerGames,
    getCasinoGame,
    loggedIn,
    rgGames,
    egGames,
    topicUrls,
    betFairWSConnected,
    getBannerGames
  } = props;
  const { events } = useSelector((state: RootState) => state.catalog);
  const dispatch = useDispatch<AppDispatch>();
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider: string
  ) => {
    if (loggedIn) {
      navigate(
        `/dc/gamev1.1/${gameName.toLowerCase().replace(/\s+/g, "-")}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    } else {
      setDialogShow(true);
    }
  };

  const getBannerGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedIn) {
      // status check
      const status = getStatusFromToken();
      if (status === 0 || status === 3) {
        navigate(`/home`);
      }
      if (provider === "Indian Casino") {
        navigate(`/casino/indian/${gameCode}`);
      } else {
        navigate(
          `/dc/gamev1.1/${gameName?.toLowerCase().replace(/\s+/g, "-")}-${btoa(
            gameId?.toString()
          )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
        );
      }
    } else {
      setOpenModal(true);
    }
  };

  useEffect(() => {
    let params = {
      providerId: "*",
      trending: true,
      // subProviderId: "Monk88",
    };
    getBannerGames(params);
    dispatch(fetchInplayEvent());
  }, []);

  return (
    <>
      {isMobile ? (
        <InplayMobPage
          getBannerGameUrl={getBannerGameUrl}
          games={bannerGames}
          setDialogShow={setDialogShow}
          dialogShow={dialogShow}
          handleGameClick={getGameUrl}
          rgGames={rgGames}
          egGames={egGames}
          inplayEvents={inplayEvents}
        />
      ) : (
        <InplayPage
          getBannerGameUrl={getBannerGameUrl}
          games={bannerGames}
          inplayEvents={inplayEvents}
          loggedIn={loggedIn}
          topicUrls={topicUrls}
          betFairWSConnected={betFairWSConnected}
        />
      )}
      <Modal
        isOpen={openModal}
        title="NOTICE"
        onClose={() => setOpenModal(false)}
      >
        <div className="bg-white">
          Access required for gameplay. Please log in to proceed.
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    inplayEvents: getInplayEvents(
      state.catalog.events,
      state.common.contentConfig
    ),
    bannerGames: state.catalog.bannerGames,
    loggedIn: state.auth.loggedIn,
    rgGames: state.catalog.rgGames,
    egGames: state.catalog.egGames,
    topicUrls: state.catalog.topicUrls,
    betFairWSConnected: state.catalog.betFairWSConnected,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getCasinoGame: (event: any) => dispatch(getCasinoGame(event)),
    getBannerGames: (event: any) => dispatch(getBannerGames(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InplayView);
