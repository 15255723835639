/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { routesConfig, sportRoutes } from "../../../routes/routesConfig";
import { connect, useDispatch } from "react-redux";
import { getCasinoGame, getEvolutionGame } from "../../../store/catalog/catalogThunk";
import { AppDispatch, RootState } from "../../../store";
import ProtectedRoute from "../../../routes/ProtectedRoutes";
import { protectedRoutesConfig } from "../../../routes/protectedRoutesConfig";
import SportsView from "../../../views/SportsView/SportsView";

interface MainContentProps {
  selectedTab: string;
  loggedIn: boolean;
}

const MainContent: React.FC<MainContentProps> = ({ selectedTab, loggedIn }) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    let params = {
      providerId: "RG",
      trending: true,
      subProviderId: "Royal Gaming"
    }
    dispatch(getCasinoGame(params));
    params = {
      providerId: "DC",
      trending: true,
      subProviderId: "Evolution Gaming",
    };
    dispatch(getEvolutionGame(params));
  }, [dispatch]);

  return (
    <Routes>
      {routesConfig.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<route.component />}
        />
      ))}
      <Route element={<SportsView loggedIn={loggedIn} />} >
        {sportRoutes.map((route, index) => (
          <Route
            key={index + "protected"}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Route>
      <Route element={<ProtectedRoute isAuthenticated={loggedIn} />}>
        {protectedRoutesConfig.map((route, index) => (
          <Route
            key={index + "protected"}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Route>
    </Routes>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps, null)(MainContent);
