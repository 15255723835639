import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordDesktop from "../components/Desktop/AuthScreens/ForgotPassword/ForgotPassword";
import ForgotPassword from "../components/Mobile/ForgotPassword/ForgotPasswordMobile";
import { RootState } from "../store";
import { resetPassword, sendOtpToUsername } from "../store/auth/authThunk";
import { useNavigate } from "react-router-dom";
import { showToast } from "../store/common/commonSlice";

interface ForgotPasswordViewProps {
  otpSuccess: boolean;
  sendOtpToUsername: Function;
  resetPassword: Function;
  showToast: Function;
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = (props) => {
  const { sendOtpToUsername, otpSuccess, resetPassword, showToast } = props;

  const [method, setMethod] = useState("Username");
  const [username, setUsername] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState<number>();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [countryCode, setCountryCode] = useState("+1");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };
  
  const handleMethodChange = (newMethod) => {
    setMethod(newMethod);
    setUsername("");
    setMobile("");
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    await sendOtpToUsername({
      username: username,
      phonenumber: mobile,
      option: method,
    });
  };

  const redirectToSignUp = () => {
    navigate("/signup");
  };

  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      showToast({
        message: "Passwords didn't match",
        type: "error",
      })
      return;
    }
    resetPassword({
      username: username,
      phonenumber: mobile,
      resetPassword: newPassword,
      otp: otp,
      option: method
    });
  };

  const redirectToSignIn = () => {
    navigate('/login');
  }

  return (
    <>
      {isMobile ? (
        <ForgotPassword
          redirectToSignIn={redirectToSignIn}
          redirectToSignUp={redirectToSignUp}
          method={method}
          otpSuccess={otpSuccess}
          mobile={mobile}
          handleMobileChange={handleMobileChange}
          username={username}
          countryCode={countryCode}
          handleCountryCodeChange={handleCountryCodeChange}
          handleUsernameChange={handleUsernameChange}
          handleMethodChange={handleMethodChange}
          handleSubmit={handleSubmit}
          otp={otp}
          setOtp={setOtp}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          resetPassword={handleResetPassword}
          showPasswordClickHandler={showPasswordClickHandler}
          showPassword={showPassword}
        />
      ) : (
        <ForgotPasswordDesktop
          redirectToSignIn={redirectToSignIn}
          showPasswordClickHandler={showPasswordClickHandler}
          showPassword={showPassword}
          redirectToSignUp={redirectToSignUp}
          method={method}
          otpSuccess={otpSuccess}
          mobile={mobile}
          handleMobileChange={handleMobileChange}
          username={username}
          countryCode={countryCode}
          handleCountryCodeChange={handleCountryCodeChange}
          handleUsernameChange={handleUsernameChange}
          handleMethodChange={handleMethodChange}
          handleSubmit={handleSubmit}
          otp={otp}
          setOtp={setOtp}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          resetPassword={handleResetPassword}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    otpSuccess: state.auth.otpSuccess,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    sendOtpToUsername: (event: any) => dispatch(sendOtpToUsername(event)),
    resetPassword: (event: any) => dispatch(resetPassword(event)),
    showToast: (event: any) => dispatch(showToast(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView);
