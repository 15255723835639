import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { isIOS, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import SVLS_API from "../../api/api-services/svls-api";
import {
  fetchEvent,
  fetchInplayEvents,
  getDcGamesAPI,
  getGameUrlAPI,
  getIndianCasinoGamesAPI,
} from "../../api/catalog";
import { PROVIDER_ID } from "../../constants/Branding";
import { EventDTO } from "../../models/common/EventDTO";
import { SelectedObj } from "../../models/SportsState";
import {
  BFToSRIdMap,
  getAccountPathFromToken,
  SPToBFIdMap,
} from "../../utils/commonUtils";
import { useAppSelector } from "../storeHooks";
import {
  disableEventData,
  fetchEventByCompetitionSuccess,
  setBannerGames,
  setCasinoGames,
  setCategoryList,
  setCompetition,
  setEgGames,
  setEventType,
  setExchEvent,
  setGames,
  setGameSrc,
  setInitGames,
  setLoading,
  setProviderList,
  setRgGames,
  setTriggerMarkets,
  updatePremiumMarkets,
  updateSecondaryMarkets,
  updateSecondaryMatchOdds,
  updateTopicUrls,
} from "./catalogSlice";
import { isAccountPath } from "../common/commonThunk";
import store from "..";
import { EXCH_COMPETITIONS_MENU } from "../../constants/CommonConstants";
import { setButtonVariables } from "../betSlip/betSlipSlice";

interface GetCasinoGamesArgs {
  providerId: string;
  trending: boolean;
  subProviderId: string;
}

interface EventTypeArgs {
  sportId: string;
  events: EventDTO[];
}

export const getCasinoGame = createAsyncThunk(
  "catalog/getCasinoGames",
  async (args: GetCasinoGamesArgs, thunkAPI) => {
    try {
      const response = await getDcGamesAPI(args);
      thunkAPI.dispatch(setRgGames(response));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getBannerGames = createAsyncThunk(
  "catalog/getBannerGames",
  async (args: GetCasinoGamesArgs, thunkAPI) => {
    try {
      const response = await getDcGamesAPI(args);
      thunkAPI.dispatch(setBannerGames(response));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getEvolutionGame = createAsyncThunk(
  "catalog/getEvolutionGame",
  async (args: GetCasinoGamesArgs, thunkAPI) => {
    try {
      const response = await getDcGamesAPI(args);
      thunkAPI.dispatch(setEgGames(response));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchInplayEvent = createAsyncThunk(
  "catalog/fetchInplayEvents",
  async (_, thunkAPI) => {
    try {
      const response = await fetchInplayEvents();
      thunkAPI.dispatch(
        fetchEventByCompetitionSuccess(
          updateEventsInStore(thunkAPI.dispatch, response)
        )
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const setEventTypeThunk = createAsyncThunk(
  "catalog/setEventTypeThunk",
  async (eventType: SelectedObj, thunkAPI) => {
    try {
      thunkAPI.dispatch(setEventType(eventType));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const setCompetitionThunk = createAsyncThunk(
  "catalog/setCompetitionThunk",
  async (competition: SelectedObj, thunkAPI) => {
    try {
      thunkAPI.dispatch(setCompetition(competition));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const setExchEventThunk = createAsyncThunk(
  "catalog/setExchEventThunk",
  async (event: SelectedObj, thunkAPI) => {
    try {
      thunkAPI.dispatch(setExchEvent(event));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchEventsBySport = createAsyncThunk(
  "catalog/fetchEventsBySport",
  async (
    { sportId, events }: { sportId: string; events: EventDTO[] },
    { dispatch }
  ) => {
    try {
      if (sportId !== "") {
        // Convert to sport radar id if it includes _
        sportId = sportId.split("_").join(":");

        dispatch(setLoading(true));
        let result = null;
        if (sessionStorage.getItem("jwt_token")) {
          result = await SVLS_API.get(
            `/catalog/v2/sports-feed/sports/${sportId}/competitions/*/events`,
            {
              headers: {
                Authorization: sessionStorage.getItem("jwt_token"),
              },
              params: {
                providerId: PROVIDER_ID,
              },
            }
          );
        } else {
          result = await SVLS_API.get(
            `/catalog/v2/sports-feed/sports/${sportId}/competitions/*/events`,
            {
              params: {
                providerId: PROVIDER_ID,
              },
            }
          );
        }

        let newList = [];

        let eventsList = [];
        if (result && result.data.length > 0) {
          updateTopicUrlsInStore(dispatch, result.data[0]);
          for (let eventData of result.data) {
            try {
              if (eventData?.eventId) {
                newList.push(eventData?.eventId);
                const eData = {
                  enabled: eventData?.enabled,
                  status: eventData?.status,
                  openDate: eventData?.openDate,
                  sportId: eventData?.sportId.includes(":")
                    ? SPToBFIdMap[eventData?.sportId]
                    : eventData?.sportId,
                  competitionId: eventData?.competitionId,
                  competitionName: eventData?.competitionName
                    ? eventData?.competitionName
                    : "Other",
                  eventId: eventData?.eventId,
                  eventName: eventData?.eventName,
                  marketId: eventData?.marketId,
                  providerName: eventData?.providerName,
                  enableFancy: eventData?.markets
                    ? eventData?.markets?.enableFancy
                    : false,
                  enableMatchOdds: eventData?.markets
                    ? eventData?.markets?.enableMatchOdds
                    : false,
                  enableBookmaker: eventData?.markets
                    ? eventData?.markets?.enableBookmaker
                    : false,
                  enablePremium: eventData?.markets
                    ? eventData?.markets?.enablePremium
                    : false,
                  catId: eventData?.catId,
                };

                eventsList.push({
                  ...eData,
                  sportId: eData.sportId,
                  competitionId: eData.competitionId,
                  matchOddsData:
                    eventData?.markets && eventData?.markets?.matchOdds
                      ? eventData?.markets?.matchOdds?.find(
                          (mo) =>
                            mo.marketName === "Match Odds" ||
                            mo?.marketName?.toLowerCase() === "moneyline" ||
                            eventData?.providerName?.toLowerCase() ===
                              "sportradar"
                        )
                      : null,
                  raceMarkets:
                    eventData.markets && eventData.markets.matchOdds
                      ? eventData.markets.matchOdds
                      : [],
                });

                if (eData.sportId === "1") {
                  if (eventData?.markets?.matchOdds?.length > 0) {
                    for (let mo of eventData.markets.matchOdds) {
                      if (
                        mo.marketName !== "Match Odds" &&
                        mo.marketName.toLowerCase() !== "moneyline"
                      ) {
                        const secMOPayload = {
                          eventId: eData.eventId,
                          marketId: mo.marketId,
                          matchOddsData: mo,
                        };
                        dispatch(updateSecondaryMatchOdds(secMOPayload));
                      }
                    }
                  }
                }
              }
            } catch (err) {
              console.log(err);
            }
          }

          // Dispatch a single action with all events
          dispatch(fetchEventByCompetitionSuccess({ events: eventsList }));

          if (events && events.length > 0) {
            for (let ie of events) {
              //todo : revisit this piece of code
              if (!newList.includes(ie.eventId.split("_").join(":"))) {
                const payload = {
                  sportId: ie.sportId,
                  competitionId: ie.competitionId,
                  eventId: ie.eventId,
                  disableEvent: false,
                };
                dispatch(disableEventData(payload));
              }
            }
          }
        } else {
          if (events) {
            for (let ie of events) {
              const payload = {
                sportId: ie.sportId,
                competitionId: ie.competitionId,
                eventId: ie.eventId,
                disableEvent: false,
              };
              dispatch(disableEventData(payload));
            }
          }
        }
        dispatch(setLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchEventsByCompetition = (
  sportId: string,
  competitionId: string,
  events: EventDTO[],
  track: string = ""
) => {
  return async (dispatch: Function) => {
    try {
      if (sportId !== "" && competitionId !== "") {
        dispatch(setLoading(true));
        let result = null;
        if (sessionStorage.getItem("jwt_token")) {
          result = await SVLS_API.get(
            `/catalog/v2/sports-feed/sports/${sportId}/competitions/${competitionId}/events`,
            {
              headers: {
                Authorization: sessionStorage.getItem("jwt_token"),
              },

              params: {
                providerId: PROVIDER_ID,
              },
            }
          );
        } else {
          result = await SVLS_API.get(
            `/catalog/v2/sports-feed/sports/${sportId}/competitions/${competitionId}/events`,
            {
              params: {
                providerId: PROVIDER_ID,
              },
            }
          );
        }

        let newList = [];
        let eventsList = [];
        let secondaryMarketEvents = [];
        let secondaryMatchOddsEvents = [];
        if (result && result.data.length > 0) {
          updateTopicUrlsInStore(dispatch, result.data[0]);
          for (let eventData of result.data) {
            try {
              if (eventData?.eventId) {
                newList.push(eventData?.eventId);
                const eData = {
                  enabled: eventData?.enabled,
                  status: eventData?.status,
                  openDate: eventData?.openDate,
                  sportId: eventData?.sportId.includes(":")
                    ? SPToBFIdMap[eventData?.sportId]
                    : eventData?.sportId,
                  competitionId: eventData?.competitionId,
                  competitionName: eventData?.competitionName
                    ? eventData?.competitionName
                    : "Other",
                  eventId: eventData?.eventId,
                  eventName: eventData?.eventName,
                  marketId: eventData?.marketId,
                  providerName: eventData?.providerName,
                  enableFancy: eventData?.markets
                    ? eventData?.markets?.enableFancy
                    : false,
                  enableMatchOdds: eventData?.markets
                    ? eventData?.markets?.enableMatchOdds
                    : false,
                  enableBookmaker: eventData?.markets
                    ? eventData?.markets?.enableBookmaker
                    : false,
                  enablePremium: eventData?.markets
                    ? eventData?.markets?.enablePremium
                    : false,
                  catId: eventData?.catId,
                };

                eventsList.push({
                  ...eData,
                  sportId: eData.sportId,
                  competitionId: eData.competitionId,
                  matchOddsData:
                    eventData?.markets && eventData?.markets?.matchOdds
                      ? eventData?.markets?.matchOdds?.find(
                          (mo) =>
                            mo.marketName === "Match Odds" ||
                            mo?.marketName?.toLowerCase() === "moneyline" ||
                            eventData?.providerName?.toLowerCase() ===
                              "sportradar"
                        )
                      : null,
                  raceMarkets:
                    eventData.markets && eventData.markets.matchOdds
                      ? eventData.markets.matchOdds
                      : [],
                });

                if (eData.sportId === "1" || eData.sportId === "4") {
                  for (let mo of eventData.markets.matchOdds) {
                    if (
                      mo.marketName !== "Match Odds" &&
                      mo.marketName.toLowerCase() !== "moneyline"
                    ) {
                      const secMOPayload = {
                        eventId: eData.eventId,
                        marketId: mo.marketId,
                        matchOddsData: mo,
                      };
                      secondaryMatchOddsEvents.push(secMOPayload);
                    }
                  }
                }
                if (eData.sportId === "1" || eData.sportId === "4") {
                  for (let mo of eventData.markets.matchOdds) {
                    if (
                      mo.marketName !== "Match Odds" &&
                      mo.marketName.toLowerCase() !== "moneyline"
                    ) {
                      const secMOPayload = {
                        eventId: eData.eventId,
                        marketId: mo.marketId,
                        matchOddsData: mo,
                      };
                      dispatch(updateSecondaryMatchOdds(secMOPayload));
                    }
                  }
                }
                if (eData.sportId === "4") {
                  const secMarketsPayload = {
                    eventId: eData.eventId,
                    bookmakerOddsData: eventData.markets
                      ? eventData.markets.bookmakers
                      : null,
                    enableBookmaker: eventData.markets
                      ? eventData.markets.enableBookmaker
                      : false,
                    sessionOddsData: eventData.markets
                      ? eventData.markets.fancyMarkets
                      : null,
                    enableFancy: eventData.markets
                      ? eventData.markets.enableFancy
                      : false,
                  };
                  if (!(track === EXCH_COMPETITIONS_MENU)) {
                    secondaryMarketEvents.push(secMarketsPayload);
                  }
                }
              }
            } catch (err) {
              console.log(err);
            }
          }

          // Dispatch a single action with all events
          dispatch(updateSecondaryMarkets({ events: secondaryMarketEvents }));
          dispatch(fetchEventByCompetitionSuccess({ events: eventsList }));

          if (events && events.length > 0) {
            for (let ie of events) {
              if (!newList.includes(ie.eventId)) {
                const payload = {
                  sportId: ie.sportId,
                  competitionId: ie.competitionId,
                  eventId: ie.eventId,
                  disableEvent: false,
                };
                dispatch(disableEventData(payload));
              }
            }
          }
        } else {
          for (let ie of events) {
            const payload = {
              sportId: ie.sportId,
              competitionId: ie.competitionId,
              eventId: ie.eventId,
              disableEvent: true,
            };
            dispatch(disableEventData(payload));
          }
        }
        dispatch(setLoading(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };
};

const updateTopicUrlsInStore = (dispatch: Function, eventData: any) => {
  // Dispatch topic urls
  const topicUrlPayload = {
    matchOddsBaseUrl: eventData?.markets?.matchOddsBaseUrl,
    matchOddsTopic: eventData?.markets?.matchOddsTopic,
    bookMakerBaseUrl: eventData?.markets?.bookMakerBaseUrl,
    bookMakerTopic: eventData?.markets?.bookMakerTopic,
    fancyBaseUrl: eventData?.markets?.fancyBaseUrl,
    fancyTopic: eventData?.markets?.fancyTopic,
    premiumBaseUrl: eventData?.markets?.premiumBaseUrl,
    premiumTopic: eventData?.markets?.premiumTopic,
  };
  dispatch(updateTopicUrls(topicUrlPayload));
};

export const fetchPremiummarketsByEventId = createAsyncThunk(
  "catalog/fetchPremiummarketsByEventId",
  async (
    {
      providerId,
      competitionId,
      sportId,
      eventId,
      marketTime,
    }: {
      providerId: string;
      competitionId: string;
      sportId: string;
      eventId: string;
      marketTime: string;
    },
    { dispatch }
  ) => {
    try {
      if (eventId !== "") {
        const eventDetails = [
          {
            providerId: providerId,
            sportId:
              providerId === "SportRadar"
                ? BFToSRIdMap[sportId] || sportId
                : sportId,
            competitionId: competitionId,
            eventId: eventId,
            marketTime: marketTime,
          },
        ];
        const response: AxiosResponse<any> = await SVLS_API.post(
          `/catalog/v2/sports-feed/sports/markets`,
          {
            providerId: providerId,
            marketsCriteria: providerId === "SportRadar" ? "ALL" : "PREMIUM",
            eventDetails: eventDetails,
          }
        );
        const eventData = { ...response.data[0], onRefresh: true };
        // TODO: uncomment when data coming from sap
        updateTopicUrlsInStore(dispatch, eventData);
        if (eventData.eventId) {
          const payload = {
            eventId: eventId,
            eventData: eventData,
          };
          if (providerId !== PROVIDER_ID) {
            dispatch(fetchEventByCompetitionSuccess({ events: [eventData] }));
          }
          dispatch(updatePremiumMarkets(payload));
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const fetchEventMarkets = createAsyncThunk(
  "catalog/fetchEventMarkets",
  async (
    {
      sportId,
      competitionId,
      eventId,
      marketTime,
    }: {
      sportId: string;
      competitionId: string;
      eventId: string;
      marketTime: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await fetchEvent(
        sportId,
        competitionId,
        eventId,
        marketTime
      );

      const eventData = response[0];
      let eventsList = [];
      let secondaryMarketEvents = [];
      updateTopicUrlsInStore(dispatch, eventData);
      if (eventData?.eventId) {
        const eData = {
          enabled: eventData?.enabled,
          status: eventData?.status,
          openDate: eventData?.openDate,
          sportId: eventData?.sportId.includes(":")
            ? SPToBFIdMap[eventData?.sportId]
            : eventData?.sportId,
          competitionId: eventData?.competitionId,
          competitionName: eventData?.competitionName
            ? eventData?.competitionName
            : "Other",
          eventId: eventData?.eventId,
          eventName: eventData?.eventName,
          marketId: eventData?.marketId,
          providerName: eventData?.providerName,
          enableFancy: eventData?.markets
            ? eventData?.markets?.enableFancy
            : false,
          enableMatchOdds: eventData?.markets
            ? eventData?.markets?.enableMatchOdds
            : false,
          enableBookmaker: eventData?.markets
            ? eventData?.markets?.enableBookmaker
            : false,
          enablePremium: eventData?.markets
            ? eventData?.markets?.enablePremium
            : false,
          catId: eventData?.catId,
        };

        eventData?.markets?.matchOdds?.forEach((mo) => {
          if (eData.sportId === "4") {
            if (
              mo.marketName !== "Match Odds" &&
              mo.marketName.toLowerCase() !== "moneyline" &&
              mo.marketId !== "1.196548297" &&
              mo.marketId !== "1.196548301"
            ) {
              const secMOPayload = {
                eventId: eventData.eventId,
                marketId: mo.marketId,
                matchOddsData: mo,
              };
              dispatch(updateSecondaryMatchOdds(secMOPayload));
            }
          } else {
            if (
              mo.marketName !== "Match Odds" &&
              mo.marketName.toLowerCase() !== "moneyline"
            ) {
              const secMOPayload = {
                eventId: eventData.eventId,
                marketId: mo.marketId,
                matchOddsData: mo,
              };
              dispatch(updateSecondaryMatchOdds(secMOPayload));
            }
          }
        });

        if (
          eData.sportId === "4" ||
          eData.sportId === "2" ||
          eData.sportId === "1"
        ) {
          const secMarketsPayload = {
            eventId: eData.eventId,
            bookmakerOddsData: eventData.markets
              ? eventData.markets.bookmakers
              : null,
            enableBookmaker: eventData.markets
              ? eventData.markets.enableBookmaker
              : false,
            sessionOddsData: eventData.markets
              ? eventData.markets.fancyMarkets
              : null,
            enableFancy: eventData.markets
              ? eventData.markets.enableFancy
              : false,
          };
          secondaryMarketEvents.push(secMarketsPayload);
        }

        const secondaryMatchOddsMarketIds: string[] = [];
        let matchOddsData = null;
        if (eventData.markets && eventData.markets.matchOdds) {
          for (let mo of eventData.markets.matchOdds) {
            if (mo.marketName === "Match Odds") {
              matchOddsData = mo;
            } else {
              secondaryMatchOddsMarketIds.push(mo.marketId);
            }
          }
        }
        eventsList.push({
          ...eData,
          sportId: SPToBFIdMap[eData.sportId]
            ? SPToBFIdMap[eData.sportId]
            : eData.sportId,
          competitionId: eData.competitionId,
          matchOddsData: matchOddsData,
          secondaryMatchOddsMarketIds: secondaryMatchOddsMarketIds,
          onRefresh: true,
        });
      }
      // Dispatch a single action with all events
      dispatch(updateSecondaryMarkets({ events: secondaryMarketEvents }));
      dispatch(fetchEventByCompetitionSuccess({ events: eventsList }));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateEventsInStore = (dispatch, result) => {
  let newList = [];
  let eventsList = [];
  if (result && result.length > 0) {
    updateTopicUrlsInStore(dispatch, result?.[0]);
    for (let eventData of result) {
      try {
        if (eventData?.eventId) {
          newList.push(eventData?.eventId);
          const eData = {
            enabled: eventData?.enabled,
            status: eventData?.status,
            openDate: eventData?.openDate,
            sportId: eventData?.sportId.includes(":")
              ? SPToBFIdMap[eventData?.sportId]
              : eventData?.sportId,
            competitionId: eventData?.competitionId,
            competitionName: eventData?.competitionName
              ? eventData?.competitionName
              : "Other",
            eventId: eventData?.eventId,
            eventName: eventData?.eventName,
            marketId: eventData?.marketId,
            providerName: eventData?.providerName,
            enableFancy: eventData?.markets
              ? eventData?.markets?.enableFancy
              : false,
            enableMatchOdds: eventData?.markets
              ? eventData?.markets?.enableMatchOdds
              : false,
            enableBookmaker: eventData?.markets
              ? eventData?.markets?.enableBookmaker
              : false,
            enablePremium: eventData?.markets
              ? eventData?.markets?.enablePremium
              : false,
            catId: eventData?.catId,
          };

          eventsList.push({
            ...eData,
            sportId: eData.sportId,
            competitionId: eData.competitionId,
            matchOddsData:
              eventData?.markets && eventData?.markets?.matchOdds
                ? eventData?.markets?.matchOdds?.find(
                    (mo) =>
                      mo.marketName === "Match Odds" ||
                      mo?.marketName?.toLowerCase() === "moneyline" ||
                      eventData?.providerName?.toLowerCase() === "sportradar"
                  )
                : null,
            raceMarkets:
              eventData.markets && eventData.markets.matchOdds
                ? eventData.markets.matchOdds
                : [],
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    return { events: eventsList };
  }
};

export const getIndianCasinoGames = createAsyncThunk(
  "catalog/getIndianCasinoGames",
  async (_, thunkAPI) => {
    const response = await getIndianCasinoGamesAPI();
    thunkAPI.dispatch(setGames(response));
    let providersList = ["All"];
    let categoriesList = ["All"];
    console.log(response);
    response.forEach((g) => {
      !providersList.includes(g.subProviderName) &&
        providersList.push(g.subProviderName);
      !categoriesList.includes(g.category) && categoriesList.push(g.category);
    });
    const providers = arrangeList(providersList);
    const categories = arrangeList(categoriesList);
    thunkAPI.dispatch(setProviderList(providers));
    thunkAPI.dispatch(setCategoryList(categories));
  }
);

export const getGameUrl = createAsyncThunk(
  "catalog/getGameUrl",
  async (payload: any, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const claims = sessionStorage.getItem("jwt_token").split(".")[1];
    const userStatus = JSON.parse(window.atob(claims)).status;
    if (userStatus === 0 || userStatus === 3) {
      const navigate = useNavigate();
      return navigate(`/home`);
    }
    const response = await getGameUrlAPI(payload);
    if (response) {
      if (isMobile && isIOS) {
        thunkAPI.dispatch(setLoading(false));
        thunkAPI.dispatch(setGameSrc(response));
      } else {
        thunkAPI.dispatch(setGameSrc(response));
        thunkAPI.dispatch(setLoading(false));
      }
    }
  }
);

export const getDcGames = createAsyncThunk(
  "catalog/getDcGames",
  async (_, thunkAPI) => {
    const response = await getDcGamesAPI({
      providerId: "*",
    });
    const rgGames = response.filter((game) => game.subProviderName.toLowerCase() === 'royal gaming')
    const egGames = response.filter((game) => game.subProviderName.toLowerCase() === 'evolution gaming')
    thunkAPI.dispatch(setRgGames(rgGames));
    thunkAPI.dispatch(setEgGames(egGames));
    thunkAPI.dispatch(setInitGames(response));
    thunkAPI.dispatch(setGames(response));
    thunkAPI.dispatch(setCasinoGames(response));
    let providersList = ["All"];
    let categoriesList = ["All"];
    response.forEach((g) => {
      !providersList.includes(g.subProviderName) &&
        providersList.push(g.subProviderName);
      !categoriesList.includes(g.category) && categoriesList.push(g.category);
    });
    const providers = arrangeList(providersList);
    const categories = arrangeList(categoriesList);
    thunkAPI.dispatch(setProviderList(providers));
    thunkAPI.dispatch(setCategoryList(categories));
  }
);

const arrangeList = (list) => {
  return list.map((item) => {
    return {
      value: item,
      label: item
    }
  })
}

export const getSelectedCategoryGames = createAsyncThunk(
  "catalog/getSelectedCategoryGames",
  async (data: any, thunkAPI) => {
    if (
      data.selectedGameType === "All" &&
      data.selectedProvider === "All" &&
      !data.searchTerm
    ) {
      data.setGamesDisplay([...data.games]);
    } else {
      const filteredGames = data.games.filter((g) => {
        let gameFound = false;
        if (data.selectedGameType !== "All") {
          if (
            g.gameName.toLowerCase().includes(data.searchTerm.toLowerCase()) &&
            (data.selectedGameType.toLowerCase() === g.category.toLowerCase() ||
              data.selectedGameType.toLowerCase() === g.gameName.toLowerCase())
          ) {
            if (data.selectedProvider === "All") {
              gameFound = true;
            } else {
              if (
                data.selectedProvider.toLowerCase() ===
                g.subProviderName.toLowerCase()
              ) {
                gameFound = true;
              }
            }
          }
        } else {
          if (data.selectedProvider === "All") {
            if (
              g.gameName.toLowerCase().includes(data.searchTerm.toLowerCase())
            ) {
              gameFound = true;
            }
          } else {
            if (
              g.gameName
                .toLowerCase()
                .includes(data.searchTerm.toLowerCase()) &&
              data.selectedProvider.toLowerCase() ===
                g.subProviderName.toLowerCase()
            ) {
              gameFound = true;
            }
          }
        }
        return gameFound;
      });
      data.setGamesDisplay(filteredGames);
    }
  }
);
