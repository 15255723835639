/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface AddNewAccountProps {
  onClick: () => void;
}

const AddNewAccount: React.FC<AddNewAccountProps> = ({ onClick }) => {
  return (
    <div className="flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center font-medium rounded-none">
        <div
          onClick={onClick}
          className="cursor-pointer flex flex-col px-5 py-9 bg-white  border border-dashed border-neutral-200"
        >
          <div className="self-center flex w-[30px] justify-center text-xl text-black whitespace-nowrap bg-zinc-100 ">
            +
          </div>
          <div className="mt-2 w-[100%] flex justify-center items-center text-[14px] text-stone-900">
            Add New Account
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAccount;
