import React from "react";
import login_bg from "../../../assets/login_bg.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import SideScreen from "./ForgotPassword/ForgotPasswordDesktop/SideImageDesktop"
import Loader from "../../Loader/Loader";

interface FormValueDTO {
  username: string;
  password: string;
  code: string;
}
type SignInProps = {
  handleSubmit: Function;
  handleDemoLogin: Function;
  redirectToSignup: Function;
  redirectToForgotPassword: Function;
  handleChange: Function;
  formValues: FormValueDTO;
  formErrors: FormValueDTO;
  demoLoading: boolean;
  loading: boolean;
  loadLogin: boolean;
  showPasswordClickHandler: Function;
  showPassword: boolean;
  redirectToHome: Function;
};

const SignIn: React.FC<SignInProps> = (props) => {
  const {
    handleDemoLogin,
    handleSubmit,
    redirectToSignup,
    redirectToForgotPassword,
    formErrors,
    formValues,
    handleChange,
    demoLoading,
    loadLogin,
    loading,
    showPasswordClickHandler,
    redirectToHome,
    showPassword,
  } = props;

  return (
    <div className="h-screen flex items-center">
      <SideScreen tagline="Bet smarter,Bet with confidence, Win bigger: your ultimate bet experience" />
      <div className=" -translate-x-40 border relative right-[40px] bg-white rounded-2xl w-[390px] min-w-[390px]">
        <section className="flex z-10 flex-col justify-center self-center px-4 py-6 mt-0 w-full text-sm font-medium bg-white rounded-2xl border-4 border-solid border-neutral-200 max-w-[430px] text-neutral-500">
          <button
            onClick={() => redirectToHome()}
            className="flex items-center gap-1.5 text-sm font-medium whitespace-nowrap text-stone-900 w-[61px]"
          >
            <FaArrowLeftLong />
            <span className="my-auto">Back</span>
          </button>
          <h2 className="self-center text-3xl font-semibold text-center text-stone-900">
            Sign in
          </h2>
          <p className="self-center mt-3 text-base text-center">
            Please enter your login details here.
          </p>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="mt-10">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Enter your username"
                className="px-3 h-[44px]  py-4 mt-3 w-full bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60"
                value={formValues.username}
                onChange={(e) => handleChange(e)}
              />
              {formErrors.username && (
                <div className="text-red-500 text-sm mt-1">
                  {formErrors.username}
                </div>
              )}
            </div>
            <div className="mt-5">
              <label htmlFor="password">Password</label>
              <div className="flex gap-5 h-[44px] justify-between px-3.5 py-3 mt-3 bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  className="w-full bg-transparent border-none"
                  value={formValues.password}
                  onChange={(e) => handleChange(e)}
                />

                <div className="cursor-pointer" onClick={() => showPasswordClickHandler()}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {formErrors.password && (
                <div className="text-red-500 text-sm mt-1">
                  {formErrors.password}
                </div>
              )}
              {
                formErrors?.code && (
                  <div className="text-red-500 text-sm mt-1">
                    {formErrors.code}
                  </div>
                )
              }
            </div>
            <a
              href="#"
              className="block self-end mt-4 text-right text-stone-900"
              onClick={(e) => redirectToForgotPassword(e)}
            >
              Forgot Password?
            </a>
            <div className="flex gap-3 mt-6 text-base font-semibold text-center text-white">
              <button
                type="button"
                className="w-full py-3 rounded-lg shadow-sm bg-stone-900"
                onClick={(e) => handleDemoLogin(e)}
              >
                Demo Login
                {demoLoading && <span className="ml-2"><Loader /></span>}
              </button>
              <button
                type="submit"
                className="w-full py-3 whitespace-nowrap bg-fuchsia-800 rounded-lg shadow-sm"
              >
                Login
                {loading && loadLogin && (
                  <span className="ml-2"><Loader /></span>
                )}
              </button>
            </div>
          </form>
          <p className="self-center mt-4 text-sm text-center">
            Don't have account?{" "}
            <a
              href="#"
              className="font-semibold text-fuchsia-800 underline"
              onClick={(e) => redirectToSignup(e)}
            >
              Sign Up
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default SignIn;
