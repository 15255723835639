/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import purple from "../../../../assets/purple_bg.png";

interface ChangePasswordProps {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  updateNewPassword: Function;
  setOldPassword: Function;
  setNewPassword: Function;
  setConfirmPassword: Function;
  resetPassword: Function;
}

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  const {
    oldPassword,
    newPassword,
    confirmPassword,
    updateNewPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    resetPassword,
  } = props;

  return (
    <div className="flex flex-col pb-20 mt-3.5 w-full bg-white rounded-md overflow-hidden border border-solid border-neutral-200 max-md:mt-6 max-md:max-w-full">
      <div className="flex flex-col justify-center items-start text-[14px] font-medium text-white rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
        <div className="flex bg-stone-900 w-full">
          <div className=" bg-[#804091] min-w-[170px] py-2 pr-4 pl-2 text-[14px] text-white font-medium">
            My Profile
          </div>
          <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[7px] w-4 h-full"></div>
          <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full -translate-x-1"></div>
          <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full"></div>
        </div>
      </div>
      <form className="flex flex-col text-[14px] w-full">
        <div className="w-full gap-3 px-2 mt-3.5 text-stone-900 max-md:flex-wrap">
          <div className="flex w-full flex-col py-0.5 max-md:max-w-full">
            <label htmlFor="firstName" className="max-md:max-w-full">
              Old Password
            </label>
            <input
              id="firstName"
              type="text"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="px-3 py-2 h-[44px] mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
              placeholder="Enter"
            />
          </div>
          <div className="flex w-full mt-3.5 flex-col py-0.5 max-md:max-w-full">
            <label htmlFor="lastName" className="max-md:max-w-full">
              New Password
            </label>
            <input
              id="lastName"
              type="text"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="px-3 py-2 h-[44px] mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
              placeholder="Enter"
            />
          </div>
          <div className="flex w-full mt-3.5 flex-col py-0.5 max-md:max-w-full">
            <label htmlFor="confirmPassword" className="max-md:max-w-full">
              Confirm Password
            </label>
            <input
              id="confirmPassword"
              type="text"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="px-3 py-2 h-[44px] mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
              placeholder="Enter"
            />
          </div>
        </div>
        <div className="flex gap-3 self-end mt-10 max-w-full text-sm text-center whitespace-nowrap w-[412px]">
          <button
            type="reset"
            onClick={() => resetPassword()}
            className="px-16 py-2.5 h-[40px] w-[192px] text-black rounded-md border border-fuchsia-700 border-solid max-md:px-5"
          >
            Reset
          </button>
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              updateNewPassword()
            }}
            className="px-16 py-2.5 h-[40px] w-[192px] text-white bg-fuchsia-700 rounded-md max-md:px-5"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
