/**
 * This code was generated by Builder.io.
 */
import React from "react";
import change_password from "../../../../assets/change_password.svg";
import deposit from "../../../../assets/deposit.svg";
import personal_info from "../../../../assets/personal_info.svg";
import referral from "../../../../assets/referral.svg";
import withdraw from "../../../../assets/withdraw.svg";

interface SocialIconsProp {
  activeTab: string;
  setActiveTab: any;
}
const SocialIcons: React.FC<SocialIconsProp> = ({
  activeTab,
  setActiveTab,
}) => {
  const icons = [
    {
      src: personal_info,
      alt: "Social icon 1",
      path: "Personal Info",
    },
    {
      src: change_password,
      alt: "Social icon 2",
      path: "Change Password",
    },
    {
      src: deposit,
      alt: "Social icon 3",
      path: "Deposit",
    },
    {
      src: withdraw,
      alt: "Social icon 4",
      path: "Withdraw",
    },
    {
      src: referral,
      alt: "Social icon 5",
      path: "Referral",
    },
  ];

  return (
    <div className="flex justify-center items-center px-16 py-2 w-full bg-neutral-200">
      <div className="flex gap-4">
        {icons.map((icon, index) => (
          <img
            key={index}
            loading="lazy"
            src={icon.src}
            alt={icon.alt}
            className={`shrink-0 w-8 aspect-[1.06] border-[2px] rounded-md p-1 border-black ${activeTab === icon.path ? 'bg-gray-400' : ''}` }
            onClick={() => setActiveTab(icon.path)}
          />
        ))}
      </div>
    </div>
  );
};

export default SocialIcons;
