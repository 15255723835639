import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccountDetails } from '../../api/account';

export const fetchAccountDetails = createAsyncThunk(
    'account/fetchAccountDetails',
    async (userId: string, thunkAPI) => {
        try {
            const response = await getAccountDetails(userId);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
