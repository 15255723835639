import {
  ConfirmBankPaymentReqPayloadType,
  DepositApiPayloadType,
  PAYMENT_METHODS,
} from "../components/Desktop/MyProfile/ProfileTabs/utils/types";
import AGPAY_API from "./api-services/feature-api";
import {
  AddAccountPayloadType,
  AddUpiAccountPayloadType,
  WithdrawFundsPayloadType,
} from "../components/Desktop/Withdraw/utils/types";

const paymentProvider = "zenpay";

const checkProvider = (paymentProvider) => paymentProvider.toLowerCase() === 'zenpay' ? 'pgman' : paymentProvider;

export const getPaymentMethods = (paymentOption: PAYMENT_METHODS) => {
  const provider = checkProvider(paymentProvider);
  return AGPAY_API.get(`/agpay/v2/${provider}/payment-methods`, {
    params: {
      admin: false,
      paymentOption,
    },
  });
};

export const reqWithdrawFunds = async (
  withdrawPayload: WithdrawFundsPayloadType
) => {
  const {
    amount,
    currency_type,
    mobile_number,
    notes,
    payment_method,
    payment_method_id,
  } = withdrawPayload;

  const payload = {
    amount,
    notes,
    payment_method_id,
    payment_method,
    currency_type,
    mobile_number,
  };
  return AGPAY_API.post(
    `/agpay/v2/${paymentProvider}/transactions/:withdraw`,
    payload
  );
};

export const reqAddNewAccount = (
  payload: AddAccountPayloadType | AddUpiAccountPayloadType
) => AGPAY_API.post(`/agpay/v2/${checkProvider(paymentProvider)}/payment-methods`, payload);

export const reqAccountDelete = (paymentMethodId: string) =>
  AGPAY_API.delete(
    `/agpay/v2/${paymentProvider}/payment-methods/${paymentMethodId}`
  );

export const reqDepositFunds = (payload: DepositApiPayloadType): any => {
  // TODO add proper return type
  return AGPAY_API.post(
    `/agpay/v2/${paymentProvider}/transactions/:deposit`,
    payload
  );
};

export const reqConfirmDeposit = (
  payload: ConfirmBankPaymentReqPayloadType
): any => {
  // TODO add proper return type
  return AGPAY_API.post(
    `/agpay/v2/${paymentProvider}/:confirm-payment`,
    payload
  );
};
