/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React, { useState } from "react";
import {
  MarketTypeMap,
  TransactionTypeMap,
} from "../../../../store/reports/reportSelectors";
import Modal from "../../../Modal/Modal";
import CustomTable from "../../../Desktop/AccountStatement/CustomTable";

interface AccountStatementTableProps {
  records: any;
}

const AccountStatementTable: React.FC<AccountStatementTableProps> = (props) => {
  const { records } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState<boolean>(false);
  // const records=[{
  //   transactionTime:"2763355353",
  //   transactionType:"82883",
  //   eventName:'jdjdjd',
  //   marketType:'jdjdjd',
  //   amount:7733,
  //   balanceAfter:28833,
  //   transactionId:37373

  // }]
  // const headings = [
  //   "Date",
  //   "Transaction",
  //   "Event",
  //   "Market Type",
  //   "Credit/Debit",
  //   "Close Balance",
  //   "Transaction ID",
  // ];

  return (
    <>
      <div className="flex flex-col justify-center px-3 py-px mt-1.5 w-full text-[14px] font-medium rounded-md bg-neutral-200 text-neutral-500">
        <div className="overflow-x-scroll text-[14px]">
          <div className="flex gap-5 justify-between w-full">
            <div className="my-auto">Date</div>
            <div className="flex gap-4 whitespace-nowrap">
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">Transaction</div>
              </div>
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">Event</div>
              </div>
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">Market Type</div>
              </div>
            </div>
          </div>
          {records?.map((row, index) => (
            <div
              key={index}
              className="flex flex-col items-center py-0.5 mt-1.5 w-full bg-white border-2 border-sky-200 border-solid"
            >
              <div className="flex z-10 gap-5 justify-between w-full max-w-[379px]">
                <div className="flex flex-col self-start mt-3 pl-[10px]">
                  <div className="text-sm font-medium text-stone-900">
                    {moment(row.transactionTime).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto text-wrap">
                    {TransactionTypeMap[row.transactionType]}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto text-wrap">{row.eventName}</div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto text-wrap">
                    {" "}
                    {MarketTypeMap[row.marketType]}
                  </div>
                </div>
              </div>
              <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
              <div className="flex">
                <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
                  <div className="flex flex-col my-auto">
                    <div className="text-wrap">
                      <div className="font-medium text-neutral-500">
                        Credit/Debit:
                      </div>
                      <div className="text-black text-center">{row.amount}</div>
                    </div>
                  </div>
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                </div>

                <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
                  <div className="flex flex-col my-auto">
                    <div className="flex gap-1">
                      <div className="font-medium text-neutral-500">
                        Close Balance:
                      </div>
                      <div className="text-black">{row.balanceAfter}</div>
                    </div>
                  </div>
                </div>
                <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
                  <div className="flex flex-col my-auto">
                    <div className="">
                      <div className="font-medium text-nowrap text-neutral-500">
                        Transaction ID:
                      </div>
                      <div className="text-black text-center">
                        {row.transactionId}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                <div className=" w-full text-[14px] max-w-[310px]">
                  <div className="">
                    <div
                      className=""
                      onClick={() => {
                        setSelectedRecord(row);
                        setShowTransactionDetailsModal(true);
                      }}
                    >
                      <div className="font-medium text-center text-neutral-500">
                        {">"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
              </div>
            </div>
          ))}
          {records && records.length === 0 && (
            <div className=" text-center">No bets</div>
          )}
        </div>
      </div>
      {/* <div className="overflow-x-scroll text-[14px]">
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500">
            {headings.map((heading, index) => (
              <th
                key={index}
                className={`px-2 py-1 border-r ${
                  index < headings.length - 1 ? "border-[#757575]" : ""
                }`}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((row, index) => (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {moment(row.transactionTime).format("DD/MM/YYYY")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {TransactionTypeMap[row.transactionType]}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.eventName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {MarketTypeMap[row.marketType]}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.amount}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.balanceAfter}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.transactionId}
              </td>
              <td
                onClick={() => {
                  setSelectedRecord(row);
                  setShowTransactionDetailsModal(true);
                }}
                className="px-6 py-4 whitespace-nowrap text-sm"
              >
                {">"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {records && records.length === 0 && (
        <div className=" text-center">No statements</div>
      )}
      </div> */}
      <Modal isOpen={isModalOpen} title="Casino " onClose={closeModal}>
        <CustomTable row={selectedRecord} />
      </Modal>
    </>
  );
};

export default AccountStatementTable;
