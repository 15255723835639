import React from "react";
import DatePicker from "./DatePicker";
import TurnoverHistoryTable from "./TurnoverHistoryTable";

import purple from "../../../assets/purple_bg.png";

interface TurnoverHistoryProps {
  fetchTurnoverHistory: Function;
  handleDateChange:any;
  turnover:any;
  filters:any;
  setFilters:any;
}

const TurnoverHistory: React.FC<TurnoverHistoryProps> = (props) => {
  const { turnover, fetchTurnoverHistory,handleDateChange,filters,setFilters } = props;

  return (
    <div className="flex flex-col bg-zinc-100">
      <div className="w-full max-md:max-w-full ">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-full">
            <div className="flex flex-col py-px w-full text-[14px] font-medium bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6">
            <div className="flex flex-col justify-center items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
                <div className="flex ">
                  <div className=" bg-[#804091] w-full py-2 pr-4 pl-2 text-[14px] text-white">
                    Turnover History
                  </div>
                  <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[8px] w-5 h-full"></div>
                  <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full -translate-x-1"></div>
                  <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full"></div>
                </div>
              </div>
              <DatePicker onDateChange={handleDateChange} setFilters={setFilters} filters={filters} />
              <TurnoverHistoryTable records={turnover} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TurnoverHistory;
