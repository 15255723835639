/**
 * This code was generated by Builder.io.
 */
import React from "react";
import TransactionFilter from "./TransactionFilter";
import TransactionList from "./TransactionList";
import purple from "../../../../assets/purple_bg.png";
import BallIcon from "../../../../assets/icons/BallIcon.webp";
interface ProfitLossProps {
  plStatement: any;
  fetchPLStatement?: Function;
  handleDateChange: Function;
  filters: any;
  setFilters: any;
  nextPage: Function;
  prevPage: Function;
  pageToken: string;
}

const ProfitLoss: React.FC<ProfitLossProps> = (props) => {
  const {
    plStatement,
    pageToken,
    nextPage,
    prevPage,
    fetchPLStatement,
    handleDateChange,
    filters,
    setFilters,
  } = props;
  const records = [{}];
  return (
    <div className="flex overflow-hidden flex-col mx-auto w-full bg-zinc-100 max-w-[480px] transition ease-in-out duration-[2000ms]">
      <main className="flex flex-col pt-px mt-3 w-full bg-white ">
        <div className="bg-[black]">
          <div
            style={{ backgroundImage: `url(${purple})` }}
            className=" flex bg-cover h-full leading-[32px] w-[60%]"
          >
            <span className="text-white ml-2">Betting Profit & Loss</span>
          </div>
        </div>
        <TransactionFilter
          startDate={filters.startDate}
          filters={filters}
          endDate={filters.endDate}
          handleDateChange={handleDateChange}
        />
        <TransactionList plStatement={plStatement} filters={filters} setFilters={setFilters}/>
        <div className=" flex justify-center">
          {filters?.pageToken.length > 0 && (
            <button
              className=" p-2 w-16 leading-[16.2px] text-white bg-fuchsia-800 m-2"
              onClick={(e) => prevPage()}
            >
              Prev({filters.pageToken.length})
            </button>
          )}
          {pageToken ? (
            <button
              className=" p-2 w-16 leading-[16.2px] text-white bg-fuchsia-800 m-2"
              onClick={(e) => nextPage()}
            >
              Next({filters?.pageToken.length + 2})
            </button>
          ) : null}
        </div>
      </main>
    </div>
  );
};

export default ProfitLoss;
