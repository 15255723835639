/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useRef, useState } from "react";
import { EventDTO } from "../../../models/common/EventDTO";
import {
  BookmakerDTO,
  BookmakerRunnerDTO,
} from "../../../models/common/BookmakerDTO";
import { PlaceBetRequest } from "../../../models/BsData";
import { UserBet } from "../../../models/UserBet";
import { CURRENCY_TYPE_FACTOR } from "../../../constants/CurrencyTypeFactor";
import {
  getCurrencyTypeFromToken,
  ThousandFormatter,
} from "../../../utils/commonUtils";
import SVLS_API from "../../../api/api-services/svls-api";
import { CashoutInfo } from "./MatchOdds";
import moment from "moment";
import { RootState } from "../../../store";
import { addExchangeBet } from "../../../store/betSlip/betSlipThunk";
import { connect } from "react-redux";
import { TOSS_ODD_VALUE } from "../../../constants/CommonConstants";
import MarketBtn from "../MarketComponents/MarketBtn/MarketBtn";
import purple from "../../../assets/purple_bg.png";
import {
  addToMultiMarket,
  checkIncludeMultiMarket,
  removeToMultiMarket,
} from "../../../store/multiMarket/multiMarketUtils";
import RemoveMultiPinDarkGreen from "../../../assets/icons/remove_multi_pin_dark_green.svg";
import RemoveMultiPinDarkViolet from "../../../assets/icons/remove_multi_pin_dark_violet.svg";
import RemoveMultiPin from "../../../assets/icons/removemultimarket.svg";
import MultiPin from "../../../assets/icons/multipin.svg";
import { useNavigate } from "react-router-dom";
import { useMarketLocalState } from "../../../hooks/storageHook";
import { isMobile } from "react-device-detect";
import Betslip from "../Betslip/Betslip";

export interface OddsInfoMsg {
  launch: boolean;
  oddsType: string;
  eventTypeID: string;
}

interface BMProps {
  eventData: EventDTO;
  bmData: BookmakerDTO[];
  bets: PlaceBetRequest[];
  openBets: UserBet[];
  commissionEnabled: boolean;
  addExchangeBet: (data: PlaceBetRequest) => void;
  loggedIn: boolean;
  getFormattedMinLimit: (num: number) => string;
  getFormattedMaxLimit: (num: number) => string;
  isMultiMarket?: boolean;
  exposureMap: any;
  fetchEvent: (
    sportId: string,
    competitionId: string,
    eventId: string,
    marketTime: string
  ) => void;
  setBetStartTime?: Function;
  setAddNewBet?: Function;
}

const multiPinsMap = {
  purple: RemoveMultiPin,
  darkvoilet: RemoveMultiPinDarkViolet,
  darkgreen: RemoveMultiPinDarkGreen,
};

const Bookmaker: React.FC<BMProps> = (props) => {
  const {
    eventData,
    bmData,
    bets,
    setAddNewBet,
    setBetStartTime,
    addExchangeBet,
    isMultiMarket,
    loggedIn,
    exposureMap,
    fetchEvent,
  } = props;
  // let history = useHistory();
  const betslipRef = useRef(null);

  useEffect(() => {
    if (bets?.length > 0 && betslipRef.current) {
      betslipRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'center', // Scroll so the element is in the center of the view
      });
    }
  }, [bets]);
  const [multiMarketData, setMultiMarketData] = useMarketLocalState();
  const navigate = useNavigate();
  const [bmLimits, setBMLimits] = useState<any>({});
  
  const [infoDilalog, setInfoDialog] = useState<OddsInfoMsg>({
    launch: false,
    oddsType: null,
    eventTypeID: null,
  });
  // const marketData = useMarketLocalState();
  const disabledStatus = [
    "suspended",
    "closed",
    "suspended-manually",
    "ball_running",
  ];

  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const tableFields = [
    {
      key: "teamName",
      Label: "Market",
      className: "market-name-cell-head",
      align: "left",
    },
    {
      key: "Back",
      Label: "Back",
      className: "odds-cell-head back-odd",
      align: "center",
    },
    {
      key: "Lay",
      Label: "Lay",
      className: "odds-cell-head lay-odd",
      align: "center",
    },
  ];

  const getOpenBetsPL = (
    runner: BookmakerRunnerDTO,
    marketId: string,
    marketName: string
  ) => {
    if (exposureMap && exposureMap?.[`${marketId}:${marketName}`]) {
      for (let rn of exposureMap[`${marketId}:${marketName}`]) {
        if (rn.runnerId === runner.runnerId) {
          return rn?.userRisk / cFactor;
        }
      }
    }
  };

  const getOpenBetsPLInArray = (
    runner: BookmakerRunnerDTO,
    marketId: string,
    marketName: string
  ) => {
    let pl = getOpenBetsPL(runner, marketId, marketName);
    return pl ? [pl] : [];
  };

  const getTotalPL = (
    runner: BookmakerRunnerDTO,
    marketId: string,
    marketName: string
  ) => {
    let returns = null;
    const mBetslipBets = bets.filter(
      (b) => b.marketId === marketId && b.amount && b.amount > 0
    );
    if (mBetslipBets.length > 0) {
      returns = getOpenBetsPL(runner, marketId, marketName);
      for (let bet of mBetslipBets) {
        const plVal = (bet.oddValue / 100 + 1) * bet.amount - bet.amount;
        if (bet.betType === "BACK") {
          if (bet.outcomeId === runner.runnerId)
            returns ? (returns += plVal) : (returns = plVal);
          else returns ? (returns -= bet.amount) : (returns = 0 - bet.amount);
        } else if (bet.betType === "LAY") {
          if (bet.outcomeId === runner.runnerId)
            returns ? (returns -= plVal) : (returns = 0 - plVal);
          else returns ? (returns += bet.amount) : (returns = bet.amount);
        }
      }
    }
    return [returns];
  };

  const getOutcomeId = (selectionId: string, runners: BookmakerRunnerDTO[]) => {
    for (let mo of runners) {
      if (mo.runnerId === selectionId) {
        return mo.runnerName;
      }
    }
  };
  const CashOut = async (matchOddsData: BookmakerDTO) => {
    try {
      const response = await SVLS_API.get(
        `/order/v2/orders/sports/${eventData?.sportId}/events/${eventData?.eventId}/markets/${matchOddsData.marketId}/cash-out-bet:probability`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
          },
        }
      );

      if (response.status === 200) {
        const betDetails: CashoutInfo = response.data;

        // Add bet to betslip
        addExchangeBet({
          sportId: eventData?.sportId,
          seriesId: eventData?.competitionId,
          seriesName: eventData?.competitionName,
          eventId: eventData?.eventId,
          eventName: eventData?.eventName,
          eventDate: eventData?.openDate,
          marketId: matchOddsData.marketId,
          marketName: matchOddsData.marketName,
          marketType: "BM",
          outcomeId: betDetails?.selectionId,
          betType: betDetails?.betType === 0 ? "BACK" : "LAY",
          oddValue: +(+betDetails?.oddValue * 100 - 100).toFixed(2),
          // initialOddValue: +betDetails.oddValue.toFixed(2),
          amount: +(betDetails?.stakeAmount / cFactor).toFixed(2),
          outcomeDesc: getOutcomeId(
            betDetails?.selectionId,
            matchOddsData.runners
          ),
          sessionPrice: -1,
          srEventId: eventData?.eventId,
          srSeriesId: eventData?.competitionId,
          srSportId: eventData?.sportId,
          oddLimt: matchOddsData?.marketLimits?.maxOdd.toString(),
          mcategory: "ALL",
        });
      }
    } catch (err) {
      console.log("Something went wrong");
    }
  };

  const isHaveCashOut = (bmMarket: BookmakerDTO) => {
    if (bmMarket?.runners?.length !== 2) {
      return false;
    }
    return true;
  };

  const handleMultiMarket = (
    competitionId,
    eventId,
    marketId,
    providerName,
    sportId,
    isAdd = true
  ) => {
    if (loggedIn) {
      if (isAdd) {
        addToMultiMarket(
          competitionId,
          eventId,
          marketId,
          providerName,
          sportId
        );
        marketId &&
          setMultiMarketData((prevState) => {
            return [
              ...prevState,
              {
                competitionId,
                eventId,
                marketId,
                providerName,
                sportId,
              },
            ];
          });
      } else {
        removeToMultiMarket(eventId, marketId);
        marketId &&
          setMultiMarketData((prevState) => {
            let data = [...prevState];
            const index = data?.findIndex(
              (itm) => itm.eventId === eventId && itm.marketId === marketId
            );
            index > -1 && data.splice(index, 1);
            return [...data];
          });
      }
    } else {
      navigate("/login");
    }
  };
  

  return (
    <div className="flex flex-col py-px mt-1.5 bg-white rounded-md border border-solid border-neutral-200 max-md:max-w-full">
      {bmData?.map((market, index) => (
        <>
          {!isMultiMarket ||
          checkIncludeMultiMarket(
            multiMarketData,
            market.marketId,
            eventData?.eventId
          ) ? (
            <>
              <div className="w-full bg-[#000000] h-9 rounded-tl-lg rounded-tr-lg relative ">
                <div className=" flex">
                  <div className=" flex bg-[#804091] h-9 rounded-tl-lg py-2 pr-4 pl-2 text-[14px] text-white font-medium">
                    {!checkIncludeMultiMarket(
                      multiMarketData,
                      market.marketId,
                      eventData?.eventId
                    ) ? (
                      <span title="Add To Multi Markets ">
                        <img
                          src={MultiPin}
                          alt="multipin"
                          className="multi-add-icon cursor-pointer "
                          onClick={(e) => {
                            e.stopPropagation();
                            multiMarketData?.length < 10 &&
                              handleMultiMarket(
                                eventData?.competitionId,
                                eventData?.eventId,
                                market.marketId,
                                eventData?.providerName,
                                eventData?.sportId,
                                true
                              );
                          }}
                        />
                      </span>
                    ) : (
                      <span title="Remove From Multi Markets">
                        <img
                          src={multiPinsMap[localStorage.getItem("userTheme")]}
                          alt="multipin"
                          className="multi-remove-icon cursor-pointer "
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMultiMarket(
                              eventData?.competitionId,
                              eventData?.eventId,
                              market.marketId,
                              eventData?.providerName,
                              eventData?.sportId,
                              false
                            );
                          }}
                        />
                      </span>
                    )}{" "}
                    <span className="ml-2">{market?.marketName}</span>
                    <span className="event-name">
                      {isMultiMarket && !isMobile ? eventData?.eventName : null}
                    </span>
                    <span className="text-[11px] absolute right-3 font-normal text-[#fff]">
                      MIN:
                      {market?.marketLimits
                        ? ThousandFormatter(
                            market?.marketLimits?.minStake / cFactor
                          )
                        : 100}{" "}
                      MAX:
                      {market?.marketLimits
                        ? ThousandFormatter(
                            market?.marketLimits?.maxStake / cFactor
                          )
                        : 5000}
                    </span>
                  </div>
                  <div className=" skew-x-[-25deg] bg-[#804091] h-9 -translate-x-[9px] w-5"></div>
                  <div className=" skew-x-[-25deg] bg-[#804091] h-9 w-1 -translate-x-1"></div>
                  <div className=" skew-x-[-25deg] bg-[#804091] h-9 w-1"></div>
                </div>
              </div>
              <table className="w-full text-[14px] font-medium text-stone-900">
                <thead>
                  <tr className=" py-2 text-[14px] font-medium whitespace-nowrap h-[32px] bg-neutral-200 text-neutral-500 ">
                    <th className=" w-[86%] text-left pl-3 ">Markets</th>
                    <th className=" w-[7%] ">Back</th>
                    <th className=" w-[7%] ">Lay</th>
                  </tr>
                </thead>
                <tbody>
                  {market?.runners?.map((runner) => (
                    <>
                     {bets?.length > 0 &&
                      bets?.[0]?.marketName === market.marketName &&
                      bets?.[0]?.marketId === market.marketId &&
                      bets?.[0]?.outcomeId === runner?.runnerId &&
                      isMobile ? (
                        
                        <tr className="inline-betslip">
                          <td colSpan={12}>
                          <div ref={betslipRef} className="betslip-container">
                            {" "}
                            <Betslip eventData={eventData} />{" "}
                            </div>
                          </td>
                        </tr>
                      ) : null}
                      <tr className="relative justify-between pr-6 mt-2 w-full pl-2 h-[32px] border-b border-[#e0e0e0] ">
                        <td className="my-auto text-[14px] font-medium text-stone-900 px-2 flex-[6] ">
                          <div>
                            {runner?.runnerName}
                            {getOpenBetsPLInArray(
                              runner,
                              market.marketId,
                              market.marketName
                            ).map((ret) =>
                              ret !== null ? (
                                <span
                                  className={
                                    ret >= 0
                                      ? " text-[#1b891b] "
                                      : " text-[#ff3a3a] "
                                  }
                                >
                                  {ret > 0
                                    ? "+" + Number(ret).toFixed(2)
                                    : Number(ret).toFixed(2)}
                                </span>
                              ) : null
                            )}
                          </div>
                          <div>
                            {bets.length > 0 && bets[0].amount > 0 ? (
                              <div>
                                {getTotalPL(
                                  runner,
                                  market.marketId,
                                  market.marketName
                                ).map((ret) =>
                                  ret !== null ? (
                                    <span
                                      className={
                                        ret >= 0
                                          ? " text-[#1b891b] "
                                          : " text-[#ff3a3a] "
                                      }
                                    >
                                      {ret > 0
                                        ? "+" + Number(ret).toFixed(2)
                                        : Number(ret).toFixed(2)}
                                    </span>
                                  ) : null
                                )}
                              </div>
                            ) : null}
                          </div>
                        </td>
                        <td className="">
                          <div className="flex rounded overflow-hidden border-r border-white">
                            <MarketBtn
                              marketType="bm"
                              mainValue={
                                disabledStatus.includes(
                                  market.status?.toLowerCase()
                                ) ||
                                disabledStatus.includes(
                                  runner.status?.toLowerCase()
                                ) ||
                                market.suspend === true
                                  ? 0
                                  : runner.backPrice
                              }
                              subValue={Number(runner.backSize)}
                              oddType="back-odd"
                              valueType="bookmakerOdds"
                              showSubValueinKformat={true}
                              disable={
                                disabledStatus.includes(
                                  market.status?.toLowerCase()
                                ) ||
                                disabledStatus.includes(
                                  runner.status?.toLowerCase()
                                ) ||
                                market.suspend === true ||
                                moment(eventData?.openDate).diff(
                                  moment(),
                                  "minutes"
                                ) >
                                  60 * 24 * 2
                              }
                              onClick={() => {
                                if (
                                  disabledStatus.includes(
                                    market.status?.toLowerCase()
                                  ) ||
                                  disabledStatus.includes(
                                    runner.status?.toLowerCase()
                                  ) ||
                                  market.suspend === true ||
                                  moment(eventData?.openDate).diff(
                                    moment(),
                                    "minutes"
                                  ) >
                                    60 * 24 * 2
                                ) {
                                  return;
                                }
                                const betData: PlaceBetRequest = {
                                  sportId: eventData?.sportId,
                                  seriesId: eventData?.competitionId,
                                  seriesName: eventData?.competitionName,
                                  eventId: eventData?.eventId,
                                  eventName: eventData?.eventName,
                                  eventDate: eventData?.openDate,
                                  marketId: market.marketId,
                                  marketName: market.marketName,
                                  marketType: "BM",
                                  outcomeId: runner.runnerId,
                                  outcomeDesc: runner.runnerName,
                                  betType: "BACK",
                                  amount: 0,
                                  oddValue: Number(runner.backPrice),
                                  sessionPrice: -1,
                                  oddLimt:
                                    market?.marketLimits?.maxOdd.toString(),
                                  minStake: market?.marketLimits?.minStake,
                                  maxStake: market?.marketLimits?.maxStake,
                                  mcategory: "ALL",
                                };
                                if (
                                  market.marketName.toUpperCase() === "TOSS" &&
                                  Number(runner.backPrice) > 0
                                ) {
                                  betData["displayOddValue"] = TOSS_ODD_VALUE;
                                }
                                addExchangeBet(betData);
                              }}
                            />
                          </div>
                        </td>
                        <td className="">
                          <div className="flex">
                            <MarketBtn
                              marketType="bm"
                              mainValue={
                                disabledStatus.includes(
                                  market.status?.toLowerCase()
                                ) ||
                                disabledStatus.includes(
                                  runner.status?.toLowerCase()
                                ) ||
                                market.suspend === true
                                  ? 0
                                  : runner.layPrice
                              }
                              subValue={Number(runner.laySize)}
                              oddType="lay-odd"
                              valueType="bookmakerOdds"
                              showSubValueinKformat={true}
                              disable={
                                disabledStatus.includes(
                                  market.status?.toLowerCase()
                                ) ||
                                disabledStatus.includes(
                                  runner.status?.toLowerCase()
                                ) ||
                                market.suspend === true ||
                                moment(eventData?.openDate).diff(
                                  moment(),
                                  "minutes"
                                ) >
                                  60 * 24 * 2
                              }
                              onClick={() => {
                                if (
                                  disabledStatus.includes(
                                    market.status?.toLowerCase()
                                  ) ||
                                  disabledStatus.includes(
                                    runner.status?.toLowerCase()
                                  ) ||
                                  market.suspend === true ||
                                  moment(eventData?.openDate).diff(
                                    moment(),
                                    "minutes"
                                  ) >
                                    60 * 24 * 2
                                ) {
                                  return;
                                }
                                const betData: PlaceBetRequest = {
                                  sportId: eventData?.sportId,
                                  seriesId: eventData?.competitionId,
                                  seriesName: eventData?.competitionName,
                                  eventId: eventData?.eventId,
                                  eventName: eventData?.eventName,
                                  eventDate: eventData?.openDate,
                                  marketId: market.marketId,
                                  marketName: market.marketName,
                                  marketType: "BM",
                                  outcomeId: runner.runnerId,
                                  outcomeDesc: runner.runnerName,
                                  betType: "LAY",
                                  amount: 0,
                                  oddValue: Number(runner.layPrice),
                                  sessionPrice: -1,
                                  oddLimt:
                                    market?.marketLimits?.maxOdd.toString(),
                                  minStake: market?.marketLimits?.minStake,
                                  maxStake: market?.marketLimits?.maxStake,
                                  mcategory: "ALL",
                                };
                                if (
                                  market.marketName.toUpperCase() === "TOSS" &&
                                  Number(runner.layPrice) > 0
                                ) {
                                  betData["displayOddValue"] = TOSS_ODD_VALUE;
                                }
                                addExchangeBet(betData);
                              }}
                            />
                          </div>
                        </td>
                        {disabledStatus.includes(
                          runner.status?.toLowerCase()
                        ) || market.suspend === true ? (
                          <div
                            className="absolute top-[5px] font-semibold text-[#c22222] text-center right-6 max-md:right-5 "
                            key={"row-" + index + "cell-5"}
                          >
                            {runner.status
                              ?.toLowerCase()
                              .includes("suspended") || market.suspend === true
                              ? "SUSPENDED"
                              : runner.status?.toLowerCase().replace("_", " ")}
                          </div>
                        ) : null}
                      </tr>

                     
                    </>
                  ))}
                </tbody>
              </table>
            </>
          ) : null}
        </>
      ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const eventType = state.catalog.selectedEventType;
  const competition = state.catalog.selectedCompetition;
  const event = state.catalog.selectedEvent;

  return {
    // eventData: getAllMarketsByEvent(
    //   state.exchangeSports.events,
    //   eventType.id,
    //   competition.id,
    //   event.id
    // ),
    // bmData: getBookmakerMarketsByEvent(
    //   state.exchangeSports.secondaryMarketsMap,
    //   event.id
    // ),
    bets: state.betSlip.bets,
    openBets: state.betSlip.openBets,
    commissionEnabled: state.common.commissionEnabled,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    addExchangeBet: (data: PlaceBetRequest) => dispatch(addExchangeBet(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookmaker);
