/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { EventDTO } from "../../../models/common/EventDTO";
import { useParams } from "react-router-dom";
import { SelectedObj } from "../../../models/SportsState";
import { connect } from "react-redux";
import { fetchEventsByCompetition } from "../../../store/catalog/catalogThunk";
import { setCompetition, setExchEvent } from "../../../store/catalog";
import { fetchEventsBySport } from "../../../api/catalog";
import { getExchangeEvents } from "../../../store/catalog/catalogSelectors";
import { RootState } from "../../../store";
import ExchEventTable from "./ExchEventTable";
import ExchRaceTable from "./ExchRaceTable";

interface TabData {
  name: string;
  data: Array<{
    date: string;
    time: string;
    team: string;
    odds: string[];
  }>;
}

interface ExchRaceTableProps {
    events: EventDTO[];
    selectedEventType: SelectedObj;
    selectedCompetition: SelectedObj;
    fetchEventsByCompetition: (
        sportId: string,
        competitionId: string,
        events: EventDTO[]
    ) => void;
    setExchEvent: (event: SelectedObj) => void;
    setCompetition: (competition: SelectedObj) => void;
    fetchEventsBySport: (sportId: string, events: EventDTO[]) => void;
    loggedIn: boolean;
    topicUrls: any;
    betFairWSConnected: boolean;
}

const ExchRacePage: React.FC<ExchRaceTableProps> = (props) => {
  const [tabs, setTabs] = useState<TabData[]>([
    {
      name: "Australia",
      data: [
        {
          date: "23 Apr",
          time: "8:30 AM",
          team: "Sydney Swans",
          odds: ["2.10", "1.95", "3.20", "2.50", "1.80", "4.00"],
        },
        {
          date: "24 Apr",
          time: "9:00 AM",
          team: "Melbourne Demons",
          odds: ["1.85", "2.20", "2.80", "3.00", "2.10", "3.50"],
        },
        {
          date: "23 Apr",
          time: "8:30 AM",
          team: "Sydney Swans",
          odds: ["2.10", "1.95", "3.20", "2.50", "1.80", "4.00"],
        },
        {
          date: "24 Apr",
          time: "9:00 AM",
          team: "Melbourne Demons",
          odds: ["1.85", "2.20", "2.80", "3.00", "2.10", "3.50"],
        },
        {
          date: "23 Apr",
          time: "8:30 AM",
          team: "Sydney Swans",
          odds: ["2.10", "1.95", "3.20", "2.50", "1.80", "4.00"],
        },
        {
          date: "24 Apr",
          time: "9:00 AM",
          team: "Melbourne Demons",
          odds: ["1.85", "2.20", "2.80", "3.00", "2.10", "3.50"],
        },
        
      ],
    },
    {
      name: "New Zealand",
      data: [
        {
          date: "23 Apr",
          time: "10:00 AM",
          team: "Auckland Blues",
          odds: ["2.30", "1.90", "3.50", "2.70", "1.75", "4.20"],
        },
        {
          date: "24 Apr",
          time: "11:30 AM",
          team: "Wellington Phoenix",
          odds: ["2.00", "2.15", "3.00", "2.90", "1.95", "3.80"],
        },
      ],
    },
    {
      name: "United Kingdom",
      data: [
        {
          date: "23 Apr",
          time: "3:00 PM",
          team: "Manchester United",
          odds: ["1.95", "2.10", "3.30", "2.60", "1.85", "4.10"],
        },
        {
          date: "24 Apr",
          time: "4:30 PM",
          team: "Liverpool FC",
          odds: ["1.80", "2.25", "2.90", "3.10", "2.05", "3.70"],
        },
      ],
    },
  ]);

  const [activeTab, setActiveTab] = useState<string>(tabs[0].name);

  const {
    events,
    selectedEventType,
    selectedCompetition,
    fetchEventsByCompetition,
    setExchEvent,
    setCompetition,
    fetchEventsBySport,
    loggedIn,
    topicUrls,
    betFairWSConnected,
} = props;

const pathParams = useParams<any>();
const [wsChannels, setWsChannels] = useState<string[]>([]);
const [eventData, setEventData] = useState<EventDTO[]>([]);
const [compitationsList, setCompitationsList] = useState<{}>({});
const [value, setValue] = useState('');
const [matchOddsBaseUrl, setMatchOddsBaseUrl] = useState<string>('');
const [matchOddsTopic, setMatchOddsTopic] = useState<string>('');

const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
};

// useEffect(() => {
//     if (!pathParams['competition']) {
//         setCompetition({ id: '', name: '', slug: '' });
//     }
// }, [pathParams]);

// const updateMatchOddsTopic = (currentTopic: string, currentBaseUrl: string) => {
//     if (matchOddsTopic !== currentTopic || matchOddsBaseUrl !== currentBaseUrl) {
//         disconnectToWS();
//         setMatchOddsTopic(currentTopic);
//         setMatchOddsBaseUrl(currentBaseUrl);
//     }
// }

// const updateEvents = () => {
//     if (!pathParams['competition']) {
//         if (
//             selectedEventType.id &&
//             events &&
//             selectedEventType.id === events[0]?.sportId
//         )
//             fetchEventsBySport(selectedEventType.id, events);
//     } else {
//         if (
//             selectedEventType.id &&
//             events &&
//             selectedEventType.id === events[0]?.sportId
//         ) {
//             fetchEventsByCompetition(
//                 selectedEventType.id,
//                 selectedCompetition.id,
//                 events
//             );
//         }
//     }
// };

// useEffect(() => {
//     if (!pathParams['competition']) {
//         fetchEventsBySport(selectedEventType.id, events);
//     }
// }, [selectedEventType]);

// useEffect(() => {
//     if (pathParams['competition'] && !events) {
//         fetchEventsByCompetition(
//             selectedEventType.id,
//             selectedCompetition.id,
//             events
//         );
//     }
// }, [selectedCompetition]);

// useEffect(() => {
//     updateEvents();
// }, [loggedIn]);

// useEffect(() => {
//     let refreshInterval = setInterval(() => {
//         updateEvents();
//     }, 60000);
//     return () => {
//         clearInterval(refreshInterval);
//     };
// }, [selectedEventType]);

// // Unsubscribe Web socket messages
// useEffect(() => {
//     unsubscribeAllWsforEvents();
//     setWsChannels([]);
// }, [selectedEventType]);

// useEffect(() => {
//     if (pathParams['competition']) {
//         unsubscribeAllWsforEvents();
//         setWsChannels([]);
//     }
// }, [selectedCompetition, pathParams]);

// useEffect(() => {
//     if (loggedIn && topicUrls?.matchOddsTopic) {
//         if (selectedEventType.id === '4' && events) {
//             let subs = [...wsChannels];
//             for (let event of events) {
//                 if (event.status === 'IN_PLAY' && !wsChannels.includes(event.eventId)) {
//                     subs.push(event.eventId);
//                     updateMatchOddsTopic(topicUrls?.matchOddsTopic, topicUrls?.matchOddsBaseUrl)
//                     subscribeWsForEventOdds(
//                         topicUrls?.matchOddsTopic,
//                         event.sportId,
//                         event.competitionId,
//                         event.eventId,
//                         event.matchOdds?.marketId,
//                         event.providerName
//                     );
//                 }
//             }
//             setWsChannels(subs);
//         }
//     }
// }, [betFairWSConnected, events, selectedEventType, loggedIn]);

useEffect(() => {
    if (events) {
        setEventData([...events]);
        setCompitations(events);
    }
}, [events]);

const getCompetitionSlug = (competitionName: string) => {
    return competitionName
        .toLocaleLowerCase()
        .replace(/[^a-z0-9]/g, ' ')
        .replace(/ +/g, ' ')
        .trim()
        .split(' ')
        .join('-');
};

const handleEventChange = (event: EventDTO) => {
    setCompetition({
        id: event.competitionId,
        name: event.competitionName,
        slug: getCompetitionSlug(event.competitionName),
    });
    setExchEvent({
        id: event.eventId,
        name: event.eventName,
        slug: event.eventSlug,
    });
};

const handleEventData = (data) => {
    return data.sort((a, b) => {
        if (a.inPlay && !b.inPlay) return -1;
        if (!a.inPlay && b.inPlay) return 1;
    });
};

const isLive = (date: Date) => {
    let duration = moment.duration(moment(date).diff(moment()));
    return duration?.asMinutes() < 10 ? true : false;
};

const setCompitations = (events: EventDTO[]) => {
    let compitations: { [key: number]: EventDTO[] } = {};
    const unique = Array.from(new Set(events.map((item) => item?.competitionId?.split('_')[0])));

    for (let key of unique) {
        compitations[key] =
            events?.filter((item) => item?.competitionId?.startsWith(key + '_')) ??
            [];
    }
    const sorted = Object.keys(compitations)
        .sort()
        .reduce((accumulator, key) => {
            accumulator[key] = compitations[key];
            return accumulator;
        }, {});

    setCompitationsList(sorted);
    value === '' && setValue(unique[0]);
};

  return (
    <section className="flex flex-col max-md:ml-0 max-md:w-full">
      <div className="flex flex-col pt-px pb-[10px] w-full bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6 max-md:max-w-full"> 
        <div className="flex flex-col justify-center items-start text-[14px] font-medium leading-4 text-center bg-white border border-solid border-neutral-200">
          <div className="flex gap-5 w-full">
            {Object?.keys(compitationsList).map((key, idx) => (
              <button
                key={key}
                onClick={(e) => handleChange(e, key)}
                className={`w-[100px] h-[32px] ${
                  value === key
                    ? "text-white bg-neutral-500"
                    : "text-neutral-500"
                }`}
              >
                {key}
              </button>
            ))}
          </div>
        </div>
        <ExchRaceTable
          compitationsList={compitationsList}
          value={value}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state: RootState) => {
    const eventType = state.catalog.selectedEventType;
    const competition = state.catalog.selectedCompetition;
    return {
        events: getExchangeEvents(
            state.catalog.events,
            eventType.id,
            competition.id
        ),
        selectedEventType: eventType,
        selectedCompetition: competition,
        loggedIn: state.auth.loggedIn,
        //   fetchingEvents: state.catalog.fetchingEvents,
        topicUrls: state?.catalog?.topicUrls,
        betFairWSConnected: state.catalog.betFairWSConnected,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        fetchEventsByCompetition: (
            sportId: string,
            competitionId: string,
            events: EventDTO[]
        ) => dispatch(fetchEventsByCompetition(sportId, competitionId, events)),
        setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
        setCompetition: (competition: SelectedObj) =>
            dispatch(setCompetition(competition)),
        fetchEventsBySport: (sportId: string) =>
            dispatch(fetchEventsBySport(sportId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchRacePage);