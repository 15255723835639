import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store";
import { getGameUrl } from "../../../store/catalog/catalogThunk";
import { FiLoader } from "react-icons/fi";
import { isMobile } from "react-device-detect";
import MobHeader from "../../Mobile/HeaderSection/MobHeader";
import Header from "../HeaderSection/Header";

type StoreProps = {
  getGameUrl: Function;
  gameSrc: string;
  loading: boolean;
  loggedIn: boolean;
};

type RouteParams = {
  gamePath: string;
};

const CasinoIframeNew: React.FC<StoreProps> = (props) => {
  const { gameSrc, getGameUrl, loading, loggedIn } = props;
  const { gamePath } = useParams<RouteParams>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const gameId = atob(gamePath.split("-")[gamePath.split("-").length - 4]);
    const gameCode = atob(gamePath.split("-")[gamePath.split("-").length - 3]);
    const provider = atob(gamePath.split("-")[gamePath.split("-").length - 2]);
    const subProvider = atob(
      gamePath.split("-")[gamePath.split("-").length - 1]
    );
    if (loggedIn) {
      getGameUrl({ gameId, gameCode, provider, subProvider });
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      {
        isMobile ?
            <MobHeader setShowSideBar={() => { }} />
          : (
            <Header selectedTab={''} setSelectedTab={() => { }} />
          )
      }
      {loading ? (
        <FiLoader />
      ) : (
        <iframe
          src={gameSrc}
          className=" h-[90vh] w-screen"
          title="DC game"
          //   allowFullScreen
          sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
        ></iframe>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gameSrc: state.catalog.gameSrc,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getGameUrl: (event: any) => dispatch(getGameUrl(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CasinoIframeNew);
