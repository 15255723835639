/**
 * This code was generated by Builder.io.
 */
import React from "react";
import ReactDatePicker from "../../Mobile/ReactDatePicker";

interface DatePickerProps {
  onDateChange: Function;
  startDate: Date;
  endDate: Date;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  startDate,
  endDate,
}) => {
  const handleFromDateChange = (date) => {
    onDateChange(date, endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(startDate, date);
  };

  return (
    <div className="flex gap-3 self-end pl-20 mt-3.5 text-[14px] whitespace-nowrap max-md:flex-wrap max-md:pl-5 max-md:mr-2.5">
      <div className="flex gap-2.5">
        <div className="grow my-auto capitalize text-neutral-500">From</div>
        <div className="flex mt-1 justify-center align-middle">
          <ReactDatePicker date={startDate} setDate={handleFromDateChange} />
        </div>
      </div>
      <div className="flex gap-2.5 mr-2 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">To</div>
        <div className="flex mt-1 justify-center align-middle">
          <ReactDatePicker date={endDate} setDate={handleToDateChange} />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
