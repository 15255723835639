import axios from 'axios';

const BASE_URL = 'https://api.example.com/account';

export const getAccountDetails = async (userId: string) => {
    try {
        const response = await axios.get(`${BASE_URL}/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
