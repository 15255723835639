/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import SidePanel from "./SidePanel";
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCasinoGame,
  getDcGames,
  getEvolutionGame,
} from "../../../store/catalog/catalogThunk";
import { AppDispatch, RootState } from "../../../store";
import ProtectedRoute from "../../../routes/ProtectedRoutes";
import { protectedRoutesConfig } from "../../../routes/protectedRoutesConfig";
import { fetchBalance } from "../../../store/auth/authThunk";
import { ALLOW_CASINO } from "../../../constants/CasinoPermission";
import { isMobile } from "react-device-detect";
import GameSection from "../../Mobile/MainPage/GameSection";
import { routesConfig, sportRoutes } from "../../../routes/routesConfig";
import SportsView from "../../../views/SportsView/SportsView";
import Footer from "../Footer/Footer";

interface UserRouterProps {
  selectedTab: string;
  loggedIn: boolean;
  status: number;
  role: string;
}

const UserRouter: React.FC<UserRouterProps> = ({
  selectedTab,
  loggedIn,
  status,
  role,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { rgGames, egGames } = useSelector((state: RootState) => state.catalog);

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider: string
  ) => {
    if (loggedIn) {
      navigate(
        `/dc/gamev1.1/${gameName.toLowerCase().replace(/\s+/g, "-")}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    } else {
      setDialogShow(true);
    }
  };

  useEffect(() => {
    dispatch(getDcGames());
  }, []);

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchBalance());
    }
  }, [loggedIn]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="w-full overflow-x-scroll scrollbar-hide">
      <main className=" flex w-full ">
        {status !== -1 && status === 4 ? (
          <Navigate to="/reset-password" />
        ) : status !== -1 && status === 2 ? (
          <Navigate to="/accept-terms" />
        ) : role && role !== "User" ? (
          <Navigate to="/access-redirect" />
        ) : !ALLOW_CASINO &&
          (window.location.pathname.includes("/casino") ||
            window.location.pathname.includes("/dc/gamev1.1/")) ? (
          <Navigate to="/access-redirect" />
        ) : (
          <div className="mx-2 py-4 w-[100%] ">
            <div className=" h-[140vh]">
              <Routes>
                {routesConfig.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
                <Route element={<SportsView loggedIn={loggedIn} />}>
                  {sportRoutes.map((route, index) => (
                    <Route
                      key={index + "protected"}
                      path={route.path}
                      element={<route.component />}
                    />
                  ))}
                </Route>
                <Route element={<ProtectedRoute isAuthenticated={loggedIn} />}>
                  {protectedRoutesConfig.map((route, index) => (
                    <Route
                      key={index + "protected"}
                      path={route.path}
                      element={<route.component />}
                    />
                  ))}
                </Route>
              </Routes>
              {(pathname.includes("/exchange_sports/inplay") ||
                pathname.includes("/home")) && <Footer />}
            </div>
          </div>
        )}
        {isMobile ? (
          <GameSection
            title="Royal Gaming"
            path="/indian-casino"
            setDialogShow={setDialogShow}
            dialogShow={dialogShow}
            handleGameClick={getGameUrl}
            games={rgGames}
          />
        ) : (
          <>
            {pathname.includes("/game-rules") ||
            pathname.includes("/favourites") ||
            pathname.includes("/my-bets") ||
            pathname.includes("/my-wallet") ||
            pathname.includes("/profit-loss") ||
            pathname.includes("/turnover-history") ||
            pathname.includes("/bonus-statement") ||
            pathname.includes("/my-transaction") ||
            pathname.includes("/stake-setting") ||
            pathname.includes("/my-profile") ||
            pathname.includes("/account-statement") ||
            pathname.split("/")?.length >= 6 ? null : (
              <SidePanel
                setDialogShow={setDialogShow}
                dialogShow={dialogShow}
                handleGameClick={getGameUrl}
                rgGames={rgGames}
                egGames={egGames}
              />
            )}
          </>
        )}
      </main>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  if (!state.auth.loggedIn) {
    return {
      role: null,
      status: -1,
      allowedConfig: state.common.allowedConfig,
    };
  }
  let claim = state.auth?.jwtToken?.split(".")?.[1] || "";
  const role = JSON.parse(window.atob(claim)).role;
  const status = JSON.parse(window.atob(claim)).sts;
  return {
    loggedIn: state.auth.loggedIn,
    status,
    role,
  };
};

export default connect(mapStateToProps, null)(UserRouter);
