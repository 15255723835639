import React from "react";
// import "react-toastify/dist/ReactToastify.css";
import login_bg from "../../../assets/login_bg.png";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { FaArrowLeftLong } from "react-icons/fa6";
import SideScreen from "./ForgotPassword/ForgotPasswordDesktop/SideImageDesktop"
import { useNavigate } from "react-router-dom";

interface SignUpFormProps {
  otpSent: boolean;
  handleSubmit: Function;
  currentStep: number;
  phoneNumber: string;
  otp: number;
  setOtp: Function;
  agreedToTerms: boolean;
  handlePhoneChange: Function;
  handleTermsChange: Function;
  username: string;
  password: string;
  handleUsernameChange: Function;
  handlePasswordChange: Function;
  redirectToSignIn: Function;
  loader: boolean;
  showPasswordClickHandler: Function;
  showPassword: boolean;
  phoneNumberExists: boolean;
}

const SignUpForm: React.FC<SignUpFormProps> = (props) => {
  const {
    loader,
    phoneNumberExists,
    otpSent,
    handlePasswordChange,
    handleUsernameChange,
    password,
    username,
    handleSubmit,
    currentStep,
    phoneNumber,
    otp,
    setOtp,
    agreedToTerms,
    handlePhoneChange,
    handleTermsChange,
    redirectToSignIn,
    showPasswordClickHandler,
    showPassword,
  } = props;
  const navigate = useNavigate();


  return (
    <main className="h-screen flex items-center">
            <SideScreen tagline="Bet smarter,Bet with confidence, Win bigger: your ultimate bet experience" />
      <div className=" -translate-x-40 border bg-white rounded-2xl w-[30%] min-w-[365px]">
        <section className="flex z-10 flex-col justify-center self-center px-4 py-6 mt-0 w-full text-sm font-medium bg-white rounded-2xl border-4 border-solid border-neutral-200 max-w-[430px] text-neutral-500">
          <button
            onClick={() => redirectToSignIn()}
            className="flex items-center gap-1.5 text-sm font-medium whitespace-nowrap text-stone-900 w-[61px]"
          >
            <FaArrowLeftLong />
            <span className="my-auto">Back</span>
          </button>
          <div className="flex flex-col flex-1 justify-center mt-2.5 w-full">
            <header className="flex flex-col justify-center self-center max-w-full text-center w-[254px]">
              <h1 className="text-3xl font-semibold text-stone-900">Sign up</h1>
              <p className="text-base text-neutral-500">
                Create your account by following these simple steps.
              </p>
            </header>
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="flex flex-col py-px mt-5 w-full text-sm font-medium rounded-none"
            >
              <div className="flex gap-5 w-full text-[14px] text-center h-[24px] rounded-md border border-solid border-[#3FA48F]">
                <div
                  className={`gap-2.5 self-stretch px-16 py-1  rounded-md min-h-[24px] max-md:px-5 ${
                    currentStep === 1 ? "bg-[#3FA48F] text-white" : "text-stone-900"
                  }`}
                >
                  Step 01
                </div>
                <div
                  className={`flex-auto self-stretch py-1  rounded-md min-h-[24px] max-md:px-5 ${
                    currentStep === 2 ? "bg-[#3FA48F] text-white" : "text-stone-900"
                  }`}
                >
                  Step 02
                </div>
              </div>
              {currentStep === 1 ? (
                <StepOne
                  otpSent={otpSent}
                  otp={otp}
                  phoneNumber={phoneNumber}
                  agreedToTerms={agreedToTerms}
                  setOtp={setOtp}
                  onPhoneChange={(e) => handlePhoneChange(e)}
                  onTermsChange={(e) => handleTermsChange(e)}
                />
              ) : (
                <StepTwo
                  showPasswordClickHandler={showPasswordClickHandler}
                  showPassword={showPassword}
                  username={username}
                  password={password}
                  onUsernameChange={(e) => handleUsernameChange(e)}
                  onPasswordChange={(e) => handlePasswordChange(e)}
                />
              )}
              <button
                type="submit"
                disabled={
                  (currentStep === 1 && (!phoneNumber || !agreedToTerms)) ||
                  (currentStep === 2 && (!username || !password)) ||
                  loader || phoneNumberExists
                }
                className={`gap-2.5 self-stretch px-16 py-3 mt-5 text-base font-semibold text-center text-white rounded-lg min-h-[44px] shadow-[0px_1px_2px_rgba(0,0,0,0.05)] max-md:px-5 ${
                  (currentStep === 1 && (!phoneNumber || !agreedToTerms)) ||
                  (currentStep === 2 && (!username || !password))
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-fuchsia-800 hover:bg-fuchsia-700"
                }`}
                // onClick={() => {
                //   if (currentStep !== 1) {
                //     navigate('/login')
                //   }
                // }}
              >
                {currentStep === 1
                  ? otpSent
                    ? "Validate OTP"
                    : "Send OTP"
                  : "Sign Up"}
              </button>
              <div className="self-center mt-6 text-sm text-center text-stone-900">
                Already have account?{" "}
                <a
                  href="#"
                  className="font-semibold text-fuchsia-800 underline"
                  onClick={() => redirectToSignIn()}
                >
                  Sign In
                </a>
              </div>
            </form>
          </div>
        </section>
      </div>
    </main>
  );
};

export default SignUpForm;
