import { AxiosResponse } from "axios";
import SVLS_API from "./api-services/svls-api";

export const loginUser = async (username: string, password: string) => {
    try {
        let response: AxiosResponse;
        response = await SVLS_API.post(
            '/account/v2/login',
            {
                username,
                password
            }
        );
        getSapToken(response.data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getSapToken = async (token: string) => {
    try {
        const response = await SVLS_API.get(
            `/account/v2/accounts/${JSON.parse(window.atob(token.split('.')[1])).aid
            }/sap-token`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        if (response.status == 200) {
            sessionStorage.setItem('sap_token', response.data);
        }
    } catch (err) {
        console.log(err);
    }
};
