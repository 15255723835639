/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/logo (2).png";
import Footer1 from "../../../assets/Footer/gpay.svg";
import Footer2 from "../../../assets/Footer/masterCard.svg";
import Footer3 from "../../../assets/Footer/pay.svg";
import Footer4 from "../../../assets/Footer/visa.svg";
import Footer5 from "../../../assets/Footer/gpay.svg";
import Footer6 from "../../../assets/Footer/masterCard.svg";

const Footer: React.FC = () => {
  return (
    <footer className="bg-white border-t w-[125%] border-neutral-200">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between">
          <div className="">
            <img
              src={logo}
              alt="Brand"
              className="w-[110px] bg-fuchsia-800 p-[10px] rounded-md"
            />
            <nav className="flex mt-3 gap-6 text-sm">
              <NavLink to="/home" className="text-stone-900 font-semibold">
                Home
              </NavLink>
              <NavLink to="/exchange_sports/inplay" className="text-stone-900">
                Exchange
              </NavLink>
              <NavLink to="/sportsbook" className="text-stone-900">
                Sportsbook
              </NavLink>
              <NavLink to="/live-casino" className="text-stone-900">
                Live Casino
              </NavLink>
            </nav>
          </div>
          <div className="mr-36">
            <span className="font-medium m-2">Payment Methods</span>
            <div className="flex">
              <img src={Footer1} alt="Payment 1" className="h-12 m-2" />
              <img src={Footer2} alt="Payment 2" className="h-12 m-2" />
            </div>
            <div className="flex">
              <img src={Footer3} alt="Payment 3" className="h-12 m-2" />
              <img src={Footer4} alt="Payment 4" className="h-12 m-2" />
            </div>
          </div>
        </div>
        <div className="text-[14px] text-center bg-neutral-200 p-4 rounded">
          <span className="font-semibold">Disclaimer:</span> Please note that
          Gambling involves a financial risk and could be addictive over time if
          not practised within limits. Only 18+ people should use the services
          and should use it responsibly. Players should be aware of any
          financial risk and govern themselves accordingly.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
