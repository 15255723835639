/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { EventDTO } from "../../../models/common/EventDTO";
import moment from "moment";
import EventDateDisplay from "../../../common/EventDateDisplay/EventDateDisplay";
import { useNavigate } from "react-router-dom";
import purple from "../../../assets/purple_bg.png";
import { connect } from "react-redux";
import { SelectedObj } from "../../../models/SportsState";
import { fetchCompetitions } from "../../../api/catalog";
import { RootState } from "../../../store";
import {
  setCompetitionThunk,
  setEventTypeThunk,
  setExchEventThunk,
} from "../../../store/catalog/catalogThunk";
import { setExchEvent } from "../../../store/catalog";
import MarketBtn from "../MarketComponents/MarketBtn/MarketBtn";
import LiveMatch from "../../../assets/LiveMatchIcon.svg";

interface Match {
  id: number;
  date: string;
  time: string;
  teams: string;
  isLive: boolean;
}
type InplayEventsObj = {
  sportId: string;
  sportName: string;
  sportSlug: string;
  events: EventDTO[];
};
interface MatchListProps {
  selectedDate?: string | null;
  showLive?: boolean;
  iEvent: InplayEventsObj;
  setEventType: Function;
  setCompetition: Function;
  setExchEvent: Function;
  loggedIn: boolean;
}

const dummyMatches: Match[] = [
  {
    id: 1,
    date: "20 Apr",
    time: "8:30 AM",
    teams: "New Zealand T10 vs South Africa T10",
    isLive: true,
  },
  {
    id: 2,
    date: "21 Apr",
    time: "10:00 AM",
    teams: "India vs Australia",
    isLive: false,
  },
  {
    id: 3,
    date: "22 Apr",
    time: "2:00 PM",
    teams: "England vs West Indies",
    isLive: false,
  },
  {
    id: 4,
    date: "23 Apr",
    time: "6:00 PM",
    teams: "Pakistan vs Sri Lanka",
    isLive: true,
  },
  {
    id: 5,
    date: "24 Apr",
    time: "9:00 AM",
    teams: "Bangladesh vs Afghanistan",
    isLive: false,
  },
  {
    id: 6,
    date: "25 Apr",
    time: "11:30 AM",
    teams: "South Africa vs Zimbabwe",
    isLive: false,
  },
  {
    id: 7,
    date: "26 Apr",
    time: "3:00 PM",
    teams: "New Zealand vs Ireland",
    isLive: true,
  },
  {
    id: 8,
    date: "27 Apr",
    time: "7:30 PM",
    teams: "Australia vs West Indies",
    isLive: false,
  },
  {
    id: 9,
    date: "28 Apr",
    time: "10:00 AM",
    teams: "India vs England",
    isLive: false,
  },
  {
    id: 10,
    date: "29 Apr",
    time: "1:00 PM",
    teams: "Sri Lanka vs Bangladesh",
    isLive: true,
  },
];

export const EmptyOddsBlock = React.memo(() => {
  return (
    <React.Fragment>
      <div className="flex w-[110px] cursor-pointer rounded-[4px] overflow-hidden mx-auto">
        <MarketBtn mainValue={null} oddType="back-odd" />
        <MarketBtn mainValue={null} oddType="lay-odd" />
      </div>
    </React.Fragment>
  );
});

const MatchList: React.FC<MatchListProps> = ({
  selectedDate,
  showLive,
  iEvent,
  setEventType,
  setCompetition,
  setExchEvent,
  loggedIn,
}) => {
  const runnerTypes = ["home", "draw", "away"];

  const filteredMatches = dummyMatches.filter((match) => {
    if (showLive) return match.isLive;
    if (selectedDate) return match.date === selectedDate;
    return true;
  });
  const navigate = useNavigate();

  const handleEventChange = (iEvent: InplayEventsObj, event: EventDTO) => {
    const competitionSlug = event.competitionName
      ? event.competitionName
          .toLocaleLowerCase()
          .replace(/[^a-z0-9]/g, " ")
          .replace(/ +/g, " ")
          .trim()
          .split(" ")
          .join("-")
      : "league";
    setEventType({
      id: iEvent.sportId,
      name: iEvent.sportName,
      slug: iEvent.sportSlug,
    });
    setCompetition({
      id: event.competitionId,
      name: event.competitionName,
      slug: competitionSlug,
    });
    setExchEvent({
      id: event.eventId,
      name: event.eventName,
      slug: event.eventSlug,
    });
    if (event?.providerName?.toLowerCase() === "sportradar" && !loggedIn) {
      navigate("/login");
    } else if (event?.catId === "SR VIRTUAL") {
      navigate(
        `/exchange_sports/virtuals/${iEvent.sportSlug}/${competitionSlug}/${
          event.eventSlug
        }/${btoa(`${event.sportId}:${event.competitionId}:${event.eventId}`)}`
      );
    } else {
      navigate(
        `/exchange_sports/${iEvent.sportSlug}/${competitionSlug}/${
          event.eventSlug
        }/${btoa(`${event.sportId}:${event.competitionId}:${event.eventId}`)}`
        // {
        //   homeTeam: event?.homeTeam,
        //   awayTeam: event?.awayTeam,
        //   openDate: event?.openDate,
        // }
      );
    }
  };

  const getOdds = (eventData: EventDTO, teamType: string) => {
    const team =
      teamType === "home"
        ? eventData.homeTeam
        : teamType === "away"
        ? eventData.awayTeam
        : teamType;

    const teamLowerCase = team.toLowerCase();
    for (let runner of eventData.matchOdds.runners) {
      let runnerLowCase = runner.runnerName.toLowerCase();
      if (
        runnerLowCase === teamLowerCase ||
        runnerLowCase.includes(teamLowerCase)
      ) {
        return [
          {
            type: "back-odd",
            price: runner?.backPrices[0]?.price,
            size: runner.backPrices[0]?.size,
            outcomeId: runner?.runnerId,
            outcomeName: runner.runnerName,
          },
          {
            type: "lay-odd",
            price: runner?.layPrices[0]?.price,
            size: runner?.layPrices[0]?.size,
            outcomeId: runner.runnerId,
            outcomeName: runner.runnerName,
          },
        ];
      }
    }
    return null;
  };

  return (
    <table className=" rounded-lg border border-[#e0e0e0] bg-white w-full border-collapse overflow-hidden my-1">
      <tr className=" bg-stone-900 text-white text-[14px] font-semibold rounded-t-md">
        <th colSpan={3} className=" h-[32px]">
          <div className=" flex">
            <div className=" bg-[#804091] py-2 pr-4 pl-2 text-[14px] text-white font-medium">
              <span>{iEvent?.sportName} Matches</span>
            </div>
            <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[8px] w-4"></div>
            <div className=" skew-x-[-25deg] bg-[#804091] w-1 -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-[#804091] w-1"></div>
          </div>
        </th>
        <th className=" w-[12%] m-0">1</th>
        <th className=" w-[12%] m-0">X</th>
        <th className=" w-[12%] m-0">2</th>
      </tr>
      {iEvent?.events?.map((match) => (
        <tr
          className="cursor-pointer w-full border-b p-[4px] h-[32px]"
          onClick={() => handleEventChange(iEvent, match)}
        >
          <td className="w-[30px]">
            {match.status.toLowerCase() === "in_play" ? (
              <td className="">
                <div className=" bg-[#3C7701] text-white font-[600] Switzer text-[14px] h-[18px] w-[36px] text-center leading-[18px] ml-1 my-1 mr-[20px] rounded-[3px]">
                  LIVE
                </div>
              </td>
            ) : (
              <div className=" bg-red-600 text-white text-[10px] font-bold h-[24px] w-[64px] text-center leading-[24px] ml-1 my-1 mr-[23px] rounded-[3px]">
                UPCOMING
              </div>
            )}
          </td>
          {match?.homeTeam !== "" && match?.awayTeam !== "" ? (
           <td className="text-[14px] text-ellipsis max-w-[60%] capitalize">
           <div className="flex items-center font-[500]">
             <div className="truncate max-w-full">
               {match.homeTeam} vs {match.awayTeam}
             </div>
             <div className="ml-2 flex-shrink-0">
               <span className="inline-block w-2.5 h-2.5 rounded-full bg-green-500 animate-blink"></span>
             </div>
           </div>
           <div className="text-[12px] text-stone-900 text-center mt-1">
             <EventDateDisplay openDate={match.openDate} />
           </div>
         </td>
          ) : (
            <td className=" text-[14px] text-ellipsis max-w-56 capitalize ">
              <div className=" font-[500]">{match.eventName}</div>
              <div className="text-[12px] text-stone-900 text-center">
                <div className=" py-1">
                  <EventDateDisplay openDate={match.openDate} />
                </div>
              </div>
            </td>
          )}
          <td className="flex justify-end items-center py-2.5 text-right">
            <div className="mr-2 w-[24px] h-6 rounded bg-[#757575]">
              <img src={LiveMatch} />
            </div>
            {match?.enableMatchOdds ? (
              <button className="w-[30px] h-6 px-[3px] text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                MO
              </button>
            ) : null}
            {match?.enableBookmaker ? (
              <button className="w-[28px] h-6 px-[3px] text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                BM
              </button>
            ) : null}
            {match?.enableFancy ? (
              <button className="w-6 h-6 text-[14px] leading-[13.5px] bg-[#804091] rounded text-white font-semibold mr-2">
                F
              </button>
            ) : null}
            {match?.enablePremium ? (
              <button className="w-6 h-6 text-[14px] leading-[13.5px] bg-red-600 rounded text-white font-semibold mr-2">
                P
              </button>
            ) : null}
          </td>
          {runnerTypes?.map((runnerType) => (
            <td>
              <div className="flex w-[110px] cursor-pointer rounded-[3px] overflow-hidden mx-auto">
                {match?.matchOdds ? (
                  getOdds(match, runnerType) ? (
                    getOdds(match, runnerType).map((odd) => (
                      <MarketBtn
                        mainValue={odd.price}
                        subValue={odd.size}
                        oddType={
                          odd.type === "back-odd" ? "back-odd" : "lay-odd"
                        }
                        disable={match.matchOdds.status
                          .toLowerCase()
                          .includes("suspended")}
                        valueType="matchOdds"
                        showSubValueinKformat={true}
                        onClick={() => null}
                      />
                    ))
                  ) : (
                    <EmptyOddsBlock />
                  )
                ) : (
                  <EmptyOddsBlock />
                )}
              </div>
            </td>
          ))}
        </tr>
      ))}
    </table>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    // bets: state.exchBetslip.bets,
    // allowedConfig: state.common.allowedConfig,
    loggedIn: state.auth.loggedIn,
    loading: state.catalog.loading,
    topicUrls: state?.catalog?.topicUrls,
    // betFairWSConnected: state.catalog.betFairWSConnected,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setEventType: (eType: SelectedObj) => dispatch(setEventTypeThunk(eType)),
    setCompetition: (competition: SelectedObj) =>
      dispatch(setCompetitionThunk(competition)),
    setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
    fetchCompetitions: (eventTypeId: string) =>
      dispatch(fetchCompetitions(eventTypeId)),
    // fetchEventsByCompetition: (etId: string, cId: string) =>
    // dispatch(fetchEventsByCompetition(etId, cId, [])),
    // addExchangeBet: (data: BsData) => dispatch(addExchangeBet(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchList);
