import {
  PAYMENT_METHODS,
  paymentMethodList,
  paymentMethodsDisplayNames,
} from "../MyProfile/ProfileTabs/utils/types";

interface PaymentOptionTabsProps {
  selectedPaymentMethod: PAYMENT_METHODS;
  setSelectedPaymentMethod: (paymentMethod: PAYMENT_METHODS) => void;
}

const PaymentOptionTabs: React.FC<PaymentOptionTabsProps> = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  return (
    <>
      <h2 className="self-start mt-3 text-[14px] font-medium capitalize text-neutral-500 max-md:max-w-full">
        Select Payment option:
      </h2>
      <div className="flex flex-col justify-center items-start mt-3.5 text-[14px] font-medium leading-4 text-center bg-white border border-solid border-neutral-200 text-neutral-500 max-md:pr-5 max-md:max-w-full">
        <div className="flex flex-col w-full">
          <div className="flex border-b border-gray-200">
            {paymentMethodList.map((paymentMethod) => {
              return (
                <button
                  onClick={() => setSelectedPaymentMethod(paymentMethod)}
                  className={`py-2 px-4 ${
                    selectedPaymentMethod === paymentMethod
                      ? "text-white bg-neutral-500"
                      : ""
                  } border border-neutral-200 flex-1 text-center cursor-pointer`}
                >
                  {paymentMethodsDisplayNames[paymentMethod]}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentOptionTabs;
