/**
 * This code was generated by Builder.io.
 */
import React from "react";
import search from '../../../assets/search.png'

interface SearchGamesProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const SearchGames: React.FC<SearchGamesProps> = ({
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <div className="flex items-center py-2 px-3 bg-zinc-100 rounded-md border border-neutral-200 text-neutral-500 mb-4">
      <img
        src={search}
        alt="Search"
        className="w-3.5 h-3.5 mr-2"
      />
      <input
        type="text"
        placeholder="Search Games"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="bg-transparent outline-none text-[14px] w-full"
      />
    </div>
  );
};

export default SearchGames;
