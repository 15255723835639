import React, { useEffect, useState } from "react";
import DepositDesktop from "../../components/Desktop/MyProfile/ProfileTabs/DepositDesktop";
import {
  BankDepositAccountDetailsFormType,
  ConfirmBankPaymentReqPayloadType,
  DEPOSIT_PAYMENT_TYPES,
  DepositApiPayloadType,
  depositMethodList,
  DepositReqUpiResType,
  PAYMENT_OPTION_TYPES,
} from "../../components/Desktop/MyProfile/ProfileTabs/utils/types";
import {
  API_PAYMENT_METHODS_MAP,
  getBankDepositFormInitialState,
  PaymentOptionTypes,
} from "../../components/Desktop/MyProfile/ProfileTabs/utils/deposit.utils";
import {
  reqConfirmDeposit,
  reqDepositFunds,
} from "../../api/depositAndWithdraw";
import { showToast } from "../../store/common/commonSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const initialBankDepositFormState = getBankDepositFormInitialState();

interface DepositViewProps {}

const DepositView: React.FC<DepositViewProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bankDepositFormData, setBankDepositFormData] = useState(
    initialBankDepositFormState
  );

  const [selectedDepositMethod, setSelectedDepositMethod] = useState<
    DEPOSIT_PAYMENT_TYPES | string
  >(DEPOSIT_PAYMENT_TYPES.BANK); // TODO: Make type check strict later

  const [upiDepositPaymentDetails, setUpiDepositPaymentDetails] =
    useState<DepositReqUpiResType>(null);

  const [bankDepositPaymentDetails, setBankDepositPaymentDetails] =
    useState(null);

  const resetPaymentDetailsInfo = () => {
    setBankDepositPaymentDetails(null);
    setUpiDepositPaymentDetails(null);
  };

  useEffect(() => resetPaymentDetailsInfo, [selectedDepositMethod]);

  const [bankDepositAccountDetailsForm, setBankDepositAccountDetailsForm] =
    useState<BankDepositAccountDetailsFormType>({
      bankTxRefId: "",
      selectedPaymentOption: PaymentOptionTypes[0],
    });

  const handleBankDetailsFormChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    bankDepositAccountDetailsForm[e.target.id] = e.target.value;
    setBankDepositAccountDetailsForm({ ...bankDepositAccountDetailsForm });
  };

  const bankAccountDetailsSelectedPaymentOpt = (opt: PAYMENT_OPTION_TYPES) => {
    // TODO: Merge this functionality to handleBankDetailsFormChange
    bankDepositAccountDetailsForm.selectedPaymentOption = opt;
    setBankDepositAccountDetailsForm({ ...bankDepositAccountDetailsForm });
  };

  const depositBtnDisabled =
    upiDepositPaymentDetails || bankDepositPaymentDetails
      ? true
      : !(
          +bankDepositFormData.amount > 0 &&
          bankDepositFormData.mobileNumber.length === 10
        );

  const [upiReferenceId, setUpiReferenceId] = useState("");

  const handleReferenceIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpiReferenceId(e.target.value);
  };

  const isConfirmPaymentBtnDisabled =
    !bankDepositAccountDetailsForm.bankTxRefId;

  const isBankDepositFormDisabled =
    upiDepositPaymentDetails || bankDepositPaymentDetails;

  const handleDepositAmountClick = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    const payload: DepositApiPayloadType = {
      amount: +bankDepositFormData.amount,
      currency_type: "INR", // Default to INR in v1
      mobile_number: bankDepositFormData.mobileNumber,
      notes: "",
      payment_method: API_PAYMENT_METHODS_MAP[selectedDepositMethod],
      upi_intent: selectedDepositMethod === DEPOSIT_PAYMENT_TYPES.UPI,
      upi_qr: selectedDepositMethod === DEPOSIT_PAYMENT_TYPES.UPI,
    };

    try {
      const paymentInfo = await reqDepositFunds(payload);
      if (selectedDepositMethod === DEPOSIT_PAYMENT_TYPES.UPI) {
        setUpiDepositPaymentDetails(paymentInfo.data);
      } else {
        setBankDepositPaymentDetails(paymentInfo.data);
      }
    } catch (err) {
      dispatch(
        showToast({
          message: err?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleBankAccountFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    bankDepositFormData[event.target.id] = event.target.value;
    setBankDepositFormData({ ...bankDepositFormData });
  };

  const getPaymentInfoBasedOnSelectedPaymentType = () => {
    if (selectedDepositMethod === DEPOSIT_PAYMENT_TYPES.UPI) {
      const upiPaymentDetails = upiDepositPaymentDetails.payment_options.find(
        (option) => option.payment_method === DEPOSIT_PAYMENT_TYPES.UPI
      );
      return {
        bank_id: upiPaymentDetails.payment_method_details.bank_id,
        gateway_provider_reference_id: upiDepositPaymentDetails.provider_ref_id,
        mobile_number: bankDepositFormData.mobileNumber,
        payment_option: bankDepositAccountDetailsForm.selectedPaymentOption,
        utr: bankDepositAccountDetailsForm.bankTxRefId,
      };
    } else {
      const bankPaymentDetails = bankDepositPaymentDetails.payment_options.find(
        (option) => option.payment_method === DEPOSIT_PAYMENT_TYPES.BANK
      );
      return {
        bank_id: bankPaymentDetails.payment_method_details.bank_id,
        gateway_provider_reference_id:
          bankDepositPaymentDetails.provider_ref_id,
        mobile_number: bankDepositFormData.mobileNumber,
        payment_option: bankDepositAccountDetailsForm.selectedPaymentOption,
        utr: bankDepositAccountDetailsForm.bankTxRefId,
      };
    }
  };

  const handleConfirmPaymentBtnClick = async () => {
    const {
      bank_id,
      gateway_provider_reference_id,
      mobile_number,
      payment_option,
      utr,
    } = getPaymentInfoBasedOnSelectedPaymentType();

    try {
      const payload: ConfirmBankPaymentReqPayloadType = {
        bank_id,
        gateway_provider_reference_id,
        mobile_number,
        payment_option,
        upi_intent: selectedDepositMethod === DEPOSIT_PAYMENT_TYPES.UPI,
        utr,
      };
      await reqConfirmDeposit(payload);
      showToast({
        message: "Transaction saved successfully",
        type: "success",
      });
      navigate("/my-transaction");
    } catch (err) {
      dispatch(
        showToast({
          message: err?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    setBankDepositFormData({...initialBankDepositFormState});
  }, [selectedDepositMethod])

  return (
    <DepositDesktop
      isBankDepositFormDisabled={isBankDepositFormDisabled}
      bankDepositAccountDetailsForm={bankDepositAccountDetailsForm}
      handleBankDetailsFormChange={handleBankDetailsFormChange}
      isDepositBtnDisabled={depositBtnDisabled}
      depositMethods={depositMethodList}
      selectedDepositMethod={selectedDepositMethod}
      setSelectedDepositMethod={setSelectedDepositMethod}
      handleDepositAmountClick={handleDepositAmountClick}
      bankDepositFormData={bankDepositFormData}
      handleBankAccountFormChange={handleBankAccountFormChange}
      upiDepositPaymentDetails={upiDepositPaymentDetails}
      handleReferenceIdChange={handleReferenceIdChange}
      isConfirmPaymentBtnDisabled={isConfirmPaymentBtnDisabled}
      setUpiReferenceId={setUpiReferenceId}
      upiReferenceId={upiReferenceId}
      bankDepositPaymentDetails={bankDepositPaymentDetails}
      handleConfirmPaymentBtnClick={handleConfirmPaymentBtnClick}
      bankAccountDetailsSelectedPaymentOpt={
        bankAccountDetailsSelectedPaymentOpt
      }
    />
  );
};

export default DepositView;
