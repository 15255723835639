/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import crouselImage1 from "../../../assets/CrouselImage/CrouselImg1.webp";
import crouselImage2 from "../../../assets/CrouselImage/CrouselImg2.webp";
import crouselImage3 from "../../../assets/CrouselImage/CrouselImg3.webp";
import crouselImage4 from "../../../assets/CrouselImage/CrouselImg4.webp";
import crouselImage5 from "../../../assets/CrouselImage/CrouselImg5.webp";
import crouselImage6 from "../../../assets/CrouselImage/CrouselImg6.webp";
import crouselImage7 from "../../../assets/CrouselImage/CrouselImg7.webp";
import crouselImage8 from "../../../assets/CrouselImage/CrouselImg8.webp";

const bannerImages = [
  crouselImage1,
  crouselImage2,
  crouselImage3,
  crouselImage4,
  crouselImage5,
  crouselImage6,
  crouselImage7,
  crouselImage8,
];

interface BannersProps {
  getBannerGameUrl: Function;
  games: any;
}

const Banners: React.FC<BannersProps> = (props) => {
  const { getBannerGameUrl, games } = props;
  const navigate = useNavigate();

  return (
    <div className="px-[12px] scrollbar-hide">
      <div className="flex mt-[10px] w-[100%] overflow-x-scroll gap-[8px]">
        {games.slice(0, 4).map((game, index) => (
          <img
            loading="lazy"
            key={index}
            src={game.trendingThumbnail}
            onClick={() => {
              getBannerGameUrl(
                game?.gameId,
                game?.gameName,
                game?.gameCode,
                game?.providerName,
                game?.subProviderName
              );
            }}
            className="w-[160px] h-[74px]"
            alt={`Banner ${index + 1}`}
          />
        ))}
      </div>
      <div className="flex mt-[10px] w-[100%] overflow-x-scroll gap-[8px]">
        {games.slice(4, 8).map((game, index) => (
          <img
            loading="lazy"
            key={index}
            src={game.trendingThumbnail}
            onClick={() => {
              getBannerGameUrl(
                game?.gameId,
                game?.gameName,
                game?.gameCode,
                game?.providerName,
                game?.subProviderName
              );
            }}
            className="w-[160px] h-[74px]"
            alt={`Banner ${index + 4}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Banners;
