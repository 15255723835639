import React, { useState } from "react";
import SearchGames from "./SearchGames";
import MenuList from "./MenuList";
import OtherMenu from "./OtherMenu";
import SocialLinks from "./SocialLinks";
import SecurityLogout from "./SecurityLogout";

type SidebarProps = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({selectedTab, setSelectedTab}) => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <aside className=" w-[220px] min-w-[200px] bg-white border-r border-neutral-200 overflow-y-auto scrollbar-hide sticky top-3 h-fit">
      <div className="flex flex-col p-4">
        <SearchGames searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <MenuList searchTerm={searchTerm} setSelectedTab={setSelectedTab} />
        <OtherMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <SocialLinks />
        <SecurityLogout />
      </div>
    </aside>
  );
};

export default Sidebar;
