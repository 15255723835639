import React from "react";
import {
  PAYMENT_METHODS,
  paymentMethodList,
} from "../../../Desktop/MyProfile/ProfileTabs/utils/types";

interface PaymentOptionTabsMobProps {
  selectedPaymentMethod: PAYMENT_METHODS;
  setSelectedPaymentMethod: (paymentMethod: PAYMENT_METHODS) => void;
}

const PaymentOptionTabMob: React.FC<PaymentOptionTabsMobProps> = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  return (
    <div className="flex text-center bg-white border border-solid border-neutral-200 text-neutral-500  w-full">
      {paymentMethodList.map((paymentMethod) => {
        return (
          <div
            onClick={() => setSelectedPaymentMethod(paymentMethod)}
            className={`flex-1 px-6 py-2.5 ${
              selectedPaymentMethod === paymentMethod
                ? "text-white border border-solid bg-neutral-500 border-neutral-200"
                : ""
            }`}
          >
            {paymentMethod}
          </div>
        );
      })}
    </div>
  );
};

export default PaymentOptionTabMob;
