/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface InputFieldProps {
  label: string;
  placeholder: string;
  value: string;
  fieldKey: string;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  placeholder,
  value,
  fieldKey,
  onChange,
  type,
  isDisabled,
}) => {
  return (
    <div className="flex flex-col self-stretch my-auto rounded-md min-w-[240px] w-full lg:w-[48%] max-md:max-w-full flex-wrap">
      <label
        htmlFor={fieldKey}
        className="self-start text-[14px] font-medium text-stone-900"
      >
        {label}
      </label>
      <input
        disabled={isDisabled}
        id={fieldKey}
        placeholder={placeholder}
        className={`gap-2.5 self-stretch px-3 py-3.5 mt-2 rounded-md border border-solid bg-zinc-100 border-neutral-200 min-h-[44px] text-[14px] ${
          isDisabled && "text-gray-400"
        }`}
        aria-label={label}
        value={value}
        type={type}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
