/**
 * This code was generated by Builder.io.
 */
import React, { useEffect } from "react";
import { Filters, turnOverReptxTypes } from "../../../store/reports/reportSelectors";
import DropDown from "../DropDown";
import ReactDatePicker from "../ReactDatePicker";

interface DatePickerProps {
  onDateChange: Function;
  filters: Filters;
  setFilters: Function
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  filters,
  setFilters
}) => {

  const handleFromDateChange = (date) => {
    onDateChange(date, filters.endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(filters.startDate, date);
  };

  const handleTransactionType = (event) => {
    setFilters({ ...filters, transactionType: event.value });
  };

  return (
    <div className="flex gap-3 px-[12px] self-end  text-[14px] whitespace-nowrap max-md:flex-wrap ">
      <div className="flex gap-2.5 flex-col w-[100%]">
        <div className="grow my-auto capitalize text-neutral-500">
          Transaction Type
        </div>
        <DropDown options={turnOverReptxTypes} selectedOption={filters?.transactionType === "ALL" ? { value: "ALL", label: "All" } : filters?.transactionType} onChange={handleTransactionType} />
      </div>
      <div className="flex w-[100%] gap-[12px]">
      <div className="flex  flex-col w-[50%]">
        <div className="grow my-auto capitalize text-neutral-500">From</div>
        <ReactDatePicker date={filters.startDate} setDate={handleFromDateChange} />
      </div>
      <div className="flex  flex-col w-[50%]">
        <div className="grow my-auto capitalize text-neutral-500">To</div>
          <ReactDatePicker date={filters.endDate} setDate={handleToDateChange} />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
