import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import {
  defaultFilters,
  Filters,
  getOutcomeDescByEnumName,
  getSportNameByIdMap,
  MarketTypeByEnumMap,
} from "../../../../store/reports/reportSelectors";
import { fetchData } from "../../../../store/reports/reportsThunk";
import DatePicker from "./DatePicker";
import GamesTabs from "../../../Mobile/MyProfile/MyBet/GameTabs";

type StoreProps = {
  bets: any;
  fetchData: Function;
  nextPage: Function;
  prevPage: Function;
  handleDateChange: Function;
  filters: Filters;
  setFilters: Function;
  nextPageToken: any;
  setNextPageToken: any;
  sports: any;
  pageToken: string;
  games: any;
  betStatus: any;
};

const MyBets: React.FC<StoreProps> = (props) => {
  const {
    bets,
    sports,
    games,
    pageToken,
    fetchData,
    nextPage,
    prevPage,
    handleDateChange,
    filters,
    setFilters,
    nextPageToken,
    setNextPageToken,
    betStatus
  } = props;

  return (
    <>
    <div className="flex  flex-col pt-px pb-20  mt-3.5 w-full bg-white rounded-md border border-solid over border-neutral-200 max-md:mt-6 max-md:max-w-full">
      <div className="flex flex-col bg-zinc-100">
        <div className="w-full max-md:max-w-full ">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-full">
              <div className="flex flex-col py-px w-full text-[14px] font-medium bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6">
                <div className="flex flex-col justify-center items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
                  <div className="flex ">
                    <div className=" bg-[#804091] w-full py-2 pr-4 pl-2 text-[14px] text-white">
                      My Bets
                    </div>
                    <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[8px] w-6 h-full"></div>
                    <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full -translate-x-1"></div>
                    <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full"></div>
                  </div>
                </div>
                <DatePicker
                  onDateChange={handleDateChange}
                  filters={filters}
                  setFilters={setFilters}
                  sports={sports}
                  games={games}
                  betStatus={betStatus}
                />
                <GamesTabs
                  filters={filters}
                  setFilters={setFilters}
                  sports={sports}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="flex  scrollbar-hide overflow-x-scroll mx-7 mt-4 max-md:mr-2.5 max-md:max-w-full">
        <table className="min-w-full divide-y divide-gray-200 ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
              >
                Event
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
              >
                Market
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
              >
                Bet
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
              >
                Odds
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
              >
                Amount
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider"
              >
                Returns
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {bets.map((row) => (
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {moment(row.betPlacedTime).format("DD-MM-YY, h:mm:ss A")}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {row.eventName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {row.marketType === "BOOKMAKER"
                    ? row.marketName || MarketTypeByEnumMap[row.marketType]
                    : MarketTypeByEnumMap[row.marketType]
                    ? MarketTypeByEnumMap[row.marketType]
                    : "Casino"}
                  <span className="event-name">
                    ({getSportNameByIdMap(row?.sportId)})
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {row.marketType === "FANCY"
                    ? row.marketName +
                      " @ " +
                      Number(row.oddValue * 100 - 100).toFixed(0)
                    : row.marketType === "BINARY"
                    ? row.outcomeDesc +
                      " @ " +
                      Number(row.sessionRuns).toFixed(0)
                    : row.outcomeDesc}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <span className="mob-fs-13">
                    {row.oddValue && row.oddValue !== -1.0
                      ? row.marketType === "FANCY" ||
                        row.marketType === "BINARY"
                        ? Number(row.sessionRuns).toFixed(0)
                        : row.marketType === "BOOKMAKER"
                        ? (row.oddValue * 100 - 100).toFixed(2)
                        : row.oddValue.toFixed(2)
                      : "-"}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {row.stakeAmount}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {getOutcomeDescByEnumName(row.outcomeResult.toString())
                    ? getOutcomeDescByEnumName(row.outcomeResult.toString())
                    : "Unsettled"}
                </td>
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500">
                  <span
                    className={`mob-fs-14 ${
                      row.payOutAmount > 0 ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {row.outcomeResult === "Open"
                      ? "-"
                      : row.payOutAmount.toFixed(2)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
      
      <div>
        {(filters?.pageToken.length > 0) && (
          <button
            className=" p-1 leading-[16.2px] text-white bg-fuchsia-800 m-2"
            onClick={(e) => prevPage()}
          >
            Prev({filters.pageToken.length})
          </button>
        )}
        {pageToken ? (
          <button
            className=" p-1 leading-[16.2px] text-white bg-fuchsia-800 m-2"
            onClick={(e) => nextPage()}
          >
            Next({filters?.pageToken.length + 2})
          </button>
        ) : null}
      </div>
      {bets.length === 0 ? <div className=" text-center">No bets</div> : null}
      </>
  );
};
export default MyBets;
