import { useState } from "react";
import Button from "./Button";
import InputField from "./InputField";
import ToggleButton from "./ToggleButton";
import CommonBackImgAuthScreens from "./ForGotPasswordImg/CommonBackImgAuthScreens";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";

interface ForgotPasswordProps {
  redirectToSignUp: Function;
  method: string;
  handleMethodChange: Function;
  handleSubmit: Function;
  username: string;
  handleUsernameChange: Function;
  countryCode: string;
  handleCountryCodeChange: Function;
  mobile: string;
  handleMobileChange: Function;
  otpSuccess: boolean;
  otp: number;
  setOtp: Function;
  newPassword: string;
  setNewPassword: Function;
  confirmPassword: string;
  setConfirmPassword: Function;
  resetPassword: Function;
  showPasswordClickHandler: Function;
  showPassword: boolean;
  redirectToSignIn: Function;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const {
    redirectToSignUp,
    method,
    otpSuccess,
    mobile,
    handleMobileChange,
    username,
    countryCode,
    handleCountryCodeChange,
    handleUsernameChange,
    handleMethodChange,
    handleSubmit,
    otp,
    setOtp,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    resetPassword,
    showPasswordClickHandler,
    showPassword,
    redirectToSignIn
  } = props;
  const [userName, setUserName] = useState<boolean>(true);
  return (
    <main className="flex items-center flex-col w-[100%]  h-[100vh]">
      <CommonBackImgAuthScreens redirectToHome={redirectToSignIn} tagline="Bet smarter,Bet with confidence, Win bigger: your ultimate bet experience" />
      <section className="relative w-[90%] mt-[15px] mb-[25px] flex z-10 flex-col self-center px-4  text-sm font-medium bg-white rounded-2xl border-4 border-solid border-neutral-200 text-neutral-500">
        {/* <button
          onClick={() => redirectToSignIn()}
          className="flex items-center gap-1.5 text-sm font-medium whitespace-nowrap text-stone-900 w-[61px]"
        >
          <FaArrowLeftLong />
          <span className="my-auto">Back</span>
        </button> */}
        <h1 className="self-center text-3xl font-semibold text-center text-stone-900">
          Forgot Password
        </h1>
        {!otpSuccess ? (
          <>
            <p className="self-center mt-2.5 text-base text-center text-neutral-500 w-[254px]">
              We'll send OTP on your registered number or username.
            </p>
            <ToggleButton
              setUserName={setUserName}
              method={method}
              handleMethodChange={handleMethodChange}
            />
            {userName ? (
              <InputField
                label="Username"
                value={username}
                onChange={(e) => handleUsernameChange(e)}
                placeholder="Enter your username"
              />
            ) : (
              <InputField
                label="Mobile Number"
                value={mobile}
                onChange={(e) => handleMobileChange(e)}
                placeholder="Enter your mobile number"
              />
            )}
            <Button onClick={() => handleSubmit()}>Send OTP</Button>
          </>
        ) : (
          <>
            <InputField
              label="OTP *"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
            />
            <div className="mt-6">
              <label htmlFor="password" className="self-start text-neutral-500">
                Password
              </label>
              <div className="flex gap-5 justify-between px-3.5 py-3 mt-3 bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60">
                <input
                  type={"text"}
                  id="password"
                  className="w-full bg-transparent border-none"
                  placeholder="Enter your password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="password" className="self-start text-neutral-500">
                Confirm Password
              </label>
              <div className="flex gap-5 justify-between px-3.5 py-3 mt-3 bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-full bg-transparent border-none"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div
                  className="cursor-pointer"
                  onClick={() => showPasswordClickHandler()}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
            </div>
            <Button onClick={() => resetPassword()}>Reset Password</Button>
          </>
        )}
        <p className="self-center mt-4 text-sm text-center text-stone-900">
          Don't have account?{" "}
          <button
            onClick={() => redirectToSignUp()}
            className="font-semibold text-fuchsia-800 underline"
          >
            Sign Up
          </button>
        </p>
      </section>
    </main>
  );
};

export default ForgotPassword;
