/**
 * This code was generated by Builder.io.
 */
import React from "react";

import telegram from '../../../assets/telegram.svg'
import whatsapp from '../../../assets/whatsapp.svg'
import linkedin from '../../../assets/linkedin.svg'

interface SocialLink {
  icon: string;
  text: string;
}

const socialLinks: SocialLink[] = [
  { icon: telegram, text: "Telegram" },
  { icon: whatsapp, text: "WhatsApp" },
  { icon: linkedin, text: "LinkedIn" },
];

const SocialLinks: React.FC = () => {
  return (
    <>
      <div className="mt-6 text-[14px] font-semibold tracking-widest text-neutral-500">
        FOLLOW US ON
      </div>
      {socialLinks.map((link, index) => (
        <React.Fragment key={index}>
          <div
            className={`flex items-center gap-3.5 mt-3 text-[14px] font-semibold whitespace-nowrap`}
          >
            <img src={link.icon} alt="" className="w-5 h-5" />
            <span>{link.text}</span>
          </div>
          {index < socialLinks.length - 1 && (
            <div className="mt-1.5 h-px bg-zinc-100" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default SocialLinks;
