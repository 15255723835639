/**
 * This code was generated by Builder.io.
 */
import React from "react";
import PaymentMethods from "./PaymentMethods";
import PaymentForm from "./PaymentForm";
import { WithdrawComponentProps } from "../../../Desktop/Withdraw/utils/types";

interface PaymentSectionProps extends WithdrawComponentProps {}

const PaymentSection: React.FC<PaymentSectionProps> = (props) => {
  return (
    <section className="flex flex-col  mt-[10px] w-full  bg-white ">
      {/* <div className="">
        <div className="flex flex-col justify-center items-start w-full bg-stone-900">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1172b327aece5e0926f249a9890f3479ca1eebc5a2895b5759a5cbfc256a9f0a?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
            alt="WinBuzz Gaming Logo"
            className="max-w-full aspect-[4.76] w-[149px]"
          />
        </div>
        <PaymentMethods />
      </div> */}
      <PaymentForm {...props} />
    </section>
  );
};

export default PaymentSection;
