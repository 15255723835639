/**
 * This code was generated by Builder.io.
 */
import React, { useCallback } from "react";
import { DEPOSIT_PAYMENT_TYPES } from "../utils/types";

interface NavigationItemProps {
  label: string;
  value: DEPOSIT_PAYMENT_TYPES;
  isActive: boolean;
  setSelectedPaymentType: (type: DEPOSIT_PAYMENT_TYPES) => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  label,
  isActive,
  setSelectedPaymentType,
  value,
}) => {
  const updateSelection = useCallback(() => setSelectedPaymentType(value), []);
  return (
    <button
      onClick={updateSelection}
      className={`gap-2.5 self-stretch py-2.5 text-[14px] w-[50%] font-medium leading-none text-center whitespace-nowrap min-h-[32px] max-md:px-5 ${
        isActive
          ? "text-white bg-neutral-500 border border-solid border-neutral-200"
          : "text-neutral-500"
      }`}
    >
      {label}
    </button>
  );
};

export default NavigationItem;
