import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { connect } from "react-redux";
import { updateNewPassword } from "../../../store/auth/authThunk";

interface AcceptTermsProps {
  updateNewPassword: Function;
}

const AcceptTerms: React.FC<AcceptTermsProps> = (props) => {
  const { updateNewPassword } = props;
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="flex justify-center items-center min-h-screen bg-purple-700 p-4">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          updateNewPassword({ newPassword, oldPassword });
        }}
        className="bg-white p-8 rounded shadow-md w-full max-w-sm"
      >
        <h2 className="text-2xl font-bold mb-6 text-center">Set Password</h2>

        <div className="mb-4">
          <label className="block text-gray-700">Old Password</label>
          <input
            type="password"
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>

        <div className="mb-4 relative">
          <label className="block text-gray-700 flex items-center">
            New Password
            <FaInfoCircle
              className="ml-2 text-gray-500 cursor-pointer"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onFocus={() => setShowTooltip(true)}
              onBlur={() => setShowTooltip(false)}
              //   tabIndex="0"
            />
          </label>
          <input
            type="password"
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          {showTooltip && (
            <div className="absolute bg-gray-700 text-white text-sm rounded p-2 shadow-lg mt-1 ml-6 z-10">
              Password must contain at least 8 characters and at most 20
              characters. It must include at least one uppercase letter, one
              lowercase letter, and one number.
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Repeat New Password</label>
          <input
            type="password"
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
            value={repeatNewPassword}
            onChange={(e) => setRepeatNewPassword(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="flex items-center text-gray-700">
            <input
              type="checkbox"
              className="mr-2"
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
              required
            />
            I understand & accept the terms and conditions
          </label>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 text-white py-2 rounded hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    updateNewPassword: (event: any) => dispatch(updateNewPassword(event)),
  };
};

export default connect(null, mapDispatchToProps)(AcceptTerms);
