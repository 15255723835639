import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Setting from "../components/Mobile/Settings/Layout"

const SettingView: React.FC = (props) => {
  
  return (
    <>
      {isMobile ?<Setting/>:<></>}
      </>
    
  );
}

export default Setting;



