import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BUTTON_VARIABLES } from '../../constants/ButtonVariables';
import { ExBetslip } from '../../models/ExchBetslip';
import { PlaceBetRequest } from '../../models/BsData';
import { ButtonVariable } from '../../models/ButtonVariables';
// import { _findIndexByArray } from '../../util/stringUtil';
import {
  fetchOpenBets,
  fetchCasinoOpenBets,
  fetchButtonVariables,
  betStatus,
  addExchangeBet,
} from './betSlipThunk';

type Action = {
  type: string;
  payload: any;
};

const initialState: ExBetslip = {
  bets: [],
  openBets: [],
  totalOrders: 0,
  buttonVariables: BUTTON_VARIABLES,
  bettingInprogress: false,
  isOneClickEnable: false,
  oneClickAmount: 0,
  betStatusResponse: null,
};

const exchBetslipSlice = createSlice({
  name: 'betslip',
  initialState,
  reducers: {
    addBetHandler: (state, action: PayloadAction<PlaceBetRequest>) => {
      if (!state.bettingInprogress) {
        state.bets.push(action.payload);
      }
    },
    removeExchangeBet: (state, action: PayloadAction<number>) => {
      state.bets.splice(action.payload, 1);
    },
    setExchangeBetStake: (state, action: PayloadAction<{ index: number; amount: number; type: 'ADD' | 'SET' }>) => {
      const bet = state.bets[action.payload.index];
      if (action.payload.type === 'ADD') {
        bet.amount += Math.floor(action.payload.amount);
      } else if (action.payload.type === 'SET') {
        bet.amount = Math.floor(action.payload.amount);
      }

      if (isNaN(bet.amount)) {
        bet.amount = 0;
      }
    },
    exchangeBetOddsChange: (state, action: PayloadAction<{ index: number; odds: number }>) => {
      const bet = state.bets[action.payload.index];
      if (bet) {
        bet.oddValue = action.payload.odds ? action.payload.odds : null;
      }
    },
    fetchOpenBetsSuccess: (state, action: PayloadAction<{ result: any[]; totalOrders: number }>) => {
      state.openBets = action.payload.result;
      state.totalOrders = action.payload.totalOrders;
    },
    addOpenBets: (state, action: PayloadAction<any[]>) => {
      state.openBets = state.openBets.concat(action.payload);
    },
    clearSuccessBets: (state) => {
      state.bets = [];
    },
    cancelBetSuccess: (state, action: PayloadAction<string>) => {
      state.openBets = state.openBets.filter((bet) => bet.betId !== action.payload);
    },
    setButtonVariables: (state, action: PayloadAction<ButtonVariable[]>) => {
      state.buttonVariables = action.payload.length > 0 ? action.payload : BUTTON_VARIABLES;
    },
    setBettingInprogress: (state, action: PayloadAction<boolean>) => {
      state.bettingInprogress = action.payload;
    },
    enableOnclickBet: (state, action: PayloadAction<boolean>) => {
      state.isOneClickEnable = action.payload;
    },
    addOnclickBetAmount: (state, action: PayloadAction<number>) => {
      state.oneClickAmount = action.payload;
    },
    updateBetslipfromWS: (state, action: PayloadAction<any>) => {
      // Implementation for updating betslip from websocket payload
    },
    resetOddsChangeMsg: (state, action: PayloadAction<any>) => {
      // Implementation for resetting odds change message
    },
    validateOdds: (state, action: PayloadAction<any>) => {
      // Implementation for validating odds
    },
    fetchBetStatusInProgress: (state, action: PayloadAction<any>) => {
      state.betStatusResponse = action.payload;
    },
    fetchBetStatusSuccess: (state, action: PayloadAction<any>) => {
      state.betStatusResponse = action.payload;
    },
    fetchBetStatusFail: (state, action: PayloadAction<any>) => {
      state.betStatusResponse = action.payload;
    },
    clearBetStatusResponse: (state) => {
      state.betStatusResponse = null;
    },
    clearExchangeBets: (state) => {
      if (!state.bettingInprogress) {
        state.bets = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOpenBets.fulfilled, (state, action) => {
      // Handle additional logic if needed
    });
    builder.addCase(fetchCasinoOpenBets.fulfilled, (state, action) => {
      // Handle additional logic if needed
    });
    builder.addCase(fetchButtonVariables.fulfilled, (state, action) => {
      // Handle additional logic if needed
    });
    builder.addCase(betStatus.fulfilled, (state, action) => {
      // Handle additional logic if needed
    });
  },
});

export const {
  addBetHandler,
  removeExchangeBet,
  setExchangeBetStake,
  exchangeBetOddsChange,
  clearExchangeBets,
  fetchOpenBetsSuccess,
  addOpenBets,
  clearSuccessBets,
  cancelBetSuccess,
  setButtonVariables,
  setBettingInprogress,
  enableOnclickBet,
  addOnclickBetAmount,
  updateBetslipfromWS,
  resetOddsChangeMsg,
  validateOdds,
  fetchBetStatusInProgress,
  fetchBetStatusSuccess,
  fetchBetStatusFail,
  clearBetStatusResponse,
} = exchBetslipSlice.actions;

export default exchBetslipSlice.reducer;
