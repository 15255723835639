import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import SignUpForm from "../components/Desktop/AuthScreens/SignUp";
import SignUpFormMobile from "../components/Mobile/SignUpMobile/SignUpMobile";
import { RootState } from "../store";
import {
  checkPhoneNumberExists,
  sendOTP,
  signUp,
  validateOtp,
} from "../store/auth/authThunk";
import { useNavigate } from "react-router-dom";
import { setCurrentStep, setOtpSent } from "../store/auth/authSlice";

interface SignUpViewProps {
  otpSent: boolean;
  otpSuccess: boolean;
  sendOTP: Function;
  signUp: Function;
  validateOtp: Function;
  currentStep: number;
  setCurrentStep: Function;
  setOtpSent: Function;
  phoneNumberExists: boolean;
  checkPhoneNumberExists: Function;
}

const SignUpView: React.FC<SignUpViewProps> = (props) => {
  const {
    sendOTP,
    otpSent,
    setOtpSent,
    phoneNumberExists,
    checkPhoneNumberExists,
    currentStep,
    signUp,
    validateOtp,
    setCurrentStep,
  } = props;
  const [countryCode, setCountryCode] = useState<string>("91");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [otp, setOtp] = useState<number>();
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreedToTerms(e.target.checked);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    if (currentStep === 1 && phoneNumber && agreedToTerms && !otpSent) {
      await sendOTP(countryCode + phoneNumber);
    } else if (
      currentStep === 1 &&
      phoneNumber &&
      agreedToTerms &&
      otp &&
      otpSent
    ) {
      await validateOtp({ phoneNumber: countryCode + phoneNumber, otp });
    } else if (currentStep === 2 && username && password) {
      await signUp({
        username: username.toLowerCase(),
        fullName: username.toLowerCase(),
        password: password,
        phoneNumber: countryCode + phoneNumber,
        otp: otp,
      });
      navigate("/home");
    } else {
      console.log("Please fill all fields");
    }
    setLoader(false);
  };

  const redirectToSignIn = () => {
    navigate("/login");
  };

  useEffect(() => {
    setCurrentStep(1);
    setOtpSent(false);
  }, []);

  useEffect(() => {
    checkPhoneNumberExists(countryCode + phoneNumber);
  }, [phoneNumber])

  return (
    <>
      {isMobile ? (
        <SignUpFormMobile
          phoneNumberExists={phoneNumberExists}
          loader={loader}
          otpSent={otpSent}
          handlePasswordChange={handlePasswordChange}
          handleUsernameChange={handleUsernameChange}
          password={password}
          username={username}
          handleSubmit={handleSubmit}
          currentStep={currentStep}
          phoneNumber={phoneNumber}
          otp={otp}
          setOtp={setOtp}
          agreedToTerms={agreedToTerms}
          handlePhoneChange={handlePhoneChange}
          handleTermsChange={handleTermsChange}
          redirectToSignIn={redirectToSignIn}
          showPasswordClickHandler={showPasswordClickHandler}
          showPassword={showPassword}
        />
      ) : (
        <SignUpForm
          phoneNumberExists={phoneNumberExists}
          loader={loader}
          otpSent={otpSent}
          handlePasswordChange={handlePasswordChange}
          handleUsernameChange={handleUsernameChange}
          password={password}
          username={username}
          handleSubmit={handleSubmit}
          currentStep={currentStep}
          phoneNumber={phoneNumber}
          otp={otp}
          setOtp={setOtp}
          agreedToTerms={agreedToTerms}
          handlePhoneChange={handlePhoneChange}
          handleTermsChange={handleTermsChange}
          redirectToSignIn={redirectToSignIn}
          showPasswordClickHandler={showPasswordClickHandler}
          showPassword={showPassword}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    otpSuccess: state.auth.otpSuccess,
    phoneNumberExists: state.auth.phoneNumberExists,
    otpSent: state.auth.otpSent,
    currentStep: state.auth.currentStep,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    sendOTP: (event: any) => dispatch(sendOTP(event)),
    signUp: (event: any) => dispatch(signUp(event)),
    validateOtp: (event: any) => dispatch(validateOtp(event)),
    setCurrentStep: (event: any) => dispatch(setCurrentStep(event)),
    setOtpSent: (event: any) => dispatch(setOtpSent(event)),
    checkPhoneNumberExists: (event: string) =>
      dispatch(checkPhoneNumberExists(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpView);
