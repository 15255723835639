/**
 * This code was generated by Builder.io.
 */
import React,{useState} from "react";
import SearchBar from "./SearchBar";
import SelectDropdown from "./SelectDropdown";
import GameGrid from "./GameGrid";
import { categories } from "../../../store/catalog/catalogSelectors";
import purple from "../../../assets/purple_bg.png";
import Modal from "../../Modal/Modal";

interface CasinoProps {
  games: any;
  providerList: any;
  selectedProvider: string;
  searchTerm: string;
  setSearchTerm: Function;
  setSelectedProvider: Function;
  selectedGameType: string;
  setSelectedGameType: Function;
  dialogShow: boolean;
  setDialogShow: Function;
  handleGameClick: Function;
  categoryList: any;
  title: string;
}

const Casino: React.FC<CasinoProps> = (props) => {
  const {
    games,
    title,
    selectedProvider,
    selectedGameType,
    setSelectedGameType,
    setSelectedProvider,
    searchTerm,
    providerList,
    setSearchTerm,
    setDialogShow,
    categoryList,
    dialogShow,
    handleGameClick,
  } = props;

  return (
    <main className="flex flex-col items-center py-1 mt-3 w-full bg-white border border-solid border-neutral-200">
      <div className="flex flex-col justify-center items-start self-stretch w-full bg-stone-900">
        <div
          style={{ backgroundImage: `url(${purple})` }}
          className=" bg-no-repeat aspect-[9.1] w-[282px] h-[31px] leading-[31px] pl-4  font-medium text-white"
        >
          {title}
        </div>
      </div>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <div className="flex gap-3 mt-2 w-full text-[14px] font-medium max-w-[406px]">
        <SelectDropdown
          selectedValue={selectedProvider}
          setSelectedValue={setSelectedProvider}
          label="Select Providers"
          options={providerList}
        />
        {title !== "Virtual Casino" && (
          <SelectDropdown
            selectedValue={selectedGameType}
            setSelectedValue={setSelectedGameType}
            label="Select Games"
            options={categoryList.map((category) => category)}
          />
        )}
      </div>
      <div className="mt-2">
      {games?.map((_, index) => (
        <GameGrid
          handleGameClick={handleGameClick}
          key={index}
          games={games.slice(index * 2, (index + 1) * 2)}
        />
      ))}
      </div>
      {games.length === 0 && <div className="text-center font-normal text-[14px]">No games to display</div>}
    </main>
  );
};

export default Casino;
