/**
 * This code was generated by Builder.io.
 */
import React from "react";
import purple from "../../../assets/purple_bg.png";
import AccountStatementTable from "./AccountStatementTable";
import DatePicker from "./DatePicker";

interface AccountStatementProps {
  records: any;
  fetchRecords: Function;
  handleDateChange: Function;
  filters: any;
  setFilters: Function;
  nextPage: Function;
  prevPage: Function;
  pageToken: string;
}

const AccountStatement: React.FC<AccountStatementProps> = (props) => {
  const {
    records,
    fetchRecords,
    pageToken,
    nextPage,
    prevPage,
    handleDateChange,
    filters,
    setFilters,
  } = props;

  return (
    <div className="flex flex-col bg-zinc-100">
      <div className="w-full max-md:max-w-full ">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-full">
            <div className="flex flex-col py-px w-full text-[14px] font-medium bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6">
              <div className="flex bg-stone-900 w-full">
                <div className=" bg-[#804091] py-2 pr-4 pl-2 text-[14px] text-white font-medium">
                  Account statement
                </div>
                <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[7px] w-4 h-full"></div>
                <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full -translate-x-1"></div>
                <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-full"></div>
              </div>
              <DatePicker
                onDateChange={handleDateChange}
                filters={filters}
                setFilters={setFilters}
              />
              <AccountStatementTable records={records} />
              <div className=" flex justify-center">
                {filters?.pageToken.length > 0 && (
                  <button
                    className=" p-2 w-14 leading-[16.2px] text-white bg-fuchsia-800 m-2"
                    onClick={(e) => prevPage()}
                  >
                    Prev({filters.pageToken.length})
                  </button>
                )}
                {pageToken ? (
                  <button
                    className=" p-2 w-14 leading-[16.2px] text-white bg-fuchsia-800 m-2"
                    onClick={(e) => nextPage()}
                  >
                    Next({filters?.pageToken.length + 2})
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountStatement;
