import axios, { AxiosResponse } from "axios";
import SVLS_API from "./api-services/svls-api";
import { PROVIDER_ID } from "../constants/Branding";
import { EventDTO } from "../models/common/EventDTO";
import { isMobile } from "react-device-detect";
import store from "../store";

const BASE_URL = `https://stage-api.uvwin2024.co`;

export const getAccountDetails = async (userId: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIndianCasinoGamesAPI = async () => {
  try {
    const response = await SVLS_API.get("/catalog/v2/indian-casino/games", {
      params: {
        providerId: "RG",
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getGameUrlAPI = async (payload) => {
  try {
    const reqBody = {
      gameId: payload.gameId,
      providerName: payload.provider,
      subProviderName: payload.subProvider,
      platformId: isMobile ? "mobile" : "desktop",
    };
    const response = await SVLS_API.post(
      "/catalog/v2/live-casino/game-url",
      reqBody,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getDcGamesAPI = async (params) => {
  try {
    let response;
    const url = "/catalog/v2/categories/indian-casino/games/";
    if (store.getState().auth.loggedIn) {
      response = await SVLS_API.get(url, {
        params,
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
      });
    } else {
      response = await SVLS_API.get(url, {
        params,
      });
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchInplayEvents = async () => {
  try {
    let response = null;
    if (sessionStorage.getItem("jwt_token")) {
      response = await SVLS_API.get(
        `catalog/v2/sports-feed/sports/live-events`,
        {
          params: {
            providerId: PROVIDER_ID,
          },
        }
      );
    } else {
      response = await SVLS_API.get(
        `catalog/v2/sports-feed/sports/live-events`,
        {
          params: {
            providerId: PROVIDER_ID,
          },
        }
      );
    }
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchEventsBySport = async (sportId: string) => {
  try {
    let result = null;
    if (sportId !== "") {
      // convert to sport radar id if it includes _
      sportId = sportId.split("_").join(":");
      if (sessionStorage.getItem("jwt_token")) {
        result = await SVLS_API.get(
          `/catalog/v2/sports-feed/sports/${sportId}/competitions/*/events`,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt_token"),
            },
            params: {
              providerId: PROVIDER_ID,
            },
          }
        );
      } else {
        result = await SVLS_API.get(
          `/catalog/v2/sports-feed/sports/${sportId}/competitions/*/events`,
          {
            params: {
              providerId: PROVIDER_ID,
            },
          }
        );
      }
      return result.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchCompetitions = async (sportId: string) => {
  try {
    if (sportId !== "") {
      // convert to sport radar id if it includes _
      sportId = sportId.split("_").join(":");
      let result = null;
      result = await SVLS_API.get(`   `, {
        params: { providerId: PROVIDER_ID },
      });
      return result.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchEvent = async (
  sportId: string,
  competitionId: string,
  eventId: string,
  marketTime?: string
) => {
  try {
    if (sportId !== "" && competitionId !== "" && eventId !== "") {
      let response = null;
      var eventDetails = [
        {
          providerId: PROVIDER_ID,
          sportId: sportId,
          competitionId: competitionId,
          eventId: eventId,
          marketTime: marketTime,
        },
      ];
      if (sessionStorage.getItem("jwt_token")) {
        response = await SVLS_API.post(
          `/catalog/v2/sports-feed/sports/markets`,
          {
            providerId: PROVIDER_ID,
            marketsCriteria: "ALL",
            eventDetails: eventDetails,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt_token")
                ? sessionStorage.getItem("jwt_token")
                : "",
            },
          }
        );
      } else {
        response = await SVLS_API.post(
          `/catalog/v2/sports-feed/sports/markets`,
          {
            providerId: PROVIDER_ID,
            marketsCriteria: "ALL",
            eventDetails: eventDetails,
          }
        );
      }
      return response.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
