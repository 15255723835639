import { createAsyncThunk } from "@reduxjs/toolkit";
// import { loginSuccess, loginUserSlice } from "./authSlice";
// import APi;
import { useNavigate } from "react-router-dom";
import API from "../../api";
import SVLS_API from "../../api/api-services/svls-api";
import {
  logoutSuccess,
  setAdminNotifications,
  setBalances,
  setCurrentStep,
  setNotifications,
  setOtpSent,
  setOtpSuccess,
  setPhoneNumberExists,
  setTimer,
  setUserDetails,
} from "./authSlice";
import { showToast } from "../common/commonSlice";
import { useAppDispatch } from "../storeHooks";
import { getUsername } from "../../utils/commonUtils";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
interface UserDetailDTO {
  username: string;
  password: string;
}

// export const loginThunk = createAsyncThunk(
//     'auth/loginThunk',
//     async ({ username, password }: { username: string, password: string }, { dispatch, rejectWithValue }) => {
//         try {
//             const response = await loginUser(username, password);
//             if (response.status === 200) {
//                 dispatch(loginSuccess(response.data));
//             }
//             return response.data;
//         } catch (error) {
//             return rejectWithValue(error.message);
//         }
//     }
// );

export const fetchBalance = createAsyncThunk(
  "auth/fetchBalance",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const walletId = sessionStorage.getItem("aid");
      const response = await SVLS_API.get(
        `/wallet/v2/wallets/${walletId}/balance`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
          },
        }
      );
      dispatch(setBalances(response.data));
      return response.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return rejectWithValue("Unauthorized");
      }
      return rejectWithValue(err.message);
    }
  }
);

export const updateNewPassword = createAsyncThunk(
  "auth/updateNewPassword",
  async (data: any, { dispatch }) => {
    try {
      const payload = {
        oldPassword: data.oldPassword.trim(),
        newPassword: data.newPassword.trim(),
      };
      const response = await SVLS_API.put(
        `/account/v2/users/${getUsername()}/password:change`,
        payload,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 204) {
        dispatch(
          showToast({
            message: "Password changed successfully",
            type: "success",
          })
        );
        dispatch(logout());
      }
    } catch (err) {
      dispatch(
        showToast({
          message: err?.response?.data?.message,
          type: "error",
        })
      );
    }
  }
);

export const fetchMailVerifiedStatus = createAsyncThunk(
  "auth/fetchMailVerifiedStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("/mail-verified", {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
      });
      if (response.status === 200) {
        return response.data.mailVerified;
      } else {
        return rejectWithValue("Mail verification failed");
      }
    } catch (ex) {
      return rejectWithValue(ex.message);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (
    { username, password }: { username: string; password: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let response;
      response = await SVLS_API.post("/account/v2/login", {
        username,
        password,
      });
      sessionStorage.setItem("username", username);
      sessionStorage.setItem("jwt_token", response.data);
      // dispatch(
      //   showToast({
      //     message: "Login successful",
      //     type: "succcess",
      //   })
      // );
      return response.data;
    } catch (err) {
      dispatch(
        showToast({
          message: err.response.data.message,
          type: "error",
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, rejectWithValue }) => {
    const token = sessionStorage.getItem("jwt_token");
    try {
      await SVLS_API.post("/account/v2/logout", null, {
        headers: {
          Authorization: token,
        },
      });
      const username = sessionStorage.getItem("username") ?? "";
      sessionStorage.removeItem("jwt_token");
      localStorage.removeItem(`multiMarket_${username}`);
      sessionStorage.clear();
      dispatch(logoutSuccess(null));
    } catch (err) {
      sessionStorage.clear();
      window.location.replace("/");
      return rejectWithValue(err.message);
    }
    window.location.replace("/");
    // navigate("/", { replace: true });
  }
);

export const sendOTP = createAsyncThunk(
  "auth/sendOTP",
  async (phone: string, thunkAPI) => {
    try {
      const response = await SVLS_API.post(
        `/account/v2/otp/?mobileNumber=${phone}`
      );
      if (response.status === 204) {
        thunkAPI.dispatch(
          showToast({
            message: "OTP sent sunccessfully.",
            type: "success",
          })
        );
        thunkAPI.dispatch(setOtpSent(true));
      } else {
        thunkAPI.dispatch(
          showToast({
            message: "Unable to send OTP.",
            type: "error",
          })
        );
      }
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(
        showToast({
          message: err.response.data.message,
          type: "error",
        })
      );
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (postBody: any, thunkAPI) => {
    try {
      const response = await SVLS_API.post(
        "/account/v2/accounts/signup",
        postBody,
        {}
      );
      if (response.status === 204) {
        thunkAPI.dispatch(
          showToast({
            message: "Signup successful.",
            type: "success",
          })
        );
      }
      login(postBody);
      (window as any).gtag("event", "sign_up");
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(
        showToast({
          message: err.response.data.message,
          type: "error",
        })
      );
    }
  }
);

export const validateOtp = createAsyncThunk(
  "auth/signUp",
  async (postBody: any, thunkAPI) => {
    try {
      const response: any = await SVLS_API.post(
        `/account/v2/otp/validate?mobileNumber=${postBody.phoneNumber}&otp=${postBody.otp}`,
        {}
      );
      if (response.status === 204) {
        thunkAPI.dispatch(
          showToast({
            message: "OTP validated.",
            type: "success",
          })
        );
        thunkAPI.dispatch(setCurrentStep(2));
      } else {
        thunkAPI.dispatch(
          showToast({
            message: response?.message,
            type: "error",
          })
        );
      }
    } catch (err) {
      thunkAPI.dispatch(
        showToast({
          message: err.response.data?.message,
          type: "error",
        })
      );
      console.log(err);
    }
  }
);

export const sendOtpToUsername = createAsyncThunk(
  "auth/sendOtpToUsername",
  async (postBody: any, thunkAPI) => {
    try {
      const username = postBody.username;
      const phoneNumber = postBody.phonenumber;
      let response = await SVLS_API.post(
        `/account/v2/users/${
          postBody.option === "Username" ? username.toLowerCase() : phoneNumber
        }/:sendResetPasswordOtp${
          postBody.option !== "Username"
            ? "?userIdentifierType=PHONE_NUMBER"
            : ""
        }`
      );
      if (response.status === 204) {
        thunkAPI.dispatch(
          showToast({
            message: "OTP sent successfully.",
            type: "success",
          })
        );
        thunkAPI.dispatch(setOtpSuccess(true));
      } else {
        thunkAPI.dispatch(
          showToast({
            message: "Unable to send OTP.",
            type: "error",
          })
        );
      }
    } catch (err) {
      thunkAPI.dispatch(
        showToast({
          message: err.response.data?.message,
          type: "error",
        })
      );
      console.log(err);
    }
  }
);

export const checkPhoneNumberExists = createAsyncThunk(
  "auth/sendOtpToUsername",
  async (phoneNumber: string, thunkAPI) => {
    try {
      const response: any = await SVLS_API.get(
        `/account/v2/users/phones/${phoneNumber}/:exists`
      );
      if (response.status === 200) {
        if (response.data === true) {
          thunkAPI.dispatch(
            showToast({
              message: "Phone number already exists.",
              type: "success",
            })
          );
          thunkAPI.dispatch(setPhoneNumberExists(true));
        } else {
          thunkAPI.dispatch(setPhoneNumberExists(false));
        }
      }
    } catch (err) {
      thunkAPI.dispatch(
        showToast({
          message: err.response.data?.message,
          type: "error",
        })
      );
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async (_, thunkAPI) => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/users/${getUsername()}/profile`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
          },
        }
      );
      thunkAPI.dispatch(setUserDetails(response.data));
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "auth/updateDetails",
  async (data: any, thunkAPI) => {
    try {
      await SVLS_API.put(`/account/v2/users/${getUsername()}/profile`, data, {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
      });
      thunkAPI.dispatch(
        showToast({
          type: "success",
          message: "Details saved successfully",
        })
      );
      getUserDetails();
    } catch (err) {
      thunkAPI.dispatch(
        showToast({
          type: "error",
          message: err.response.data.message,
        })
      );
    }
  }
);

export const fetchNotifications = createAsyncThunk(
  "auth/fetchNotifications",
  async (_, thunkAPI) => {
    try {
      const response = await SVLS_API.get("/catalog/v2/notifications/", {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
        params: {
          type: "ACTIVE",
        },
      });
      thunkAPI.dispatch(setNotifications(response.data));
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchAdminNotifications = createAsyncThunk(
  "auth/fetchAdminNotifications",
  async (_, thunkAPI) => {
    try {
      const response = await SVLS_API.get("/catalog/v2/notifications/", {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
        params: {
          type: "ALL",
        },
      });
      thunkAPI.dispatch(setAdminNotifications(response.data));
    } catch (err) {
      console.log(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload: any, thunkAPI) => {
    try {
      const username = payload.username;
      const phoneNumber = payload.phonenumber;
      const response = await SVLS_API.put(
        `/account/v2/users/${
          payload.option === "Username" ? username : phoneNumber
        }/password:resetOnline${
          payload.option === "Username"
            ? ""
            : "?userIdentifierType=PHONE_NUMBER"
        }`,
        { resetPassword: payload.resetPassword },
        {
          params: {
            otp: payload.otp,
          },
        }
      );
      if (response.status === 204) {
        thunkAPI.dispatch(
          showToast({
            message: "Password reset successful.",
            type: "success",
          })
        );
        window.location.replace("/login")
      } else {
        thunkAPI.dispatch(
          showToast({
            message: "Password reset failed.",
            type: "error",
          })
        );
      }
    } catch (err) {
      thunkAPI.dispatch(
        showToast({
          message: err?.response?.data?.message,
          type: "error",
        })
      );
      console.log(err);
    }
  }
);

const uniqueGuid = (): string => {
  const id = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    id() +
    id() +
    "-" +
    id() +
    "-" +
    id() +
    "-" +
    id() +
    "-" +
    id() +
    id() +
    id()
  );
};
