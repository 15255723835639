/**
 * This code was generated by Builder.io.
 */
import React from "react";

import gameRules from '../../../assets/game_rules.svg'
import favourites from '../../../assets/favourites.svg'
import my_bets from '../../../assets/my_bets.svg'
import my_wallet from '../../../assets/my_wallet.svg'
import profit_loss from '../../../assets/profit_loss.svg'
import turnover_history from '../../../assets/turnover_history.svg'
import acc_statement from '../../../assets/acc_statement.svg'
import bonus_statement from '../../../assets/bonus_statement.svg'
import my_transaction from '../../../assets/my_transaction.svg'
import stake_settings from '../../../assets/stake_settings.svg'
import my_profile from '../../../assets/my_profile.svg'
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/storeHooks";


interface MenuItem {
  icon: string;
  text: string;
  path: string;
  requiresAuth?: boolean;
}

const menuItems: MenuItem[] = [
  { icon: gameRules, text: "Game Rules", path: "/game-rules" },
  { icon: favourites, text: "Favourites", path: "/favourites", requiresAuth: true },
  { icon: my_bets, text: "My Bets", path: "/my-bets", requiresAuth: true },
  { icon: my_wallet, text: "My Wallet", path: "/my-wallet", requiresAuth: true },
  { icon: profit_loss, text: "Betting Profit & Loss", path: "/profit-loss", requiresAuth: true },
  { icon: turnover_history, text: "Turnover History", path: "/turnover-history", requiresAuth: true },
  { icon: turnover_history, text: "Commission Report", path: "/commission_report", requiresAuth: true },
  { icon: acc_statement, text: "Account Statement", path: "/account-statement", requiresAuth: true },
  { icon: bonus_statement, text: "Bonus Statement", path: "/bonus-statement", requiresAuth: true },
  { icon: my_transaction, text: "My Transaction", path: "/my-transaction", requiresAuth: true },
  { icon: stake_settings, text: "Stake Setting", path: "/stake-setting", requiresAuth: true },
  { icon: my_profile, text: "My Profile", path: "/my-profile", requiresAuth: true },
];

type OtherMenuProps = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const OtherMenu: React.FC<OtherMenuProps> = ({ selectedTab, setSelectedTab }) => {
  const { loggedIn } = useAppSelector(state => state.auth);
  return (
    <>
      <div className="mt-6 text-[11px] font-semibold tracking-widest text-neutral-500">
        OTHER MENU
      </div>
      {menuItems.map((item, index) => {
        if (item.requiresAuth && !loggedIn) {
          return null;
        }
        return (
          <React.Fragment key={index}>
            <Link to={item.path} onClick={() => setSelectedTab(item.path)}>
              <div className={`flex font-[600] items-center gap-3.5 mt-3 text-[14px]`} onClick={() => setSelectedTab(item.text)}>
                <img src={item.icon} alt="" className="w-5 h-5" />
                <span>{item.text}</span>
              </div>
            </Link>
            {index < menuItems.length - 1 && (
              <div className="mt-1.5 h-px bg-zinc-100" />
            )}
          </React.Fragment>
        )
      }
      )}
    </>
  );
};

export default OtherMenu;
