import React from 'react';
import { CommissionReportRecord } from '../../models/CommissionReport';

type CommisionProps = {
  setSportDetails: (record: CommissionReportRecord) => void;
  records: CommissionReportRecord[];
};

const SportAggreagtionReport: React.FC<CommisionProps> = (props) => {
  const { setSportDetails, records } = props;

  return (
    <div className="reports-ctn user-commission-ctn">
      <div className="content-ctn light-bg">
        <div className="balance-history-tbl-ctn">
          <div className="tbl-ctn">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-[#E0E0E0] border-b">
                  <tr>
                    <th className="text-left px-6 py-2 text-[14px] font-medium text-gray-700">
                      Sport
                    </th>
                    <th className="text-left px-6 py-2 text-[14px] font-medium text-gray-700">
                      Commission
                    </th>
                  </tr>
                </thead>
                {records.length > 0 ? (
                  <tbody className="tbl-body">
                    {records.map((row, idx) => (
                      <tr
                        key={idx}
                        className="border-b hover:bg-gray-100 cursor-pointer"
                        onClick={() => setSportDetails(row)}
                      >
                        <td className="px-6 py-2 text-[14px] capitalize text-blue-600 hover:underline">
                          {row.sportName}
                        </td>
                        <td className="px-6 py-2 text-[14px]">
                          {row.commission?.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td
                        colSpan={2}
                        className="text-center py-4 text-gray-500"
                      >
                        No records available
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportAggreagtionReport;
