import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import MatchList from "../Tables/MatchList";
import { EventDTO } from "../../../models/common/EventDTO";
import {
  fetchEventsByCompetition,
  fetchEventsBySport,
} from "../../../store/catalog/catalogThunk";
import { AppDispatch, RootState } from "../../../store";
import { getExchangeEvents } from "../../../store/catalog/catalogSelectors";
import { SPToBFIdMap } from "../../../utils/commonUtils";
import ExchEventTable from "./ExchEventTable";
import purple from "../../../assets/purple_bg.png";
import { SelectedObj } from "../../../models/SportsState";
import { setEventType } from "../../../store/catalog";
import { SPORTS_MAP } from "../../../constants/EventType";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EventType } from "../../../models/EventType";
import { isMobile } from "react-device-detect";
import ExchRaceTable from "./ExchRaceTable";
import ExchRacePage from "./ExchRacingPage";

type EventsObj = {
  sportId: string;
  sportName: string;
  sportSlug: string;
  events: EventDTO[];
};

type EventProps = {
  fetchEventsBySport: Function;
  events: EventDTO[];
  setEventType: Function;
  browseAll?: boolean;
  contentConfig: any;
  selectedEventType: SelectedObj;
  eventTypes: EventType[];
  selectedCompetition: SelectedObj;
};

const EventPage: React.FC<EventProps> = (props) => {
  const {
    fetchEventsBySport,
    events,
    setEventType,
    browseAll,
    contentConfig,
    selectedEventType,
    eventTypes,
    selectedCompetition,
  } = props;
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showLive, setShowLive] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathParams = useParams();
  const eventType = pathParams["eventType"];

  useEffect(() => {
    if (!pathParams["competition"]) {
      fetchEventsBySport(
        SPToBFIdMap[selectedEventType?.id]
          ? SPToBFIdMap[selectedEventType?.id]
          : selectedEventType?.id,
        events
      );
    }
  }, [selectedEventType]);

  const updateEvents = () => {
    if (!pathParams["competition"]) {
      fetchEventsBySport(
        SPToBFIdMap[selectedEventType?.id]
          ? SPToBFIdMap[selectedEventType?.id]
          : selectedEventType?.id,
        events
      );
    } else {
      fetchEventsByCompetition(
        SPToBFIdMap[selectedEventType?.id]
          ? SPToBFIdMap[selectedEventType?.id]
          : selectedEventType?.id,
        selectedCompetition?.id,
        events
      );
    }
  };

  useEffect(() => {
    let refreshInterval = setInterval(() => {
      updateEvents();
    }, 60 * 1000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [selectedEventType]);

  return (
    <>
      <div className=" rounded-md border flex flex-col border-[#e0e0e0] bg-white w-full border-collapse overflow-hidden my-1">
        <div className=" bg-stone-900 flex w-full h-9 text-white text-[14px] font-semibold rounded-t-md">
          <div className="flex flex-col justify-center w-[50%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
            <div className="flex min-w-[170px]">
              <div className=" bg-[#804091] w-full h-9 py-2 pr-4 pl-2">
                {selectedEventType?.name}
              </div>
              <div className=" skew-x-[-25deg] bg-[#804091] -translate-x-[8px]  w-5 h-[36px]"></div>
              <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-9 -translate-x-1"></div>
              <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-9"></div>
            </div>
          </div>
          {isMobile ? (
            <></>
          ) : (
            <React.Fragment>
              <div className=" m-0 w-[16%] text-right leading-8">1</div>
              <div className=" m-0 w-[16%] text-right leading-8">X</div>
              <div className=" m-0 w-[16%] text-right leading-8">2</div>
            </React.Fragment>
          )}
        </div>
        {!["7", "4339"]?.includes(selectedEventType?.id)
          ? events?.length > 0 &&
            events?.map((iEvent, idx) => (
              <ExchEventTable
                selectedDate={selectedDate}
                showLive={showLive}
                iEvent={iEvent}
              />
            ))
          : events?.length > 0 && <ExchRacePage />}
        {events?.length === 0 || events === null ? (
          <div className="text-[14px] text-[#757575] text-center py-5">
            No events available
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const eventType = state.catalog.selectedEventType;
  const competition = state.catalog.selectedCompetition;

  return {
    events: getExchangeEvents(
      state.catalog.events,
      SPToBFIdMap[eventType?.id] ? SPToBFIdMap[eventType?.id] : eventType?.id,
      competition?.id
    ),
    contentConfig: state.common.contentConfig,
    selectedEventType: state.catalog.selectedEventType,
    eventTypes: state.catalog.eventTypes,
    selectedCompetition: state.catalog.selectedCompetition,

    //   upcomingEvents: getUpcomingEvents(
    //     state.exchangeSports.events,
    //     state.common.contentConfig
    //   ),
    //   cupWinnerEvents: getCupWinnerEvents(
    //     state.exchangeSports.events,
    //     state.common.contentConfig
    //   ),
    //   notificationUpdated: state.common.notificationUpdated,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchEventsByCompetition: (
      sportId: string,
      competitionId: string,
      events: EventDTO[]
    ) => dispatch(fetchEventsByCompetition(sportId, competitionId, events)),
    //   setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
    //   setCompetition: (competition: SelectedObj) =>
    //     dispatch(setCompetition(competition)),
    // addExchangeBet: (data: BsData) => dispatch(addExchangeBet(data)),
    setEventType: (eventType: SelectedObj) => dispatch(setEventType(eventType)),
    fetchEventsBySport: (sportId: string, events: EventDTO[]) =>
      dispatch(fetchEventsBySport({ sportId, events })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
