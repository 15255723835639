import ExchAllMarkets from "../components/Desktop/ExchAllMarkets/ExchAllMarkets";
import ExchEventPage from "../components/Desktop/ExchEventPage/ExchEventPage";
import GameRules from "../components/Desktop/GameRules/Gamerules";
import TermsAndConditions from "../components/Desktop/TermsAndConditions/TermsAndConditions";
import NotFoundPage from "../pages/NotFound/NotFound";
import AccountStatementView from "../views/AccountStatementView";
import IndianCasinoView from "../views/IndianCasinoView";
import InplayView from "../views/InplayView/InplayView";
import LiveCasinoView from "../views/LiveCasinoView";
import MultiMarketView from "../views/MultiMarketView";
import VirtualCasinoView from "../views/VirtualCasinoView";

const sportRoutes = [
  {
    path: "/",
    component: InplayView,
  },
  {
    path: "/home",
    component: InplayView,
  },
  {
    path: "/exchange_sports/inplay",
    component: InplayView,
  },
  {
    path: "/exchange_sports/:eventType",
    component: ExchEventPage,
  },
  {
    path: "/exchange_sports/:eventType/:competition/:eventId/:eventInfo",
    component: ExchAllMarkets,
  }
];

const routesConfig = [
  {
    path: "/multimarket",
    component: MultiMarketView,
  },
  {
    path: "/live-casino",
    component: LiveCasinoView,
  },
  {
    path: "/exchange",
    component: InplayView,
  },
  {
    path: "/virtual-sports",
    component: LiveCasinoView,
  },
  {
    path: "/virtual-casino",
    component: VirtualCasinoView,
  },
  {
    path: "/Indian-casino",
    component: IndianCasinoView,
  },
  {
    path: "/game-rules",
    component: GameRules,
  },
  {
    path: "/account-statement",
    component: AccountStatementView,
  },
  {
    path: "/terms",
    component: TermsAndConditions,
  },
  {
    path: "*",
    component: NotFoundPage,
  },
];

export {
  routesConfig,
  sportRoutes
};
