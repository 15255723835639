import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { MatchOddsRunnerDTO } from '../../models/common/MatchOddsDTO';
import { SuspendedMarketDTOMap } from '../../models/common/SuspendedMarketDTO';
import { ExchangePriceDTO } from '../../models/SPEventDTO';
import { BookmakerDTO, BookmakerRunnerDTO } from '../../models/common/BookmakerDTO';

const initialState = {
  multiMarketData: {},
  secondaryMultiMatchOddsMap: {},
  secondaryMultiMarketsMap: {},
  suspendedMarketsMap: {},
  triggerFetchMarkets: null,
  triggerFetchOrders: null,
  triggerBetStatus: null,
};

const getMatchOddsSet = (prices: ExchangePriceDTO[]) => {
  let pricesSet: ExchangePriceDTO[] = [];
  if (prices?.length > 0) {
    for (let i = 0; i < 3; i += 1) {
      if (prices[i]) pricesSet.push(prices[i]);
      else pricesSet.push({ price: null, size: null });
    }
  }
  return pricesSet;
};

const getSuspendValue = (
  suspendMarketsMap: SuspendedMarketDTOMap,
  providerId: string,
  sportId: string,
  competitionId: string,
  dtoEventId: string,
  dtoMarketType: string,
  dtoMarketId: string,
  dtoSuspsend: boolean
) => {
  let suspend: boolean;
  let key = dtoEventId + ':' + dtoMarketId;

  // case: for storing initial state from markets api call
  if (dtoSuspsend !== undefined) {
    suspend = dtoSuspsend;
    suspendMarketsMap[key] = {
      providerId: providerId,
      sportId: sportId,
      competitionId: competitionId,
      eventId: dtoEventId,
      marketType: dtoMarketType,
      marketId: dtoMarketId,
      suspend: dtoSuspsend,
    };
  } else {
    // case for odds updates from websockets
    // suspend field will not be present in odds update from websocket
    let suspendedMarket = suspendMarketsMap[key];
    if (!suspendedMarket) {
      suspend = false;
    } else if (suspendedMarket) {
      suspend = suspendedMarket.suspend;
    }
  }

  return suspend;
};

const multiMarketSlice = createSlice({
  name: 'multiMarket',
  initialState,
  reducers: {
    setMultiMarketEventData: (state, action) => {
      const { sportId, competitionId, eventId, eventData } = action.payload;
      let data = { ...state.multiMarketData };

      let suspendMarketsMap: SuspendedMarketDTOMap = {
        ...state.suspendedMarketsMap,
      };
      // Add events data
      if (action.payload.eventData) {
        let eData;
        let limitMap = new Map();
        if (data[`${sportId}-${competitionId}-${eventId}`]) {
          eData = { ...data[`${sportId}-${competitionId}-${eventId}`] };
          limitMap.set(
            data[`${sportId}-${competitionId}-${eventId}`]?.matchOdds.marketId,
            data[`${sportId}-${competitionId}-${eventId}`]?.matchOdds
              ?.marketLimits
          );
        } else eData = { ...action.payload.eventData };

        if (eData && eData.eventId && eData.eventId !== 'undefined') {
          let homeTeam = eData.homeTeam ? eData.homeTeam : '';
          let awayTeam = eData.awayTeam ? eData.awayTeam : '';
          if (
            homeTeam === '' &&
            awayTeam === '' &&
            (eData.eventName?.toLowerCase().includes(' v ') ||
              eData.eventName?.toLowerCase().includes(' vs '))
          ) {
            homeTeam = eData.eventName?.toLowerCase().includes(' v ')
              ? eData.eventName.split(' v ')[0].trim()
              : eData.eventName.includes(' VS ')
                ? eData.eventName.split(' VS ')[0].trim()
                : eData.eventName.split(' Vs ')[0].trim();
            awayTeam = eData.eventName?.toLowerCase().includes(' v ')
              ? eData.eventName.split(' v ')[1].trim().split(' - ')[0]
              : eData.eventName.includes(' VS ')
                ? eData.eventName.split(' VS ')[1].trim().split(' - ')[0]
                : eData.eventName.includes('Vs')
                  ? eData.eventName.split(' Vs ')[1].trim().split(' - ')[0]
                  : eData.eventName.split(' vs ')[1].trim().split(' - ')[0];
          }

          // Set MatchOdds Data.
          const matchOddsData = action.payload.matchOddsData;
          const runners: MatchOddsRunnerDTO[] = [];
          let suspend: boolean = getSuspendValue(
            suspendMarketsMap,
            eData?.providerId,
            eData?.sportId,
            eData?.competitionId,
            eData?.eventId,
            eData?.marketType,
            eData?.marketId,
            matchOddsData?.suspended
          );
          if (matchOddsData) {
            if (matchOddsData.runners && matchOddsData.runners.length > 0) {
              let i = 0;
              for (let e of matchOddsData.runners) {
                if (e) {
                  let runnerName = e.runnerName ? e.runnerName : e.RunnerName;
                  if (runnerName === undefined) {
                    runnerName = '';
                  }

                  if (!runnerName.toLowerCase().includes('draw') && i === 0) {
                    if (runnerName === '') {
                      runnerName = homeTeam;
                    } else if (runnerName !== awayTeam) {
                      homeTeam = runnerName;
                    }
                  }

                  if (!runnerName.toLowerCase().includes('draw') && i !== 0) {
                    if (runnerName === '') {
                      runnerName = awayTeam;
                    } else if (runnerName !== homeTeam) {
                      awayTeam = runnerName;
                    }
                  }

                  i += 1;
                  runners.push({
                    runnerId: e.runnerId ? e.runnerId : e.runnerId,
                    runnerName: runnerName,
                    backPrices: getMatchOddsSet(e.backPrices),
                    layPrices: getMatchOddsSet(e.layPrices),
                    status: e.status,

                    clothNumber: e?.clothNumber,
                    jockeyName: e?.jockeyName,
                    runnerAge: e?.runnerAge,
                    runnerIcon: e?.runnerIcon,
                    stallDraw: e?.stallDraw,
                    trainerName: e?.trainerName,
                  });
                }
              }
            }
            const bLimits = eData?.matchOdds?.limits;
            eData.matchOdds = {
              marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
              marketName: matchOddsData.marketName
                ? matchOddsData.marketName
                : '',
              // inplay: matchOddsData.inplay ? matchOddsData.inplay : false,
              status: matchOddsData.status ? matchOddsData.status : '',
              runners: runners,
              limits: matchOddsData.limits ? matchOddsData?.limits : bLimits,
              marketLimits: matchOddsData?.marketLimits
                ? matchOddsData?.marketLimits
                : limitMap.get(matchOddsData?.marketId),
              suspend: suspend,
            };
          } else {
            let matchOdds = eData.matchOdds;
            if (matchOdds) {
              eData.matchOdds = {
                marketId: matchOdds?.marketId,
                marketName: matchOdds?.marketName,
                // inplay: matchOdds?.inplay,
                status: 'SUSPENDED',
                runners: matchOdds?.runners,
                limits: matchOddsData?.limits,
                marketLimits: matchOdds?.marketLimits
                  ? matchOdds?.marketLimits
                  : limitMap.get(matchOdds?.marketId),
                suspend: true,
              };
            } else {
              eData.matchOdds = {
                marketId: '',
                marketName: '',
                // inplay: false,
                status: 'SUSPENDED',
                runners: [],
                limits: null,
                suspend: true,
              };
            }
          }
          // Set EventData
          eData.homeTeam = homeTeam;
          eData.awayTeam = awayTeam;
          eData.eventSlug = eData.eventSlug
            ? eData.eventSlug
            : eData.eventName
              ? eData.eventName
                .toLowerCase()
                .replace(/[^a-z0-9]/g, ' ')
                .replace(/ +/g, ' ')
                .trim()
                .split(' ')
                .join('-')
              : '';

          data[`${sportId}-${competitionId}-${eventId}`] = eData;
        }
      }
      state.multiMarketData = data;
      state.suspendedMarketsMap = suspendMarketsMap;
    },

    updateMultiSecondaryMatchOdds: (state, action) => {
      const { sportId, competitionId, eventId } = action.payload;
      const marketId: string = action.payload.marketId;
      const allSecMatchOddsMap = { ...state.secondaryMultiMatchOddsMap };
      const matchOddsData = action?.payload?.matchOddsData;
      let suspendMarketsMap: SuspendedMarketDTOMap = {
        ...state.suspendedMarketsMap,
      };

      let runners: MatchOddsRunnerDTO[] = [];
      let runnersData: MatchOddsRunnerDTO[] = allSecMatchOddsMap[
        `${sportId}-${competitionId}-${eventId}-${marketId}`
      ]?.runners?.length
        ? [
          ...allSecMatchOddsMap[
            `${sportId}-${competitionId}-${eventId}-${marketId}`
          ]?.runners,
        ]
        : [];
      if (matchOddsData) {
        let suspend: boolean = getSuspendValue(
          suspendMarketsMap,
          '-',
          sportId,
          competitionId,
          eventId,
          matchOddsData?.marketType,
          matchOddsData?.marketId,
          matchOddsData?.suspended
        );
        if (matchOddsData?.runners && matchOddsData?.runners?.length > 0) {
          let data: any = {};
          for (let e of matchOddsData.runners) {
            if (e) {
              data = runnersData?.find(
                (item) => item?.runnerId === e?.runnerId
              );
              runners.push({
                runnerId: e?.runnerId,
                runnerName: e?.runnerName,
                backPrices: getMatchOddsSet(e.backPrices),
                layPrices: getMatchOddsSet(e.layPrices),
                status: e?.status,
                clothNumber: e?.clothNumber
                  ? e?.clothNumber
                  : data?.clothNumber ?? '',
                jockeyName: e?.jockeyName
                  ? e?.jockeyName
                  : data?.jockeyName ?? '',
                runnerAge: e?.runnerAge ? e?.runnerAge : data?.runnerAge ?? '',
                runnerIcon: e?.runnerIcon
                  ? e?.runnerIcon
                  : data?.runnerIcon ?? '',
                stallDraw: e?.stallDraw ? e?.stallDraw : data?.stallDraw ?? '',
                trainerName: e?.trainerName
                  ? e?.trainerName
                  : data?.trainerName ?? '',
              });
            }
          }
        }

        allSecMatchOddsMap[
          `${sportId}-${competitionId}-${eventId}-${marketId}`
        ] = {
          marketId: matchOddsData.marketId,
          marketName: matchOddsData.marketName,
          marketTime: matchOddsData.marketTime,
          // inplay: matchOddsData.inplay,
          status: matchOddsData.status,
          runners: runners,
          limits: matchOddsData.limits
            ? matchOddsData.limits
            : allSecMatchOddsMap[
              `${sportId}-${competitionId}-${eventId}-${marketId}`
            ]?.limits,
          marketLimits: matchOddsData?.marketLimits
            ? matchOddsData.marketLimits
            : allSecMatchOddsMap[
              `${sportId}-${competitionId}-${eventId}-${marketId}`
            ]?.marketLimits,
          suspend: suspend,
        };
      } else {
        let matchOdds =
          allSecMatchOddsMap[
          `${sportId}-${competitionId}-${eventId}-${marketId}`
          ];
        if (matchOdds) {
          allSecMatchOddsMap[
            `${sportId}-${competitionId}-${eventId}-${marketId}`
          ] = {
            marketId: matchOdds.marketId,
            marketName: matchOdds.marketName,
            marketTime: matchOddsData.marketTime,
            // inplay: matchOdds.inplay,
            status: 'SUSPENDED',
            runners: matchOdds.runners,
            limits: matchOdds.limits,
            marketLimits: matchOdds?.marketLimits
              ? matchOdds.marketLimits
              : allSecMatchOddsMap[
                `${sportId}-${competitionId}-${eventId}-${marketId}`
              ]?.marketLimits,
            suspend: true,
          };
        } else {
          allSecMatchOddsMap[
            `${sportId}-${competitionId}-${eventId}-${marketId}`
          ] = {
            marketId: '',
            marketName: '',
            // inplay: false,
            status: 'SUSPENDED',
            runners: [],
            limits: null,
            suspend: true,
          };
        }
      }

      const secMatchOddsMap = { ...state.secondaryMultiMatchOddsMap };
      secMatchOddsMap[`${sportId}-${competitionId}-${eventId}-${marketId}`] =
        allSecMatchOddsMap[
        `${sportId}-${competitionId}-${eventId}-${marketId}`
        ];

      state.secondaryMultiMatchOddsMap = allSecMatchOddsMap;
      state.suspendedMarketsMap = suspendMarketsMap;
    },

    updateMultiSecondaryMarkets: (state, action) => {
      const { sportId, competitionId, eventId, bookmakerOddsData, enableBookmaker } = action.payload;
      const marketsMap = { ...state.secondaryMultiMarketsMap };
      let suspendMarketsMap = { ...state.suspendedMarketsMap };

      if (action.payload.eventId) {
        if (!marketsMap[`${sportId}-${competitionId}-${eventId}`]) {
          marketsMap[`${sportId}-${competitionId}-${eventId}`] = {
            bookmakers: [],
            enableBookmaker: false,
            fancyMarkets: [],
            enableFancy: false,
          };
        }

        // Set BookmakerOdds Data...
        let bookMakerOdds = [];

        marketsMap[`${sportId}-${competitionId}-${eventId}`].bookmakers = bookMakerOdds;
        marketsMap[`${sportId}-${competitionId}-${eventId}`].enableBookmaker = enableBookmaker;
      }

      state.secondaryMultiMarketsMap = marketsMap;
      state.suspendedMarketsMap = suspendMarketsMap;
    },

    updateMultiMarketBookmakerMarkets: (state, action) => {
      const { sportId, competitionId, eventId } = action.payload;
      //const eventId: string = action.payload.eventId;
      const marketsMap = { ...state.secondaryMultiMarketsMap };
      let suspendMarketsMap: SuspendedMarketDTOMap = {
        ...state.suspendedMarketsMap,
      };
      if (action.payload.eventId) {
        if (!marketsMap[`${sportId}-${competitionId}-${eventId}`]) {
          marketsMap[`${sportId}-${competitionId}-${eventId}`] = {
            ...marketsMap[`${sportId}-${competitionId}-${eventId}`],
            bookmakers: [],
            enableBookmaker: false,
          };
        }

        // Set BookmakerOdds Data.
        const bookMakerOddsData =
          action?.payload?.bookmakerOddsData &&
            Array.isArray(action?.payload?.bookmakerOddsData)
            ? action?.payload?.bookmakerOddsData
            : [action?.payload?.bookmakerOddsData];
        let bookMakerOdds: BookmakerDTO[] = [];
        if (
          marketsMap[`${sportId}-${competitionId}-${eventId}`]?.bookmakers
            ?.length
        )
          bookMakerOdds =
            marketsMap[`${sportId}-${competitionId}-${eventId}`]?.bookmakers;
        if (bookMakerOddsData && bookMakerOddsData.length) {
          for (let br of bookMakerOddsData) {
            let bmRunners: BookmakerRunnerDTO[] = [];
            let suspend: boolean = getSuspendValue(
              suspendMarketsMap,
              '-',
              sportId,
              competitionId,
              eventId,
              br?.marketType,
              br?.marketId,
              br?.suspended
            );
            for (let b of br.runners) {
              bmRunners.push({
                runnerId: b.runnerId ? b.runnerId : '',
                runnerName: b.runnerName ? b.runnerName : '',
                backPrice: b.backPrices[0]?.price,
                backSize: b.backPrices[0]?.size,
                layPrice: b.layPrices[0]?.price,
                laySize: b.layPrices[0]?.size,
                status: b.status ? b.status : '',
                sort: b.sort,
              });
            }
            bmRunners.sort((a, b) => {
              const aDesc = a.sort;
              const bDesc = b.sort;
              if (aDesc > bDesc) return 1;
              else if (aDesc < bDesc) return -1;
              else return 0;
            });
            let index = bookMakerOdds?.length
              ? bookMakerOdds.findIndex((itm) => itm.marketId === br.marketId)
              : -1;
            if (index > -1)
              bookMakerOdds[index] = {
                suspend: suspend,
                marketId: br.marketId ? br.marketId : '-1',
                marketName: br.marketName ? br.marketName : 'Bookmaker',
                runners: bmRunners,
                status: br.status ? br.status : 'OPEN',
                commissionEnabled:
                  br.commissionEnabled !== undefined
                    ? br.commissionEnabled
                    : bookMakerOdds[index].commissionEnabled,
                marketLimits: br?.marketLimits
                  ? br.marketLimits
                  : bookMakerOdds[index].marketLimits,
              };
            else
              bookMakerOdds.push({
                suspend: suspend,
                marketId: br.marketId ? br.marketId : '-1',
                marketName: br.marketName ? br.marketName : 'Bookmaker',
                runners: bmRunners,
                status: br.status ? br.status : 'OPEN',
                commissionEnabled:
                  br.commissionEnabled !== undefined
                    ? br.commissionEnabled
                    : false,
                marketLimits: br?.marketLimits,
              });
          }
        }
        bookMakerOdds.sort((a, b) => {
          const aDesc = a.marketName;
          const bDesc = b.marketName;
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          else return 0;
        });
        marketsMap[`${sportId}-${competitionId}-${eventId}`].bookmakers =
          bookMakerOdds;
        marketsMap[`${sportId}-${competitionId}-${eventId}`].enableBookmaker =
          action.payload.enableBookmaker;
        return {
          ...state,
          secondaryMultiMarketsMap: marketsMap,
          suspendedMarketsMap: suspendMarketsMap,
        };
      }
    },

    updateMultiSuspendedMarkets: (state, action) => {
      const suspendedMarket = action.payload;
      const allSuspendedMarketsMap = { ...state.suspendedMarketsMap };
      const { sportId, competitionId, eventId, marketType, marketId } = suspendedMarket;
      const key = `${eventId}:${marketId}`;
      allSuspendedMarketsMap[key] = suspendedMarket;

      switch (marketType) {
        case 'MATCH_ODDS': {
          const allEvents = { ...state.multiMarketData };
          if (eventId && allEvents[`${sportId}-${competitionId}-${eventId}`]) {
            let matchOddsMarket = allEvents[`${sportId}-${competitionId}-${eventId}`]?.matchOdds;
            if (matchOddsMarket.marketId === marketId) {
              matchOddsMarket.suspend = suspendedMarket.suspend;
            }
          }

          const allSecMatchOddsMap = { ...state.secondaryMultiMatchOddsMap };
          if (allSecMatchOddsMap[`${sportId}-${competitionId}-${eventId}-${marketId}`]) {
            let matchOddsMarket = allSecMatchOddsMap[`${sportId}-${competitionId}-${eventId}-${marketId}`];
            matchOddsMarket.suspend = suspendedMarket.suspend;
          }

          state.multiMarketData = allEvents;
          state.secondaryMultiMatchOddsMap = allSecMatchOddsMap;
          break;
        }
        case 'BOOKMAKER': {
          const marketsMap = { ...state.secondaryMultiMarketsMap };
          if (marketsMap[`${sportId}-${competitionId}-${eventId}`]) {
            let bookmakerMarkets = marketsMap[`${sportId}-${competitionId}-${eventId}`]?.bookmakers;
            if (bookmakerMarkets && bookmakerMarkets.length) {
              for (let bm of bookmakerMarkets) {
                if (bm.marketId === marketId) bm.suspend = suspendedMarket.suspend;
              }
            }
          }
          state.secondaryMultiMarketsMap = marketsMap;
          break;
        }
        default:
          console.log('SuspendMarket:Multi-Market: Invalid market type: ', marketType);
      }

      state.suspendedMarketsMap = allSuspendedMarketsMap;
    },

    updateMultiCommissionMarkets: (state, action) => {
      const commissionMarket = action.payload;
      const { marketType, marketId } = commissionMarket;
      const secondaryMarketsMap = { ...state.secondaryMultiMarketsMap };

      switch (marketType) {
        case 'BOOKMAKER': {
          const bookmakers = secondaryMarketsMap[`${commissionMarket.sportId}-${commissionMarket.competitionId}-${commissionMarket.eventId}`]?.bookmakers;
          if (bookmakers && bookmakers.length) {
            for (let bm of bookmakers) {
              if (bm.marketId === marketId) bm.commissionEnabled = commissionMarket.commission;
            }
          }
          break;
        }
        default:
          console.log('CommissionMarket:Multi-Market: Invalid market type: ', marketType);
      }

      state.secondaryMultiMarketsMap = secondaryMarketsMap;
    },

    triggerMultiFetchMarkets: (state, action) => {
      let limitKey = action.payload.data.limitKey;
      let trigger = false;

      if (action.payload?.accountPath) {
        if (action.payload.accountPath.includes(limitKey)) {
          trigger = true;
        }
      } else {
        const multiMarketData = state.multiMarketData;
        limitKey = limitKey.concat('/');
        const keys = Object.keys(multiMarketData);

        for (let key of keys) {
          const ids = key.split('-');
          const checkKey = limitKey.includes(`/EI/`)
            ? `/EI/${ids[2]}/`
            : limitKey.includes(`/CI/`)
              ? `/CI/${ids[1]}/`
              : limitKey.includes(`/SI/`)
                ? `/SI/${ids[0]}/`
                : limitKey.includes('/SPORTS/')
                  ? `/SPORTS/`
                  : null;

          if (checkKey && limitKey.includes(checkKey)) {
            trigger = true;
            break;
          }
        }
      }
      if (trigger) {
        state.triggerFetchMarkets = moment.now();
      }
    },

    triggerMultiFetchOrders: (state, action) => {
      const eventId = action.payload;
      const multiMarketData = state.multiMarketData;
      if (multiMarketData) {
        let trigger = false;
        const keys = Object.keys(multiMarketData);
        for (let key of keys) {
          if (key.split('-')[2] == eventId) {
            trigger = true;
          }
        }
        if (trigger) {
          state.triggerFetchOrders = moment.now();
        }
      }
    },

    triggerMultiBetStatus: (state, action) => {
      const eventId = action.payload;
      const multiMarketData = state.multiMarketData;
      if (multiMarketData) {
        let trigger = false;
        const keys = Object.keys(multiMarketData);
        for (let key of keys) {
          if (key.split('-')[2] == eventId) {
            trigger = true;
          }
        }
        if (trigger) {
          state.triggerBetStatus = moment.now();
        }
      }
    },
  },
});

export const {
  setMultiMarketEventData,
  updateMultiSecondaryMatchOdds,
  updateMultiSecondaryMarkets,
  updateMultiSuspendedMarkets,
  updateMultiCommissionMarkets,
  triggerMultiFetchMarkets,
  triggerMultiFetchOrders,
  triggerMultiBetStatus,
  updateMultiMarketBookmakerMarkets
} = multiMarketSlice.actions;

export default multiMarketSlice.reducer;
