import { showToast } from "./commonSlice";

// TODO: Add TS support to err and dispatch params
export const commonErrorHandler = (err, dispatch) => {
  if (err.response && err.response.data) {
    dispatch(
      showToast({
        message: err?.response?.data?.message,
        options: {
          type: "error",
        },
      })
    );
  }
};
