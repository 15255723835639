export enum PAYMENT_METHODS {
  BANK_TRANSFER = "NEFT",
  UPI = "UPI",
}

export enum PAYMENT_OPTION_TYPES {
  IMPS = "IMPS",
  NEFT = "NEFT",
  RTGS = "RTGS",
}

export enum API_PAYMENT_METHODS {
  UPI_TRANSFER = "UPI_TRANSFER",
  BANK_TRANSFER = "BANK_TRANSFER",
}

export const API_PAYMENT_METHODS_MAP = {
  [PAYMENT_METHODS.BANK_TRANSFER]: API_PAYMENT_METHODS.BANK_TRANSFER,
  [PAYMENT_METHODS.UPI]: API_PAYMENT_METHODS.UPI_TRANSFER,
};

export const paymentMethodsDisplayNames = {
  [PAYMENT_METHODS.BANK_TRANSFER]: "Bank Transfer",
  [PAYMENT_METHODS.UPI]: "UPI",
};

export const paymentMethodList = Object.values(
  PAYMENT_METHODS
) as PAYMENT_METHODS[];

export enum DEPOSIT_PAYMENT_TYPES {
  BANK = "BANK_TRANSFER",
  UPI = "UPI",
}

export const depositPaymentTypesDisplayNamesMap = {
  [DEPOSIT_PAYMENT_TYPES.BANK]: "Bank",
  [DEPOSIT_PAYMENT_TYPES.UPI]: "UPI",
};

export const depositMethodList = Object.values(
  DEPOSIT_PAYMENT_TYPES
) as DEPOSIT_PAYMENT_TYPES[];

export type BankDepositInitialFormType = {
  amount: string;
  mobileNumber: string;
};

export type DepositApiPayloadType = {
  amount: number;
  currency_type: string; // TODO: Add currency types enum
  mobile_number: string;
  notes: string;
  payment_method: DEPOSIT_PAYMENT_TYPES | string;
  upi_intent: boolean;
  upi_qr: boolean;
};

export type UpiPaymentMethodsType = {
  upi_id: string;
  bank_id: string;
  bank_account_holder: string;
  upi_qr: string;
  upi_intent: string;
  utr_required: boolean;
};

export type BankPaymentMethodsType = {
  bank_account_number: string;
  bank_account_holder: string;
  ifs_code: string;
  bank_name: string;
  bank_id: string;
};

export type DepositReqUpiResType = {
  application_id: string;
  provider_ref_id: string;
  payment_options: {
    payment_method: DEPOSIT_PAYMENT_TYPES;
    payment_method_details: UpiPaymentMethodsType;
  }[];
  transaction_id: number;
};

export type DepositReqBankResType = {
  application_id: string;
  provider_ref_id: string;
  payment_options: {
    payment_method: DEPOSIT_PAYMENT_TYPES;
    payment_method_details: BankPaymentMethodsType;
  }[];
  transaction_id: number;
};

export type DepositBankResType = {
  application_id: string;
  provider_ref_id: string;
  payment_options: any[];
  transaction_id: number;
};

export type BankDepositAccountDetailsFormType = {
  bankTxRefId: string;
  selectedPaymentOption: PAYMENT_OPTION_TYPES;
};

export type ConfirmBankPaymentReqPayloadType = {
  bank_id: string;
  gateway_provider_reference_id: string;
  mobile_number: string;
  payment_option: PAYMENT_OPTION_TYPES;
  upi_intent: boolean;
  utr: string;
  reference_id_modified?: boolean;
};

export type ConfirmUpiPaymentReqPayloadType = {
  bank_id: string;
  gateway_provider_reference_id: string;
  mobile_number: string;
  payment_option: PAYMENT_OPTION_TYPES;
  reference_id_modified: boolean;
  upi_intent: boolean;
  utr: string;
};

export interface DepositPageProps {
  depositMethods: DEPOSIT_PAYMENT_TYPES[];
  selectedDepositMethod: DEPOSIT_PAYMENT_TYPES | string; // TODO: Make type check strict later
  setSelectedDepositMethod: (method: DEPOSIT_PAYMENT_TYPES | string) => void;
  isDepositBtnDisabled: boolean;
  handleDepositAmountClick: (e: React.FormEvent<HTMLFormElement>) => void;
  bankDepositFormData: BankDepositInitialFormType;
  handleBankAccountFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  upiDepositPaymentDetails: DepositReqUpiResType;
  upiReferenceId: string;
  setUpiReferenceId: (text: string) => void;
  isConfirmPaymentBtnDisabled: boolean;
  handleReferenceIdChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  bankDepositPaymentDetails: DepositBankResType;
  bankDepositAccountDetailsForm: BankDepositAccountDetailsFormType;
  handleBankDetailsFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  bankAccountDetailsSelectedPaymentOpt: (opt: any) => void; // TODO: Add proper type
  handleConfirmPaymentBtnClick: () => void;
  isBankDepositFormDisabled: boolean;
}
