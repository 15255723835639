import MyBetView from "../views/MyBetView";
import MatchList from "../components/Desktop/Tables/MatchList";
import AccountStatementView from "../views/AccountStatementView";
import BonusStatement from "../views/BonusStatementView";
import MyTransaction from "../views/MyTransctionView";
import BettingProfitLoss from "../views/ProfitLossView";
import StakeSettingsContent from "../components/Desktop/StakeSettings/StakeSettingsContent";
import TurnoverHistory from "../views/TurnoverHistoryView";
import ProfileSectionView from "../views/ProfileSectionView";
import NotFoundPage from "../pages/NotFound/NotFound";
import MyWalletView from "../views/MywalletView";
import WithdrawComponent from "../views/WithdrawView";
import DepositView from "../views/DepositView/DepositView";
import SettingView from "../views/SettingView";
import SportsbookIframe from "../components/Sportsbook/SportsbookIframe";
import UpLineReport from "../components/CommissionReport/UplineReport";

export const protectedRoutesConfig = [
  {
    path: "/favourites",
    component: MatchList,
  },
  {
    path: "/my-bets",
    component: MyBetView,
  },
  {
    path: "/my-wallet",
    component: MyWalletView,
  },
  {
    path: "/stake-setting",
    component: StakeSettingsContent,
  },
  {
    path: "/my-profile",
    component: ProfileSectionView,
  },
  {
    path: "/profit-loss",
    component: BettingProfitLoss,
  },
  {
    path: "/turnover-history",
    component: TurnoverHistory,
  },
  {
    path: "/account-statement",
    component: AccountStatementView,
  },
  {
    path: "/bonus-statement",
    component: BonusStatement,
  },
  {
    path: "/setting",
    component: SettingView,
  },
  {
    path: "/sportsbook",
    component: SportsbookIframe,
  },
  {
    path: "/my-transaction",
    component: MyTransaction,
  },
  {
    path: "/withdraw",
    component: WithdrawComponent,
  },
  {
    path: "/deposit",
    component: DepositView,
  },
  {
    path: "/commission_report",
    component: UpLineReport
  }
];
