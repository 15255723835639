/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
interface ToggleBtn{
  setUserName:React.Dispatch<React.SetStateAction<boolean>>;
  handleMethodChange: Function;
  method: string;
}
const ToggleButton: React.FC<ToggleBtn> = ({setUserName, handleMethodChange, method}) => {

  return (
    <div className="flex flex-col w-[360px] h-[44px] justify-center border border-solid border-[#701a75] mt-7 text-center rounded-md ">
      <div className="flex gap-5">
        <div
          onClick={() =>{ handleMethodChange("Username");setUserName(true)}}
          className={`flex-1  py-3 whitespace-nowrap rounded-md
            ${method === "Username"
              ? "text-white bg-fuchsia-800"
              : "text-stone-900"}
          `}
        >
          Username
        </div>
        <div
          onClick={() => {handleMethodChange("mobile");setUserName(false)}}
          className={`flex-1  py-3 whitespace-nowrap rounded-md
            ${method === "mobile"
              ? "text-white bg-fuchsia-800"
              : "text-stone-900"}
          `}
        >
          Mobile Number
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
