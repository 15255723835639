import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ProfileSection from "../components/Desktop/MyProfile/ProfileSection";
import ProfileSectionMobile from "../components/Mobile/MyProfile/MyProfileMobile";
import { RootState } from "../store";
import { connect, useDispatch } from "react-redux";
import {
  fetchBalance,
  getUserDetails,
  updateNewPassword,
  updateUserDetails,
} from "../store/auth/authThunk";
import { showToast } from "../store/common/commonSlice";

interface ProfileSectionProps {
  getUserDetails: Function;
  updateUserDetails: Function;
  fetchBalance: Function;
  userDetails: any;
  balances: any;
  updateNewPassword: Function;
}

const ProfileSectionView: React.FC<ProfileSectionProps> = (props) => {
  const {
    userDetails,
    fetchBalance,
    balances,
    updateUserDetails,
    updateNewPassword,
    getUserDetails,
  } = props;

  const defaultDetails = {
    username: "",
    fullName: "",
    emailId: "",
    phoneNumber: "",
    address: "",
    city: "",
    pinCode: "",
  };
  
  const [profileDetails, setProfileDetails] = useState<any>(defaultDetails);
  const [confirmPassword, setConfirmPassword] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const dispatch = useDispatch();

  const resetDetails = () => {
    setProfileDetails(defaultDetails);
  };

  const hanldeUpdatePassword = () => {
    if (newPassword !== confirmPassword) {
        dispatch(showToast({
            message: `New password and confirm password didn't match.`,
            type: 'error'
        }))
        return;
    }
    updateNewPassword({newPassword, oldPassword});
  }

  const resetPassword = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  }

  useEffect(() => {
    getUserDetails();
    fetchBalance();
  }, []);

  useEffect(() => {
    setProfileDetails(userDetails);
  }, [userDetails]);

  return (
    <>
      {isMobile ? (
        <ProfileSectionMobile
          profileDetails={profileDetails}
          setProfileDetails={setProfileDetails}
          updateUserDetails={updateUserDetails}
          resetDetails={resetDetails}
          updateNewPassword={hanldeUpdatePassword}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          setOldPassword={setOldPassword}
          setNewPassword={setNewPassword}
          setConfirmPassword={setConfirmPassword}
          resetPassword={resetPassword}
        />
      ) : (
        <ProfileSection
          balances={balances}
          username={userDetails.username}
          profileDetails={profileDetails}
          setProfileDetails={setProfileDetails}
          updateUserDetails={updateUserDetails}
          resetDetails={resetDetails}
          updateNewPassword={hanldeUpdatePassword}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          setOldPassword={setOldPassword}
          setNewPassword={setNewPassword}
          setConfirmPassword={setConfirmPassword}
          resetPassword={resetPassword}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.auth.userDetails,
    balances: state.auth.balances,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    getUserDetails: () => dispatch(getUserDetails()),
    updateUserDetails: (event: any) => dispatch(updateUserDetails(event)),
    updateNewPassword: (event: any) => dispatch(updateNewPassword(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSectionView);
