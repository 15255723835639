import React from "react";
import { Game } from "../../Desktop/LiveCasino/types";
import { useAppSelector } from "../../../store/storeHooks";

interface GameCardProps {
  game: Game;
  handleGameClick: Function;
}

const GameCard: React.FC<GameCardProps> = (props) => {
  const { game, handleGameClick } = props;

  return (
    <div className="flex flex-wrap ">
      <div className="flex flex-col pl-2 pt-1">
        <img
          loading="lazy"
          src={game.urlThumb}
          alt={game.gameName}
          onClick={() =>
            handleGameClick(
              game.gameId,
              game.gameName,
              game.gameCode,
              game.subProviderName,
              game.providerName
            )
          }
          className="self-center aspect-[1.54] w-[170px] object-cover"
        />
        <div className="flex w-[170px] gap-2 justify-between px-2 py-1 border border-solid bg-zinc-100 border-neutral-200">
          <div className="truncate my-auto overflow-hidden whitespace-nowrap text-[11px] max-w-[75%]">
            {game.gameName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
