/**
 * This code was generated by Builder.io.
 */
import React from "react";
import QRCode from "react-qr-code";

interface ScanMeComponentProps {
  title: string;
  qrInfoStr: string;
}

const ScanMeComponent: React.FC<ScanMeComponentProps> = ({
  title,
  qrInfoStr,
}) => {
  return (
    <section className="flex flex-col rounded-none max-w-[906px]">
      <h2 className="self-start text-xs font-semibold text-stone-900">
        {title}
      </h2>
      <div className="flex flex-col justify-center items-center p-2 mt-2 w-full rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pl-5 max-md:max-w-full">
          <QRCode value={qrInfoStr} />
      </div>
    </section>
  );
};

export default ScanMeComponent;
