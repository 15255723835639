/**
 * This code was generated by Builder.io.
 */
import React from "react";
import TransactionFilter from "./TransactionFilter";
import TransactionList from "./TransactionList";
import purple from "../../../../assets/purple_bg.png"
import BallIcon from "../../../../assets/icons/BallIcon.webp"
interface AccountStatement {
  fetchTurnoverHistory: Function;
  handleDateChange:any;
  turnover:any;
  filters:any;
  setFilters:any;
}
const records=[{}]


const AccountStatement: React.FC<AccountStatement> = (props) => {
  const { turnover, fetchTurnoverHistory,handleDateChange,filters,setFilters } = props;
  return (
    <div className="flex overflow-hidden flex-col mx-auto w-full bg-zinc-100 max-w-[480px] transition ease-in-out duration-[2000ms]">
      <main className="flex flex-col pt-px mt-3 w-full bg-white ">
        <div className="bg-[black]">
          <div style={{ backgroundImage: `url(${purple})` }} className=" flex bg-cover h-full leading-[32px] w-[60%]">
            <span className="text-white ml-2">Turnover History</span>
          </div>
        </div>
        <TransactionFilter handleDateChange={handleDateChange} filters={filters} setFilters={setFilters} />
        <TransactionList records={turnover} />
      </main>
    </div>
  );
};

export default AccountStatement;
