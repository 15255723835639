/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import FormField from "../FormField";
import SocialIcons from "../SocialIcons";

interface PersonalInfoProps {
  profileDetails: any;
  setProfileDetails: Function;
  updateUserDetails: Function;
  resetDetails: Function;
}

const PersonalInfo: React.FC<PersonalInfoProps> = (props) => {
  const { profileDetails, resetDetails, updateUserDetails, setProfileDetails } =
    props;

  const fields = [
    {
      label: "User Name",
      name: "userName",
      value: profileDetails.username,
      onHandleChange: (e) =>
        setProfileDetails({
          ...profileDetails,
          username: e.target.value,
        }),
    },
    {
      label: "Full Name",
      name: "userName",
      value:profileDetails.fullName,
      onHandleChange:(e) =>
        setProfileDetails({
          ...profileDetails,
          fullName: e.target.value,
        })
    },
    {
      label: "Email Address",
      name: "email",
      value: profileDetails.emailId,
      onHandleChange: (e) =>
        setProfileDetails({
          ...profileDetails,
          emailId: e.target.value,
        }),
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      value: profileDetails.phoneNumber,
      onHandleChange: (e) =>
        setProfileDetails({
          ...profileDetails,
          phoneNumber: e.target.value,
        }),
    },
    {
      label: "Address",
      name: "address",
      value: profileDetails.address,
      onHandleChange: (e) =>
        setProfileDetails({
          ...profileDetails,
          address: e.target.value,
        }),
    },
    {
      label: "City",
      name: "city",
      value: profileDetails.city,
      onHandleChange: (e) =>
        setProfileDetails({
          ...profileDetails,
          city: e.target.value,
        }),
    },
    {
      label: "Pin Code",
      name: "pinCode",
      value: profileDetails.pinCode,
      onHandleChange: (e) =>
        setProfileDetails({
          ...profileDetails,
          pinCode: e.target.value,
        }),
    },
  ];

  return (
    <>
      {fields.map((field) => (
        <FormField field={field} />
      ))}

      <div className="flex gap-3.5 px-5 justify-center mt-3 w-full text-[14px] font-medium whitespace-nowrap">
        <button
          type="reset"
          onClick={() => resetDetails()}
          className="flex-1 px-16 py-3 text-black rounded-md border border-fuchsia-700 border-solid"
        >
          Reset
        </button>
        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            updateUserDetails(profileDetails);
          }}
          className="flex-1 px-16 py-3 text-white bg-fuchsia-700 rounded-md"
        >
          Save
        </button>
      </div>
    </>
  );
};

export default PersonalInfo;
