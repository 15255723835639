/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Filters } from "../../../../store/reports/reportSelectors";
import DropDown from "../../../Mobile/DropDown";
import ReactDatePicker from "../../../Mobile/ReactDatePicker";

interface DatePickerProps {
  onDateChange: Function;
  filters: Filters;
  setFilters: Function;
  sports: any;
  games: any;
  betStatus: any;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  filters,
  setFilters,
  sports,
  games,
  betStatus,
}) => {
  const handleFromDateChange = (date) => {
    onDateChange(date, filters.endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(filters.startDate, date);
  };

  const handleBetStatus = (event) => {
    setFilters({ ...filters, status: event.value });
  };

  const handleSport = (event) => {
    setFilters({ ...filters, selectedGame: event.value });
  };

  return (
    <div className="flex gap-3 self-end pl-20 mt-3.5 text-[14px] whitespace-nowrap max-md:flex-wrap max-md:pl-5 max-md:mr-2.5">
      <div className="flex gap-2.5"></div>
      <div className="flex gap-2.5 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">
          Bet Status
        </div>
        <DropDown
          options={betStatus}
          selectedOption={
            filters?.status === "Open"
              ? { value: "Open", label: "Open" }
              : filters?.status
          }
          onChange={handleBetStatus}
        />
      </div>
      <div className="flex gap-2.5 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">
          Select Games
        </div>
        <div>
          <DropDown
            options={games}
            selectedOption={
              filters?.selectedGame === "SPORTS"
                ? { value: "SPORTS", label: "Sports" }
                : filters?.selectedGame
            }
            onChange={handleSport}
          />
        </div>
      </div>
      <div className="flex gap-2.5 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">From</div>
        <div className="flex mt-1 justify-center align-middle">
          <ReactDatePicker date={filters.startDate} setDate={handleFromDateChange} />
        </div>
      </div>
      <div className="flex gap-2.5 mr-2 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">To</div>
        <div className="flex mt-1 justify-center align-middle">
          <ReactDatePicker date={filters.endDate} setDate={handleToDateChange} />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
