import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAccountDetails } from './accountActions';

interface AccountState {
    details: Record<string, any>;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: AccountState = {
    details: {},
    status: 'idle',
    error: null,
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAccountDetails.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = 'succeeded';
                state.details = action.payload;
            })
            .addCase(fetchAccountDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch API data';
            });
    },
});

export default accountSlice.reducer;
