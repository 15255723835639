import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchBalance,
  fetchMailVerifiedStatus,
  login,
  logout,
} from "./authThunk";

const initialState = {
  balanceSummary: {
    balance: null,
    balanceId: 0,
    currenciesAllowed: 0,
    currency: "",
    exposure: null,
    exposureLimit: 0,
    maxStakeSB: 0,
    minStakeSB: 0,
    preferredCurrency: "",
    username: "",
    bonus: null,
    bonusRedeemed: null,
  },
  bcToken: sessionStorage.getItem("bc_token") || null,
  bgToken: sessionStorage.getItem("bg_token") || "-",
  jwtToken: sessionStorage.getItem("jwt_token") || "",
  loading: false,
  loginError: "",
  loggedIn: sessionStorage.getItem("jwt_token") ? true : false,
  mailVerified: null,
  openDepositModal: false,
  openWithdrawModal: false,
  isMolobby: sessionStorage.getItem("mlobby") ? true : false,
  otpSuccess: false,
  phoneNumberExists: false,
  balances: {},
  userDetails: [],
  notifications:[],
  adminNotifications: [],
  currentStep: 1,
  otpSent: false,
  timer: 0
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOpenDepositModal: (state, action) => {
      state.openDepositModal = action.payload;
    },
    setOpenWithdrawModal: (state, action) => {
      state.openWithdrawModal = action.payload;
    },
    handleSideBar: (state) => {
      // Toggle sidebar open state here if implemented
    },
    enableMlobby: (state, action) => {
      state.isMolobby = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOtpSuccess: (state, action) => {
      state.otpSuccess = action.payload;
    },
    setPhoneNumberExists: (state, action) => {
      state.phoneNumberExists = action.payload;
    },
    setBalances: (state, action) => {
      state.balances = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setAdminNotifications: (state, action) => {
      state.adminNotifications = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setOtpSent: (state, action) => {
      state.otpSent = action.payload;
    },
    setTimer: (state, action) => {
      state.timer = action.payload;
    },
    loginSuccess: (state, action) => {
      state.jwtToken = action.payload;
      state.loggedIn = true;
    },
    logoutSuccess: (state, action) => {
      state.jwtToken = "";
      state.loggedIn = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.balanceSummary = action.payload;
      })
      .addCase(fetchBalance.rejected, (state) => {
        state.loading = false;
        state.balanceSummary = initialState.balanceSummary;
      })
      .addCase(fetchMailVerifiedStatus.fulfilled, (state, action) => {
        state.mailVerified = action.payload;
      })
      .addCase(fetchMailVerifiedStatus.rejected, (state) => {
        state.mailVerified = null;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.loginError = "";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.jwtToken = action.payload;
        state.loading = false;
        state.loginError = "";
        state.loggedIn = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.bgToken = "-";
        state.jwtToken = "";
        state.loading = false;
        state.loginError = "Some error occured";
        state.loggedIn = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loggedIn = false;
        state.bcToken = null;
        state.bgToken = "-";
        state.jwtToken = "";
        state.loading = false;
        state.mailVerified = null;
      });
  },
});

export const {
  setTimer,
  setOtpSuccess,
  setPhoneNumberExists,
  setBalances,
  setUserDetails,
  setNotifications,
  setAdminNotifications,
  setCurrentStep,
  setOtpSent,
  setOpenDepositModal,
  setOpenWithdrawModal,
  handleSideBar,
  enableMlobby,
  setLoading,
  loginSuccess,
  logoutSuccess
} = authSlice.actions;

export default authSlice.reducer;
