/**
 * This code was generated by Builder.io.
 */
import React from "react";

import gameRules from '../../../assets/game_rules.svg'
import favourites from '../../../assets/favourites.svg'
import my_bets from '../../../assets/my_bets.svg'
import my_wallet from '../../../assets/my_wallet.svg'
import profit_loss from '../../../assets/profit_loss.svg'
import turnover_history from '../../../assets/turnover_history.svg'
import acc_statement from '../../../assets/acc_statement.svg'
import bonus_statement from '../../../assets/bonus_statement.svg'
import my_transaction from '../../../assets/my_transaction.svg'
import stake_settings from '../../../assets/stake_settings.svg'
import my_profile from '../../../assets/my_profile.svg'
import { Link } from "react-router-dom";


interface MenuItem {
  icon: string;
  text: string;
  path:string;
}

const menuItems: MenuItem[] = [
  { icon: gameRules, text: "Game Rules",path:"/game-rules" },
  { icon: favourites, text: "Favourites" ,path:"/favourites" },
  { icon: my_bets, text: "My Bets" ,path:"/my-bets" },
  { icon: my_wallet, text: "My Wallet",path:"/my-wallet"  },
  { icon: profit_loss, text: "Betting Profit & Loss" ,path:"/profit-loss" },
  { icon: turnover_history, text: "Turnover History" ,path:"/turnover-history" },
  { icon: turnover_history, text: "Commission Report", path: "/commission_report" },
  { icon: acc_statement, text: "Account Statement",path:"/account-statement"  },
  { icon: bonus_statement, text: "Bonus Statement" ,path:"/bonus-statement" },
  { icon: my_transaction, text: "My Transaction" ,path:"/my-transaction" },
  { icon: stake_settings, text: "Stake Setting" ,path:"/stake-setting" },
  { icon: my_profile, text: "My Profile" ,path:"/my-profile" },
];

type OtherMenuProps = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  setShowSidebar: any;
}

const OtherMenu: React.FC<OtherMenuProps> = ({selectedTab, setSelectedTab, setShowSidebar}) => {
  return (
    <>
      <div className="mt-6 text-[14px] font-semibold tracking-widest text-neutral-500">
        OTHER MENU
      </div>
      {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          <Link  to={item.path} onClick={() => {setSelectedTab(item.path);setShowSidebar(prev=>!prev)}}>
          <div className={`flex items-center gap-3.5 font-semibold mt-3 text-[14px]`} onClick={() => setSelectedTab(item.text)}>
            <img src={item.icon} alt="" className="w-5 h-5" />
            <span>{item.text}</span>
          </div>
          </Link>
          {index < menuItems.length - 1 && (
            <div className="mt-1.5 h-px bg-zinc-100" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default OtherMenu;
