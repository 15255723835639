import axios from 'axios';
import store, { RootState, AppDispatch } from '../../../store';
import { logout } from '../../../store/auth/authThunk';
import { getBaseUrl } from '../environment-url/environment-url';

const API_SPORT_FEED = axios.create({
  baseURL: getBaseUrl(
    process.env.REACT_APP_NODE_ENV,
    'REACT_APP_SPORT_FEED_URL'
  ),
  responseType: 'json',
  withCredentials: false,
  timeout: 1000 * 20, // 20 seconds
});

// Add a response interceptor
API_SPORT_FEED.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('***********Unauthorized Error**********');
      const token = sessionStorage.getItem('jwt_token');
      if (token) {
        const dispatch: AppDispatch = store.dispatch;
        dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default API_SPORT_FEED;
