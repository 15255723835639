import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import Layout from './components/Desktop/Layout/Layout';
import LoginView from './views/LoginView';
import SignUpView from './views/SignUpView';
import ForgotPasswordView from './views/ForgotPasswordView';
import AcceptTerms from './components/Desktop/AcceptTerms/AcceptTerms';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CasinoIframe from './components/Desktop/LiveCasino/CasinoIframe';
import SportsbookIframe from './components/Sportsbook/SportsbookIframe';
import { useState } from 'react';

const rootLevelRoutes = [
  {
    path: '/login',
    component: LoginView
  },
  {
    path: '/signup',
    component: SignUpView
  },
  {
    path: '/forgotpassword',
    component: ForgotPasswordView
  },
  {
    path: '/accept-terms',
    component: AcceptTerms
  },
  {
    path: "/dc/gamev1.1/:gamePath",
    component: CasinoIframe,
  },
  {
    path: "/sportsbook",
    component: SportsbookIframe
  }
]

function App() {
  const [selectedTab, setSelectedTab] = useState("Exchange");
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {
              rootLevelRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  Component={route.component}
                />
              ))
            }
            <Route
              key={'login'}
              path='*'
              Component={Layout}
            />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </Provider>
    </div>
  );
}

export default App;
