import React, { useState } from 'react';

type LiveStreamProps = {
  eventID: string;
  providerUrl: string;
};

const LiveStream: React.FC<LiveStreamProps> = (props) => {
  const { eventID, providerUrl } = props;

  return (
    <div className="cricket-live-stream-ctn">
      {providerUrl && providerUrl !== "-" ? (
        <iframe
          id="fp_embed_player"
          src={providerUrl}
          scrolling="no"
          allowFullScreen={true}
        ></iframe>
      ) : (
        <iframe
          title="mob-live-stream"
          allowFullScreen={false}
          src={`https://vid.dreamcasino.live/GetAPI.html?MatchID=${eventID}`}
          sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
        ></iframe>
      )}
    </div>
  );
};

export default LiveStream;
