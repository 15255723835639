/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface FormFieldProps {
  field: any;
}

const FormField: React.FC<FormFieldProps> = (props) => {
  const { field } = props;
  const { name, label, value, onHandleChange } = field;

  return (
    <>
      <div className="px-2">
        <label
          htmlFor={name}
          className="mt-4 w-full text-[14px] font-medium text-stone-900"
        >
          {label}
        </label>
        <input
          type="text"
          id={name}
          name={name}
          value={value}
          onChange={(e) => onHandleChange(e)}
          className="px-3 py-2 h-[44px] mt-2 w-full text-[14px] font-medium whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200 text-stone-900"
          placeholder={"Enter " + label}
        />
      </div>
    </>
  );
};

export default FormField;
