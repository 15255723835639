import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="px-6 py-12 bg-white rounded shadow-md text-center">
                <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
                <p className="text-2xl text-gray-600 mb-8">Page Not Found</p>
                <Link to="/" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
                    Go Home
                </Link>
            </div>
        </div>
    );
};

export default NotFoundPage;
