import axios from "axios";
import { getBaseUrl } from "../environment-url/environment-url";
import store, { AppDispatch } from "../../../store";
import { logout } from "../../../store/auth/authThunk";

const AGPAY_API = axios.create({
  baseURL: getBaseUrl(
    process.env.REACT_APP_NODE_ENV,
    "REACT_APP_REST_AGPAY_URL"
  ),
  responseType: "json",
  withCredentials: false,
  timeout: 10000, // 10 seconds
});

AGPAY_API.interceptors.request.use(
  (config) => {
    const authToken = sessionStorage.getItem("jwt_token");

    if (authToken) {
      config.headers.Authorization = authToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//Add a response interceptor
AGPAY_API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("***********Unauthorized Error**********");
      const token = sessionStorage.getItem("jwt_token");
      if (token) {
        const dispatch = store.dispatch as AppDispatch;
        dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default AGPAY_API;
