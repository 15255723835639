/**
 * This code was generated by Builder.io.
 */
import React from "react";
import EventCategory from "./EventCategory";
import EventItem from "./EventItem";
import { EventDTO } from "../../../models/common/EventDTO";

const eventCategories = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b88f6871455a8bd38fc9bbf6dd3ff47dcdf2c8bedf1c3d04ae1f1a0bebce99f9?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c",
    name: "Cricket",
    events: [
      {
        isLive: true,
        name: "New Zealand T10 vs South Africa T10",
        time: "LIVE",
      },
      {
        isLive: false,
        name: "New Zealand T10 vs South Africa T10",
        time: "Today 8:30 AM",
      },
      {
        isLive: false,
        name: "New Zealand T10 vs South Africa T10",
        time: "Today 8:30 AM",
      },
      {
        isLive: false,
        name: "New Zealand T10 vs South Africa T10",
        time: "Today 8:30 AM",
      },
      {
        isLive: false,
        name: "New Zealand T10 vs South Africa T10",
        time: "Today 8:30 AM",
      },
    ],
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/15f6e28c2490ccf288b257621b003fda90da1e64d4d0ecc71c5dd29b171a9c02?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c",
    name: "Football",
    events: [
      {
        isLive: true,
        name: "New Zealand T10 vs South Africa T10",
        time: "LIVE",
      },
      {
        isLive: false,
        name: "New Zealand T10 vs South Africa T10",
        time: "Today 8:30 AM",
      },
    ],
  },
];

type InplayEventsObj = {
  sportId: string;
  sportName: string;
  sportSlug: string;
  events: EventDTO[];
};

type EventlistProp = {
  inplayEvents: InplayEventsObj[];
}

const EventList: React.FC<EventlistProp> = ({inplayEvents}) => {
  return (
    <div className="flex flex-col py-px mt-1.5 w-full bg-white border border-solid border-neutral-200 max-md:max-w-full">
      {inplayEvents.map((iEvent, index) => (
        <div className=" mb-3" key={index}>
          <EventCategory  name={iEvent?.sportName} />
          {iEvent.events.map((match, eventIndex) => (
            <React.Fragment key={eventIndex}>
              <EventItem match={match} iEvent={iEvent} />
              {eventIndex < iEvent.events.length - 1 && (
                <div className="mt-1.5 w-full border border-solid border-neutral-200 min-h-[1px] max-md:max-w-full" />
              )}
            </React.Fragment>
          ))}
          {index < eventCategories.length - 1 && (
            <div className="mt-1.5 w-full border border-solid border-neutral-200 min-h-[1px] max-md:max-w-full" />
          )}
        </div>
      ))}
    </div>
  );
};

export default EventList;
