/**
 * This code was generated by Builder.io.
 */
import React from "react";
import backGroundImg from "../../../../assets/AuthScreens/Background.webp"

const BackgroundImage: React.FC = () => {
  return (
    <img
      loading="lazy"
      src={backGroundImg}
      className="object-cover absolute inset-0 size-full"
      alt=""
    />
  );
};

export default BackgroundImage;
