/**
 * This code was generated by Builder.io.
 */
import React from "react";

import purple from '../../../assets/purple_bg.png'


const TermsAndConditions: React.FC = () => {
  return (
    <div className=" bg-white pb-3 text-[14px] font-normal mt-3">
       <div className="flex flex-col justify-center items-start self-stretch w-full bg-stone-900">
        <div
          style={{ backgroundImage: `url(${purple})` }}
          className=" bg-no-repeat aspect-[9.1] w-[282px] h-[31px] leading-[31px] pl-4  font-medium text-white"
        >
          Terms & Conditions
        </div>
      </div>
      <h2 className=" h-[31px] font-medium leading-[31px] text-[14px] pl-3 whitespace-nowrap bg-neutral-200 text-stone-900 max-md:max-w-full">
        Introduction
      </h2>
      <div className="mt-3.5 text-[14px] text-black max-md:max-w-full">
        <p className=" font-[400] text-[14px] leading-[18.68px] text-sm text-stone-900 px-3">
          The following terms and conditions
          apply to your use of this website (the "website") and its related or
          connected services (collectively, the "service"). You should carefully
          review these terms as they contain important information concerning
          your rights and obligations relating to your use of the website,
          whether as a guest or registered user with an account (an "account").
          By accessing this website and using the service you agree to be bound
          by these terms together with any amendments which may be published
          from time to time by us. If you do not accept these terms, you must
          not access this website or use any part of it.
        </p>
        <h2 className=" h-[31px] text-[14px] leading-[31px] mt-3 text-[14px] pl-3 font-medium whitespace-nowrap bg-neutral-200 text-stone-900 max-md:max-w-full">
          General Terms
        </h2>
        <p className=" font-[400] text-[14px] leading-[18.68px] text-sm text-stone-900 mt-4 px-3">
          We reserve the right to revise and amend these terms of service at any
          time. You should visit this page periodically to review the terms and
          conditions. Any such changes will be binding and effective immediately
          upon publication on this website, unless you object to any such
          changes, in which case you must stop using our services. Your
          continued use of our website following such publication will indicate
          your agreement to be bound by the terms as amended. Any bets not
          settled prior to such changes taking effect will be subject to the
          pre-existing terms.
        </p>
        <h2 className=" h-[31px] text-[14px] leading-[31px] mt-3 text-[14px] pl-3 font-medium whitespace-nowrap bg-neutral-200 text-stone-900 max-md:max-w-full">
          Your Obligation
        </h2>
        
        <p className="text-sm px-3 font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-4">
          You Acknowledge That At All Times When Accessing The Website And Using
          The Service:
        </p>
        <div className=" px-3">
        <ul className="list-disc pl-6 mt-2 font-[400] text-[14px] leading-[18.68px]">
          <li className="text-sm leading-5 text-stone-900">
            3.1. You must be 18 years or older, or at least the legal age of
            majority in the jurisdiction where you live, to participate in any
            of our games. We reserve the right to ask for proof of age documents
            at any time.
          </li>
          <li className="text-sm leading-5 text-stone-900 mt-2">
            3.2. You Are Of Legal Capacity And Can Enter Into A Binding Legal
            Agreement With Us. You Must Not Access The Website Or Utilize The
            Service If You Are Not Of Legal Capacity.
          </li>
          <li className="text-sm leading-5 text-stone-900 mt-2">
            3.3. You Are A Resident In A Jurisdiction That Allows Gambling. You
            Are Not A Resident Of Any Country In Which Access To Online Gambling
            To Its Residents Or To Any Person Within Such Country Is Prohibited.
            It Is Your Sole Responsibility To Ensure That Your Use Of The
            Service Is Legal.
          </li>
          <li className="text-sm leading-5 text-stone-900 mt-2">
            3.4. You May Not Use A VPN, Proxy Or Similar Services Or Devices
            That Mask Or Manipulate The Identification Of Your Real Location.
          </li>
          <li className="text-sm leading-5 text-stone-900 mt-2">
            3.5. You Are The Authorized User Of The Payment Method You Use.
          </li>
          <li className="text-sm leading-5 text-stone-900 mt-2">
            3.6. You Must Make All Payments To Us In Good Faith And Not Attempt
            To Reverse A Payment Made Or Take Any Action Which Will Cause Such
            Payment To Be Reversed By A Third Party.
          </li>
          <li className="text-sm leading-5 text-stone-900 mt-2">
            3.7. When Placing Bets You May Lose Some Or All Of Your Money
            Deposited To The Service In Accordance With These Terms And You Will
            Be Fully Responsible For That Loss.
          </li>
          <li className="text-sm leading-5 text-stone-900 mt-2">
            3.8. When Placing Bets You Must Not Use Any Information Obtained In
            Breach Of Any Legislation In Force In The Country In Which You Were
            When Family Members, Are Not Registered As An Affiliate In Our
            Affiliate Program.
          </li>
        </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
