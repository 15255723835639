/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React, { useEffect } from "react";
import { TransactionMap } from "../../../store/reports/reportSelectors";

interface MyTransactionTableProps {
  records: any;
}

const MyTransactionTable: React.FC<MyTransactionTableProps> = (props) => {
  const { records } = props;
  const headings = [
    "Transaction Time",
    "Transaction ID",
    "Transaction Type",
    "Amount",
    "Approved Amount",
    "Transaction Status",
    "Notes",
    "Payment Method",
  ];

  return (
    <>
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500">
            {headings.map((heading, index) => (
              <th
                key={index}
                className={`px-2 py-1 border-r font-[500] text-[14px] leading-[16.2px] ${
                  index < headings.length - 1 ? "border-[#757575]" : ""
                }`}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records?.map((row, index) => (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {moment(row?.startTime).format("DD-MM-YY, h:mm:ss A")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.transactionId}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.transactionType}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.amount.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.approvedAmount ? row.approvedAmount : "-"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.bonus_status}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {TransactionMap[row.txStatus]
                  ? TransactionMap[row.txStatus]
                  : row?.txStatus}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.notes}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.paymentMethod == "UNRECOGNIZED"
                  ? "-"
                  : row?.paymentMethod}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {records && records.length === 0 && (
        <div className=" h-12 flex justify-center items-center">No transactions</div>
      )}
    </>
  );
};

export default MyTransactionTable;
