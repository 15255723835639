/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React from "react";
import { isMobile } from "react-device-detect";

interface MatchInfoProps {
  awayTeam: string;
  homeTeam: string;
  openDate: Date
}
const MatchInfo: React.FC<MatchInfoProps> = (props) => {
  const { awayTeam, homeTeam, openDate } = props;

  return (
    <React.Fragment>
      {
        isMobile ?
          <div className="flex justify-start items-center py-1 text-center mx-2 rounded-md overflow-hidden border border-solid border-neutral-200 text-stone-900 max-md:px-[4px] max-md:max-w-full bg-[#fff] ">
            <div className="flex gap-5 justify-center items-center max-md:flex-wrap">
              <div className="flex flex-col self-stretch px-8 py-0.5 text-white rounded bg-neutral-500 max-md:px-5">
                <div className="text-base font-semibold tracking-widest uppercase">
                  {moment(openDate).format('hh : mm A')}
                </div>
                <div className="text-[14px] font-medium">
                  {moment(openDate).format('DD MMM YYYY')}
                </div>
              </div>
              <div className="text-start">
                <div className="self-stretch my-auto text-[14px] font-medium capitalize">
                  {homeTeam}
                </div>
                <div className="self-stretch my-auto text-[14px] font-medium capitalize">
                  {awayTeam}
                </div>
              </div>
            </div>
          </div>
          :
          <div className="flex justify-center items-center px-16 py-1 text-center bg-white rounded-md border border-solid border-neutral-200 text-stone-900 max-md:px-5 max-md:max-w-full">
            <div className="flex gap-5 justify-between items-center max-md:flex-wrap">
              <div className="self-stretch my-auto text-sm font-medium capitalize">
                {homeTeam}
              </div>
              <div className="flex flex-col self-stretch px-5 py-0.5 text-white rounded bg-neutral-500 max-md:px-5">
                <div className="text-base font-semibold tracking-widest uppercase">
                  {moment(openDate).format('hh : mm A')}
                </div>
                <div className="text-[14px] font-medium">
                  {moment(openDate).format('DD MMM YYYY')}
                </div>
              </div>
              <div className="self-stretch my-auto text-sm font-medium capitalize">
                {awayTeam}
              </div>
            </div>
          </div>
      }
    </React.Fragment>
  );
};

export default MatchInfo;
