/**
 * This code was generated by Builder.io.
 */
import React from "react";
import NavigationBar from "./NavigationBar";
import { DEPOSIT_PAYMENT_TYPES } from "../utils/types";

interface NavigationBarWrapperProps {
  selectedPaymentType: DEPOSIT_PAYMENT_TYPES | string;
  setSelectedPaymentType: (type: DEPOSIT_PAYMENT_TYPES) => void;
}

const NavigationBarWrapper: React.FC<NavigationBarWrapperProps> = ({
  selectedPaymentType,
  setSelectedPaymentType,
}) => {
  return (
    <header className="flex flex-col text-[14px] font-medium leading-none text-center whitespace-nowrap text-neutral-500">
      <NavigationBar
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
      />
    </header>
  );
};

export default NavigationBarWrapper;
