/**
 * This code was generated by Builder.io.
 */
import React, { FormEvent } from "react";
import PaymentCard from "./PaymentCard";
import AddNewAccount from "./AddNewAccount";
import PaymentOptionTabMob from "./PaymentOptionTabMob";
import {
  addAccountFormArr,
  addUpiFormArr,
  withdrawFormArr,
} from "../../../Desktop/Withdraw/utils/Withdraw.utils";
import {
  AddAccountFormType,
  AddUpiAccountFormType,
  WithdrawComponentProps,
  WithdrawFormType,
} from "../../../Desktop/Withdraw/utils/types";
import { PAYMENT_METHODS } from "../../../Desktop/MyProfile/ProfileTabs/utils/types";

const PaymentForm: React.FC<WithdrawComponentProps> = ({
  currentSelectedAccount,
  addAccountFormData,
  handleAddAccountFormChange,
  handleWithdrawFormChange,
  isWithdrawSaveBtnDisabled,
  onAccountDeleteClick,
  onAddAccountSaveBtnClick,
  onAddNewAccountPress,
  onSavedAccountCardClick,
  onWithdrawFormSave,
  paymentMethodList,
  resetForms,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  showAddAccountForm,
  withdrawFormData,
  isAddAccountSaveBtnDisabled,
  addUpiAccountFormData,
  handleAddUpiAccountFormChange,
}) => {
  return (
    <div className="flex flex-col  mt-4 mb-32 w-full text-[14px] font-medium">
      <PaymentOptionTabMob
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
      />
      <div className="mt-3 w-full text-[14px] font-medium text-neutral-500">
        Enter following details to deposit amount:
      </div>
      <div className="mt-4 w-full">
        <div className="flex gap-5 max-md:flex-row overflow-scroll">
          {paymentMethodList.length > 0 &&
            paymentMethodList.map((currentPaymentMethod) => {
              const { id } = currentPaymentMethod;
              return (
                <PaymentCard
                  key={id}
                  currentPaymentMethod={currentPaymentMethod}
                  onAccountDeleteClick={onAccountDeleteClick}
                  onSavedAccountCardClick={onSavedAccountCardClick}
                />
              );
            })}

          <AddNewAccount onClick={onAddNewAccountPress} />
        </div>

        {showAddAccountForm ? (
          <AddAccountFields
            selectedPaymentMethod={selectedPaymentMethod}
            addUpiAccountFormData={addUpiAccountFormData}
            handleAddUpiAccountFormChange={handleAddUpiAccountFormChange}
            addAccountFormData={addAccountFormData}
            handleAddAccountFormChange={handleAddAccountFormChange}
            isSaveBtnDisabled={isAddAccountSaveBtnDisabled}
            onSave={onAddAccountSaveBtnClick}
            resetForms={resetForms}
          />
        ) : (
          <>
            {currentSelectedAccount && (
              <WithdrawFormFields
                withdrawFormData={withdrawFormData}
                handleWithdrawFormChange={handleWithdrawFormChange}
                isWithdrawSaveBtnDisabled={isWithdrawSaveBtnDisabled}
                resetForms={resetForms}
                onSave={onWithdrawFormSave}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

interface WithdrawFormFieldsProps {
  withdrawFormData: WithdrawFormType;
  handleWithdrawFormChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  isWithdrawSaveBtnDisabled: boolean;
  resetForms: () => void;
  onSave: (e: React.FormEvent<HTMLFormElement>) => void;
}

interface AddAccountFieldsProps {
  resetForms: () => void;
  onSave: (e: React.FormEvent<HTMLFormElement>) => void;
  addAccountFormData: AddAccountFormType;
  handleAddAccountFormChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  isSaveBtnDisabled: boolean;
  handleAddUpiAccountFormChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  addUpiAccountFormData: AddUpiAccountFormType;
  selectedPaymentMethod: PAYMENT_METHODS;
}

const WithdrawFormFields: React.FC<WithdrawFormFieldsProps> = ({
  withdrawFormData,
  handleWithdrawFormChange,
  isWithdrawSaveBtnDisabled,
  resetForms,
  onSave,
}) => {
  return (
    <form
      onSubmit={onSave}
      className="flex flex-col  mt-4 mb-32 w-full text-[14px] font-medium"
    >
      <div className="text-neutral-500 mt-[10px]">Enter Payment details</div>
      <div className="flex flex-col	px-[10px]">
        {withdrawFormArr.map((field) => {
          const { key, label, placeholder, type } = field;
          return (
            <React.Fragment key={key}>
              <label htmlFor={key} className="mt-5 text-stone-900">
                {label}
              </label>
              <input
                value={withdrawFormData[key]}
                id={key}
                type={type}
                className="px-3 py-2 mt-3 rounded-md border border-solid bg-zinc-100 border-neutral-200 text-stone-900"
                placeholder={placeholder}
                onChange={handleWithdrawFormChange}
              />
            </React.Fragment>
          );
        })}
        <div className="flex gap-3.5 px-5 justify-center mt-3 w-full text-[14px] font-medium whitespace-nowrap">
          <button
            type="reset"
            onClick={resetForms}
            className="flex-1 px-16 py-3 text-black rounded-md border border-fuchsia-700 border-solid"
          >
            Reset
          </button>
          <button
            type="submit"
            className={`flex-1 px-16 py-3  rounded-md text-white ${
              isWithdrawSaveBtnDisabled ? "bg-fuchsia-400" : "bg-fuchsia-700"
            } `}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

const AddAccountFields: React.FC<AddAccountFieldsProps> = ({
  resetForms,
  onSave,
  addAccountFormData,
  handleAddAccountFormChange,
  isSaveBtnDisabled,
  selectedPaymentMethod,
  addUpiAccountFormData,
  handleAddUpiAccountFormChange,
}) => {
  return (
    <form
      onSubmit={onSave}
      className="flex flex-col  mt-4 mb-32 w-full text-[14px] font-medium"
    >
      <div className="text-neutral-500 mt-[10px]">Enter Payment details</div>
      <div className="flex flex-col	px-[10px]">
        {selectedPaymentMethod === PAYMENT_METHODS.UPI ? (
          <>
            {addUpiFormArr.map((field) => {
              const { key, label, placeholder, type } = field;
              return (
                <React.Fragment key={key}>
                  <label htmlFor={key} className="mt-5 text-stone-900">
                    {label}
                  </label>
                  <input
                    value={addUpiAccountFormData[key]}
                    id={key}
                    type={type}
                    className="px-3 py-2 mt-3 rounded-md border border-solid bg-zinc-100 border-neutral-200 text-stone-900"
                    placeholder={placeholder}
                    onChange={handleAddUpiAccountFormChange}
                  />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <>
            {addAccountFormArr.map((field) => {
              const { key, label, placeholder, type } = field;
              return (
                <React.Fragment key={key}>
                  <label htmlFor={key} className="mt-5 text-stone-900">
                    {label}
                  </label>
                  <input
                    value={addAccountFormData[key]}
                    id={key}
                    type={type}
                    className="px-3 py-2 mt-3 rounded-md border border-solid bg-zinc-100 border-neutral-200 text-stone-900"
                    placeholder={placeholder}
                    onChange={handleAddAccountFormChange}
                  />
                </React.Fragment>
              );
            })}
          </>
        )}

        <div className="flex gap-3.5 px-5 justify-center mt-3 w-full text-[14px] font-medium whitespace-nowrap">
          <button
            type="reset"
            onClick={resetForms}
            className="flex-1 px-16 py-3 text-black rounded-md border border-fuchsia-700 border-solid"
          >
            Reset
          </button>
          <button
            type="submit"
            disabled={isSaveBtnDisabled}
            className={`flex-1 px-16 py-3 rounded-md text-white ${
              isSaveBtnDisabled ? "bg-fuchsia-400" : "bg-fuchsia-700"
            } `}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default PaymentForm;
