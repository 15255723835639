/**
 * This code was generated by Builder.io.
 */
import React from "react";

interface SearchProps {
  searchTerm: string;
  setSearchTerm: Function;
}

const SearchBar: React.FC<SearchProps> = (props) => {
  const { searchTerm, setSearchTerm } = props;
  return (
    <form className="flex flex-col justify-center items-start py-2 mt-2.5 max-w-full text-[14px] border border-solid bg-zinc-100 border-neutral-200 rounded-[10000px] text-neutral-500 w-[406px]">
      <div className="flex gap-2">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/9bab1e4cb0e5b462be013d382d7c24497d554ddc3bc6405089a8b8cef41db6da?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
          alt=""
          className="shrink-0 self-start w-3.5 aspect-square"
        />
        <label htmlFor="searchInput" className="sr-only">
          Search Games
        </label>
        <input
          type="text"
          id="searchInput"
          placeholder="Search Games"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="bg-transparent border-none outline-none"
        />
      </div>
    </form>
  );
};

export default SearchBar;
