/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import TransactionFilter from "./TransactionFilter";
import TransactionList from "./TransactionList";
import purple from "../../../../assets/purple_bg.png";
interface AccountStatement {
  records: any;
  fetchRecords: Function;
  handleDateChange: Function;
  filters: any;
  setFilters: any;
  nextPage: Function;
  prevPage: Function;
  pageToken: string;
}

const AccountStatement: React.FC<AccountStatement> = (props) => {
  const {
    handleDateChange,
    filters,
    pageToken,
    nextPage,
    prevPage,
    setFilters,
    records,
    fetchRecords,
  } = props;
  return (
    <div className="flex overflow-hidden flex-col mx-auto w-full bg-zinc-100 max-w-[480px] transition ease-in-out duration-[2000ms]">
      <main className="flex flex-col pt-px mt-[12px] w-full bg-white ">
        <div className="bg-[black]">
          <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
            <div className="flex flex-col justify-center w-[55%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
              <div className="flex min-w-[170px]">
                <div className=" bg-[#804091] h-8 w-full py-2 pr-4 pl-2">
                  Account Statement
                </div>
                <div className=" skew-x-[-25deg] bg-[#804091] h-8 -translate-x-[7px] w-4"></div>
                <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-8 -translate-x-1"></div>
                <div className=" skew-x-[-25deg] bg-[#804091] w-1 h-8"></div>
              </div>
            </div>
          </div>
        </div>
        <TransactionFilter
          handleDateChange={handleDateChange}
          filters={filters}
          setFilters={setFilters}
        />
        <TransactionList records={records} />
        <div className=" flex justify-center">
          {filters?.pageToken.length > 0 && (
            <button
              className=" p-2 w-16 leading-[16.2px] text-white bg-fuchsia-800 m-2"
              onClick={(e) => prevPage()}
            >
              Prev({filters.pageToken.length})
            </button>
          )}
          {pageToken ? (
            <button
              className=" p-2 w-16 leading-[16.2px] text-white bg-fuchsia-800 m-2"
              onClick={(e) => nextPage()}
            >
              Next({filters?.pageToken.length + 2})
            </button>
          ) : null}
        </div>
      </main>
    </div>
  );
};

export default AccountStatement;
