/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { login } from "../../../store/auth/authThunk";
import login_bg from "../../../assets/login_bg.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import CommonBackImg from "../ForgotPassword/ForGotPasswordImg/CommonBackImgAuthScreens"
import Loader from "../../Loader/Loader";

type FormValueDTO = {
  username: string;
  password: string;
  code: string;
};
type SignInProps = {
  handleSubmit: Function;
  handleDemoLogin: Function;
  redirectToSignup: Function;
  redirectToForgotPassword: Function;
  handleChange: Function;
  formValues: FormValueDTO;
  formErrors: FormValueDTO;
  demoLoading: boolean;
  loading: boolean;
  loadLogin: boolean;
  showPasswordClickHandler: Function;
  showPassword: boolean;
  redirectToHome: Function;
};

const SignInForm: React.FC<SignInProps> = (props) => {
  const {
    handleDemoLogin,
    handleSubmit,
    redirectToSignup,
    redirectToForgotPassword,
    formErrors,
    formValues,
    handleChange,
    demoLoading,
    loadLogin,
    loading,
    showPasswordClickHandler,
    showPassword,
    redirectToHome
  } = props;

  return (
    <main className="flex items-center flex-col w-[100%]  h-[100vh]">
    <CommonBackImg redirectToHome={redirectToHome} tagline="Bet smarter, Bet with confidence, Win bigger: your ultimate bet experience" />
    <div className="relative w-[90%] mt-[15px] mb-[25px] flex z-10 flex-col self-center px-4  text-sm font-medium bg-white rounded-2xl border-4 border-solid border-neutral-200 text-neutral-500">
      <h2 className="self-center text-3xl font-semibold text-center text-stone-900">
        Sign in
      </h2>
      <p className="self-center mt-[2px] text-base text-center">
        Please enter your login details here.
      </p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="mt-[34px]">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Enter your username"
            className="px-3 py-4 mt-[7px] w-full bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60"
            value={formValues.username}
            onChange={(e) => handleChange(e)}
          />
          {formErrors.username && (
            <div className="text-red-500 text-sm mt-1">
              {formErrors.username}
            </div>
          )}
        </div>
        <div className="mt-5">
          <label htmlFor="password">Password</label>
          <div className="flex gap-5 justify-between px-3.5 py-3 mt-[7px] bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Enter password"
              className="w-full bg-transparent border-none"
              value={formValues.password}
              onChange={(e) => handleChange(e)}
            />
            <div
              className="cursor-pointer"
              onClick={() => showPasswordClickHandler()}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
          {formErrors.password && (
            <div className="text-red-500 text-sm mt-1">
              {formErrors.password}
            </div>
          )}
          {formErrors?.code && (
            <div className="text-red-500 text-sm mt-1">
              {formErrors.code}
            </div>
          )}
        </div>
        <a
          href="#"
          className="block self-end mt-[10px] text-right text-stone-900"
          onClick={(e) => redirectToForgotPassword(e)}
        >
          Forgot Password?
        </a>
        <div className="flex w-[100%] justify-center gap-[12px] mt-6 text-base font-semibold text-center text-white">
          <button
            type="button"
            className="h-[44px] w-[144px] py-1 rounded-lg shadow-sm bg-stone-900"
            onClick={(e) => handleDemoLogin(e)}
          >
            Demo Login
            {demoLoading && <span className="ml-2"><Loader /></span>}
          </button>
          <button
            type="submit"
            className="w-[144px] h-[44px] py-1 whitespace-nowrap bg-fuchsia-800 rounded-lg shadow-sm"
          >
            Login
            {loading && loadLogin && <span className="ml-2"><Loader /></span>}
          </button>
        </div>
      </form>
      <p className="self-center mt-4 text-sm text-center">
        Don't have an account?{" "}
        <a
          href="#"
          className="font-semibold text-fuchsia-800 underline"
          onClick={(e) => redirectToSignup(e)}
        >
          Sign Up
        </a>
      </p>
    </div>
  </main>
  
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    // loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    // domainConfig: state.common.domainConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    // fetchBalance: () => dispatch(fetchBalance()),
    // loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    // loginFailed: (err: string) => dispatch(loginFailed(err)),
    // requestStart: () => dispatch(requestStart()),
    // requestEnd: () => dispatch(requestEnd()),
    loginUserThunk: (username: string, password: string) =>
      dispatch(login({ username, password })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
