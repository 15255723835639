import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import MultiMarkets from '../components/Mobile/MultiMarket/MultiMarket'
import purple from "../assets/purple_bg.png";
import { UserBet } from "../models/UserBet";
import { PlaceBetRequest } from "../models/BsData";
import { isPushNotificationConnected, subscribeWsForNotifications, subscribeWsForNotificationsPerAdmin, unsubscribePNWsforEvents } from "../webSocket/pnWebSocket";
import { useLocation } from "react-router-dom";
import { useMarketLocalState } from "../hooks/storageHook";
import SVLS_API from "../api/api-services/svls-api";
import { RootState } from "../store";
import { getHouseIdFromToken, getParentIdFromToken } from "../utils/commonUtils";
import { fetchMultiMarketEventData } from "../store/multiMarket/multiMarketThunks";
import { clearBetStatusResponse, clearExchangeBets, setBettingInprogress } from "../store/betSlip/betSlipSlice";
import { fetchOpenBets } from "../store/betSlip/betSlipThunk";
import { connect } from "react-redux";
import { getBookmakerMarketsByEvent, getSecondaryMarketsByEvent, getSecondaryMatchOddsByEvent } from "../store/catalog/catalogSelectors";

type StoreProps = {
  fetchOpenBets: (eventId: string[] | string, sportId?: string) => void;
  fetchMultiMarketEventData: () => void;
  marketData: any;
  secondaryMultiMatchOdds: any;
  secondaryMultiMarket: any;
  openBets: UserBet[];
  totalOrders: number;
  loggedIn: boolean;
  bets: PlaceBetRequest[];
  triggerFetchMarkets: number;
  triggerFetchOrders: number;
  houseId: string;
  parentId: string;
  accountId: string;
  triggerBetStatus: number;
  betStatusResponse: any;
  clearExchangeBets: () => void;
  setBettingInprogress: (val: boolean) => void;
  clearBetStatusResponse: () => void;
  topicUrls: any;
};

const MultiMarketView: React.FC<StoreProps> = (props) => {
  const {
    fetchMultiMarketEventData,
    marketData,
    secondaryMultiMatchOdds,
    secondaryMultiMarket,
    loggedIn,
    openBets,
    totalOrders,
    fetchOpenBets,
    bets,
    triggerFetchMarkets,
    triggerFetchOrders,
    houseId,
    parentId,
    accountId,
    triggerBetStatus,
    betStatusResponse,
    clearExchangeBets,
    setBettingInprogress,
    clearBetStatusResponse,
    topicUrls
  } = props;

  const [multiMarketData] = useMarketLocalState();
  const [openBetslip, setOpenBetslip] = useState<boolean>(true);
  const [tabVal, setTabVal] = useState(0);
  const isMobile = window.innerWidth > 1120 ? false : true;
  const [exposureMap, setExposureMap] = useState(null);
  const [enableFetchOrders, setEnableFetchOrders] = useState<boolean>(false);
  const pathLocation = useLocation();
  const [startTime, setStartTime] = useState<Date>();
  const [fetchOpenOrders, setFetchOpenOrders] = useState<number>(null);
  const [addNewBet, setAddNewBet] = useState<boolean>(true);
  const intervalRef = useRef(null);
  const isFirstRender = useRef(true);
  const isFirstRenderStartTime = useRef(true);
  
  useEffect(() => {
    fetchMultiMarketEventData();
  }, [triggerFetchMarkets]);

  useEffect(() => {
    if (multiMarketData?.length) {
      let eventIds = [];
      multiMarketData.forEach((item) => {
        eventIds.push(item?.eventId);
      });
      fetchOpenBets(eventIds);
      fetchOpenBetsRisk();
    }
    setEnableFetchOrders(true);
  }, [multiMarketData]);

  useEffect(() => {
    if (loggedIn && enableFetchOrders && multiMarketData?.length) {
      let eventIds = [];
      multiMarketData.forEach((item) => {
        eventIds.push(item?.eventId);
      });
      setTimeout(() => {
        fetchOpenBets(eventIds);
        fetchOpenBetsRisk();
      }, 1000);
    }
    // }, [loggedIn, triggerFetchOrders, fetchOpenOrders]);
  }, [loggedIn, triggerFetchOrders]);

  useEffect(() => {
    if (totalOrders && !isMobile) {
      setTabVal(1);
    }
  }, [totalOrders]);
  useEffect(() => {
    if (bets.length > 0) {
      setTabVal(0);
    }
  }, [bets]);

  useEffect(() => {
    if (loggedIn) {
      unsubscribePNWsforEvents(houseId);
      unsubscribePNWsforEvents(parentId);
      subscribeWsForNotificationsPerAdmin(true, houseId, parentId, accountId);
      subscribeWsForNotifications(true, houseId);
      return () => {
        unsubscribePNWsforEvents(houseId);
        unsubscribePNWsforEvents(parentId);
        if (!window.location.pathname.includes('multi-market')) {
          subscribeWsForNotificationsPerAdmin(
            false,
            houseId,
            parentId,
            accountId
          );
          subscribeWsForNotifications(false, houseId);
        }
      };
    }
  }, [isPushNotificationConnected(), loggedIn]);

  const fetchOpenBetsRisk = async () => {
    try {
      if (!multiMarketData?.length) {
        return;
      }

      let eventIds = [];
      let marketIds = [];
      multiMarketData.forEach((item) => {
        eventIds.push(item?.eventId);
        marketIds.push(item?.marketId);
      });

      const response = await SVLS_API.get(
        '/reports/v2/risk-management/user-risk',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            eventId: Array.isArray(eventIds) ? eventIds.join(',') : eventIds,
            marketId: Array.isArray(marketIds)
              ? marketIds.join(',')
              : marketIds,
          },
        }
      );
      setExposureMap(response.data.marketExposureMap);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="text-[18px] font-bold ">
        Multi Market
      </div>
      {
        multiMarketData?.length &&
          marketData &&
          Object.keys(marketData)?.length ? (
          Object.keys(marketData).map((item) => {
            return (
              <MultiMarkets
                key={item}
                topicUrls={topicUrls}
                loggedIn={loggedIn}
                eventData={marketData[item] ?? {}}
                secondaryMarkets={getSecondaryMarketsByEvent(
                  secondaryMultiMarket,
                  item
                )}
                secondaryMatchOdds={getSecondaryMatchOddsByEvent(
                  secondaryMultiMatchOdds,
                  item
                )}
                bmMData={getBookmakerMarketsByEvent(
                  secondaryMultiMarket,
                  item
                )}
                eventId={marketData[item]?.eventId}
                isMultiMarket={true}
                exposureMap={exposureMap ? exposureMap : null}
              />
            );
          })
        ) : null
        // : < MultiMarketDesktop />
      }
      {
        (multiMarketData?.length === 0 || Object.keys(marketData)?.length === 0) && (
          <div className="no-data multimarket-no-data">
            There are currently no followed multi markets.
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    openBets: state.betSlip.openBets,
    totalOrders: state.betSlip.totalOrders,
    loggedIn: state.auth.loggedIn,
    secondaryMultiMarket: state.multiMarket.secondaryMultiMarketsMap,
    secondaryMultiMatchOdds: state.multiMarket.secondaryMultiMatchOddsMap,
    bets: state.betSlip.bets,
    marketData: state.multiMarket.multiMarketData,
    triggerFetchMarkets: state.multiMarket.triggerFetchMarkets,
    triggerFetchOrders: state.multiMarket.triggerFetchOrders,
    houseId: getHouseIdFromToken(),
    parentId: getParentIdFromToken(),
    accountId: sessionStorage.getItem('aid'),
    triggerBetStatus: state.multiMarket.triggerBetStatus,
    betStatusResponse: state.betSlip.betStatusResponse,
    topicUrls: state.catalog.topicUrls
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchOpenBets: (eventId: string[] | string, sportId?: string) =>
      dispatch(fetchOpenBets({ eventId, sportId })),
    fetchMultiMarketEventData: () => dispatch(fetchMultiMarketEventData()),
    clearExchangeBets: () => dispatch(clearExchangeBets()),
    setBettingInprogress: (val: boolean) => dispatch(setBettingInprogress(val)),
    clearBetStatusResponse: () => dispatch(clearBetStatusResponse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiMarketView);
