import React, { useEffect, useState } from "react";
import { isMobile, isDesktop } from "react-device-detect";
import Header from "../components/Desktop/HeaderSection/Header";
import Sidebar from "../components/Desktop/Sidebar/Sidebar";
import MainContent from "../components/Desktop/MainContent/UserRouter";
import MobHeader from "../components/Mobile/HeaderSection/MobHeader";
import CategoryNavigation from "../components/Mobile/HeaderSection/CategoryNavigation";
import MainContentMobile from "../components/Mobile/MainContent/MainContentMobile";
import MobileSidebar from "../components/Mobile/Sidebar/Sidebar";
import BottomTabNav from "../components/Mobile/BottomTabNavigation/BottomTabNav";
import Footer from "../components/Desktop/Footer/Footer";

const SignUpView: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("Exchange");
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      {isMobile ?
        <>
          <MobileSidebar setShowSidebar={setShowSidebar} classProp={` absolute ${showSidebar ? ' left-[0]' : ' left-[-224px]'}`} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <div onClick={() => { showSidebar && setShowSidebar(false) }} className={` pb-[70px] ${showSidebar ? 'blur-md h-screen overflow-hidden ' : 'do-nothing'}`}>
            <div className="sticky top-0 h-fit z-20">
              <MobHeader setShowSideBar={setShowSidebar} />
              <CategoryNavigation />
            </div>
            <MainContentMobile selectedTab={selectedTab} />
          </div>
          {!showSidebar && <BottomTabNav />}
        </> :
        <>
          <Header selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <div className="flex w-full">
            <Sidebar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <MainContent selectedTab={selectedTab} />
          </div>
        </>
      }
    </>
  );
};

export default SignUpView;
