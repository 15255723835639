import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import LiveCasinoDesktop from "../components/Desktop/LiveCasino/LiveCasino";
import LiveCasinoMobile from "../components/Mobile/LiveCasino/WinBuzzGaming";
import { RootState } from "../store";
import { categories } from "../store/catalog/catalogSelectors";
import { getIndianCasinoGames, getSelectedCategoryGames } from "../store/catalog/catalogThunk";
import Modal from "../components/Modal/Modal";

interface IndianCasinoViewProps {
  games: any;
  initGames: any;
  casinoGames: any;
  providerList: any;
  categoryList: any;
  getIndianCasinoGames: Function;
  loggedIn: boolean;
  getSelectedCategoryGames: Function;
}

const IndianCasinoView: React.FC<IndianCasinoViewProps> = (props) => {
  const {
    games,
    getIndianCasinoGames,
    getSelectedCategoryGames,
    initGames,
    casinoGames,
    loggedIn,
    providerList,
    categoryList
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("All");
  const [selectedGameType, setSelectedGameType] = useState("All");
  const [gamesDisplay, setGamesDisplay] = useState([]);
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const navigate = useNavigate();

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider: string
  ) => {
    if (loggedIn) {
      navigate(
        `/dc/gamev1.1/${gameName.toLowerCase().replace(/\s+/g, "-")}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    } else {
      setDialogShow(true);
    }
  };

  useEffect(() => {
    getSelectedCategoryGames({selectedGameType, selectedProvider, searchTerm, setGamesDisplay, games});
  }, [selectedGameType, selectedProvider, searchTerm]);

  useEffect(() => {
    setGamesDisplay(games);
  }, [games]);

  useEffect(() => {
    getIndianCasinoGames();
  }, []);

  return (
    <>
      {isMobile ? (
        <LiveCasinoMobile
          title="Indian Casino"
          games={gamesDisplay}
          setDialogShow={setDialogShow}
          dialogShow={dialogShow}
          providerList={providerList}
          categoryList={categoryList}
          selectedProvider={selectedProvider}
          selectedGameType={selectedGameType}
          setSelectedGameType={setSelectedGameType}
          setSelectedProvider={setSelectedProvider}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleGameClick={getGameUrl}
        />
      ) : (
        <LiveCasinoDesktop
          categoryList={categoryList}
          providerList={providerList}
          title="Indian Casino"
          games={gamesDisplay}
          setDialogShow={setDialogShow}
          dialogShow={dialogShow}
          selectedProvider={selectedProvider}
          selectedGameType={selectedGameType}
          setSelectedGameType={setSelectedGameType}
          setSelectedProvider={setSelectedProvider}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleGameClick={getGameUrl}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    games: state.catalog.games,
    initGames: state.catalog.initGames,
    casinoGames: state.catalog.casinoGames,
    providerList: state.catalog.providerList,
    categoryList: state.catalog.categoryList,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getIndianCasinoGames: () => dispatch(getIndianCasinoGames()),
    getSelectedCategoryGames: (event: boolean) => dispatch(getSelectedCategoryGames(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndianCasinoView);
