import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo (2).png";
import { useAppSelector, useAppDispatch } from "../../../store/storeHooks";
import { getUsername } from "../../../utils/commonUtils";
import star from "../../../assets/star.png";
import { RootState } from "../../../store";
import { fetchNotifications } from "../../../store/auth/authThunk";
import { connect } from "react-redux";
import Modal from "../../Modal/Modal";
import Notifications from "../../Desktop/HeaderSection/Notifications";
import searchIcon from "../../../assets/icons/searchIcon.webp"
type MobHeaderProp = {
  setShowSideBar: any;
  fetchNotifications: Function;
  notifications: [];
};

const MobHeader: React.FC<MobHeaderProp> = (props) => {
  const { notifications, fetchNotifications, setShowSideBar } = props;
  const { loggedIn, balanceSummary } = useAppSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <header>
      {
        loggedIn ? (
          <div className="flex flex-col justify-center px-2.5 py-0.5 w-full text-[14px] tracking-wide text-white bg-stone-900 max-md:max-w-full ">
            <div className="marquee">
              <div className="marquee-content">
                <div className="flex  justify-center items-center ">
                  {notifications.map((item: any, index) => (
                    <React.Fragment key={index}>
                      <span className="mx-4">{item.message}</span>
                      <img
                        src={star}
                        alt=""
                        className="inline-block w-5 h-5 mx-2"
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : null
      }

      <div className="flex px-1  justify-center py-2 w-full bg-fuchsia-800 max-md:max-w-full">
        <div className="flex gap-2 justify-between w-full bg-fuchsia-800  max-md:max-w-full">
          <div className="flex gap-1 items-center">
            {!["/sportsbook", "/dc/gamev1.1"].includes(
              window.location.pathname
            ) ? (
              <div className="flex justify-center items-center p-1 my-auto w-6 h-6 bg-white rounded-[1000px]">
                <img
                  onClick={() => setShowSideBar((prev) => !prev)}
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2cb91377ad084469095b3c73bc0d7598e9dab42e91dbfcf32cfe2d16a2d0b48f?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
                  className="w-4 aspect-square"
                  alt="WinBuzz Gaming logo"
                />
              </div>
            ) : null}

            <Link to="/">
              <img src={logo} className="w-[80px]" />
            </Link>
          </div>
          <div className="flex gap-2 text-[12px] ">
            {loggedIn ? (
              <div className="flex gap-2">
                <div
                  onClick={() => navigate("/deposit")}
                  className="flex items-center font-[500] text-[14px] leading-[16.2px] cursor-pointer border border-white justify-center h-[34px] text-white	rounded-md w-[56px]  bg-[#3C7701] "
                >
                  Deposit
                </div>
                {/* <div
                  onClick={() => navigate("/withdraw")}
                  className="flex items-center font-[500] text-[14px] leading-[16.2px] cursor-pointer border border-white justify-center h-[34px] text-white	rounded-md w-[30px]  bg-[#B40202] "
                >
                  W
                </div> */}
                <div className="flex items-center font-[500] text-[14px] leading-[16.2px] justify-center border border-white h-[34px] text-white	rounded-md w-[60px]   bg-fuchsia-700 ">
                  Bonus: {balanceSummary?.bonus ? balanceSummary?.bonus : 0}
                </div>
                <div className="flex  my-auto text-[14px] font-medium text-center text-white whitespace-nowrap">
                  <div className="flex flex-col justify-center rounded-none text-[12px] font-medium mr-3">
                    <button className="flex w-[100px] h-[36px] items-center gap-6  font-medium bg-white text-white border border-white rounded-md overflow-hidden ">
                      <div
                        className="flex w-[25px] font-[500] text-[11px] leading-[12.2px] z-20 text-black px-1 py-3 flex-col"
                      >
                        <div>Bal:</div>
                        <div>
                          {balanceSummary?.balance
                            ? balanceSummary?.balance
                            : "0"}
                        </div>
                      </div>
                      <div className=" bg-fuchsia-700 font-[500] text-[11px] leading-[12.2px] py-3 w-[50px] pl-[10px] flex-col signup-btn-clip">
                        <div>Exp:</div>
                        <div>
                          {balanceSummary?.exposure
                            ? balanceSummary?.exposure
                            : "0.00"}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center  my-auto text-[14px] font-medium text-center text-white whitespace-nowrap mr-3">
                 {/* <div className="mr-[6px]">
                    <img src={searchIcon}/>
                  </div> */}
                <div className="flex flex-col justify-center rounded-none text-[12px] font-medium">
                 
                  <button className="flex w-full h-[36px] items-center  font-medium bg-white text-white border border-white rounded-md overflow-hidden ">
                    <NavLink to="/login" className={"w-[50%]"}>
                      <div
                        className="flex z-20 text-black px-2 py-3 "
                      >
                        LOGIN
                      </div>
                    </NavLink>
                    <NavLink to="/signup" className={"w-[50%]"}>
                      <div className=" bg-fuchsia-700 py-3 px-2 signup-btn-clip">
                        SIGNUP
                      </div>
                    </NavLink>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        title="Notifications"
        onClose={() => setOpenModal(false)}
      >
        <Notifications />
      </Modal>
    </header>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    notifications: state.auth.notifications,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchNotifications: () => dispatch(fetchNotifications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobHeader);
