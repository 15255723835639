import { createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { PAYMENT_METHODS } from "../../components/Desktop/MyProfile/ProfileTabs/utils/types";
import {
  getPaymentMethods,
  reqAccountDelete,
  reqAddNewAccount,
  reqWithdrawFunds,
} from "../../api/depositAndWithdraw";
import {
  PaymentMethodObjType,
  setLoading,
  setPaymentMethodList,
  unsetLoading,
} from "./withdrawSlice";
import { showToast } from "../common/commonSlice";
import { AxiosError, AxiosResponse } from "axios";
import {
  AddAccountPayloadType,
  AddUpiAccountPayloadType,
  DeleteAccountParamsType,
  WithdrawFundsPayloadType,
} from "../../components/Desktop/Withdraw/utils/types";
import { commonErrorHandler } from "../common/commonUtils";

export const getPaymentProviders = createAsyncThunk(
  "withdraw/getPaymentProviders",
  async (paymentMethod: PAYMENT_METHODS, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { data: list }: AxiosResponse<PaymentMethodObjType[]> =
        await getPaymentMethods(paymentMethod);
      dispatch(setPaymentMethodList(list));
    } catch (err) {
      if (err.response && err.response.data) {
        commonErrorHandler(err, dispatch);
      }
    }

    dispatch(unsetLoading());
  }
);

export const withdrawFunds = createAsyncThunk(
  "withdraw/withdrawAmount",
  async (withdrawFundsPayload: WithdrawFundsPayloadType, { dispatch }) => {
    dispatch(setLoading());

    const { amount, onSuccessCallback } = withdrawFundsPayload;

    if (Number(amount) < 100) {
      dispatch(
        showToast({
          type: "error",
          message: "Withdrawal Amount cannot be less than 100",
        })
      );
      return false;
    }

    try {
      await reqWithdrawFunds(withdrawFundsPayload);
      dispatch(
        showToast({
          message: "Transaction Saved Successfully!",
          options: {
            type: "success",
          },
        })
      );
      onSuccessCallback();
    } catch (err) {
      commonErrorHandler(err, dispatch);
    }

    dispatch(unsetLoading());
  }
);

export const createNewAccount = createAsyncThunk(
  "withdraw/withdrawAmount",

  async (
    addAccountPayload: AddAccountPayloadType | AddUpiAccountPayloadType,
    { dispatch }
  ) => {
    dispatch(setLoading());

    try {
      await reqAddNewAccount(addAccountPayload);
      addAccountPayload.onSuccessCallback();
      dispatch(
        showToast({
          message: "Details Saved Successfully!",
          options: {
            type: "success",
          },
        })
      );
    } catch (err) {
      if (err.response && err.response.data) {
        commonErrorHandler(err, dispatch);
      }
    }

    dispatch(unsetLoading());
  }
);

export const deleteAccount = createAsyncThunk(
  "withdraw/deleteAccount",
  async (deleteAccountPayload: DeleteAccountParamsType, { dispatch }) => {
    dispatch(setLoading());

    try {
      await reqAccountDelete(deleteAccountPayload.paymentMethodId);
      deleteAccountPayload.onSuccessCallback();
      dispatch(
        showToast({
          message: "Account Deleted Successfully!",
          options: {
            type: "success",
          },
        })
      );
    } catch (err) {
      if (err.response && err.response.data) {
        commonErrorHandler(err, dispatch);
      }
    }

    dispatch(unsetLoading());
  }
);
